import type { SortOrder as TableSortOrder } from 'antd/lib/table/interface';

import { PaginationInput, SortOrder } from '../../../graphql/api/generated';
import type { IUseTableDataArgs } from '../typings';

import { formatQueryFilters } from './formatQueryFilters';

export function formatQueryVariables(args: IUseTableDataArgs) {
  const { tableConfig, tableState, tableArgs, formattedColumns } = args;
  const { fixedQueryFilters } = tableConfig;
  const { pageNumber, pageLimit } = tableState;

  // @TODO: Search is not currently supported

  const columnFilters = formatQueryFilters(formattedColumns, tableState.filters);
  const fixedFilters = fixedQueryFilters ? fixedQueryFilters(tableArgs) : {};

  const filters = { ...columnFilters, ...fixedFilters };

  let sortField: string | undefined;
  let tableSortOrder: TableSortOrder | undefined;
  let sortOrder: SortOrder | undefined;

  if (tableState.orderBy) {
    [sortField, tableSortOrder] = Object.entries(tableState.orderBy)[0];

    sortOrder = tableSortOrder === 'ascend' ? SortOrder.asc : SortOrder.desc;
  }

  return {
    // Set distinct pagination variables based on what is needed for each query.
    // If the same GraphQL query is used for both, the Apollo cache will
    // exhibit strange behavior when the same variables are passed.
    totalQueryVariables: {
      input: {
        filters,
        pagination: {
          totalLimit: tableConfig.getTotalLimit(),
        } as PaginationInput,
      }
    },
    listQueryVariables: {
      input: {
        filters,
        pagination: {
          sortField,
          sortOrder,
          pageNumber,
          pageLimit,
        } as PaginationInput,
      },
    },
  };
}
