import { Button, Divider, Form, Input, notification, Select, Spin, Table, Tabs, Typography } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import validate from 'validator';

import EnumTag from '../../../components/EnumTag';
import { apiClient } from '../../../graphql/api/apiClient';
import {
  messageSentFromHubSubscription,
  usemessageSentFromHubSubscription,
  usesendMessageToHubsMutation,
} from '../../../graphql/api/generated';
import {
  HubDeviceType_enum,
  HubUniqueLabelFragment,
} from '../../../graphql/hasura/generated';
import { Color, displayErrorMessage, formatTimestamp } from '../../../utils';
import model from '../model';

const { TabPane } = Tabs;

const formItemLayout = {
  labelCol: {
    sm: { span: 7 },
    xs: { span: 24 },
  },
  wrapperCol: {
    md: { span: 10 },
    sm: { span: 12 },
    xs: { span: 24 },
  },
};

const submitFormLayout = {
  wrapperCol: {
    sm: { span: 10, offset: 7 },
    xs: { span: 24, offset: 0 },
  },
};

// @TODO: Define enums in API(s) for strong typing?
const gamMessageTypes = [
  'ADJUST_READER_POWER',
  'QUERY_READERS_STATUS',
  'READERS_REPORT',
  'SENDING_MANUFACTURING_MESSAGES',
  'TOGGLE_READER_FIRMWARE_KEYSET',
  'TOGGLE_READER_HIGH_FREQUENCY',
  'TOGGLE_READER_LOW_FREQUENCY',
  'TOGGLE_SCANNING_FOR_ALL_READERS',
  'TOGGLE_SCANNING_FOR_NEW_READER',
];

const hubMessageTypes = [
  'FORCE_PULL_REPLICATION_ALL_TABLES',
  'FORCE_PULL_REPLICATION_BY_TABLE',
  'MONITORING_DPS',
  'PING',
  'REBOOT',
  'RESTART_SERVICE',
  ...gamMessageTypes,
];

type HubMessage = messageSentFromHubSubscription['messageSentFromHub'];

interface IHubMessengerProps {
  hub: HubUniqueLabelFragment;
}

interface IReaderMessage {
  readerAddress: number;
  status: number;
  messageId: string;
}

// status 0x00 = Unknown
// status 0x01 = Lost Communication
// status Ox02 = Communicating, Clear Text
// status 0x03 = Communicating, Unlinked/Unencrypted
// status 0x04 = Linked/Encrypted
// status 0x05 = Key Mismatched
const renderReaderStatus = (status: number) => {
  const statusArr = [
    'Unknown',
    'Lost Communication',
    'Clear Text',
    'Unencrypted',
    'Encrypted',
    'Key Mismatched',
  ];

  const statusWord = status <= 0 || status > 5 ?
    statusArr[0] : statusArr[status];

  const colorMap = { [statusWord]: Color.Orange };
  if (['Encrypted', 'Linked'].includes(statusWord)) {
    colorMap[statusWord] = Color.Green;
  } else if (statusWord === 'Key Mismatched') {
    colorMap[statusWord] = Color.Red;
  }

  return (
    <EnumTag enumValue={statusWord} colorMap={colorMap} />
  );
}

const HubMessenger = ({ hub }: IHubMessengerProps) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [hubMessages, setHubMessages] = useState<HubMessage[]>([]);
  const [readerMessages, setReaderMessages] = useState<IReaderMessage[]>([]);
  const [activeTab, setActiveTab] = useState<string>('Hub Messages');

  const { deviceType, hubId } = hub;
  const hubLabel = model.labels.getUniqueLabel(hub);

  const [sendMessageToHubsMutation, { loading }] = usesendMessageToHubsMutation({
    client: apiClient,
  });

  usemessageSentFromHubSubscription({
    client: apiClient,
    variables: {
      input: { hubIds: [hubId] },
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const messageSentFromHub = subscriptionData?.data?.messageSentFromHub;

      if (messageSentFromHub) {
        const isReaderMessage = messageSentFromHub.type === 'READER_COMMUNICATION_STATUS';
        if (isReaderMessage) {
          const { messageId, payload } = messageSentFromHub;
          const newReaderMessage = {
            messageId,
            readerAddress: payload?.readerAddress,
            status: payload?.status,
          };

          const isEdgeReader = deviceType === HubDeviceType_enum.ETHOS_EDGE_READER;

          if (isEdgeReader) {
            // only need to overwrite array since there is only 1 row
            setReaderMessages([newReaderMessage]);
          } else {
            setReaderMessages(prevReaderMessages => {
              const readerMessageIndex = prevReaderMessages
                .findIndex(readerMessage => readerMessage.readerAddress === newReaderMessage.readerAddress);

              // if new reader message can replace old address, update previous array of reader messages
              // else, add the reader message to the previous array
              const updatedMessages = readerMessageIndex >= 0 ?
                prevReaderMessages.map((readerMessage, index) => readerMessageIndex === index ?
                  newReaderMessage : readerMessage) :
                [newReaderMessage, ...prevReaderMessages];
              return updatedMessages.sort((a, b) => a.readerAddress - b.readerAddress);
            });
          }
        } else {
          setHubMessages(prevHubMessages => [messageSentFromHub, ...prevHubMessages]);
        }
      }
    },
  });

  const handleOnFinish = async ({ type, payload }: Store) => {
    try {
      await sendMessageToHubsMutation({
        variables: {
          input: {
            type,
            hubIds: [hubId],
            payload: payload ? JSON.parse(payload) : null,
          },
        },
      });

      notification.success({
        message: 'Success',
        description: 'Message successfully sent to hub!',
      });
    } catch (error) {
      displayErrorMessage(error as Error);
    }
    setSubmitted(false);
  };

  const handleOnFinishFailed = () => {
    setSubmitted(true);
  };

  const isWLDevice = deviceType === HubDeviceType_enum.ETHOS_EDGE_READER ||
    deviceType === HubDeviceType_enum.ETHOS_EDGE_READER_NC ||
    deviceType === HubDeviceType_enum.ETHOS_EDGE_READER_NO ||
    deviceType === HubDeviceType_enum.ETHOS_SECURE_SIDE_DEVICE ||
    deviceType === HubDeviceType_enum.ETHOS_SECURE_SIDE_DEVICE_NC ||
    deviceType === HubDeviceType_enum.ETHOS_SECURE_SIDE_DEVICE_NO;

  return (
    <>
      <Form initialValues={{ type: 'PING' }}
        onFinish={handleOnFinish}
        onFinishFailed={handleOnFinishFailed}
        validateTrigger={submitted ? ['onSubmit', 'onBlur', 'onChange'] : ['onSubmit']}
      >
        <Form.Item {...formItemLayout} label='Hub'>
          {/* @TODO: Allow hub selection */}
          {hubLabel}
        </Form.Item>
        <Form.Item {...formItemLayout} label='Type' name='type'>
          <Select onSelect={messageType =>
            setActiveTab(gamMessageTypes.includes(messageType as string) ? 'Readers' : 'Hub Messages')}>
            {hubMessageTypes.map((messageType) => {
              if (!isWLDevice && gamMessageTypes.includes(messageType as string)) {
                return;
              }

              return (
                <Select.Option key={messageType} value={messageType}>
                  {messageType}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item {...formItemLayout}
          label='Payload'
          name='payload'
          rules={[{
            validator: (_, value) => {
              if (value && !validate.isJSON(value)) {
                return Promise.reject('Must be valid JSON format');
              }

              return Promise.resolve();
            }
          }]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>
        <Form.Item {...submitFormLayout} style={{ textAlign: 'center' }}>
          <Button
            type='primary'
            size='middle'
            htmlType='submit'
            disabled={loading}
          >
            Send Message
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Typography.Paragraph style={{ textAlign: 'center' }}>
        <Spin style={{ marginRight: '10px' }} /> Listening for messages from hub...
      </Typography.Paragraph>
      <Tabs
        activeKey={activeTab}
        defaultActiveKey='Hub Messages'
        onChange={tab => setActiveTab(tab)}>
        <TabPane tab='Hub Messages' key='Hub Messages'>
          <Table
            dataSource={hubMessages}
            pagination={false}
            rowKey={(record) => record.messageId}
            columns={[
              {
                title: 'Type',
                key: 'type',
                dataIndex: 'type',
              },
              {
                title: 'Message',
                key: 'message',
                dataIndex: 'message',
              },
              {
                title: 'Payload',
                key: 'payload',
                dataIndex: 'payload',
                render: payload => isEmpty(payload) ? '' :
                  <SyntaxHighlighter
                    language='json'
                    style={coy}
                    customStyle={{ marginTop: 0, marginBottom: 0 }}
                    wrapLines
                    wrapLongLines
                  >
                    { /* JSON.stringify purges all properties that are undefined...
                  Let's convert undefined to null so we perserve them */ }
                    {JSON.stringify(payload, (_, v) => v === undefined ? null : v, 2)}
                  </SyntaxHighlighter>,
              },
              {
                title: 'Timestamp',
                key: 'timestamp',
                dataIndex: 'timestamp',
                render: (_, { timestamp }) => formatTimestamp(timestamp),
              },
            ]}
          />
        </TabPane>
        {isWLDevice &&
          <TabPane tab='Readers' key='Readers'>
            <Table
              dataSource={readerMessages}
              pagination={false}
              rowKey={(record) => record.messageId}
              columns={[
                {
                  title: 'Reader',
                  key: 'readerAddress',
                  dataIndex: 'readerAddress',
                  render: readerAddress => {
                    return `Reader ${readerAddress}`
                  }
                },
                {
                  title: 'Status',
                  key: 'status',
                  dataIndex: 'status',
                  render: renderReaderStatus,
                },
              ]}
            />
          </TabPane>
        }
      </Tabs>
    </>
  );
};

export default HubMessenger;
