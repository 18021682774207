import {
  GpioMode_enum,
  PermissionKey_enum,
  PermissionScope_enum,
} from '../../graphql/hasura/generated';
import { unlockHubRelays } from '../Hub/helpers';

import model from './model';

export const unlockRelayAction = model.createAction({
  label: () => 'Unlock',
  description: 'Unlocks this relay',
  enabledByModel: ({ hasPermission, currentPermissionScope }) => (
    hasPermission(PermissionKey_enum.Hub_Installation) &&
    currentPermissionScope === PermissionScope_enum.GLOBAL
  ),
  enabledByRow: ({ gpioMode }) => gpioMode !== GpioMode_enum.INPUT,
  confirmation: {
    title: () => 'Unlock Relay',
    content: relay => (
      `Are you sure you want to unlock ${model.labels.getLabel(relay)}?`
    ),
  },
  async executes(relay) {
    const { hub, position } = relay;
    const label = model.labels.getLabel(relay);

    await unlockHubRelays({
      hubId: hub.hubId,
      relayPositions: [position],
      successMessage: `${label} successfully unlocked`,
      unknownMessage: `Unlock request sent to ${label}`,
      errorTitle: `Failed to unlock ${label}`,
    });
  },
});
