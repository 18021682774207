import { enumManagers } from '@chirp/enums';
import { gte } from 'semver';
import validator from 'validator';

import { MIN_VERSION_HOME_ACCESS_AUTH } from '../../config';
import { AuthMethod_enum, SessionType_enum, UserLabelFragment } from '../../graphql/hasura/generated';
import { formatTimestamp } from '../../utils';
import { UserModel } from '../User/model';

import { SessionModel } from './model';

export const idColumn = SessionModel.createIdColumn();

export const userColumn = SessionModel.createColumn<{ user: UserLabelFragment }>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'userId',
    model: UserModel,
  },
  render: ({ user }) => UserModel.routes.renderRowLink(user),
});

// @TODO: Label should not be nullable in the DB
export const typeColumn = SessionModel.createColumn<{ type: SessionType_enum }>({
  title: 'Type',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'SessionType',
  },
  render: ({ type }) => enumManagers.SessionType.getLabel(type),
});

export const authMethodColumn = SessionModel.createColumn<{ authMethod?: AuthMethod_enum | null }>({
  title: 'Authentication Method',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'AuthMethod',
  },
  render: ({ authMethod }) => authMethod ? enumManagers.AuthMethod.getLabel(authMethod) : null,
});

export const deviceInfoColumn = SessionModel.createColumn<{ deviceInfo: any }>({
  title: 'Device Info',
  render: ({ deviceInfo }) => {
    let parsedDeviceInfo: any = {};

    if (typeof deviceInfo === 'object') {
      parsedDeviceInfo = deviceInfo;
    } else if (typeof deviceInfo === 'string' && validator.isJSON(deviceInfo)) {
      parsedDeviceInfo = JSON.parse(deviceInfo);
    } else {
      return deviceInfo;
    }

    // Render Device Info column text as:
    // ${brand} - ${model} (System: ${system} ${systemVersion}, App Version: ${appVersion}), Home Access Auth: ${homeAccessAuth}
    const { brand, model, system, systemVersion, appVersion, homeAccessAuth } = parsedDeviceInfo;

    const bM = brand && model
      ? `${brand} - ${model}`
      : (brand || model) ? `${brand || model}` : ''

    const sSV = system && systemVersion
      ? `System: ${system} ${systemVersion}`
      : (system || systemVersion) ? `System: ${system || systemVersion}` : ''

    const aV = appVersion && sSV
      ? `, App Version: ${appVersion}`
      : appVersion ? `App Version: ${appVersion}` : ''

    const hAA = appVersion && gte(appVersion, MIN_VERSION_HOME_ACCESS_AUTH)
      ? (homeAccessAuth ? `, Home Access Auth: Enabled` : `, Home Access Auth: Disabled`)
      : '';

    return `${bM}${sSV || aV ? ` (${sSV}${aV})` : ''}${hAA}`
  }
});

export const renewedAtColumn = SessionModel.createColumn<{ renewedAt?: string | null }>({
  title: 'Last Renewal',
  dataIndex: 'renewedAt',
  filterOptions: {
    type: 'DATE_RANGE',
    disableFutureDates: true,
  },
  render: ({ renewedAt }) => formatTimestamp(renewedAt),
});

export const expirationDateColumn = SessionModel.createColumn<{ expiresAt: string }>({
  title: 'Next Renewal',
  dataIndex: 'expiresAt',
  filterOptions: {
    type: 'DATE_RANGE',
  },
  render: ({ expiresAt }) => formatTimestamp(expiresAt),
});

export const revokedAtColumn = SessionModel.createColumn<{ revokedAt?: string | null }>({
  dataIndex: 'revokedAt',
  filterOptions: {
    type: 'DATE_RANGE',
    disableFutureDates: true,
  },
  render: ({ revokedAt }) => formatTimestamp(revokedAt),
});

export const revokedByUserColumn = SessionModel.createColumn<{
  revokedByUser?: UserLabelFragment | null,
}>({
  title: 'Revoked By',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'revokedByUserId',
    model: UserModel,
  },
  render: ({ revokedByUser }) => UserModel.routes.renderRowLink(revokedByUser),
});
