import validator from 'validator';

import {
  Credential_bool_exp,
  CredentialRequiredRowFragment,
  CredentialType_enum,
} from '../../graphql/hasura/generated';
import emptySearchConditionsFilter from '../../utils/emptySearchConditionsFilter';
import Model from '../Model';

export const CredentialModel = new Model<
  Credential_bool_exp,
  CredentialRequiredRowFragment,
  CredentialRequiredRowFragment,
  CredentialRequiredRowFragment
>({
  names: {
    schemaName: 'Credential',
  },

  permissionsOptions: {
    canCreate: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canCreate(),
    canUpdate: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canUpdate(),
    canDelete: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canDelete(),
    canDeleteRow: (_, { type }) => type === CredentialType_enum.FOB,
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            {
              ownedByProperty: {
                name: { _ilike: `%${word}%` },
              },
            },
            {
              assignedToUser: {
                firstName: { _ilike: `%${word}%` },
              },
            },
            {
              assignedToUser: {
                lastName: { _ilike: `%${word}%` },
              },
            },
            {
              provisionedByUser: {
                firstName: { _ilike: `%${word}%` },
              },
            },
            {
              provisionedByUser: {
                lastName: { _ilike: `%${word}%` },
              },
            },
            {
              identifier: { _ilike: word },
            },
            {
              facilityCode: { _eq: validator.isNumeric(word) ? +word : undefined },
            },
            {
              cardNumber: { _eq: validator.isNumeric(word) ? +word : undefined },
            },
          ].filter(emptySearchConditionsFilter),
        }
      ))
    }),
  }
});

export default CredentialModel;
