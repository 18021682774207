import { PermissionKey_enum, RoleKey_enum } from '../../graphql/hasura/generated';
import { authentication } from '../../stores';

import model from './model';
import UserDetailsPage from './user-details-page';

model.routes.setRoutes({
  detailsRoute: {
    component: UserDetailsPage,
    enabled: () => true,
  },
  createRoute: {
    path: '/grant-access',
    enabled: ({ hasPermission }) => (
      hasPermission(PermissionKey_enum.User_Create) &&
      !!authentication.grantableRoleScopes.length
    ),
  },
  importRoute: {
    enabled: ({ hasPermission, limitStratisPermissions }) => {
      if (limitStratisPermissions) {
        return false;
      }

      const hasResidentPermissions = (
        hasPermission(PermissionKey_enum.UserRole_GrantRevokeScoped) ||
        authentication.currentRoleRelationships.some(r => (
          r.relatedRole.key === RoleKey_enum.RESIDENT &&
          r.canReadUserRole &&
          r.canGrantUserRole
        ))
      );

      return (hasResidentPermissions && hasPermission(PermissionKey_enum.User_Create));
    },
  },
  editRoute: {
    enabled: () => false,
  },
});
