import {
  RelayActionFragmentDoc,
  RelayTableFragment,
  RelayTableFragmentDoc,
} from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';

import * as actions from './actions';
import * as columns from './columns';
import model from './model';

model.actions.setDefaultActions([
  actions.unlockRelayAction,
], RelayActionFragmentDoc);

const commonColumns = [
  columns.panelColumn,
  columns.positionColumn,
  columns.nameColumn,
  columns.accessPointsColumn,
  columns.failurePlanColumn,
  columns.dmuColumn,
  columns.readerTypeColumn,
  columns.inputDeviceColumn,
  createdAtColumn,
];

export const RelaysMainTable = model.createTable<RelayTableFragment>({
  fragment: RelayTableFragmentDoc,
  columns: [
    columns.propertyColumn,
    columns.hubColumn,
    ...commonColumns,
  ],
});

export const RelaysByHubTable = model.createTable<RelayTableFragment, string>({
  fragment: RelayTableFragmentDoc,
  fixedQueryFilters: (hubId) => ({
    _or: [
      {
        hubId: { _eq: hubId },
      },
      {
        childHubId: { _eq: hubId },
      },
    ],
  }),
  columns: [
    ...commonColumns,
  ],
});

export const RelaysByAccessPointTable = model.createTable<RelayTableFragment, string>({
  fragment: RelayTableFragmentDoc,
  fixedQueryFilters: (accessPointId) => ({
    accessPointRelays: {
      accessPointId: { _eq: accessPointId },
    },
  }),
  columns: [
    columns.hubColumn,
    ...commonColumns,
  ],
});

export const RelaysByPropertyTable = model.createTable<RelayTableFragment, string>({
  fragment: RelayTableFragmentDoc,
  fixedQueryFilters: (propertyId) => ({
    hub: {
      propertyId: { _eq: propertyId },
    },
  }),
  columns: [
    columns.hubColumn,
    ...commonColumns,
  ],
});
