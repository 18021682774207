import { generateListOperation, hasuraClient } from '../../../graphql';
import { generateWordsFromSearch } from '../../../utils';
import type { CreatePaginatedCsvFetcherArgs, PaginatedCsvFetcher } from '../typings';

import { formatListOperationOutput } from './formatOperationOutput';
import { formatOperationVariables } from './formatOperationVariables';

export function createPaginatedCsvFetcher(
  args: CreatePaginatedCsvFetcherArgs,
): PaginatedCsvFetcher {
  const { tableConfig, tableState, formattedColumns, tableArgs } = args;
  const { model, fragment } = tableConfig;

  const listOperation = generateListOperation(model, 'query', fragment);
  const searchWords = tableState.search ? generateWordsFromSearch(tableState.search) : [];

  return async ({ pageNumber, pageLimit }) => {
    const { listOperationVariables } = formatOperationVariables({
      tableConfig,
      formattedColumns,
      searchWords,
      tableArgs,
      tableState: { ...tableState, pageNumber, pageLimit },
    });

    const { data } = await hasuraClient.query({
      query: listOperation,
      variables: listOperationVariables,
    });

    return formatListOperationOutput(model.names.schemaName, data);
  };
}
