import { pickBy } from 'lodash';
import { JsonParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import type { IOnTableChangeArgs, IUseTableStateArgs } from './typings';

export function useTableQueryParams(args: IUseTableStateArgs) {
  const { defaultPageSize, defaultColumnFilters } = args;
  const defaultOrderBy = args.defaultOrderBy as any || { createdAt: 'descend' };

  const [query, setQuery] = useQueryParams({
    pageNumber: withDefault(NumberParam, 1),
    pageLimit: withDefault(NumberParam, defaultPageSize || 25),
    search: StringParam,
    filters: withDefault(JsonParam, defaultColumnFilters as any || {}),
    orderBy: withDefault(JsonParam, defaultOrderBy),
  });

  const onTableChange = ({ pagination, filters, sorter }: IOnTableChangeArgs) => {
    let orderBy = null;

    try {
      if (sorter && sorter.hasOwnProperty('field') && !Array.isArray(sorter)) {
        const { field, order } = sorter;

        if (field && !Array.isArray(field)) {
          const [defaultField] = Object.entries(defaultOrderBy)[0];

          if (field === defaultField) {
            orderBy = { [field]: !order || order === 'ascend' ? 'ascend' : 'descend' };
          } else if (order) {
            orderBy = { [field]: order };
          }
        }
      }
    } catch {
      // Fail silently if bad parameters are provided
    }

    setQuery({
      orderBy,
      search: query.search,
      pageNumber: pagination?.current || 1,
      pageLimit: pagination?.pageSize || 25,
      filters: pickBy(filters || {}, v => v !== null && v !== undefined),
    });
  };

  const onSearchChange = (value: string) => {
    setQuery({ search: value, pageNumber: 1 });
  };

  return { onTableChange, onSearchChange, tableState: query };
}
