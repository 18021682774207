import {
  AccessPointUserRolesTableFragment,
  AccessPointUserRolesTableFragmentDoc,
  PermissionKey_enum,
  PermissionScope_enum,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { createdAtColumn } from '../../common/columns';
import PERMISSION_SCOPE_ICON_MAP from '../../helpers/permission-scopes';
import * as columns from '../columns';
import model from '../model';

import { commonTableConfig } from './common';

export const isAccessPointScopeReadable = () => (
  authentication.hasPermission(PermissionKey_enum.User_Read) ||
  authentication.readableRoleScopes.includes(PermissionScope_enum.ACCESS_POINT)
);

export const AccessPointUserRolesTable = model.createTable<AccessPointUserRolesTableFragment>({
  ...commonTableConfig,
  title: 'Access Point Roles',
  enabled: isAccessPointScopeReadable,
  icon: PERMISSION_SCOPE_ICON_MAP[PermissionScope_enum.ACCESS_POINT],
  fragment: AccessPointUserRolesTableFragmentDoc,
  fixedQueryFilters: () => ({
    scopedAccessPointId: { _is_null: false },
  }),
  columns: [
    columns.statusColumn,
    columns.accessPointRoleColumn,
    columns.managedWithinPropertyColumn,
    columns.accessPointColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});

export const AccessPointUserRolesByAccessPointTable = model.createTable<
  AccessPointUserRolesTableFragment, string
>({
  ...commonTableConfig,
  title: 'Assigned Roles',
  enabled: isAccessPointScopeReadable,
  fragment: AccessPointUserRolesTableFragmentDoc,
  fixedQueryFilters: (accessPointId) => ({
    scopedAccessPointId: { _eq: accessPointId },
    status: {
      _in: [UserRoleStatus_enum.ACTIVE, UserRoleStatus_enum.PENDING],
    },
  }),
  columns: [
    columns.statusColumn,
    columns.accessPointRoleColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});

export const AccessPointUserRolesByUserTable = model.createTable<
  AccessPointUserRolesTableFragment, string
>({
  ...commonTableConfig,
  title: 'Access Point Roles',
  enabled: isAccessPointScopeReadable,
  fragment: AccessPointUserRolesTableFragmentDoc,
  fixedQueryFilters: (assignedToUserId) => ({
    assignedToUserId: { _eq: assignedToUserId },
    scopedAccessPointId: { _is_null: false },
    status: {
      _in: [UserRoleStatus_enum.ACTIVE, UserRoleStatus_enum.PENDING],
    },
  }),
  columns: [
    columns.statusColumn,
    columns.accessPointRoleColumn,
    columns.managedWithinPropertyColumn,
    columns.accessPointColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});
