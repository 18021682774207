import { AccessPointType_enum, PermissionScope_enum } from '../../../../graphql/hasura/generated';

import { IScopeQueriesResult, IUseGrantableRolesArgs, IWarningFlags } from './typings';

export function getWarningFlags(
  args: IUseGrantableRolesArgs,
  scopeQueriesResult: IScopeQueriesResult,
): IWarningFlags {
  const { permissionScope, scopedIds } = args;
  const { unitsAggregate, accessPoints } = scopeQueriesResult;

  const controlledByPms = permissionScope === PermissionScope_enum.GLOBAL
    ? false // Prevent previous unit data from conflicting with global scope
    : (unitsAggregate.aggregate?.count || 0) > 0;

  const totalAmenities = accessPoints.filter(a => a.type === AccessPointType_enum.AMENITY).length;

  const allAmenities = (
    permissionScope === PermissionScope_enum.ACCESS_POINT &&
    totalAmenities === scopedIds.length
  );

  const mixedAmenities = (
    permissionScope === PermissionScope_enum.ACCESS_POINT &&
    totalAmenities > 0 &&
    totalAmenities < scopedIds.length
  );

  const someReservationOnly = (
    !!accessPoints.length &&
    accessPoints.some(a => a.type === AccessPointType_enum.RESERVATION_ONLY)
  );

  return {
    controlledByPms,
    allAmenities,
    mixedAmenities,
    someReservationOnly,
  };
}
