import validator from 'validator';

import {
  order_by,
  PermissionScope_enum,
  Unit_bool_exp,
  Unit_order_by,
  UnitLabelFragment,
  UnitLabelFragmentDoc,
  UnitUniqueLabelFragment,
  UnitUniqueLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import { emptySearchConditionsFilter, formatUnitDisplayName } from '../../utils';
import Model from '../Model';

const BUILDING_WORDS = ['bldg.', 'bldg', 'bldng.', 'bldng', 'building'];
const BUILDING_SEARCH_WORD_FLAG = 'Bldg. ';

export const UnitModel = new Model<
  Unit_bool_exp,
  UnitLabelFragment,
  UnitUniqueLabelFragment,
  UnitLabelFragment,
  Unit_order_by
>({
  names: {
    schemaName: 'Unit',
  },

  permissionsOptions: {
    canCreate: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canCreate(),
    canUpdate: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canUpdate(),
    canDelete: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canDelete(),
    canDeleteRow: (_, { sourceId }) => sourceId === null,
  },

  labels: {
    getLabel: (u) => formatUnitDisplayName(u),
    getUniqueLabel: (u) => (
      `${u.property.name} - ${formatUnitDisplayName(u, true)}`
    ),
    labelFragment: UnitLabelFragmentDoc,
    uniqueLabelFragment: UnitUniqueLabelFragmentDoc,
  },

  queryOptions: {
    getTableCountLimit: () => {
      const permissionScope = authentication.currentDataScope.permissionScope;

      if (
        permissionScope === PermissionScope_enum.GLOBAL ||
        permissionScope === PermissionScope_enum.ORGANIZATION
      ) {
        return 100000; // Allow Camden to view/export all of their units
      }

      return 10000;
    },
    getSearchConditions: (words) => {
      const newWords = words.reduce((accWords, currentValue, index) => {
        if (BUILDING_WORDS.includes(currentValue.toLowerCase())) {
          const nextWord = accWords[index + 1];

          if (nextWord) {
            // Flag next word as building search word
            accWords[index + 1] = `${BUILDING_SEARCH_WORD_FLAG}${nextWord}`
          }

          // Remove building from search words
          accWords.splice(index, 1);
        }

        return accWords;
      }, words);

      return {
        _and: newWords.map((word) => {
          const isBuildingSearchWord = word.startsWith(BUILDING_SEARCH_WORD_FLAG);
          const newWord = isBuildingSearchWord ? word.replace(BUILDING_SEARCH_WORD_FLAG, '') : word;

          if (isBuildingSearchWord) {
            return {
              _or: [
                {
                  building: { _ilike: `${newWord}%` },
                },
              ],
            };
          }

          const isNumeric = validator.isNumeric(newWord);

          return {
            _or: [
              {
                property: {
                  name: { _ilike: `%${newWord}%` },
                },
              },
              {
                unitNumber: { _ilike: !isNumeric ? `%${newWord}%` : undefined }, // Contains word
              },
              {
                unitNumber: { _ilike: isNumeric ? `${newWord}%` : undefined }, // Starts with number
              },
              {
                unitNumber: { _ilike: isNumeric ? `% ${newWord}` : undefined }, // Ends with space + number (e.g., "Unit 1")
              },
              {
                building: { _eq: newWord }, // Exact match
              },
            ].filter(emptySearchConditionsFilter),
          };
        })
      };
    },

    defaultSelectOrderBy: [
      {
        property: {
          name: order_by.asc,
        },
      },
      {
        unitNumber: order_by.asc,
      },
      {
        building: order_by.asc,
      },
    ],
  },
});

export default UnitModel;
