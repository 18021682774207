import React from 'react';

import { TableCellDescriptions } from '../../../components/TableCellDescriptions';
import { AugustLockReportFragment as AugustLock } from '../../../graphql/api/generated';

export function getAugustPinCodeDescriptions(augustLock: AugustLock | null | undefined) {
  const getPinCodeCount = (status: string) => (
    augustLock?.pinCodes?.filter(p => p.status === status).length || 0
  );

  return {
    Created: getPinCodeCount('created'),
    Loaded: getPinCodeCount('loaded'),
    Expired: getPinCodeCount('deleting'),
  };
}

export const AugustPinCodeDescriptions: React.FC<{ augustLock: AugustLock | null | undefined }> = (props) => {
  const descriptions = getAugustPinCodeDescriptions(props.augustLock);

  return <TableCellDescriptions descriptions={descriptions} />;
};
