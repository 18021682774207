import { notification } from 'antd';
import React, { useContext } from 'react';

import RefreshButton from '../../../components/RefreshButton';
import {
  AugustRemoteOperation,
  useremoteOperateAugustLockMutation,
} from '../../../graphql/hasura/generated';
import { displayErrorMessage, RefetchContext } from '../../../utils';

export const RefreshAugustLockStatuses: React.FC<{ augustLockId: string }> = (props) => {
  const [refreshAugustLockStatuses] = useremoteOperateAugustLockMutation({
    variables: {
      input: {
        augustLockId: props.augustLockId,
        operation: AugustRemoteOperation.status,
      },
    },
  });

  const refetch = useContext(RefetchContext);

  return (
    <RefreshButton
      disabled={false}
      refresh={async () => {
        let remoteOperateError = null;

        try {
          await refreshAugustLockStatuses();
        } catch (error) {
          remoteOperateError = error;
        }

        // Get updated Wi-Fi Bridge Status and Lock Status
        // (Could still have updates even if the call above fails)
        await refetch();

        if (remoteOperateError) {
          displayErrorMessage(remoteOperateError as Error);
        } else {
          notification.success({
            message: 'Success',
            description: 'The statuses were successfully refreshed!',
          });
        }
      }}
      buttonText='Refresh Statuses'
      buttonStyle={{ float: 'left', marginTop: 15 }}
    />
  )
};
