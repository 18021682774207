import { Avatar, Modal } from 'antd';
import React from 'react';

import { ImageThumbnailFragment as Image } from '../../graphql/hasura/generated';
import { getImageUrl } from '../../utils';

interface IImageThumbnailProps {
  image?: Image | null;
  title: string;
}

const ImageThumbnail: React.FC<IImageThumbnailProps> = (props) => {
  const { image, title } = props;

  if (image && image.cloudId) {
    const thumbnailUrl = getImageUrl(image.cloudId, { height: 50, crop: 'fill' });
    const fullResUrl = getImageUrl(image.cloudId);

    return (
      <a
        onClick={() => {
          Modal.info({
            title,
            icon: null,
            maskClosable: true,
            okButtonProps: {
              style: { display: 'none' },
            },
            okCancel: true,
            cancelText: 'Close',
            width: '750px',
            content: (
              <div style={{ textAlign: 'center' }}>
                <img src={fullResUrl} style={{ maxWidth: '100%' }} />
              </div>
            ),
          });
        }}
      >
        <Avatar src={thumbnailUrl} shape='square' />
      </a>
    );
  }

  return null;
}

export default ImageThumbnail;
