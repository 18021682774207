import { notification } from 'antd';

import {
  PermissionKey_enum,
  revokeSessionDocument,
  revokeSessionMutation,
  revokeSessionMutationVariables,
} from '../../graphql/hasura/generated';

import model from './model';

export const revokeAction = model.createAction({
  label: () => 'Revoke',
  description: `Revokes this user's session`,
  enabledByModel: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.Session_Revoke)
  ),
  enabledByRow: ({ revokedAt }) => !revokedAt,
  confirmation: {
    title: () => 'Revoke Session',
    content: () => `Are you sure you want to revoke this user's session?`,
  },
  async executes({ sessionId }, { hasuraClient, refetchContext }) {
    const { data } = await hasuraClient
      .mutate<revokeSessionMutation, revokeSessionMutationVariables>({
        mutation: revokeSessionDocument,
        variables: {
          sessionId,
        },
      });

    if (!data?.custom_revokeSession.session) {
      throw new Error(`Unable to revoke user's session`);
    }

    await refetchContext();

    notification.success({
      message: 'Success',
      description: `The user's session was successfully revoked!`,
    });
  },
});
