import { Card } from 'antd';
import { withFormik } from 'formik';
import React from 'react';

import { RoleKey_enum, usegetGrantableRolesQuery } from '../../../graphql/hasura/generated';
import { displayErrorMessage } from '../../../utils';
import { AdditionalContextSection } from '../shared-form/AdditionalContextSection';
import { DurationOfAccessSection } from '../shared-form/DurationOfAccessSection';
import { SubmitFormSection } from '../shared-form/SubmitFormSection';

import { ExistingUserSection } from './ExistingUserSection';
import {
  handleSubmit,
  IRegisteredUserFormProps,
  IRegisteredUserFormValues,
  mapPropsToValues,
  validate,
} from './formik';
import { NewUserSection } from './NewUserSection';
import { SelectPermissionScope } from './SelectPermissionScope';
import { SelectRoleSection } from './SelectRoleSection';
import { SelectScopeIds } from './SelectScopeIds';
import type { PresetFormValues } from './usePresetFormValues';

const RegisteredUserFormik = withFormik<IRegisteredUserFormProps, IRegisteredUserFormValues>({
  mapPropsToValues,
  validate,
  handleSubmit,
  enableReinitialize: false,
  validateOnChange: true,
})((props) => {
  const { newUser, loading } = props;

  return (
    <>
      <Card bordered={false} style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        {newUser && <NewUserSection {...props} />}
        {!newUser && <ExistingUserSection {...props} />}
      </Card>
      <Card loading={loading} bordered={false} style={{ marginTop: 25, marginBottom: 75 }}>
        <SelectPermissionScope {...props} />
        <SelectScopeIds {...props} />
        <SelectRoleSection {...props} />
        <DurationOfAccessSection {...props} />
        <AdditionalContextSection {...props} />
        <SubmitFormSection {...props} />
      </Card>
    </>
  );
});

type RegisteredUserFormParentProps = PresetFormValues &
  Pick<IRegisteredUserFormProps, 'newUser' | 'UserModel' |'UserRoleModel'>;

export const RegisteredUserForm: React.FC<RegisteredUserFormParentProps> = (props) => {
  const { presetRoleKey } = props;

  const { data: rolesData, loading: rolesLoading } = usegetGrantableRolesQuery({
    skip: !presetRoleKey || !Object.values(RoleKey_enum).includes(presetRoleKey as RoleKey_enum),
    variables: {
      where: {
        key: { _eq: presetRoleKey as RoleKey_enum },
      },
    },
    onError: (error) => displayErrorMessage(error),
  });

  const presetRole = rolesData?.roles ? rolesData.roles[0] : null;

  return (
    <RegisteredUserFormik
      {...props}
      loading={rolesLoading}
      presetRole={presetRole}
    />
  );
};
