import { WarningOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';

const { Paragraph } = Typography;

interface IAppErrorProps {
  errorMessage?: string;
}

const AppError = (props: IAppErrorProps) => (
  <div className='app error'>
    <WarningOutlined style={{ fontSize: '4em', marginBottom: '10px' }} />
    <Paragraph>{props.errorMessage || 'An unexpected error has occurred'}</Paragraph>
  </div>
);

export default AppError;
