import { red } from '@ant-design/colors';
import { Form } from '@ant-design/compatible';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import { FormikProps } from 'formik';
import React, { PureComponent, useContext } from 'react';
import { Prompt } from 'react-router-dom';

import { apiClient, generateReadQuery, hasuraClient } from '../../graphql';
import {
  uploadImageDocument,
  uploadImageMutation,
  uploadImageMutationVariables,
} from '../../graphql/api/generated';
import { ModelsContext } from '../../hooks/providers';
import { IModel } from '../../models/typings';
import { displayErrorMessage, getImageUrl } from '../../utils';

const FormItem = Form.Item;


type Image = uploadImageMutation['uploadImage'];

interface IImageFieldProps {
  error?: string;
  label?: string;
  keyProp?: string;
  required?: boolean;
  value?: string | null;
  model: IModel;
  submitCount: FormikProps<{}>['submitCount'];
  dirty: FormikProps<{}>['dirty'];
  fieldName: string;
  onChange: (value: string | null) => void | Promise<void>;
  disabled?: boolean;
}

interface IImageFieldState {
  image: Image | null;
  value: string | null;
  fileList: any;
  handledErrorType: 'FILE_TYPE' | 'FILE_SIZE' | null;
}

const formLayout = {
  labelCol: {
    sm: { span: 7 },
    xs: { span: 24 },
  },
  wrapperCol: {
    md: { span: 10 },
    sm: { span: 12 },
    xs: { span: 24 },
  },
};

const extraListStyle = {
  marginBottom: '0px',
  paddingInlineStart: '1rem',
};

const extraListItemStyle = {
  fontSize: '12px',
  transitionProperty: 'color',
  transitionDuration: '.3s',
};

const acceptedFileTypes = [
  '.png',
  '.jpg',
  '.jpeg',
];

class ImageField extends PureComponent<IImageFieldProps, IImageFieldState> {

  state: IImageFieldState = {
    fileList: [],
    image: null,
    value: null,
    handledErrorType: null,
  };

  async componentDidMount() {
    await this.loadImage();
  }

  async componentDidUpdate() {
   const submitted = this.props.submitCount > 0;

    if (!submitted && this.props.dirty) {
      window.onbeforeunload = (e: { returnValue: string | boolean; }) => {
        const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';

        e.returnValue = confirmationMessage;
        return confirmationMessage;
      };
    } else {
      window.onbeforeunload = null;
    }
  }

  async componentWillUnmount() {
    window.onbeforeunload = null;
  }

  get isControlledComponent() {
    const { onChange } = this.props;
    return typeof onChange === 'function';
  }

  get imageId(): string | null {
    return (this.isControlledComponent ? this.props.value : this.state.value) || null;
  }

  async loadImage() {
    const { image } = this.state;
    const imageQuery = generateReadQuery(this.props.model);

    if (!imageQuery) {
      return false;
    }

    if (this.imageId && !image) {
      const { data } = await hasuraClient.query({
        query: imageQuery,
        variables: { imageId: this.imageId },
      });

      const imageData: Image | null = data.Image_by_pk;

      if (imageData) {
        this.setState({
          image: imageData,
          fileList: [{
            uid: imageData.imageId,
            url: getImageUrl(imageData.cloudId),
          }],
        });
      }
    }
  }

  onChange = async (info: any) => {
    let fileList = info.fileList;
    fileList = fileList.slice(-1);

    this.setState({ fileList });
  }

  handleRequest = async ({ file, onError }: RcCustomRequestOptions) => {
    try {
      const { data } = await apiClient.mutate<uploadImageMutation, uploadImageMutationVariables>({
        mutation: uploadImageDocument,
        variables: { file },
      });

      const uploadedImage = data?.uploadImage;

      if (!uploadedImage) {
        throw new Error('Failed to upload image');
      }

      this.setState({
        image: uploadedImage,
        value: uploadedImage.imageId,
        handledErrorType: null,
        fileList: [{
          uid: uploadedImage.imageId,
          url: getImageUrl(uploadedImage.cloudId),
        }],
      }, () => {
        this.props.onChange(uploadedImage.imageId);
      });
    } catch (error) {
      if (error && error.message) {
        if (error.message.includes('file type')) {
          this.setState({ handledErrorType: 'FILE_TYPE' });
        } else if (error.message.includes('file size')) {
          this.setState({ handledErrorType: 'FILE_SIZE' });
        }
      }
      displayErrorMessage(error);
      onError(error);
    }
  }

  render() {
    const { handledErrorType } = this.state;
    const submitted = this.props.submitCount > 0;

    return (
      <FormItem
        {...formLayout}
        validateStatus={this.props.error ? 'error' : undefined}
        help={this.props.error}
        label={this.props.label}
        key={this.props.keyProp}
        required={this.props.required}
        extra={(
          <ul style={extraListStyle}>
            <li style={{ ...extraListItemStyle, color: handledErrorType === 'FILE_TYPE' ? red[5] : undefined }}>
              {`Supported file types: ${acceptedFileTypes
                .join(', ').replace(/\./g, '').toLocaleUpperCase().replace(/, ([^,]*)$/, ', and $1')}`}
            </li>
            <li style={{ ...extraListItemStyle, color: handledErrorType === 'FILE_SIZE' ? red[5] : undefined }}>
              Maximum file size: 10MB
            </li>
            <li style={extraListItemStyle}>Image changes will not be saved until you click "Save" below</li>
          </ul>
        )}
      >
        <Prompt
          // @TODO: Currently when navigating backward/forward, the state of the form is lost:
          // https://github.com/ReactTraining/react-router/issues/5405#issuecomment-596924433
          // https://github.com/ReactTraining/react-router/issues/5405#issuecomment-682730905
          // However, this solution works when attempting to navigate to another link in the admin panel.
          when={!submitted && this.props.dirty}
          message='You have unsaved changes. Are you sure you want to leave?'
        />
        <Upload
          multiple={false}
          listType='picture-card'
          fileList={this.state.fileList}
          onChange={this.onChange}
          onRemove={() => {
            this.props.onChange(null);
          }}
          customRequest={this.handleRequest}
          accept={acceptedFileTypes.join(',')}
          disabled={this.props.disabled}
        >
          <Button disabled={this.props.disabled}>
            <UploadOutlined />Upload
          </Button>
        </Upload>
      </FormItem>
    );
  }
}

const ImageFieldWithModel: React.FC<Omit<IImageFieldProps, 'model'>> = (props) => {
  const { getModel } = useContext(ModelsContext);
  const ImageModel = getModel('Image');

  return (
    <ImageField {...props} model={ImageModel} />
  );
};

export default ImageFieldWithModel;
