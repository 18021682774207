import { Button, Modal, Spin, Table, Typography } from 'antd';
import React, { useState } from 'react';

import { apiClient } from '../../../graphql/api/apiClient';
import {
  UserIdentifierType,
  useverificationCodesSubscription,
  VerificationCode,
} from '../../../graphql/api/generated';
import { formatPhoneNumber, formatTimestamp } from '../../../utils';

interface IVerificationCodesProps {
  userId: string;
}

export const VerificationCodes: React.FC<IVerificationCodesProps> = ({ userId }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [verificationCodes, setVerificationCodes] = useState<VerificationCode[]>([]);

  useverificationCodesSubscription({
    client: apiClient,
    variables: { userId },
    skip: !visible,
    onSubscriptionData: ({ subscriptionData }) => {
      const verificationCode = subscriptionData.data?.verificationCodes;

      if (verificationCode) {
        const index = verificationCodes.findIndex(
          (v) => v.verificationCodeId === verificationCode?.verificationCodeId
        );

        const nextVerificationCodes = [...verificationCodes];

        if (index !== -1) {
          // Update verification code
          nextVerificationCodes[index] = verificationCode;
        } else {
          // Add new verification code to the top of the list
          nextVerificationCodes.unshift(verificationCode);
        }

        setVerificationCodes(nextVerificationCodes);
      }
    },
  });

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  return (
    <>
      <Button
        onClick={openModal}
        style={{ margin: '5px', float: 'left' }}
      >
        Verification Codes
      </Button>
      <Modal
        title='Verification Codes'
        visible={visible}
        onCancel={closeModal}
        width='60%'
        footer={null}
        destroyOnClose
      >
        {visible && (
          <>
            <Typography.Paragraph style={{ textAlign: 'center' }}>
              <Spin style={{ marginRight: '10px' }} /> Waiting for verification codes...
            </Typography.Paragraph>
            <Table
              dataSource={verificationCodes}
              pagination={false}
              rowKey={(record) => record.verificationCodeId}
              columns={[
                {
                  title: 'Identifier',
                  render: (_, { userIdentifier }) => {
                    if (userIdentifier.type === UserIdentifierType.PHONE_NUMBER) {
                      return formatPhoneNumber(userIdentifier.value);
                    }

                    return userIdentifier.value;
                  },
                },
                {
                  title: 'Created',
                  dataIndex: 'createdAt',
                  render: (_, { createdAt }) => formatTimestamp(createdAt),
                },
                {
                  title: 'Expiration Time',
                  dataIndex: 'expiresAt',
                  render: (_, { expiresAt }) => formatTimestamp(expiresAt),
                },
                {
                  title: 'Attempts',
                  dataIndex: 'attempts',
                  render: (_, { attempts }) => {
                    if (attempts >= 3) {
                      return <span style={{ color: '#ff4d4f' }}>{attempts}</span>;
                    }

                    return attempts;
                  },
                },
              ]}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default VerificationCodes;
