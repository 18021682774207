import { Typography } from 'antd';
import numeral from 'numeral';
import React, { useState } from 'react';

import { PaginatedCsvExportButton } from '../components/CsvExport/PaginatedCsvExportButton';
import PaginatedTable from '../components/PaginatedTable';
import RefreshButton from '../components/RefreshButton';
import { ITab } from '../components/TabsManager';
import { IModelTable } from '../models/typings';

import { TableManager, useTableManager } from './useTableManager';

interface IUseTabTableOutput {
  tab: ITab | null;
  tableManager: TableManager | null;
}

export function useTabTable<TRowData = any, TBoolExp = any, TArgs = any>(
  tableConfig: IModelTable<TRowData, TBoolExp, TArgs>,
  tableArgs: TArgs,
): IUseTabTableOutput {
  const [touchedTab, setTouchedTab] = useState(false);

  if (!tableConfig.enabled(tableArgs)) {
    return { tab: null, tableManager: null };
  }

  const tableManager = useTableManager({
    tableConfig,
    tableArgs,
    shouldSkipList: !tableConfig.eagerLoading && !touchedTab,
    disableColumnFilters: true,
  });

  const { tableData, tableState, hasFiltersApplied } = tableManager;
  const dynamicTabTitle = !tableData.totalLoading
    ? `${tableConfig.title} (${numeral(tableData.total).format('1,000')})`
    : tableConfig.title;

  const tabEmpty = !tableData.totalLoading && tableData.total === 0;

  return {
    tableManager,
    tab: {
      key: tableConfig.title,
      title: dynamicTabTitle,
      loading: tableData.totalLoading,
      empty: tabEmpty && !tableState.search && !hasFiltersApplied,
      onTouchedTab: () => {
        if (!touchedTab) {
          setTouchedTab(true);
        }
      },
      content: () => (
        <>
          <div style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginBottom: 15,
          }}>
            <RefreshButton
              disabled={tableData.listLoading || tableData.totalLoading}
              refresh={tableData.refetch}
              useSubscription={!!tableConfig.useSubscription}
            />
            <PaginatedCsvExportButton tableManager={tableManager} />
          </div>
          {tableConfig.description && (
            <Typography.Paragraph style={{ paddingBottom: 5 }}>
              {tableConfig.description()}
            </Typography.Paragraph>
          )}
          <PaginatedTable tableManager={tableManager} />
        </>
      ),
    },
  };
}

export default useTabTable;
