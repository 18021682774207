import { PermissionScope_enum } from '../../graphql/hasura/generated';

import model from './model';

model.routes.setRoutes({
  createRoute: {
    enabled: ({ canCreate, currentPermissionScope }) => (
      canCreate() && currentPermissionScope === PermissionScope_enum.GLOBAL
    ),
  },

  editRoute: {
    enabled: ({ canUpdate, currentPermissionScope }) => (
      canUpdate() && currentPermissionScope === PermissionScope_enum.GLOBAL
    ),
  },
});
