import { Table } from 'antd';
import React from 'react';

import { apiClient } from '../../../graphql';
import { SmartLockLogTableFragment, usegetSmartLockLogsQuery } from '../../../graphql/api/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import normalizeColumns from '../../helpers/normalizeColumns';
import { SmartLockLogsMainTable } from '../tables';

const SmartLockLogDetailsLayout = createDetailsLayout<SmartLockLogTableFragment>();

export const SmartLockLogDetailsPage: React.FC<any> = () => {
  return (
    <SmartLockLogDetailsLayout
      modelName='SmartLockLog'
      useQuery={(smartLockLogId) => {
        const { data, ...queryResult } = usegetSmartLockLogsQuery({
          variables: {
            input: {
              filters: { smartLockLogId },
            },
          },
          client: apiClient,
        });

        return {
          ...queryResult,
          data: data ? { smartLockLog: data.smartLockLogsPaginated.list[0] } : data,
        };
      }}
      body={(smartLockLog) => {
        const columns = normalizeColumns(SmartLockLogsMainTable.columns);

        return (
          <Table
            columns={columns}
            dataSource={[smartLockLog]}
            rowKey={u => u.smartLockLogId}
            pagination={false}
            expandable={SmartLockLogsMainTable.expandable}
          />
        );
      }}
    />
  );
};

export default SmartLockLogDetailsPage;
