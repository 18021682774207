import { LockFilled, SafetyCertificateFilled, WifiOutlined } from '@ant-design/icons';
import { enumManagers } from '@chirp/enums';
import { Tooltip } from 'antd';
import { sortBy } from 'lodash';
import React from 'react';

import EnumTag from '../../components/EnumTag';
import {
  AccessPointLabelFragment,
  FailurePlan_enum,
  GpioMode_enum,
  HubLabelFragment,
  InputDevice_enum,
  PermissionScope_enum,
  PropertyLabelFragment,
  ReaderType_enum,
} from '../../graphql/hasura/generated';
import { Color, formatEnumValue } from '../../utils';
import { AccessPointModel } from '../AccessPoint/model';
import { HubModel } from '../Hub/model';
import { PropertyModel } from '../Property/model';

import model from './model';

export const propertyColumn = model.createColumn<
  { hub: { property?: PropertyLabelFragment | null } }
>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'hub.propertyId',
    model: PropertyModel,
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ hub }) => PropertyModel.routes.renderRowLink(hub.property),
});

export const hubColumn = model.createColumn<{ hub: HubLabelFragment }>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'hubId',
    model: HubModel,
  },
  render: ({ hub }) => HubModel.routes.renderRowLink(hub),
});

export const gpioModeColumn = model.createColumn<{ gpioMode: GpioMode_enum }>({
  title: 'GPIO Mode',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'GpioMode',
  },
  render: ({ gpioMode }) => enumManagers.GpioMode.getLabel(gpioMode),
});

export const inputDeviceColumn = model.createColumn<{ inputDevice?: InputDevice_enum | null }>({
  title: 'Input Device',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'InputDevice',
  },
  render: ({ inputDevice }) => inputDevice ? enumManagers.InputDevice.getLabel(inputDevice) : null,
});

export const panelColumn = model.createColumn<{ position: number, gpioMode: GpioMode_enum }>({
  title: 'Panel',
  sorter: false,
  render: ({ gpioMode, position }) => {
    if (gpioMode === GpioMode_enum.INPUT) {
      return null;
    }

    return position > 8 ? 'Left' : 'Right'
  },
});

export const positionColumn = model.createColumn<{ position: number, gpioMode: GpioMode_enum }>({
  title: 'Position',
  render: ({ gpioMode, position }) => {
    if (gpioMode === GpioMode_enum.INPUT) {
      return position;
    }

    return position > 8 ? position - 8 : position;
  },
});

export const nameColumn = model.createColumn<{ name: string }>({
  dataIndex: 'name',
});

export const dmuColumn = model.createColumn<{ childHub?: HubLabelFragment | null }>({
  title: 'DMU',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'childHubId',
    model: HubModel,
  },
  render: ({ childHub }) => HubModel.routes.renderRowLink(childHub),
});

export const accessPointsColumn = model.createColumn<{
  accessPointRelays: { accessPoint: AccessPointLabelFragment }[]
}>({
  title: 'Access Points',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'accessPointRelays.accessPointId',
    model: AccessPointModel,
  },
  render: ({ accessPointRelays }) => {
    const sortedAccessPointRelays = sortBy(accessPointRelays, a => a.accessPoint.name);
    const accessPoints = sortedAccessPointRelays.map(a => a.accessPoint);

    return AccessPointModel.routes.renderRowLinks(accessPoints);
  },
  renderString: ({ accessPointRelays }) => {
    const sortedAccessPointRelays = sortBy(accessPointRelays, a => a.accessPoint.name);
    const accessPoints = sortedAccessPointRelays.map(a => a.accessPoint);

    return accessPoints.map(a => AccessPointModel.labels.getLabel(a)).join(', ');
  },
});

export const failurePlanColumn = model.createColumn<{
  gpioMode?: GpioMode_enum;
  failurePlan: FailurePlan_enum;
  unlockOnInternetFailure?: boolean | null;
}>({
  title: 'Failure Plan',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'FailurePlan',
  },
  render: ({ gpioMode, failurePlan, unlockOnInternetFailure }) => {
    if (gpioMode === GpioMode_enum.INPUT) {
      return 'N/A';
    }

    if (!failurePlan) {
      return null;
    }

    return (
      <div style={{ whiteSpace: 'nowrap' }}>
        <EnumTag
          enumValue={failurePlan}
          colorMap={{
            [FailurePlan_enum.FAIL_SAFE]: Color.Green,
            [FailurePlan_enum.FAIL_SECURE]: Color.Orange,
          }}
          iconMap={{
            [FailurePlan_enum.FAIL_SAFE]: <SafetyCertificateFilled />,
            [FailurePlan_enum.FAIL_SECURE]: <LockFilled />,
          }}
        />
        {!!unlockOnInternetFailure && (
          <Tooltip title='Unlocks during Internet Outage'>
            <WifiOutlined style={{ fontSize: '14px' }} />
          </Tooltip>
        )}
      </div>
    );
  },
  renderString: ({ failurePlan }) => formatEnumValue(failurePlan) || '',
});

export const readerTypeColumn = model.createColumn<{ readerType?: ReaderType_enum | null }>({
  title: 'Reader Type',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'ReaderType',
  },
  render: ({ readerType }) => readerType ? enumManagers.ReaderType.getLabel(readerType) : null,
});
