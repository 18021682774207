import {
  AccessPoint_bool_exp,
  AccessPointActionsFragment,
  AccessPointLabelFragment,
  AccessPointLabelFragmentDoc,
  AccessPointUniqueLabelFragment,
  AccessPointUniqueLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import Model from '../Model';

export const AccessPointModel = new Model<
  AccessPoint_bool_exp,
  AccessPointLabelFragment,
  AccessPointUniqueLabelFragment,
  AccessPointActionsFragment
>({
  names: {
    schemaName: 'AccessPoint',
  },

  permissionsOptions: {
    canUpdateRow: (_, { archivedAt }) => !archivedAt,
  },

  labels: {
    getLabel: a => a.name,
    getUniqueLabel: a => (
      `${a.property.name} - ${a.name}`
    ),
    labelFragment: AccessPointLabelFragmentDoc,
    uniqueLabelFragment: AccessPointUniqueLabelFragmentDoc,
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            {
              property: {
                name: { _ilike: `%${word}%` },
              },
            },
            {
              name: { _ilike: `%${word}%` },
            },
          ],
        }
      )),
    }),
  },
});

export default AccessPointModel;
