import { Axis, Chart, Geom, Tooltip as ChartTooltip } from 'bizcharts';
import React from 'react';

interface IChartProps {
  dataType: string;
  data: any[];
  ticks: string[];
}

export const HealthChart: React.FC<IChartProps> = (props) => {
  return (
    <Chart
      animate={false}
      height={500}
      padding='auto'
      data={props.data}
      forceFit
      scale={{
        type: 'time',
        [props.dataType]: {
          alias: `${props.dataType}`,
        },
        date: {
          alias: 'Date',
          ticks: props.ticks,
        },
      }}
    >
      <ChartTooltip/>
      <Axis />
      <Geom type='line' shape='smooth' position={`date*${props.dataType}`} size={2} color={'#02c8df'} />
    </Chart>
  )
}

export default HealthChart;
