import { Favorite_bool_exp } from '../../graphql/hasura/generated';
import Model from '../Model';

export const FavoriteModel = new Model<Favorite_bool_exp>({
  names: {
    schemaName: 'Favorite',
  },
});

export default FavoriteModel;
