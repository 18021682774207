import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

import EnumTag from '../../../components/EnumTag';
import { AugustLockBridgeFragment, AugustPinCodeFragment } from '../../../graphql/api/generated';
import { AugustPinCodeUserRoleFragment } from '../../../graphql/hasura/generated';
import { formatTimestamp } from '../../../utils';
import { AugustPinCodeStatus, AugustWiFiBridgeStatus } from '../../SmartLock/components';
import { SmartLockModel } from '../../SmartLock/model';
import { UnitModel } from '../../Unit/model';
import { UserModel } from '../../User/model';

export interface IPinCodeRow {
  pinCode?: AugustPinCodeFragment;
  Bridge?: AugustLockBridgeFragment | null;
  userRole: AugustPinCodeUserRoleFragment;
  stale: boolean;
}

export function createSmartLockPinCodeColumns(): ColumnsType<IPinCodeRow> {
  return [
    {
      title: 'Wi-Fi Bridge Status',
      render: (_, { Bridge }) => {
        if (!Bridge) {
          return <Tag style={{ fontWeight: 600 }}>NOT APPLICABLE</Tag>;
        }

        return <AugustWiFiBridgeStatus Bridge={Bridge} />;
      },
    },
    {
      title: 'PIN Code Status',
      render: (_, { pinCode, stale }) => {
        if (pinCode) {
          return <AugustPinCodeStatus status={pinCode.status} stale={stale} />;
        }

        return <EnumTag colorMap={{}} enumValue='DELETED' />;
      },
    },
    {
      title: 'Unit',
      render: (_, { userRole }) => (
        UnitModel.routes.renderRowLink(userRole.scopedSmartLock?.unit)
      ),
    },
    {
      title: 'Smart Lock',
      render: (_, { userRole }) => (
        SmartLockModel.routes.renderRowLink(userRole.scopedSmartLock)
      ),
    },
    {
      title: 'Created By',
      render: (_, { userRole }) => (
        UserModel.routes.renderRowLink(userRole.createdByUser)
      ),
    },
    {
      title: 'Created',
      render: (_, { userRole }) => formatTimestamp(userRole.createdAt),
    },
  ];
}
