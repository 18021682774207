import { ExclamationCircleOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import React from 'react';

import { IDescriptions, TableCellDescriptions } from '../../../components/TableCellDescriptions';
import { AugustLockReportFragment as AugustLock } from '../../../graphql/api/generated';
import { Color, formatTimestamp } from '../../../utils';

export function getAugustBatteryDescriptions(augustLock: AugustLock | null | undefined): IDescriptions {
  const batteryLevel = augustLock?.batteryInfo.level;

  return {
    Level: typeof batteryLevel === 'number' ? numeral(batteryLevel).format('0.00%') : null,
    'Last Synced': formatTimestamp(augustLock?.batteryInfo.infoUpdatedDate),
  };
}

export const AugustBatteryDescriptions: React.FC<{ augustLock: AugustLock | null | undefined }> = (props) => {
  const { augustLock } = props;
  const descriptions = getAugustBatteryDescriptions(augustLock);

  if (
    descriptions.Level &&
    augustLock?.batteryInfo.warningState &&
    augustLock?.batteryInfo.warningState !== 'lock_state_battery_warning_none'
  ) {
    descriptions.Level = (
      <>{descriptions.Level} <ExclamationCircleOutlined style={{ color: Color.Red }} /></>
    );
  }

  return <TableCellDescriptions descriptions={descriptions} />;
};
