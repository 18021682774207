import * as Sentry from '@sentry/browser';

import { SENTRY_DSN } from '../config';

Sentry.init({
  dsn: SENTRY_DSN,
  attachStacktrace: true,
  enabled: Boolean(SENTRY_DSN && process.env.NODE_ENV !== 'development'),
  ignoreErrors: [
    /Network request failed/,
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    /ResizeObserver loop completed with undelivered notifications/,
    /ResizeObserver loop limit exceeded/,
    // Hasura
    /JWTExpired/,
    /JWTIssuedAtFuture/,
    /Unable to mark ticket as solved/,
    // API
    /Incorrect code/,
    /No user found/,
    /Not authorized - renew access token/,
    /There are no enabled relays for this entry point/,
    /This user already has already been assigned the selected role/,
    /Too many requests/,
    /Try sending a new code/,
  ],
});
