import {
  AccessPointType_enum,
  PermissionKey_enum,
  PermissionScope_enum,
  Role_bool_exp,
  RoleKey_enum,
  RoleWithStaffPermissionsFragment,
} from '../../../graphql/hasura/generated';

export function getRoleAttributes(
  roleId: string | null | undefined,
  roles: RoleWithStaffPermissionsFragment[],
) {
  const role = roleId ? roles.find(r => r.roleId === roleId) : null;

  const isUnitRole = role?.permissionScope === PermissionScope_enum.UNIT;
  const isAccessPointRole = role?.permissionScope === PermissionScope_enum.ACCESS_POINT;

  const hasStaffAccess = role?.rolePermissions.some(rp => (
    rp.permission.key === PermissionKey_enum.AccessPoint_StaffAccess
  )) || false;

  const canBypassSchedule = role?.rolePermissions.some(rp => (
    rp.permission.key === PermissionKey_enum.AccessPoint_BypassSchedule
  )) || false;

  // The only point in saving an "AccessPointRole" record for these roles is to set a custom schedule.
  // This is because they should be automatically whitelisted.
  const customScheduleRequired = !canBypassSchedule && (hasStaffAccess || isAccessPointRole);

  return {
    isUnitRole, isAccessPointRole, hasStaffAccess, canBypassSchedule, customScheduleRequired,
  };
}

export function getApplicableRolesByAccessPoint(
  accessPointId: string,
  type: AccessPointType_enum,
): Role_bool_exp {
  const excludedRoleKeys: RoleKey_enum[] = [];
  const excludedRolesExp: Role_bool_exp[] = [];

  if (type !== AccessPointType_enum.AMENITY) {
    excludedRoleKeys.push(RoleKey_enum.AMENITY_ACCESS);
  }

  if (type !== AccessPointType_enum.RESERVATION_ONLY) {
    excludedRoleKeys.push(RoleKey_enum.RESERVATION);
  }

  if (excludedRoleKeys.length) {
    excludedRolesExp.push({
      _or: [
        {
          key: { _is_null: true },
        },
        {
          key: { _nin: excludedRoleKeys },
        },
      ],
    });
  }

  return {
    rolePermissions: {
      permission: {
        key: { _eq: PermissionKey_enum.AccessPoint_Unlock },
      },
    },
    // Default roles and custom roles
    _or: [
      {
        organizationId: { _is_null: true },
      },
      {
        organization: {
          properties: {
            accessPoints: {
              accessPointId: { _eq: accessPointId },
            },
          },
        },
      },
    ],
    _and: excludedRolesExp,
  };
}

export function getSelectableRolesByAccessPoint(accessPointId: string): Role_bool_exp {
  const excludeExistingRolesExp: Role_bool_exp[] = [
    {
      _not: {
        accessPointRoles: {
          accessPointId: { _eq: accessPointId },
        },
      },
    },
    // Unit roles can be repeated for different unit groups. Other role scopes cannot
    {
      permissionScope: { _eq: PermissionScope_enum.UNIT },
    },
  ];

  return {
    rolePermissions: {
      permission: {
        key: { _eq: PermissionKey_enum.AccessPoint_Unlock },
      },
    },
    _and: [
      {
        // Default roles and custom roles
        _or: [
          {
            organizationId: { _is_null: true },
          },
          {
            organization: {
              properties: {
                accessPoints: {
                  accessPointId: { _eq: accessPointId },
                },
              },
            },
          },
        ],
      },
      // Exclude roles scoped by access point
      {
        permissionScope: { _neq: PermissionScope_enum.ACCESS_POINT },
      },
      // Exclude roles that have automatic access
      {
        _not: {
          rolePermissions: {
            permission: {
              key: { _eq: PermissionKey_enum.AccessPoint_StaffAccess },
            },
          },
        },
      },
      // Exclude existing roles
      {
        _or: excludeExistingRolesExp,
      },
    ],
  };
}
