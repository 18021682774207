import { order_by, RoleRelationship_bool_exp } from '../../graphql/hasura/generated';
import Model from '../Model';

export const RoleRelationshipModel = new Model<RoleRelationship_bool_exp>({
  names: {
    schemaName: 'RoleRelationship',
  },

  permissionsOptions: {
    canRead: () => true,
  },

  queryOptions: {
    defaultTableOrderBy: [
      {
        relatedRole: {
          rolePermissions_aggregate: {
            count: order_by.desc,
          },
        },
      },
      {
        relatedRole: {
          name: order_by.asc,
        },
      },
    ],
  },
});

export default RoleRelationshipModel;
