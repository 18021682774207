import { Card, Typography } from 'antd';
import { withFormik } from 'formik';
import React from 'react';

import { RoleKey_enum, usegetGrantableRolesQuery } from '../../../graphql/hasura/generated';
import { displayErrorMessage } from '../../../utils';
import { TextField } from '../../ModelForm/form-fields';
import { AdditionalContextSection } from '../shared-form/AdditionalContextSection';
import { DurationOfAccessSection } from '../shared-form/DurationOfAccessSection';
import { SubmitFormSection } from '../shared-form/SubmitFormSection';

import {
  handleSubmit,
  IVendorPinFormProps,
  IVendorPinFormValues,
  mapPropsToValues,
  validate,
} from './formik';
import { SelectPropertySection } from './SelectPropertySection';

export const VendorPinForm = withFormik<IVendorPinFormProps, IVendorPinFormValues>({
  mapPropsToValues,
  validate,
  handleSubmit,
  enableReinitialize: false,
  validateOnChange: true,
})((props) => {
  const { UserRoleModel, values } = props;
  const { propertyFeatureFlags } = values;

  const { loading } = usegetGrantableRolesQuery({
    variables: {
      where: {
        key: { _eq: RoleKey_enum.VENDOR_PIN },
      },
    },
    onCompleted: ({ roles }) => {
      const [role] = roles;

      if (role) {
        props.setFieldValue('role', role);
      }
    },
    onError: (error) => displayErrorMessage(error),
  });

  return (
    <>
      <Card bordered={false} style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
        <Typography.Paragraph>
          Generate a PIN that a vendor can use for property access after scanning a Smart Sign.
          (No mobile app required.)
        </Typography.Paragraph>
        <TextField
          {...props}
          formItemLayout={{}}
          className='label-align-top'
          required
          isNew
          label='Vendor Name'
          field={UserRoleModel.introspection.getField('nickname')}
          maxLength={100}
        />
      </Card>
      <Card loading={loading} bordered={false} style={{ marginTop: 25, marginBottom: 75 }}>
        <SelectPropertySection {...props} />
        {propertyFeatureFlags?.VENDOR_PINS && (
          <>
            <DurationOfAccessSection {...props} />
            <AdditionalContextSection {...props} />
            <SubmitFormSection {...props} />
          </>
        )}
      </Card>
    </>
  );
});
