import { Form } from '@ant-design/compatible';
import { DatePicker, Input } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';
import { FormikHandlers } from 'formik';
import { lowerCase } from 'lodash';
import moment from 'moment';
import React from 'react';

import { EnumSelect, IEnumSelectProps } from '../../components/EnumSelect';
import { IIntrospectionField } from '../../models/typings';
import { getFormLabel, TIMESTAMP_FORMAT } from '../../utils';

export const FORM_ITEM_DEFAULT_LAYOUT = {
  labelCol: {
    sm: { span: 7 },
    xs: { span: 24 },
  },
  wrapperCol: {
    md: { span: 10 },
    sm: { span: 12 },
    xs: { span: 24 },
  },
};

// @TODO: Utilize these components for CustomFormField component
// @TODO: Reorganize props to make it clear what gets passed to FormItem vs. input

interface IGeneralProps extends FormItemProps {
  // formik derived props
  handleChange: FormikHandlers['handleChange'];
  isSubmitting: boolean;
  isNew: boolean;
  values: any;
  errors: any;

  // field related
  field: IIntrospectionField;

  // ui related
  formItemLayout?: { [k: string]: any };
  disabled?: boolean;
  required?: boolean;
}

export const FormItem: React.FC<IGeneralProps> = (props) => {
  const {
    formItemLayout = FORM_ITEM_DEFAULT_LAYOUT,
    required,
    field,
    errors,
    validateStatus,
    help,
    children,
    className,
    label,
  } = props;

  // Can be a simple string or a more complex object, depending on the field
  const fieldErrors = errors[field.name];

  return (
    <Form.Item
      {...formItemLayout}
      validateStatus={validateStatus || (fieldErrors ? 'error' : undefined)}
      help={help || (typeof fieldErrors === 'string' ? fieldErrors : undefined)}
      label={label || getFormLabel(field.name)}
      required={typeof required === 'boolean' ? required : field.required}
      className={className}
    >
      {children}
    </Form.Item>
  );
};

interface ITextFieldProps extends IGeneralProps {
  maxLength?: number;
  extra?: React.ReactNode;
}

export const TextField: React.FC<ITextFieldProps> = (props) => {
  const {
    isSubmitting,
    disabled,
    field,
    values,
    handleChange,
    maxLength,
    extra,
  } = props;

  return (
    <FormItem {...props}>
      <Input
        disabled={!!isSubmitting || disabled}
        name={field.name}
        value={values[field.name]}
        onChange={handleChange}
        type={field.scalarName === 'Int' ? 'number' : 'text'}
        maxLength={maxLength}
      />
      {extra}
    </FormItem>
  );
}

interface ITextAreaFieldProps extends IGeneralProps {
  maxLength?: number;
}

export const TextAreaField: React.FC<ITextAreaFieldProps> = (props) => {
  const {
    isSubmitting,
    disabled,
    field,
    values,
    handleChange,
    maxLength,
  } = props;

  return (
    <FormItem {...props}>
      <Input.TextArea
        disabled={!!isSubmitting || disabled}
        name={field.name}
        value={values[field.name]}
        onChange={handleChange}
        maxLength={maxLength}
      />
    </FormItem>
  );
}

interface IEnumFormSelectProps {
  enumSelectProps: IEnumSelectProps;
  formItemProps: IGeneralProps;
}

export const EnumFormSelect: React.FC<IEnumFormSelectProps> = (props) => {
  const { enumSelectProps, formItemProps } = props;
  const { isSubmitting, disabled, field } = formItemProps;

  return (
    <FormItem {...formItemProps}>
      <EnumSelect
        {...enumSelectProps}
        allowClear={!field.required}
        disabled={!!isSubmitting || disabled}
        placeholder={`Select ${lowerCase(getFormLabel(field.name))}`}
      />
    </FormItem>
  );
}

interface IDateFieldProps extends IGeneralProps {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const DateField: React.FC<IDateFieldProps> = (props) => {
  const {
    disabled,
    field,
    values,
    isSubmitting,
    setFieldValue
  } = props;

  return (
    <FormItem {...props}>
      <DatePicker
        showTime
        name={field.name}
        disabled={!!isSubmitting || disabled}
        style={{ width: '100%' }}
        value={field.name in values && moment(values[field.name]) || undefined}
        format={TIMESTAMP_FORMAT}
        onChange={time => setFieldValue(field.name, time && time.toDate() || null, false)}
      />
    </FormItem>
  )
}

// @TODO: expose a Time picker when needed for the custom routes
export const TimeField: React.FC<IGeneralProps> = (props: IGeneralProps) => {
  return null;
}
