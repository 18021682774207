export const DEPRECATED_TYPE_MAP: { [key: string]: string } = {
  PROPERTY_MANAGER: 'Property Manager',
  MAINTENANCE: 'Maintenance Technician',
  INTEGRATOR: 'Integrator',
  STAFF: 'Staff',
  RESIDENT: 'Resident',
  GUEST: 'Guest',
};

export const deprecatedTypeOptions = Object.entries(DEPRECATED_TYPE_MAP)
  .map(([value, label]) => ({ value, label }))
  .filter(({ value }) => (
    value === 'PROPERTY_MANAGER' ||
    value === 'MAINTENANCE'
  ));
