import { getOrganizationsQuery } from '../../../../graphql/hasura/generated';

import { IWarningFlags } from './typings';

export function getWarnings(
  organizations: getOrganizationsQuery['organizations'],
  warningFlags: IWarningFlags,
) {
  const warnings: string[] = [];

  if (organizations.length > 1) {
    warnings.push(
      'Custom organization roles not available. ' +
      'Selected scope falls within multiple organizations.'
    );
  }

  if (warningFlags.controlledByPms) {
    warnings.push(
      'Resident role not available. ' +
      'One or more selected units are controlled by Property Management System.'
    );
  }

  if (warningFlags.someReservationOnly) {
    warnings.push(
      'One or more selected access points are Reservation Only. ' +
      'Reservations can only be made through the Chirp mobile app.'
    );
  }

  if (warningFlags.mixedAmenities) {
    warnings.push(
      'Amenity Access role not available. ' +
      'Selected access points include a mix of amenities and non-amenities.'
    );
  }

  return warnings;
}
