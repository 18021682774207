import { FormikBag, FormikErrors, FormikProps } from 'formik';

import { apiClient } from '../../../graphql/api/apiClient';
import {
  grantVendorPinUserRoleDocument,
  grantVendorPinUserRoleMutation,
  grantVendorPinUserRoleMutationVariables,
} from '../../../graphql/api/generated';
import { PermissionScope_enum } from '../../../graphql/hasura/generated';
import { hasuraClient } from '../../../graphql/hasura/hasuraClient';
import { IModel } from '../../../models/typings';
import history from '../../../routes/history';
import { displayErrorMessage, PropertyFeatureFlags } from '../../../utils';
import { ISharedFormValues, validateSharedFormValues } from '../shared-form/formik';

export interface IVendorPinFormProps {
  UserRoleModel: IModel;
  PropertyModel: IModel;
}

export interface IVendorPinFormValues extends ISharedFormValues {
  nickname?: string | null;
  propertyFeatureFlags?: PropertyFeatureFlags;
}

export interface ISgtUserFormMergedProps extends
  IVendorPinFormProps, FormikProps<IVendorPinFormValues> {}

export function mapPropsToValues(props: IVendorPinFormProps): IVendorPinFormValues {
  return {
    permissionScope: PermissionScope_enum.PROPERTY, // Satisfy props for AdditionalContextSection
    scopedIds: [],
    activatesAt: new Date(),
    acknowledged: false,
  };
}

export function validate(values: IVendorPinFormValues, props: IVendorPinFormProps) {
  const errors: FormikErrors<IVendorPinFormValues> = {};

  if (!values.acknowledged) {
    return errors;
  }

  if (!values.nickname) {
    errors.nickname = `Please enter the vendor's name`;
  }

  if (!values.propertyFeatureFlags?.VENDOR_PINS) {
    // @ts-ignore (ModelFormSelect does not handle array)
    errors.scopedIds = `Vendor PINs are not enabled for this property`;
  }

  if (!values.scopedIds.length) {
    // @ts-ignore (ModelFormSelect does not handle array)
    errors.scopedIds = `Please select a property`;
  }

  return { ...errors, ...validateSharedFormValues(values) };
}

export async function handleSubmit(
  values: IVendorPinFormValues,
  formikBag: FormikBag<IVendorPinFormProps, IVendorPinFormValues>
) {
  try {
    const { data } = await apiClient.mutate<
      grantVendorPinUserRoleMutation,
      grantVendorPinUserRoleMutationVariables
    >({
      variables: {
        input: {
          scopedPropertyId: values.scopedIds[0],
          nickname: values.nickname as string,
          activatesAt: values.activatesAt.toISOString(),
          expiresAt: values.expiresAt?.toISOString(),
          schedule: values.schedule,
          reason: values.reason,
          notes: values.notes,
        },
      },
      mutation: grantVendorPinUserRoleDocument,
    });

    const userRoleId = data?.grantVendorPinUserRole.userRoleId;

    // Ensure that cached tables refetch data when redirected
    await hasuraClient.resetStore();
    await apiClient.resetStore();

    if (userRoleId) {
      history.push(`/assigned-roles/details/${userRoleId}`);
    }
  } catch (error) {
    formikBag.setSubmitting(false);
    displayErrorMessage(error);
  }
}
