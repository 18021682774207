import type { SortOrder } from 'antd/lib/table/interface';

import type { ColumnFilters, IModel, IModelColumn } from '../typings';

import { formatDateRangeColumn } from './formatDateRangeColumn';
import { formatEnumColumn } from './formatEnumColumn';
import { formatHighlightedColumn } from './formatHighlightedColumns';
import { formatModelSelectColumn } from './formatModelSelectColumn';
import { formatRadioColumn } from './formatRadioColumn';
import { formatSortableColumn } from './formatSortableColumn';

interface IFormatColumnsArgs {
  columns: IModelColumn<any>[];
  model: IModel;
  filters?: ColumnFilters;
  orderBy?: { [key: string]: SortOrder } | null;
  searchWords?: string[];
  disableColumnFilters?: boolean;
}

export function formatColumns(args: IFormatColumnsArgs) {
  const { columns, model, orderBy, filters, searchWords, disableColumnFilters } = args;

  return columns.map((c) => {
    let column = { ...c };

    column = formatSortableColumn({ column, model, orderBy });
    column = formatHighlightedColumn({ column, searchWords });

    if (!disableColumnFilters) {
      column = formatModelSelectColumn({ column, filters });
      column = formatEnumColumn({ column, filters });
      column = formatDateRangeColumn({ column, filters });
      column = formatRadioColumn({ column, filters  });
    }

    return column;
  })
  .filter(c => c.enabled());
}
