import React from 'react';

import { IDescriptions, TableCellDescriptions } from '../../../components/TableCellDescriptions';
import { PermissionScope_enum, UserRoleDetailsFragment } from '../../../graphql/hasura/generated';
import * as columns from '../columns';

export const ScopeOfAccessDescriptions: React.FC<{ userRole: UserRoleDetailsFragment}> = (props) => {
  const {
    role, scopedOrganization, scopedUnit, scopedAccessPoint, scopedSmartLock, managedWithinProperty,
  } = props.userRole;

  if (role.permissionScope === PermissionScope_enum.GLOBAL) {
    return <>Global</>;
  }

  const descriptions: IDescriptions = {};

  if (managedWithinProperty) {
    // Applies to Property, Unit, AP, and Smart Lock roles
    descriptions['Property'] = columns.managedWithinPropertyColumn.render({ managedWithinProperty });
  }

  if (scopedOrganization) {
    descriptions['Organization'] = columns.organizationColumn.render({ scopedOrganization });
  } else if (scopedUnit) {
    descriptions['Unit'] = columns.unitColumn.render({ scopedUnit });
  } else if (scopedAccessPoint) {
    descriptions['Access Point'] = columns.accessPointColumn.render({ scopedAccessPoint });
  } else if (scopedSmartLock) {
    descriptions['Unit'] = columns.smartLockUnitColumn.render({ scopedSmartLock });
    descriptions['Smart Lock'] = columns.smartLockColumn.render({ scopedSmartLock });
  }

  return <TableCellDescriptions descriptions={descriptions} />;
};
