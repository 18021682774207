import { Alert } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';

import {
  AccountStatus_enum,
  AugustAccessUserRoleFragment,
} from '../../../../graphql/hasura/generated';
import * as userColumns from '../../../User/columns';
import { assignedToColumn } from '../../../UserRole/columns';

import { renderChirpRole } from './column-helpers';

export const noAccessColumns: ColumnProps<AugustAccessUserRoleFragment>[] = [
  {
    title: userColumns.accountStatusColumn.title,
    render: (_, { assignedToUser }) => {
      return assignedToUser ? userColumns.accountStatusColumn.render(assignedToUser) : '';
    },
  },
  {
    title: 'Name',
    render: (_, userRole) => assignedToColumn.render(userRole),
  },
  {
    title: 'Role',
    render: (_, userRole) => {
      const { role } = userRole;

      return renderChirpRole({ role, userRole });
    },
  },
  {
    title: userColumns.phoneNumberColumn.title,
    render: (_, { assignedToUser }) => {
      return assignedToUser ? userColumns.phoneNumberColumn.render(assignedToUser) : '';
    },
  },
  {
    title: userColumns.emailColumn.title,
    render: (_, { assignedToUser }) => {
      return assignedToUser ? userColumns.emailColumn.render(assignedToUser) : '';
    },
  },
  {
    title: userColumns.hasAugustAccountColumn.title,
    render: (_, { assignedToUser }) => {
      return assignedToUser ? userColumns.hasAugustAccountColumn.render(assignedToUser) : '';
    },
  },
  {
    title: userColumns.hasPasswordColumn.title,
    render: (_, { assignedToUser }) => {
      return assignedToUser ? userColumns.hasPasswordColumn.render(assignedToUser) : '';
    },
  },
  {
    title: 'Ineligibility Reasons',
    render: (_, userRole) => {
      const user = userRole?.assignedToUser;
      const warnings: string[] = [];

      if (user) {
        if (user.accountStatus === AccountStatus_enum.DISABLED) {
          warnings.push('Chirp account is disabled');
        }

        if (!user.augustUserId && !user.augustSubUserId) {
          warnings.push('No connected August account');
        }
      } else {
        warnings.push('No Chirp user has been assigned');
      }

      if (warnings.length) {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {warnings.map((warning, index) => (
              <Alert
                key={warning}
                showIcon
                type='warning'
                message={warning}
                style={{ marginBottom: index !== warnings.length + 1 ? 10 : 0 }}
              />
            ))}
          </div>
        );
      }

      return null;
    },
  }
];
