import { Card, Col, Row, Statistic } from 'antd';
import numeral from 'numeral';
import React from 'react';

import { TextWithIconTooltip } from '../../components/TextWithIconTooltip';
import { apiClient } from '../../graphql/api/apiClient';
import { useuserStatisticsQuery } from '../../graphql/api/generated';
import { displayErrorMessage } from '../../utils';

interface IUserMetricsStatisticsProps {
  startDate: string;
  endDate: string;
  roleIds: string[];
  propertyId?: string;
}

const UserMetricsStatistics = React.memo((props: IUserMetricsStatisticsProps) => {
  const { startDate, endDate, propertyId, roleIds } = props;

  const { data, loading } = useuserStatisticsQuery({
    client: apiClient,
    fetchPolicy: 'cache-first',
    skip: !propertyId,
    variables: {
      input: {
        startDate,
        endDate,
        roleIds,
        propertyId: props.propertyId as string,
      },
    },
    onError: error => displayErrorMessage(error),
  });

  const totalEligibleUsers = data?.userStatistics?.totalEligibleUsers || 0;
  const totalActivatedUsers = data?.userStatistics?.totalActivatedUsers || 0;
  const totalActiveUsers = data?.userStatistics?.totalActiveUsers || 0;

  const statistics = [
    {
      title: 'Eligible Users',
      count: totalEligibleUsers,
      // tslint:disable-next-line max-line-length
      tooltip: 'This is the total number of users with access to the Chirp app during this period of time. This total includes all eligible users regardless of whether the user has downloaded or logged in to the app.',
    },
    {
      title: 'Activated Users',
      count: totalActivatedUsers,
      total: totalEligibleUsers,
      // tslint:disable-next-line max-line-length
      tooltip: 'This is the number of Eligible Users that have successfully downloaded and logged into the Chirp app at least once.',
    },
    {
      title: 'Active Users',
      count: totalActiveUsers,
      total: totalActivatedUsers,
      // tslint:disable-next-line max-line-length
      tooltip: 'This is the total number of Activated Users that have performed at least one lock or unlock action during this period of time.',
    },
  ];

  return (
    <Row gutter={16} style={{ marginBottom: 20 }}>
      {statistics.map(({ title, count = 0, total, tooltip }) => {
        const percentage = total ? (count || 0) / total : null;
        const percentageFormat = (percentage && percentage < 0.01) ? '0.00%' : '0%';

        const suffix = typeof percentage === 'number'
          ? `(${numeral(percentage).format(percentageFormat)})`
          : null;

        return (
          <Col key={title} span={8}>
            <Card loading={loading} bordered={false}>
              <Statistic
                title={(
                  <TextWithIconTooltip
                    text={title}
                    tooltip={{ title: tooltip, placement: 'right' }}
                  />
                )}
                value={count || 0}
                suffix={suffix}
              />
            </Card>
          </Col>
        );
      })}
    </Row>
  );
});

export default UserMetricsStatistics;
