import type { RelayLogFiltersInput } from '../../graphql/api/generated';
import { PermissionKey_enum } from '../../graphql/hasura/generated';
import Model from '../Model';

export const RelayLogModel = new Model<RelayLogFiltersInput>({
  names: {
    schemaName: 'RelayLog',
    displayName: 'Gateway Log',
    pluralDisplayName: 'Gateway Logs',
  },
  permissionsOptions: {
    // Bypass introspection check (queries will be made to Main API rather than Hasura)
    canRead: ({ hasPermission }) => hasPermission(PermissionKey_enum.RelayLog_Read),
  },
});

export default RelayLogModel;
