import { enumManagers } from '@chirp/enums';
import validator from 'validator';

import {
  Hub_bool_exp,
  HubLabelFragment,
  HubLabelFragmentDoc,
  HubRequiredRowFragment,
  HubUniqueLabelFragment,
  HubUniqueLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import { emptySearchConditionsFilter, formatPrimaryKey } from '../../utils';
import Model from '../Model';

const getLabel = (h: HubLabelFragment) => h.name || `Unnamed Hub (#${formatPrimaryKey(h.hubId)})`;

export const HubModel = new Model<
  Hub_bool_exp,
  HubLabelFragment,
  HubUniqueLabelFragment,
  HubRequiredRowFragment
>({
  names: {
    schemaName: 'Hub',
  },

  labels: {
    getLabel,
    getUniqueLabel: (h) => {
      const property = h.property ? h.property.name : 'UNASSIGNED';

      return `${property} - ${getLabel(h)} - ${enumManagers.HubDeviceType.getLabel(h.deviceType)}`;
    },
    labelFragment: HubLabelFragmentDoc,
    uniqueLabelFragment: HubUniqueLabelFragmentDoc,
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            {
              property: {
                name: { _ilike: `%${word}%` },
              },
            },
            {
              name: { _ilike: `%${word}%` },
            },
            {
              deviceTypeEnum: {
                label: { _ilike: `%${word}%` },
              },
            },
            {
              hubId: { _eq: (validator.isUUID(word) ? word : undefined) },
            },
            {
              port: { _eq: validator.isNumeric(word) ? Number(word) : undefined },
            },
            {
              macAddress: { _ilike: word },
            },
          ].filter(emptySearchConditionsFilter),
        }
      )),
    }),
  }
});

export default HubModel;
