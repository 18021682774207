import { Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import React from 'react';

import normalizeColumns from '../../models/helpers/normalizeColumns';
import { IChirpTableColumn } from '../../models/typings';

export interface IChirpTableProps extends Omit<TableProps<any>, 'columns'> {
  columns: IChirpTableColumn[];
}

// Wrapper for Table component that normalizes Chirp columns as Ant Design columns
export const ChirpTable: React.FC<IChirpTableProps> = ({ columns, ...tableProps }) => {
  const normalizedColumns = normalizeColumns(columns);

  return (
    <AntTable {...tableProps} columns={normalizedColumns} />
  );
};

export default ChirpTable;
