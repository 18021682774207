import model from './model';

const formFields = model.createFormFields([
  {
    fieldName: 'name',
    maxLength: 100,
  },
  {
    fieldName: 'identifier',
    maxLength: 100,
  },
]);

model.setFormOptions({
  fields: formFields,
});
