import { INTROSPECTION_TTL, INTROSPECTION_VERSION } from '../../config';
import { authentication } from '../../stores';
import { introspectionDocument, introspectionQuery } from '../hasura/generated';
import { hasuraClient } from '../hasura/hasuraClient';

type IntrospectionResult = introspectionQuery['__schema'];

const localStorage = window.localStorage;

async function introspectSchema(): Promise<IntrospectionResult> {
  const { data } = await hasuraClient.query<introspectionQuery>({
    query: introspectionDocument,
    fetchPolicy: 'network-only',
    // the easiest way would be to use apollo's cache policy if it supported TTL
    // we might be able to replace it when they launch a new client in case it supports it
    // https://github.com/apollographql/apollo-feature-requests/issues/4
    // although the issue on top doesnt seem very clear on what it covers
  });

  return data.__schema;
}

const getIntrospection = async () => {
  try {
    const cachedResult = getCachedIntrospection();

    if (cachedResult) {
      return cachedResult;
    }
  } catch {
    // Fail silently
  }

  const introspectionResult = await introspectSchema();

  try {
    cacheIntrospectionResult(introspectionResult);
  } catch {
    // Fail silently
  }

  return introspectionResult;
};

export default getIntrospection;

interface ICachedItem {
  value: IntrospectionResult;
  exp: number;
  version?: string;
}

function getIntrospectionKey() {
  const { hasuraDefaultRole } = authentication;

  if (!hasuraDefaultRole) {
    throw new Error('Hasura default role not available');
  }

  return `introspection:${hasuraDefaultRole}`;
}

function cacheIntrospectionResult(introspectionResult: IntrospectionResult) {
  const cachedItem: ICachedItem = {
    value: introspectionResult,
    exp: Date.now() + INTROSPECTION_TTL,
    version: INTROSPECTION_VERSION,
  };

  localStorage.setItem(getIntrospectionKey(), JSON.stringify(cachedItem));
}

function getCachedIntrospection() {
  const stringifiedValue = localStorage.getItem(getIntrospectionKey());

  if (!stringifiedValue) {
    return null;
  }

  const cachedItem: ICachedItem = JSON.parse(stringifiedValue);

  if (!cachedItem.version || cachedItem.version !== INTROSPECTION_VERSION) {
    clearIntrospectionCache();

    return null;
  }

  if (Date.now() > cachedItem.exp) {
    clearIntrospectionCache();

    return null;
  }

  return cachedItem.value;
}

export function clearIntrospectionCache() {
  localStorage.removeItem(getIntrospectionKey());
}
