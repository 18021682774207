import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';
import React from 'react';

import { formatTimestamp, timeAgo } from '../../utils';

export const formatAsTimeAgo = (timestamp: string | null | undefined) => {
  return timestamp ? timeAgo.format(new Date(timestamp), 'round') : ''
};

interface ITimeAgoWithTooltipProps {
  timestamp: string | null | undefined;
  tooltipProps?: TooltipProps;
  style?: React.CSSProperties;
  children?: (timeAgoFormat: string | null) => React.ReactNode;
}

export const TimeAgoWithTooltip: React.FC<ITimeAgoWithTooltipProps> = (props) => {
  const { timestamp, tooltipProps, style, children } = props;
  const timeAgoFormat = formatAsTimeAgo(timestamp);

  return (
    <Tooltip
      title={timestamp ? formatTimestamp(timestamp) : null}
      {...tooltipProps}
    >
      <span style={style}>
        {typeof children === 'function' ? children(timeAgoFormat) : timeAgoFormat}
      </span>
    </Tooltip>
  );
}

export default TimeAgoWithTooltip;
