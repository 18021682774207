import {
  PermissionKey_enum,
  PermissionScope_enum,
  RoleKey_enum,
} from '../../../graphql/hasura/generated';

export interface IPresetRole {
  name: string;
  permissionScope: PermissionScope_enum;
  deprecatedType: 'PROPERTY_MANAGER' | 'MAINTENANCE';
  permissionKeys: PermissionKey_enum[];
  roleRelationships: {
    relatedRole: {
      key: RoleKey_enum;
    } | {
      name: string;
      permissionScope: PermissionScope_enum;
    };
    canReadUserRole: boolean;
    canGrantUserRole: boolean;
    canRevokeUserRole: boolean;
  }[];
}

export const PRESET_ROLES: IPresetRole[] = [
  {
    name: 'Property Manager',
    permissionScope: PermissionScope_enum.PROPERTY,
    deprecatedType: 'PROPERTY_MANAGER',
    permissionKeys: [
      PermissionKey_enum.AccessPoint_BypassSchedule,
      PermissionKey_enum.AccessPoint_Read,
      PermissionKey_enum.AccessPoint_StaffAccess,
      PermissionKey_enum.AccessPoint_Unlock,
      PermissionKey_enum.AccessPoint_Update,
      PermissionKey_enum.Beacon_Read,
      PermissionKey_enum.Credential_Create,
      PermissionKey_enum.Credential_Delete,
      PermissionKey_enum.Credential_Read,
      PermissionKey_enum.Hub_Read,
      PermissionKey_enum.Hub_Restart,
      PermissionKey_enum.PinCode_AutoReceive,
      PermissionKey_enum.PinCode_Delete,
      PermissionKey_enum.PinCode_GenerateRandomByUser,
      PermissionKey_enum.PinCode_Read,
      PermissionKey_enum.Property_PmsResidentAudit,
      PermissionKey_enum.Property_Update,
      PermissionKey_enum.Relay_Read,
      PermissionKey_enum.RelayLog_Read,
      PermissionKey_enum.Session_AccessApp,
      PermissionKey_enum.Session_AdminPanel,
      PermissionKey_enum.Session_Read,
      PermissionKey_enum.Session_Revoke,
      PermissionKey_enum.SmartLock_PropertyReport,
      PermissionKey_enum.SmartLock_Read,
      PermissionKey_enum.SmartLock_Update,
      PermissionKey_enum.Ticket_Read,
      PermissionKey_enum.Ticket_Update,
      PermissionKey_enum.Unit_PmsResidentAudit,
      PermissionKey_enum.Unit_PmsResidentSync,
      PermissionKey_enum.Unit_Read,
      PermissionKey_enum.User_Create,
      PermissionKey_enum.User_Metrics,
      PermissionKey_enum.User_SendMessage,
      PermissionKey_enum.User_UpdateProfile,
    ],
    roleRelationships: [
      // Custom roles
      {
        relatedRole: {
          name: 'Property Manager',
          permissionScope: PermissionScope_enum.PROPERTY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          name: 'Office Staff',
          permissionScope: PermissionScope_enum.PROPERTY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          name: 'Maintenance Technician',
          permissionScope: PermissionScope_enum.PROPERTY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      // Default roles
      {
        relatedRole: {
          key: RoleKey_enum.AMENITY_ACCESS,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.BASIC_ACCESS,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.GUEST_PROPERTY_KEY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.GUEST_UNIT_KEY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.INTEGRATOR,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.INTEGRATOR_GATEWAY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.INTEGRATOR_HOME_ACCESS,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.INTEGRATOR_SUPERVISOR_PROPERTY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.RESERVATION,
        },
        canReadUserRole: true,
        canGrantUserRole: false,
        canRevokeUserRole: false,
      },
      {
        relatedRole: {
          key: RoleKey_enum.RESIDENT,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.STAFF_PROPERTY_KEY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.STAFF_UNIT_KEY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      }
    ],
  },
  {
    name: 'Office Staff',
    permissionScope: PermissionScope_enum.PROPERTY,
    deprecatedType: 'PROPERTY_MANAGER',
    permissionKeys: [
      PermissionKey_enum.AccessPoint_BypassSchedule,
      PermissionKey_enum.AccessPoint_Read,
      PermissionKey_enum.AccessPoint_StaffAccess,
      PermissionKey_enum.AccessPoint_Unlock,
      PermissionKey_enum.Credential_Create,
      PermissionKey_enum.Credential_Delete,
      PermissionKey_enum.Credential_Read,
      PermissionKey_enum.Hub_Read,
      PermissionKey_enum.Hub_Restart,
      PermissionKey_enum.PinCode_AutoReceive,
      PermissionKey_enum.PinCode_GenerateRandomByUser,
      PermissionKey_enum.PinCode_Read,
      PermissionKey_enum.Property_PmsResidentAudit,
      PermissionKey_enum.RelayLog_Read,
      PermissionKey_enum.Session_AccessApp,
      PermissionKey_enum.Session_AdminPanel,
      PermissionKey_enum.Session_Read,
      PermissionKey_enum.SmartLock_PropertyReport,
      PermissionKey_enum.SmartLock_Read,
      PermissionKey_enum.Ticket_Read,
      PermissionKey_enum.Ticket_Update,
      PermissionKey_enum.Unit_PmsResidentAudit,
      PermissionKey_enum.Unit_PmsResidentSync,
      PermissionKey_enum.Unit_Read,
      PermissionKey_enum.User_Create,
      PermissionKey_enum.User_Metrics,
      PermissionKey_enum.User_SendMessage,
      PermissionKey_enum.User_UpdateProfile,
    ],
    roleRelationships: [
      // Custom roles
      {
        relatedRole: {
          name: 'Property Manager',
          permissionScope: PermissionScope_enum.PROPERTY,
        },
        canReadUserRole: true,
        canGrantUserRole: false,
        canRevokeUserRole: false,
      },
      {
        relatedRole: {
          name: 'Office Staff',
          permissionScope: PermissionScope_enum.PROPERTY,
        },
        canReadUserRole: true,
        canGrantUserRole: false,
        canRevokeUserRole: false,
      },
      {
        relatedRole: {
          name: 'Maintenance Technician',
          permissionScope: PermissionScope_enum.PROPERTY,
        },
        canReadUserRole: true,
        canGrantUserRole: false,
        canRevokeUserRole: false,
      },
      // Default roles
      {
        relatedRole: {
          key: RoleKey_enum.GUEST_PROPERTY_KEY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.GUEST_UNIT_KEY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.RESERVATION,
        },
        canReadUserRole: true,
        canGrantUserRole: false,
        canRevokeUserRole: false,
      },
      {
        relatedRole: {
          key: RoleKey_enum.RESIDENT,
        },
        canReadUserRole: true,
        canGrantUserRole: false,
        canRevokeUserRole: false,
      },
      {
        relatedRole: {
          key: RoleKey_enum.STAFF_PROPERTY_KEY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
      {
        relatedRole: {
          key: RoleKey_enum.STAFF_UNIT_KEY,
        },
        canReadUserRole: true,
        canGrantUserRole: true,
        canRevokeUserRole: true,
      },
    ],
  },
  {
    name: 'Maintenance Technician',
    permissionScope: PermissionScope_enum.PROPERTY,
    deprecatedType: 'MAINTENANCE',
    permissionKeys: [
      PermissionKey_enum.AccessPoint_BypassSchedule,
      PermissionKey_enum.AccessPoint_StaffAccess,
      PermissionKey_enum.AccessPoint_Unlock,
      PermissionKey_enum.Hub_Read,
      PermissionKey_enum.Hub_Restart,
      PermissionKey_enum.PinCode_AutoReceive,
      PermissionKey_enum.Session_AccessApp,
      PermissionKey_enum.Session_AdminPanel,
      PermissionKey_enum.SmartLock_Create,
      PermissionKey_enum.SmartLock_FactoryReset,
      PermissionKey_enum.SmartLock_PropertyReport,
      PermissionKey_enum.SmartLock_Read,
      PermissionKey_enum.SmartLock_Update,
      PermissionKey_enum.Ticket_Read,
      PermissionKey_enum.Ticket_Update,
      PermissionKey_enum.Unit_Read,
    ],
    roleRelationships: [
      // Default roles
      {
        relatedRole: {
          key: RoleKey_enum.STAFF_UNIT_KEY,
        },
        canReadUserRole: false,
        canGrantUserRole: true,
        canRevokeUserRole: false,
      },
    ]
  },
];

export default PRESET_ROLES;
