import { List, Radio, Tag } from 'antd';
import { groupBy, sortBy } from 'lodash';
import React from 'react';
import { useHistory } from 'react-router';

import { SessionScopeInput } from '../../graphql/api/generated';
import { authenticatedUserQuery, PermissionScope_enum } from '../../graphql/hasura/generated';
import { authentication } from '../../stores';

import styles from './index.module.less';

type UserRole = authenticatedUserQuery['userRoles'][0];

interface IDataScopeOptions {
  title: string;
  description?: string;
  roleNames: string[];
  sessionScopeInput: SessionScopeInput;
}

interface IDataScopeOptionConverter {
  getTitle: (userRoles: UserRole[]) => string;
  description?: string | undefined;
  getScopedId: (u: UserRole) => string | null | undefined,
}

const OPTION_CONVERTERS: {
  [key in PermissionScope_enum]?: IDataScopeOptionConverter
} = {
  GLOBAL: {
    getTitle: () => 'Global Scope',
    getScopedId: () => null,
  },
  ORGANIZATION: {
    getTitle: (userRoles) => userRoles[0].scopedOrganization?.name || '',
    description: 'Organization',
    getScopedId: u => u.scopedOrganization?.organizationId,
  },
  PROPERTY: {
    getTitle: (userRoles) => userRoles[0].scopedProperty?.name || '',
    description: 'Property',
    getScopedId: u => u.scopedProperty?.propertyId,
  },
};

const ChangeDataScope: React.FC = () => {
  const history = useHistory();
  const { userRoles, currentDataScope } = authentication;

  const options: IDataScopeOptions[] = [];

  for (const [permissionScope, converter] of Object.entries(OPTION_CONVERTERS)) {
    if (converter) {
      const filteredUserRoles = userRoles.filter(u => (
        u.role.permissionScope === permissionScope
      ));

      const groupedUserRoles = groupBy(filteredUserRoles, converter.getScopedId);

      const scopeOptions = Object.entries(groupedUserRoles).map(([, userRolesByScope]) => ({
        title: converter.getTitle(userRolesByScope),
        description: converter.description,
        roleNames: userRolesByScope.map(u => u.role.name).sort(),
        sessionScopeInput: {
          permissionScope,
          scopedId: converter.getScopedId(userRolesByScope[0]),
        },
      }));

      const sortedScopeOptions = sortBy(scopeOptions, o => o.title);

      options.push(...sortedScopeOptions);
    }
  }

  return (
    <List
      itemLayout='horizontal'
      dataSource={options}
      renderItem={option => {
        const isCurrentScope = (
          option.sessionScopeInput.permissionScope === currentDataScope.permissionScope &&
          option.sessionScopeInput.scopedId === currentDataScope.scopedId
        );

        return (
          <List.Item
          className={styles.dataScopeOption}
            onClick={async () => {
              if (!isCurrentScope) {
                await authentication.changeDataScope(option.sessionScopeInput);
                history.push('/dashboard');
              }
            }}
          >
            <List.Item.Meta
              avatar={<Radio checked={isCurrentScope} style={{ paddingLeft: 12 }} />}
              title={option.title}
              description={(
                <>
                  <div style={{ marginBottom: 5 }}>{option.description}</div>
                  <div>
                    {option.roleNames.map((roleName) => (
                      <Tag key={roleName} style={{ cursor: 'pointer', marginBottom: 8 }}>
                        {roleName}
                      </Tag>
                    ))}
                  </div>
                </>
              )}
            />
          </List.Item>
        );
      }}
    />
  );
}

export default ChangeDataScope;
