import { Form } from '@ant-design/compatible';
import { useQuery } from '@apollo/client';
import { Button, Card, notification } from 'antd';
import { Formik, FormikActions } from 'formik';
import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import useAsync from 'react-use/lib/useAsync';

import ModelBreadcrumbs from '../../components/ModelBreadcrumbs';
import TableActions from '../../components/TableActions/TableActions';
import TabsManager from '../../components/TabsManager';
import { generateReadQuery } from '../../graphql';
import getTypes from '../../graphql/introspectSchema';
import useTabTable from '../../hooks/useTabTable';
import BasicLayout from '../../layouts/BasicLayout';
import { IModel } from '../../models/typings';
import {
  DEFAULT_ERROR_MESSAGE,
  displayErrorMessage,
  getErrorInfo,
  RefetchContext,
} from '../../utils';

import applyDefaultFormValues from './applyDefaultFormValues';
import ModelFields from './ModelFields';
import RelationshipFields from './RelationshipFields';
import { onSubmitForm } from './submit';

const FormItem = Form.Item;

interface IModelFormProps extends RouteComponentProps<{ id: string }> {
  model: IModel;
}

const ModelForm: React.FC<IModelFormProps> = (props) => {
  const { location, history, match, model } = props;
  const { params } = match;

  const isNew = !params.id;

  const handleFormSubmit = useCallback(async (values: any, form: FormikActions<any>) => {
    let errored = false;
    let itemId = params.id;

    form.setSubmitting(true);

    try {
      const submittedData = await onSubmitForm(values, form, model, isNew);

      itemId = (submittedData as any)[Object.keys(submittedData)[0]][model.primaryKey];
    } catch (error) {
      errored = true;

      const { friendlyErrorMessage, formErrors } = getErrorInfo(error);

      if (formErrors) {
        form.setErrors(formErrors);
      }

      notification.error({
        message: 'Error',
        description: friendlyErrorMessage || DEFAULT_ERROR_MESSAGE,
      });
    }

    if (!errored) {
      notification.success({
        message: 'Success',
        description: `The ${model.names.displayName.toLowerCase()} was saved successfully!`,
      });

      if (model.routes.detailsRoute?.enabled()) {
        history.push(`${model.routes.basePath}/details/${itemId}`);
      } else if (isNew && model.permissions.canUpdate()) {
        history.push(`${model.routes.basePath}/edit/${itemId}`);
      } else if (isNew) {
        history.push(model.routes.defaultRoute.path);
      }
    }
  }, [history, isNew, model]);

  const modelName = model.names.schemaName;

  // Acts as the componentDidMount
  const types = useAsync(() => getTypes(modelName), [modelName]);
  const { validationSchema, typeSchema } = types.value || {
    typeSchema: {},
    validationSchema: {},
  };

  const query = useMemo(() => generateReadQuery(model), [modelName]);
  const { data, error, loading, refetch } = useQuery(query, {
    skip: isNew || !query,
    variables: { [model.primaryKey]: `${params.id}` },
  });

  const loadedData = data ? data[`${modelName}_by_pk`] : null;

  if (error) {
    displayErrorMessage(error);
  }

  // check if we have data on urlParams
  const urlParams = useMemo(() => new URLSearchParams(location.search), [location]);

  const item: any = {};

  if (!isNew && loadedData) {
    Object.assign(item, loadedData);
  }

  if (isNew || (!isNew && loadedData)) {
    Object.assign(item, applyDefaultFormValues(item, model, isNew));

    // @TODO: Test this against XSS
    if (urlParams.has('data')) {
      try {
        const paramData = JSON.parse(urlParams.get('data') || '{}');
        Object.assign(item, paramData);
      } catch {
        // Do nothing
      }
    }
  }

  const submitFormLayout = {
    wrapperCol: {
      sm: { span: 10, offset: 7 },
      xs: { span: 24, offset: 0 },
    },
  };

  const modelDisplayName = model.names.displayName;

  const actions = [model.actions.deleteAction, ...model.actions.defaultActions];

  if (model.routes.detailsRoute?.enabled()) {
    actions.unshift(model.actions.detailsAction);
  }

  const tabs = model.tables.tabTables.map((table) => useTabTable(table, params.id).tab);

  const canUpdateRow = Boolean(item && model.permissions.canUpdateRow(item));

  // @TODO Make save button disabled configurable per model
  return (
    <Observer>
      {() => (
        <BasicLayout pageTitle={`${isNew ? 'New' : 'Edit'} ${modelDisplayName}`}>
          <Card
            bordered={false}
            title={loading ? '...' : <ModelBreadcrumbs model={model} rowBreadCrumb={{ isNew, row: item }} />}
            loading={!isNew && (isEmpty(item) || loading)}
            extra={(
              !isNew && !isEmpty(item) && (
                <TableActions row={item} actions={actions} />
              )
            )}
          >
            <Formik
              initialValues={item}
              onSubmit={handleFormSubmit}
              validationSchema={validationSchema}
              validate={model.formOptions.validate}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize
              render={formikProps => (
                  <Form
                    style={{ marginTop: 8 }}
                    onSubmit={e => formikProps.handleSubmit(e)}
                  >
                    <RefetchContext.Provider value={refetch}>
                      <ModelFields
                        formikProps={formikProps}
                        canUpdateRow={canUpdateRow}
                        isNew={isNew}
                        model={model}
                        typeSchema={typeSchema}
                      />
                    </RefetchContext.Provider>
                    <RelationshipFields
                      formikProps={formikProps}
                      canUpdateRow={canUpdateRow}
                      model={model}
                      isNew={isNew}
                    />
                    <FormItem {...submitFormLayout} style={{ marginTop: 60, textAlign: 'center' }}>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={formikProps.isSubmitting}
                        disabled={!isNew && !canUpdateRow}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => history.goBack()}
                        style={{ marginLeft: 8 }}
                      >
                        Cancel
                      </Button>
                    </FormItem>
                  </Form>
                )
              }
            />
          </Card>
          {!isNew && !!tabs.length && (
            <div style={{ marginTop: 24 }}>
              <TabsManager tabs={tabs} />
            </div>
          )}
        </BasicLayout >
      )}
    </Observer>
  );
}

export default ModelForm;
