import { filterQueriedRoles } from './filterQueriedRoles';
import { getScopeQueryFilters } from './getScopeQueryFilters';
import { getWarningFlags } from './getWarningFlags';
import { getWarnings } from './getWarnings';
import { IUseGrantableRolesArgs } from './typings';
import { useRolesQuery } from './useRolesQuery';
import { useScopeQueries } from './useScopeQueries';

export function useGrantableRoles(args: IUseGrantableRolesArgs) {
  const scopeQueryFilters = getScopeQueryFilters(args);

  const scopeQueriesResult = useScopeQueries(scopeQueryFilters);

  const warningFlags = getWarningFlags(args, scopeQueriesResult);
  const warnings = getWarnings(scopeQueriesResult.organizations || [], warningFlags);

  const rolesQueryResult = useRolesQuery(args, scopeQueriesResult);
  const roles = filterQueriedRoles(
    rolesQueryResult.roles,
    args.permissionScope,
    warningFlags,
  );

  return {
    roles,
    warnings,
    loading: scopeQueriesResult.loading || rolesQueryResult.loading,
  };
}
