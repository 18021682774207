import { MutationOptions } from '@apollo/client';
import { FormikActions } from 'formik';
import mapValues from 'lodash/mapValues';

import {
  apiClient,
  generateCreateMutation,
  generateReadQuery,
  generateUpdateMutation,
  hasuraClient,
  sanitizeInput
} from '../../graphql';
import { UserRoleStatus_enum } from '../../graphql/hasura/generated';
import { IModel } from '../../models/typings';

export type SubmitFormHandler = (
  values: any,
  form: FormikActions<any>,
  model: IModel,
  isNew: boolean,
  composeApolloOptions?: (object: MutationOptions<any, any>) => MutationOptions<any, any>
) => Promise<boolean>

export const onSubmitForm: SubmitFormHandler = async (values, form, model, isNew, composeApolloOptions = ((opts) => opts)) => {
  const primaryKey = model.primaryKey;
  const readQuery = generateReadQuery(model);

  if (!readQuery) {
    return false;
  }

  const mutation = isNew ? generateCreateMutation(model) : generateUpdateMutation(model);

  if (!mutation) {
    return false;
  }

  form.setSubmitting(true);

  // store empty string value as null
  const newValues = mapValues(values, v => (v === '' ? null : v));

  // @TODO: Move email lowercase conversion logic to model configuration
  if (typeof newValues.email === 'string') {
    newValues.email = newValues.email.toLowerCase();
  }

  const variables = { data: newValues };

  if (!isNew) {
    // @ts-ignore
    variables[primaryKey] = values[primaryKey];

    // NOTE: We need to double check this on each schema and ensure
    // we have the default on each one of these.
    variables.data.updatedAt = new Date();
  } else {
    // NOTE: We need to double check this on each schema and ensure
    // we have the default on each one of these.
    variables.data.createdAt = new Date();
    variables.data.updatedAt = new Date();
  }

  // TODO: Decouple this from here
  if (
    variables.data.revokedAt &&
    variables.data.status === UserRoleStatus_enum.ACTIVE
  ) {
    variables.data.status = UserRoleStatus_enum.REVOKED;
  }
  if (
    variables.data.revokedAt === null &&
    variables.data.status === UserRoleStatus_enum.REVOKED
  ) {
    variables.data.status = UserRoleStatus_enum.ACTIVE;
  }

  try {
    const { data } = await hasuraClient.mutate(composeApolloOptions({
      mutation,
      variables: {
        ...variables,
        data: sanitizeInput(
          isNew ? 'insert' : 'update',
          model,
          variables.data
        ),
      },
    }));

    // Reset cache to reflect inserted/updated data
    await hasuraClient.resetStore();
    await apiClient.resetStore();

    form.setSubmitting(false);

    return data;
  } catch (error) {
    form.setSubmitting(false);
    throw error;
  }
}
