import {
  PermissionKey_enum,
  PermissionScope_enum,
  PropertyUserRolesTableFragment,
  PropertyUserRolesTableFragmentDoc,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { createdAtColumn } from '../../common/columns';
import PERMISSION_SCOPE_ICON_MAP from '../../helpers/permission-scopes';
import * as columns from '../columns';
import model from '../model';

import { commonTableConfig } from './common';

const isPropertyScopeReadable = () => (
  authentication.hasPermission(PermissionKey_enum.User_Read) ||
  authentication.readableRoleScopes.includes(PermissionScope_enum.PROPERTY)
);

export const PropertyUserRolesTable = model.createTable<PropertyUserRolesTableFragment>({
  ...commonTableConfig,
  title: 'Property Roles',
  enabled: isPropertyScopeReadable,
  icon: PERMISSION_SCOPE_ICON_MAP[PermissionScope_enum.PROPERTY],
  fragment: PropertyUserRolesTableFragmentDoc,
  fixedQueryFilters: () => ({
    scopedPropertyId: { _is_null: false },
  }),
  columns: [
    columns.statusColumn,
    columns.propertyRoleColumn,
    columns.propertyColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});

export const PropertyUserRolesByUserTable = model.createTable<PropertyUserRolesTableFragment, string>({
  ...commonTableConfig,
  title: 'Property Roles',
  enabled: (_, assignedToUserId) => (
    isPropertyScopeReadable() || assignedToUserId === authentication.chirpUserId
  ),
  icon: PERMISSION_SCOPE_ICON_MAP[PermissionScope_enum.PROPERTY],
  fragment: PropertyUserRolesTableFragmentDoc,
  fixedQueryFilters: (assignedToUserId) => ({
    assignedToUserId: { _eq: assignedToUserId },
    scopedPropertyId: { _is_null: false },
    status: {
      _in: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
    },
  }),
  // defaultColumnFilters: {
  //   UserRoleStatus: [UserRoleStatus_enum.ACTIVE],
  // },
  columns: [
    columns.statusColumn,
    columns.propertyRoleColumn,
    columns.propertyColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});
