import { OrganizationsMainTableFragment } from '../../graphql/hasura/generated';

import model from './model';

export const nameColumn = model.createColumn<{ name: string }>({
  dataIndex: 'name',
});

export const identifierColumn = model.createColumn<{ identifier: string }>({
  dataIndex: 'identifier',
});

export const totalPropertiesColumn = model.createColumn<
  Pick<OrganizationsMainTableFragment, 'properties_aggregate'>
>({
  title: 'Total Properties',
  render: ({ properties_aggregate }) => {
    return properties_aggregate.aggregate?.count || 0;
  },
});

export const totalRolesColumn = model.createColumn<
  Pick<OrganizationsMainTableFragment, 'roles_aggregate'>
>({
  title: 'Total Custom Roles',
  render: ({ roles_aggregate }) => {
    return roles_aggregate.aggregate?.count || 0;
  },
});
