import { useState } from 'react';

import type { IOnTableChangeArgs, ITableState, IUseTableStateArgs } from './typings';

export function useTableState(args: IUseTableStateArgs) {
  const { defaultPageSize, defaultColumnFilters } = args;
  const defaultOrderBy = args.defaultOrderBy || { createdAt: 'descend' };

  const [tableState, setTableState] = useState<ITableState>({
    pageNumber: 1,
    pageLimit: defaultPageSize || 25,
    search: null,
    filters: defaultColumnFilters || {},
    orderBy: defaultOrderBy,
  });

  const onTableChange = ({ pagination, filters, sorter }: IOnTableChangeArgs) => {
    let orderBy = null;

    if (sorter && sorter.hasOwnProperty('field') && !Array.isArray(sorter)) {
      const { field, order } = sorter;

      if (field && order && !Array.isArray(field)) {
        orderBy = { [field]: order };
      }
    }

    setTableState({
      orderBy,
      search: tableState.search,
      pageNumber: pagination?.current || 1,
      pageLimit: pagination?.pageSize || 25,
      filters: filters || {},
    });
  };

  const onSearchChange = (value: string) => {
    setTableState({ ...tableState, search: value, pageNumber: 1 });
  };

  return { onTableChange, onSearchChange, tableState };
}
