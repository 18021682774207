import type { DateRangeInput } from '../../../graphql/api/generated';
import type { ColumnFilters, IModelColumn } from '../../../models/typings';

export function formatQueryFilters(columns: IModelColumn<any>[], columnFilters: ColumnFilters) {
  return Object.keys(columnFilters)
    .reduce<{ [key: string]: any }>((queryFilters, filterKey) => {
      const values = columnFilters[filterKey];

      if (!values?.length) {
        return queryFilters;
      }

      const column = columns.find(c => c.key === filterKey);
      const filterOptions = column?.filterOptions;

      if (filterOptions?.type === 'MODEL_SELECT') {
        // @TODO: Support multiple select?
        return { ...queryFilters, [filterKey]: values[0] };
      }

      if (filterOptions?.type === 'ENUM') {
        return { ...queryFilters, [filterKey]: values };
      }

      if (filterOptions?.type === 'DATE_RANGE') {
        const [startDate, endDate] = values as string[];
        const dateRange: DateRangeInput = { startDate, endDate };

        return { ...queryFilters, [filterKey]: dateRange };
      }

      if (filterOptions?.type === 'RADIO') {
        const { options: radioOptions } = filterOptions;
        const [value] = values;

        if (radioOptions?.length) {
          const radioQueryFilters = radioOptions.find(o => o.label === value)?.queryFilters;

          // Use custom radio options
          if (radioQueryFilters) {
            return { ...queryFilters, ...radioQueryFilters };
          }
        } else {
          // Default to boolean field behavior
          return { ...queryFilters, [filterKey]: value === 'Yes' ? true : false };
        }
      }

      return queryFilters;
    }, {});
}
