import { AccessPoint, AccessPointType_enum } from '../../graphql/hasura/generated';

export function validateAccessPoint(values: AccessPoint) {
  const errors: { [k: string]: any } = {};
  const { type, maxOccupancy } = values;

  if (type === AccessPointType_enum.RESERVATION_ONLY) {
    if (maxOccupancy === undefined || maxOccupancy === null || (maxOccupancy && maxOccupancy <= 0)) {
      errors['maxOccupancy'] = 'Please provide a maximum occupancy'
    }
  }

  return errors;
}

export default validateAccessPoint;
