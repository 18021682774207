export interface ICredentialFormValues {
  fobFlow: FobFlow;
  identifier: string | null;
  ownedByPropertyId: string | null;
  facilityCode: string | null;
  cardNumber: string | null;
  bitFormat: string | null;
}

export enum FobFlow {
  ENROLLMENT_READER = 'ENROLLMENT READER',
  ENROLLMENT_USB = 'ENROLLMENT USB',
  MANUAL_ENROLLMENT = 'MANUAL_ENROLLMENT',
}
