import { Alert, Divider, Typography } from 'antd';
import React from 'react';

import { usegetPropertyDetailsQuery } from '../../../graphql/hasura/generated';
import { getPropertyFeatureFlags } from '../../../utils';
import { ModelFormSelect } from '../../ModelForm/ModelFormSelect';

import { ISgtUserFormMergedProps } from './formik';

export const SelectPropertySection: React.FC<ISgtUserFormMergedProps> = (props) => {
  const { setFieldValue, values, errors: formikErrors, isSubmitting, PropertyModel } = props;
  const { scopedIds, propertyFeatureFlags } = values;

  const [propertyId] = scopedIds;

  usegetPropertyDetailsQuery({
    skip: !propertyId,
    variables: { propertyId },
    onCompleted: (data) => {
      setFieldValue('propertyFeatureFlags', getPropertyFeatureFlags(data.property));
    },
  });

  const vendorPinsDisabled = propertyFeatureFlags?.VENDOR_PINS === false;

  return (
    <>
      <Typography.Title level={4}>Select scope of access</Typography.Title>
      <Typography.Paragraph>
        Please select a property with Smart Signs and Vendor PINs enabled.
      </Typography.Paragraph>
      <ModelFormSelect
        label='Property'
        fieldName='scopedIds'
        formItemProps={{
          className: 'label-align-top',
          style: { marginBottom: 20 },
        }}
        modelSelectProps={{
          style: { width: '100%' },
          model: PropertyModel,
          value: propertyId,
          multiple: false,
          allowClear: false,
          required: true,
          disabled: isSubmitting,
          autoSelectSingleResult: true,
        }}
        setFieldValue={(fieldName, nextScopedIds) => {
          setFieldValue(fieldName, [nextScopedIds]);
        }}
        errors={formikErrors}
        submitCount={1}
      />
      {vendorPinsDisabled && (
        <div style={{ marginTop: 30 }}>
          <Alert
            showIcon
            type='warning'
            message='Vendor PINs are not enabled for this property'
          />
        </div>
      )}
      {!vendorPinsDisabled && scopedIds.length > 0 && <Divider />}
    </>
  );
};
