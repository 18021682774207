import { CheckOutlined } from '@ant-design/icons';
import { enumManagers } from '@chirp/enums';
import { Tag } from 'antd';
import React from 'react';

import EnumTag, { Color } from '../../components/EnumTag';
import ImageThumbnail from '../../components/ImageThumbnail';
import {
  AccessPointAvailabilityFragment,
  AccessPointType_enum,
  ImageThumbnailFragment as Image,
  PermissionScope_enum,
  PropertyLabelFragment,
} from '../../graphql/hasura/generated';
import { getAvailabilityDescription } from '../../utils';
import { PropertyModel } from '../Property/model';

import model from './model';

export const propertyColumn = model.createColumn<{ property: PropertyLabelFragment }>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'propertyId',
    model: PropertyModel,
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ property }) => PropertyModel.routes.renderRowLink(property),
});

export const nameColumn = model.createColumn<{ name: string }>({
  dataIndex: 'name',
});

export const imageColumn = model.createColumn<{ name: string, image?: Image | null }>({
  title: 'Image',
  sorter: false,
  render: ({ name, image }) => <ImageThumbnail title={name} image={image} />,
  renderString: false,
});

export const typeColumn = model.createColumn<{ type: AccessPointType_enum }>({
  title: 'Type',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'AccessPointType',
  },
  render: ({ type }) => {
    return (
      <EnumTag
        enumValue={type}
        colorMap={{
          [AccessPointType_enum.COMMON_AREA]: Color.Green,
          [AccessPointType_enum.AMENITY]: Color.Blue,
          [AccessPointType_enum.RESERVATION_ONLY]: Color.Blue,
        }}
      />
    );
  },
  renderString: ({ type }) => enumManagers.AccessPointType.getLabel(type),
});

export const accessTimeoutColumn = model.createColumn<{ accessTimeout?: number | null }>({
  title: 'Access Timeout',
  dataIndex: 'accessTimeout',
  render: ({ accessTimeout }) => (
    typeof accessTimeout === 'number' ? `${(accessTimeout / 1000)} second(s)` : null
  ),
});

export const availabilityColumn = model.createColumn<AccessPointAvailabilityFragment>({
  title: 'Availability',
  filterOptions: {
    type: 'RADIO',
    key: 'availability',
    options: [
      {
        label: 'Available 24/7',
        queryFilters: {
          _and: [
            {
              timetable: { _is_null: true },
            },
            {
              _not: {
                accessPointRoles: {
                  schedule: { _is_null: false }
                },
              },
            }
          ],
        },
      },
      {
        label: 'Schedules Applied',
        queryFilters: {
          _or: [
            {
              timetable: { _is_null: false },
            },
            {
              accessPointRoles: {
                schedule: { _is_null: false }
              },
            },
          ],
        },
      },
    ]
  },
  render: ({ timetable, property }) => {
    const availability = getAvailabilityDescription(new Date(), timetable, property.timezone);

    if (availability === 'LOCKDOWN') {
      return (
        <Tag color={Color.Red} style={{ fontWeight: 'bold' }}>
          LOCKDOWN
        </Tag>
      );
    }

    return availability;
  },
});

export const spaceshipModeEnabledColumn = model.createColumn<{ spaceshipModeEnabled: boolean }>({
  title: 'Spaceship Mode Enabled',
  filterOptions: {
    type: 'RADIO',
    key: 'spaceshipModeEnabled',
  },
  render: ({ spaceshipModeEnabled }) => {
    return spaceshipModeEnabled ? <CheckOutlined /> : null;
  },
  renderString: ({ spaceshipModeEnabled }) => spaceshipModeEnabled ? 'Yes' : 'No',
});

export const proximityRestrictionColumn = model.createColumn<{ proximityRestriction: boolean }>({
  title: 'Proximity Restriction',
  filterOptions: {
    type: 'RADIO',
    key: 'proximityRestriction',
  },
  render: ({ proximityRestriction }) => {
    return proximityRestriction ? <CheckOutlined /> : null;
  },
  renderString: ({ proximityRestriction }) => proximityRestriction ? 'Yes' : 'No',
});
