import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/browser';
import { createUploadLink } from 'apollo-upload-client';
import { GraphQLError } from 'graphql';
import { createClient } from 'graphql-ws';
import { camelCase } from 'lodash';
import { fetch } from 'whatwg-fetch';

import { GRAPHQL_AUTH_SERVER } from '../../config';
import { authentication } from '../../stores';
import { getDeviceFingerprint } from '../../utils';

const httpLink = createUploadLink({
  fetch,
  uri: GRAPHQL_AUTH_SERVER,
  headers: {
    'apollo-require-preflight': 'true', // Preventing CSRF
  },
});

const WS_GRAPHQL_SERVER = GRAPHQL_AUTH_SERVER.replace(/^http/, 'ws');

const wsLink = new GraphQLWsLink(createClient({
  url: WS_GRAPHQL_SERVER,
  lazy: true,
  shouldRetry: () => true,
  connectionParams: () => ({
    authorization: authentication.chirpAccessToken,
  }),
}));

const splittedLink = split(
  ({ query }: any) => {
    // https://github.com/apollographql/apollo-client/issues/3090#issuecomment-379804727
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    );
  },
  wsLink,
  // @ts-ignore @TODO: Upgrade package (requires upgraded Node version)
  httpLink,
);

const authMiddleware = setContext(async () => {
  const { chirpAccessToken } = authentication;
  const headers: { [k: string]: string } = {
    'x-device-fingerprint': await getDeviceFingerprint(),
  };

  if (chirpAccessToken) {
    headers.authorization = chirpAccessToken;
  }

  return { headers };
});

const errorLink = onError(
  ({ operation, graphQLErrors = [], networkError, forward }) => {
    const combinedErrors = networkError ? [networkError, ...graphQLErrors] : [...graphQLErrors];

    const logError = ({ message, originalError }: GraphQLError) => {
      const loggedError = originalError || new Error(message);

      Sentry.withScope((scope) => {
        scope.setTransactionName(operation.operationName);

        Sentry.captureException(loggedError, {
          fingerprint: [operation.operationName, message],
          tags: {
            'apollo-client': 'main-api',
          },
          extra: {
            operationName: operation.operationName,
            variables: JSON.stringify(operation.variables, null, 2),
          },
        });
      });
    };

    const handleError = ({ message }: Error) => {
      if (
        typeof message === 'string' &&
        message.includes('renew access token') &&
        operation.operationName !== 'signOut'
      ) {
        return authentication.renewSessionLink(operation, forward);
      }
    };

    // Do not log network error
    for (const error of graphQLErrors) {
      logError(error);
    }

    for (const error of combinedErrors) {
      const observable = handleError(error);

      if (observable) {
        return observable;
      }
    }
  }
);

const apolloLink = ApolloLink.from([errorLink, authMiddleware, splittedLink]);

export const apiClient = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      // These types do not follow the same convention as our database tables
      switch (object.__typename) {
        case 'AugustLock':
          return `${object.LockID}`;
        case 'AugustLockBridge':
          return `${object._id}`;
        case 'AugustLockUser':
          return `${object.userId}`;
        case 'AugustPinCode':
          return `${object.lockID}:${object.userID}`;
        default:
          break;
      }

      const objectId = (object as any)[`${camelCase(object.__typename)}Id`];

      if (!objectId) {
        return undefined;
      }

      return `${object.__typename}_${objectId}`;
    },
    typePolicies: {
      // https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
      // Objects that are not normalized are instead embedded within their parent object in the cache.
      // You can't access these objects directly, but you can access them via their parent
      AugustLockBatteryInfo: { keyFields: false },
      AugustLockBridgeStatus: { keyFields: false },
      AugustLockFirmwareInfo: { keyFields: false },
      AugustLockStatus: { keyFields: false },
    },
  }),
  link: apolloLink
});

export async function clearApiCache() {
  await apiClient.cache.reset();
}
