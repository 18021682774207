import {
  Ticket_bool_exp,
  TicketLabelFragment,
  TicketRequiredRowFragment,
} from '../../graphql/hasura/generated';
import Model from '../Model';

export const TicketModel = new Model<
  Ticket_bool_exp,
  TicketLabelFragment,
  TicketLabelFragment,
  TicketRequiredRowFragment
>({
  names: {
    schemaName: 'Ticket',
  },
});

export default TicketModel;
