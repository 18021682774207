import { AccessPointRole_bool_exp, PermissionKey_enum } from '../../graphql/hasura/generated';
import Model from '../Model';

export const AccessPointRoleModel = new Model<AccessPointRole_bool_exp>({
  names: {
    schemaName: 'AccessPointRole',
  },

  permissionsOptions: {
    canRead: ({ hasPermission }) => hasPermission(PermissionKey_enum.AccessPoint_Read),
    canCreate: ({ hasPermission }) => hasPermission(PermissionKey_enum.AccessPoint_Update),
    canUpdate: ({ hasPermission }) => hasPermission(PermissionKey_enum.AccessPoint_Update),
    canDelete: ({ hasPermission }) => hasPermission(PermissionKey_enum.AccessPoint_Update),
  },
});

export default AccessPointRoleModel;
