import { kebabCase } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import ModelForm from '../pages/ModelForm';
import ModelImport from '../pages/ModelImport';
import ModelMainTable from '../pages/ModelMainTable';

import { IModel, IModelRoute, IModelRouteOptions, IModelRoutes } from './typings';

export class ModelRoutes<
  TLabel,
  TUniqueLabel extends TLabel = TLabel
> implements IModelRoutes<TLabel, TUniqueLabel> {

  public readonly basePath: string;

  // tslint:disable variable-name
  private _defaultRoute: IModelRoute;
  private _createRoute: IModelRoute;
  private _importRoute: IModelRoute;
  private _editRoute: IModelRoute;
  private _detailsRoute?: IModelRoute;
  private _customRoutes: IModelRoute[] = [];

  private readonly model: IModel<TLabel, any, any>;

  constructor(model: IModel<TLabel, any, any>) {
    this.model = model;
    this.basePath = `/${kebabCase(this.model.names.pluralDisplayName)}`;

    const routes = this.setRoutes({}); // Set default configuration

    this._defaultRoute = routes.defaultRoute;
    this._createRoute = routes.createRoute;
    this._importRoute = routes.importRoute;
    this._editRoute = routes.editRoute;
  }

  public get defaultRoute() { return this._defaultRoute; }
  public get createRoute() { return this._createRoute; }
  public get importRoute() { return this._importRoute; }
  public get editRoute() { return this._editRoute; }
  public get detailsRoute() { return this._detailsRoute; }
  public get customRoutes() { return this._customRoutes; }

  public setRoutes(routes: {
    defaultRoute?: Partial<IModelRouteOptions>;
    createRoute?: Partial<IModelRouteOptions>;
    importRoute?: Partial<IModelRouteOptions>;
    editRoute?: Partial<IModelRouteOptions>;
    detailsRoute?: Omit<IModelRouteOptions, 'path'>;
    customRoutes?: IModelRouteOptions[];
  }) {
    this._defaultRoute = {
      path: this.basePath,
      component: ModelMainTable,
      ...routes.defaultRoute,
      enabled: () => (
        typeof routes.defaultRoute?.enabled === 'function'
          ? routes.defaultRoute.enabled(this.model.permissions)
          : this.model.permissions.canRead()
      ),
    };

    this._createRoute = {
      path: `${this.basePath}/new`,
      component: ModelForm,
      ...routes.createRoute,
      enabled: () => (
        typeof routes.createRoute?.enabled === 'function'
          ? routes.createRoute.enabled(this.model.permissions)
          : this.model.permissions.canCreate()
      ),
    };

    this._importRoute = {
      path: `${this.basePath}/import`,
      component: ModelImport,
      ...routes.importRoute,
      enabled: () => (
        typeof routes.importRoute?.enabled === 'function'
          ? routes.importRoute.enabled(this.model.permissions)
          : !!this.model.formOptions.importOptions && this.model.permissions.canCreate()
      ),
    };

    this._editRoute = {
      path: `${this.basePath}/edit/:id`,
      component: ModelForm,
      ...routes.editRoute,
      enabled: () => (
        typeof routes.editRoute?.enabled === 'function'
          ? routes.editRoute.enabled(this.model.permissions)
          : this.model.permissions.canUpdate()
      ),
    };

    this._detailsRoute = routes?.detailsRoute
      ? {
        path: `${this.basePath}/details/:id`,
        ...routes.detailsRoute,
        enabled: () => (
          typeof routes.detailsRoute?.enabled === 'function'
            ? routes.detailsRoute.enabled(this.model.permissions)
            : this.model.permissions.canRead()
        ),
      }
      : undefined;

    this._customRoutes = (routes.customRoutes || []).map(route => ({
      ...route,
      enabled: () => {
        if (typeof route?.enabled === 'function') {
          return route.enabled(this.model.permissions);
        }

        return this.model.permissions.canRead();
      },
    }));

    return {
      defaultRoute: this._defaultRoute,
      createRoute: this._createRoute,
      importRoute: this._importRoute,
      editRoute: this._editRoute,
      detailsRoute: this._detailsRoute,
      customRoutes: this._customRoutes,
    };
  }

  public renderRowLink(row: TLabel | null | undefined) {
    if (!row) {
      return null;
    }

    const link = this.getRowLink(row);
    const label = this.model.labels.getLabel(row);

    if (!link) {
      return label;
    }

    return <Link to={link}>{label}</Link>;
  }

  public renderUniqueRowLink(row: TUniqueLabel | null | undefined) {
    if (!row) {
      return null;
    }

    const link = this.getRowLink(row);
    const label = this.model.labels.getUniqueLabel(row);

    if (!link) {
      return label;
    }

    return <Link to={link}>{label}</Link>;
  }

  public renderRowLinks(rows: TLabel[]) {
    return rows.map((row, index, arr) => (
      <span key={index}>
        {this.renderRowLink(row)}
        {(index !== arr.length - 1) && (
          <>{','}<br /></>
        )}
      </span>
    ));
  }

  public getRowLinkById(id: string) {
    if (this.detailsRoute && this.detailsRoute.enabled()) {
      return this.detailsRoute.path.replace(':id', id);
    }

    if (this.model.routes.editRoute.enabled()) {
      return this.editRoute.path.replace(':id', id);
    }

    return null;
  }

  public getRowLink(row: TLabel | TUniqueLabel) {
    if (!row) {
      return null;
    }

    const id = (row as any)[this.model.primaryKey] as string;

    if (!id) {
      return null;
    }

    return this.getRowLinkById(id);
  }
}

export default ModelRoutes;
