import model from './model';

model.routes.setRoutes({
  defaultRoute: {
    enabled: () => false,
  },
  editRoute: {
    enabled: () => false,
  },
});
