import { Table } from 'antd';
import React from 'react';

import { apiClient } from '../../../graphql/api/apiClient';
import { RelayLogTableFragment, usegetRelayLogsQuery } from '../../../graphql/api/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import normalizeColumns from '../../helpers/normalizeColumns';
import { RelayLogsMainTable } from '../tables';

const RelayLogDetailsLayout = createDetailsLayout<RelayLogTableFragment>();

export const RelayLogDetailsPage: React.FC<any> = () => {
  return (
    <RelayLogDetailsLayout
      modelName='RelayLog'
      useQuery={(relayLogId) => {
        const { data, ...queryResult } = usegetRelayLogsQuery({
          variables: {
            input: {
              filters: { relayLogId },
            },
          },
          client: apiClient,
        });

        return {
          ...queryResult,
          data: data ? { relayLog: data.relayLogs.list[0] } : data,
        };
      }}
      body={(relayLog) => {
        const columns = normalizeColumns(RelayLogsMainTable.columns);

        return (
          <Table
            columns={columns}
            dataSource={[relayLog]}
            rowKey={u => u.relayLogId}
            pagination={false}
            expandable={RelayLogsMainTable.expandable}
          />
        );
      }}
    />
  );
};

export default RelayLogDetailsPage;
