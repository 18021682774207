import { Card, Col, Row, Spin, Statistic } from 'antd';
import React, { useCallback, useState } from 'react';

import { DatePickerWithFormItem } from '../../components/DatePicker';
import { useaugustSdkFeesReportLazyQuery } from '../../graphql/hasura/generated';

const AugustSDKFeesReport = () => {
  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(null);
  const [loadReport, { data, loading }] = useaugustSdkFeesReportLazyQuery();

  const stats = [
    {
      title: 'Total Active Locks',
      value:  data?.totalSmartLocks?.aggregate?.count,
    },
    {
      title: 'Total Properties w/ Active Locks',
      value: data?.totalProperties?.aggregate?.count,
    },
  ];

  const renderReportStats = useCallback(() => {
    return stats.map(({ title, value }) => (
      <Col key={title} span={6}>
        <Statistic
          title={title}
          valueRender={() => {
            return loading ? <Spin /> : (value ?? '-');
          }}
        />
      </Col>
    ));
  }, [stats]);

  return <>
    <Card
      title='August SDK Fees Report'
      bordered={false}
      style={{ marginBottom: '20px' }}
    >
      <Row gutter={48}>
        <Col span={6}>
          <DatePickerWithFormItem
            unit={'month'}
            startDate={selectedStartDate}
            setSelectedStartDate={(nextSelectedStartDate) => {
              const startDate = nextSelectedStartDate.toISOString();
              const endDate = nextSelectedStartDate.clone().endOf('month').toISOString();

              setSelectedStartDate(startDate);
              loadReport({
                variables: { startDate, endDate },
              });
            }}
            disabled={loading}
            required
          />
        </Col>
        {renderReportStats()}
      </Row>
    </Card>
  </>;
}

export default AugustSDKFeesReport;
