import {
  order_by,
  Property_bool_exp,
  PropertyActionsFragment,
  PropertyLabelFragment,
  PropertyLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import { emptySearchConditionsFilter } from '../../utils';
import Model from '../Model';

export const PropertyModel = new Model<
  Property_bool_exp,
  PropertyLabelFragment,
  PropertyLabelFragment,
  PropertyActionsFragment
>({
  names: {
    schemaName: 'Property',
  },

  labels: {
    getLabel: (p) => p.name,
    labelFragment: PropertyLabelFragmentDoc,
  },

  permissionsOptions: {
    canRead: () => true,
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            {
              organization: {
                name: { _ilike: `%${word}%` },
              },
            },
            {
              name: { _ilike: `%${word}%` },
            },
          ],
        }
      ))
    }),
    getTableSearchConditions: (words) => ({
      _and: words.map((word) => {
        // Strip special characters for phone number search: ( ) - +
        const phoneNumberWord = word.replace(/[\(\)\-\+]/g, '');

        return {
          _or: [
            {
              organization: {
                name: { _ilike: `%${word}%` },
              },
            },
            {
              name: { _ilike: `%${word}%` },
            },
            {
              supportPhoneNumber: { _ilike: phoneNumberWord ? `%${phoneNumberWord}%` : undefined },
            },
            {
              supportEmail: { _ilike: `%${word}%` },
            },
            {
              propertyFeatureFlags: {
                featureFlag: {
                  name: { _ilike: `%${word}%` },
                },
              },
            },
          ].filter(emptySearchConditionsFilter),
        };
      }),
    }),
    defaultSelectOrderBy: {
      name: order_by.asc,
    },
  },
});

export default PropertyModel;
