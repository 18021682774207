import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  fromPromise,
  InMemoryCache,
  NextLink,
  Operation,
  split,
  toPromise,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import * as Sentry from '@sentry/browser';
import { GraphQLError } from 'graphql';
import { createClient } from 'graphql-ws';
import { camelCase } from 'lodash';
import { fetch } from 'whatwg-fetch';

import { GRAPHQL_SERVER } from '../../config';
import { authentication } from '../../stores';
import { isConstraintViolationError } from '../../utils';

import { UserRoleLoader } from './data-loaders/UserRoleLoader';

const WS_GRAPHQL_SERVER = GRAPHQL_SERVER.replace(/^http/, 'ws');

function retryOperation(operation: Operation, forward: NextLink, delay = 1000) {
  return fromPromise((async () => {
    await new Promise(r => setTimeout(r, delay));

    return toPromise(forward(operation));
  })());
}

const httpLink = createHttpLink({
  fetch,
  uri: GRAPHQL_SERVER,
});

const wsLink = new GraphQLWsLink(createClient({
  url: WS_GRAPHQL_SERVER,
  lazy: true,
  shouldRetry: () => true,
  connectionParams: () => ({
    headers: authentication.headers,
  }),
}));

const splittedLink = split(
  ({ query }: any) => {
    // https://github.com/apollographql/apollo-client/issues/3090#issuecomment-379804727
    const definition = getMainDefinition(query);

    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

const authMiddleware = setContext(async ({ operationName }) => {
  const { chirpAccessToken } = authentication;

  if (chirpAccessToken) {
    const { chirpRefreshToken } = authentication;

    if (!chirpRefreshToken) {
      await authentication.logout();

      return false;
    }

    const headers = { ...authentication.headers } as any;

    if (operationName === 'authenticatedUser') {
      // We need to use this Hasura role in order to query all allowed roles for the user
      headers['x-hasura-role'] = 'user';
    }

    return { headers };
  }

  return {};
});

const errorLink = onError(
  ({ operation, graphQLErrors = [], networkError, forward }) => {
    const combinedErrors = networkError ? [networkError, ...graphQLErrors] : [...graphQLErrors];

    const logError = ({ message, originalError }: GraphQLError) => {
      const loggedError = originalError || new Error(message);

      Sentry.withScope((scope) => {
        scope.setTransactionName(operation.operationName);

        Sentry.captureException(loggedError, {
          fingerprint: [operation.operationName, message],
          tags: {
            'apollo-client': 'hasura',
          },
          extra: {
            operationName: operation.operationName,
            hasuraRole: operation.getContext()?.headers?.['x-hasura-role'],
            variables: JSON.stringify(operation.variables, null, 2),
          },
        });
      });
    };

    const handleError = ({ message }: Error) => {
      if (typeof message === 'string') {
        // We need to upgrade Hasura to 2.x to avoid this error
        // https://github.com/hasura/graphql-engine/issues/2109
        if (message.includes('JWTIssuedAtFuture')) {
          return retryOperation(operation, forward);
        }

        if (
          message.includes('JWT') ||
          message.includes('Expired') ||
          // @TODO: Temporary solution for user UUID migration. Remove this later.
          message.includes('invalid input syntax for type uuid') ||
          message.includes('invalid input syntax for integer')
        ) {
          return authentication.renewSessionLink(operation, forward);
        }
      }
    };

    // Do not log network error
    for (const error of graphQLErrors) {
      if (isConstraintViolationError(error)) {
        continue; // Ignore
      }

      logError(error);
    }

    for (const error of combinedErrors) {
      const observable = handleError(error);

      if (observable) {
        return observable;
      }
    }
  }
);

const apolloLink = ApolloLink.from([errorLink, authMiddleware, splittedLink]);

export const hasuraClient = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: (object) => {
      const objectId = (object as any)[`${camelCase(object.__typename)}Id`];

      if (!objectId) {
        return undefined;
      }

      return `${object.__typename}_${objectId}`;
    },
  }),
  link: apolloLink
});

export const userRoleLoader = new UserRoleLoader(hasuraClient);

export async function clearHasuraCache() {
  await hasuraClient.cache.reset();
  userRoleLoader.clearCache();
}
