import { LockTwoTone } from '@ant-design/icons';
import { Divider, Skeleton, Switch, Typography } from 'antd';
import React from 'react';

import {
  PermissionKey_enum,
  PermissionScope_enum,
  RoleDetailsFragment,
  RoleKey_enum,
  usegetRelatableRolesQuery,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';

import RoleRelationshipTable from './RoleRelationshipTable';

// Map role scopes that are allowed to read/grant/revoke other role scopes
// (Exclude those we don't want managed by admin panel users)
const ROLE_RELATIONSHIP_SCOPES: {
  [key in PermissionScope_enum]: PermissionScope_enum[]
} = {
  GLOBAL: [
    PermissionScope_enum.GLOBAL,
    // Exclude scoped roles since that is controlled by the UserRole_GrantRevokeScoped permission
  ],
  ORGANIZATION: [
    PermissionScope_enum.ORGANIZATION,
    PermissionScope_enum.PROPERTY,
    PermissionScope_enum.UNIT,
    PermissionScope_enum.ACCESS_POINT,
    PermissionScope_enum.SMART_LOCK,
  ],
  PROPERTY: [
    PermissionScope_enum.PROPERTY,
    PermissionScope_enum.UNIT,
    PermissionScope_enum.ACCESS_POINT,
    PermissionScope_enum.SMART_LOCK,
  ],
  UNIT: [],
  ACCESS_POINT: [],
  SMART_LOCK: [],
};

interface IRoleRelationshipManagerProps {
  role: RoleDetailsFragment;
  editMode: boolean;
  setEditMode: (editMode: boolean) => any;
}

export const RoleRelationshipManager: React.FC<IRoleRelationshipManagerProps> = (props) => {
  const { role, editMode, setEditMode } = props;

  const withinPermissionScope = (
    (role.permissionScope !== PermissionScope_enum.GLOBAL && !!role.organization) ||
    authentication.hasPermission(PermissionKey_enum.Role_ManageUnrestricted)
  );

  const readOnly = (
    role.isLocked ||
    !authentication.hasPermission(PermissionKey_enum.Role_ManagePermissions) ||
    !withinPermissionScope ||
    ![
      PermissionScope_enum.GLOBAL,
      PermissionScope_enum.ORGANIZATION,
      PermissionScope_enum.PROPERTY,
      // The only Unit role that can currently read/grant/revoke is Resident.
      // Access Point and Smart Lock roles cannot currently read/grant/revoke.
    ].includes(role.permissionScope)
  );

  const roleRelationshipScopes = ROLE_RELATIONSHIP_SCOPES[role.permissionScope];
  const skipQuery = readOnly || !roleRelationshipScopes.length;

  const organizationId = role.organization?.organizationId;

  const { data, loading, error } = usegetRelatableRolesQuery({
    fetchPolicy: 'network-only',
    skip: skipQuery,
    variables: {
      where: {
        permissionScope: { _in: roleRelationshipScopes },
        _or: [
          {
            organizationId: { _is_null: true },
          },
          {
            organizationId: organizationId
              ? { _eq: role.organization?.organizationId }
              : undefined,
          },
        ],
        _and: [{
          _or: [
            {
              key: { _is_null: true },
            },
            {
              // Ensure this is never an option
              key: { _neq: RoleKey_enum.SUPER_ADMIN },
            },
          ],
        }],
      },
    },
  });

  if (!skipQuery && (loading || error || !data?.roles)) {
    return <Skeleton loading active />;
  }

  if (role.rolePermissions.some(rp => rp.permission.key === PermissionKey_enum.UserRole_GrantRevokeGlobal)) {
    return (
      <div style={{ paddingTop: 20 }}>
        <Typography.Paragraph>
          This role is able to grant/revoke ALL global roles.
        </Typography.Paragraph>
      </div>
    );
  }

  return (
    <div style={{ paddingTop: 20 }}>
      {!readOnly && (
        <div>
          Edit Mode: <strong>{editMode ? 'ON' : 'OFF'}</strong>
          <Switch
            checked={editMode}
            onChange={(checked) => setEditMode(checked)}
            style={{ marginLeft: 15 }}
            disabled={readOnly}
          />
        </div>
      )}

      {role.isLocked && (
        <Typography.Paragraph>
          <LockTwoTone style={{ marginRight: 5 }} /> Managed roles are locked for this role and cannot be edited.
        </Typography.Paragraph>
      )}

      {(!readOnly || role.isLocked) && <Divider />}

      <RoleRelationshipTable
        role={role}
        relatableRoles={data?.roles || []}
        readOnly={readOnly || !editMode}
      />
    </div>
  );
};

export default RoleRelationshipManager;
