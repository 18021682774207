import { PermissionKey_enum } from '../../graphql/hasura/generated';

import model from './model';
import UnitDetailsPage from './pages/UnitDetailsPage';
import UnitResidentAuditPage from './pages/UnitResidentAuditPage';

model.routes.setRoutes({
  detailsRoute: {
    component: UnitDetailsPage,
  },
  customRoutes: [
    {
      component: UnitResidentAuditPage,
      path: `${model.routes.basePath}/resident-audit/:unitId`,
      enabled: ({ hasPermission }) => (
        hasPermission(PermissionKey_enum.Unit_PmsResidentAudit)
      ),
    },
  ],
});
