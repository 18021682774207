import moment from 'moment-timezone';
import React from 'react';

import { TimetableRule, TimetableRuleState } from '../../graphql/api/generated';

const DAY_ABBREVIATIONS = [
  'M',
  'Tu',
  'W',
  'Th',
  'F',
  'Sa',
  'Su',
];

interface ITimetableProps {
  timetable?: TimetableRule[] | null;
  filter?: (r: TimetableRule) => boolean;
  twentyFourSevenElement?: React.ReactNode;
}

export const Timetable: React.FC<ITimetableProps> = (props) => {
  const { timetable, filter, twentyFourSevenElement } = props;

  const filteredTimetable = timetable && typeof filter === 'function'
    ? timetable.filter(filter)
    : (timetable || []);

  const isTwentyFourSeven = !filteredTimetable || !filteredTimetable.length || (
    filteredTimetable.some(r => (
      (!r.state || r.state === TimetableRuleState.CONTROLLED) &&
      r.days.length === 7 &&
      r.start === '00:00' &&
      r.end === '00:00'
    ))
  );

  if (isTwentyFourSeven) {
    return <>{twentyFourSevenElement || '24/7'}</>;
  }

  return (
    <>
      {filteredTimetable.map(({ days, start, end }, index) => {
        const dayAbbreviations = days.map(day => DAY_ABBREVIATIONS[day - 1]).join(', ');

        const parsedStartTime = moment(start, 'HH:mm');
        const parsedEndTime = moment(end, 'HH:mm');

        const times = `${parsedStartTime.format('h:mma')} - ${parsedEndTime.format('h:mma')}`;

        return (
          <div
            key={index}
            style={{
              display: 'table-row',
              whiteSpace: 'nowrap',
              marginBottom: index !== filteredTimetable.length - 1 ? 10 : undefined,
            }}
          >
            <span style={{ display: 'table-cell', paddingRight: 15 }}>
              {dayAbbreviations}:&nbsp;
            </span>
            <span style={{ display: 'table-cell' }}>{times}</span>
          </div>
        );
      })}
    </>
  );
};
