import { CloseOutlined } from '@ant-design/icons';
import { ExternalFloor } from '@chirp/engrain-client';
import { Modal } from 'antd';
import React, { useState } from 'react';

import { PropertyLabelFragment } from '../../../graphql/hasura/generated';

import PropertyMapModal from './PropertyMapModal';

interface IViewPropertyMapActionProps {
  property: PropertyLabelFragment;
  externalMapId: string;
  externalFloors: ExternalFloor[];
  editMode: boolean;
}

export const ViewPropertyMapAction: React.FC<IViewPropertyMapActionProps> = (props) => {
  const { property, externalMapId, externalFloors, editMode } = props;
  const [visible, setVisible] = useState(false);

  return (
    <>
      <a onClick={(e) => {
        e.preventDefault();
        setVisible(true);
      }}>
        View Map
      </a>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        keyboard={false}
        centered
        width='100%'
        footer={null}
        wrapClassName='property-map-wrapper'
        closeIcon={<CloseOutlined style={{ fontSize: 30 }}/>}
        destroyOnClose={true}
      >
        <div style={{ position: 'relative', height: '100vh', width: '100%'}}>
          <PropertyMapModal
            mapId={externalMapId}
            propertyId={property.propertyId}
            name={property.name}
            floors={externalFloors}
            editMode={editMode}
          />
        </div>
      </Modal>
    </>
  );
};

export default ViewPropertyMapAction;
