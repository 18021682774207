import React from 'react';
import { Link } from 'react-router-dom';

import { SmartLockDetailsFragment, syncSmartLockAccessDocument } from '../../graphql/api/generated';
import { PermissionKey_enum, PermissionScope_enum } from '../../graphql/hasura/generated';
import { buildGrantAccessPageUrl } from '../../pages/GrantAccess/buildGrantAccessPageUrl';
import { authentication } from '../../stores';

import { AugustAccessAudit } from './components/AugustAccessAudit';
import model from './model';

export const createKeyAction = model.createAction({
  description: 'Create unit key to access the lock',
  enabledByModel: () => {
    return authentication.grantableRoleScopes.includes(PermissionScope_enum.UNIT);
  },
  render({ unitId }) {
    const url = buildGrantAccessPageUrl({
      userId: authentication.user?.userId,
      permissionScope: PermissionScope_enum.UNIT,
      scopedIds: [unitId],
    });

    return (
      <Link to={url}>Create Key</Link>
    );
  },
});

export const syncAccessAction = model.createAction({
  label: () => 'Sync Access',
  description: 'Force synchronization of August access for this smart lock based on Chirp roles',
  enabledByModel: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.SmartLock_Read)
  ),
  confirmation: {
    title: () => 'Synchronize Access',
    content: smartLock => (
      `Are you sure you want to synchronize access for ` +
      `${model.labels.getLabel(smartLock)}?`
    ),
  },
  executes: async ({ smartLockId }, { apiClient }) => {
    await apiClient.mutate({
      mutation: syncSmartLockAccessDocument,
      variables: { smartLockId },
    });
  },
});

export const accessAuditAction = model.createAction<SmartLockDetailsFragment>({
  label: () => 'Access Audit',
  description: 'Audit current mobile and PIN code access from August based on Chirp roles',
  enabledByModel: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.SmartLock_Read)
  ),
  enabledByRow: smartLock => !!smartLock.augustLock,
  renderModalContent: smartLock => {
    return smartLock.augustLock
      ? <AugustAccessAudit smartLock={smartLock} actions={[syncAccessAction]} />
      : null;
  },
  getModalProps: () => ({
    width: '90%',
    title: null,
    footer: null,
    destroyOnClose: true,
    bodyStyle: { padding: 10 },
  }),
});
