import { LockOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';

import { buildGrantAccessPageUrl } from '../../pages/GrantAccess/buildGrantAccessPageUrl';
import Authentication from '../../stores/authentication';
import { formatUserName } from '../../utils';
import HeaderDropdown from '../HeaderDropdown';

import styles from './index.module.less';

interface IGlobalHeaderRightProps {
  authentication: Authentication;
}

const GlobalHeaderRight: React.FC<IGlobalHeaderRightProps> = (props) => {
  return useObserver(() => {
    const { authentication } = props;
    const currentUser = authentication.user;

    const menu = (
      <Menu className={styles.menu} selectedKeys={[]}>
        <Menu.Item>
          {currentUser && (
            <Link to={`/users/details/${currentUser.userId}`}>
              <UserOutlined />
              <Text>My Profile</Text>
            </Link>
          )}
        </Menu.Item>

        {currentUser && !!authentication.grantableRoleScopes.length && (
          <Menu.Item>
            <Link to={buildGrantAccessPageUrl({ userId: currentUser.userId })}>
              <LockOutlined />
              <Text>Grant Access</Text>
            </Link>
          </Menu.Item>
        )}

        <Menu.Item onClick={() => authentication.logout()}>
          <LogoutOutlined />
          <Text>Logout</Text>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className={styles.right}>
        <HeaderDropdown overlay={menu}>
          <span className={`${styles.userInfo} ${styles.action}`}>

            <span>
              {currentUser && formatUserName(currentUser)}
              {!currentUser && ('Loading user info...')}
            </span>

            <UserOutlined style={{ marginLeft: 10 }} />

            <br />

            {currentUser && (
              <div className={styles.subtitle}>
                {currentUser.email || currentUser.phoneNumber}
              </div>
            )}
          </span>
        </HeaderDropdown>
      </div>
    );
  });
}

export default GlobalHeaderRight;
