import React from 'react';
import { Link } from 'react-router-dom';

import { SplitDescriptions } from '../../../components/SplitDescriptions';
import { TableCellDescriptions } from '../../../components/TableCellDescriptions';
import {
  RoleKey_enum,
  usegetUserRoleDetailsSubscription,
  UserRoleDetailsFragment,
} from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import { createdAtColumn } from '../../common/columns';
import { getDescriptionsFromColumns } from '../../helpers/getDescriptionsFromColumns';
import * as columns from '../columns';
import {
  PropertyPinCodeDescriptions,
  shouldDisplayPropertyPinCode,
} from '../components/PropertyPinCodeDescriptions';
import { ScopeOfAccessDescriptions } from '../components/ScopeOfAccessDescriptions';
import { UserRoleDetailsFooter } from '../components/UserRoleDetailsFooter';

const UserRoleDetailsLayout = createDetailsLayout<UserRoleDetailsFragment>();

export const UserRoleDetailsPage: React.FC<any> = () => {
  return (
    <UserRoleDetailsLayout
      modelName='UserRole'
      useQuery={(userRoleId) => {
        return usegetUserRoleDetailsSubscription({
          variables: { userRoleId },
        });
      }}
      body={(userRole) => {
        const { role, parentUserRoleId } = userRole;

        return (
          <>
            <SplitDescriptions
              row={userRole}
              leftSideColumns={[
                {
                  title: 'Status',
                  render: () => (
                    <>
                      {columns.statusColumn.render(userRole)}
                      <div style={{ float: 'right' }}>
                        <TableCellDescriptions
                          descriptions={getDescriptionsFromColumns(userRole, [
                            columns.revokedAtColumn,
                            columns.revokedByUserColumn,
                          ])}
                        />
                      </div>
                    </>
                  ),
                },
                columns.createRoleColumn(userRole.role.permissionScope),
                {
                  title: 'Scope of Access',
                  render: () => <ScopeOfAccessDescriptions userRole={userRole} />,
                },
                columns.assignedToColumn,
                columns.grantedByColumn,
                createdAtColumn,
              ]}
              rightSideColumns={[
                {
                  title: 'Duration of Access',
                  render: () => (
                    <TableCellDescriptions
                      descriptions={getDescriptionsFromColumns(userRole, [
                        columns.activationDateColumn,
                        columns.expirationDateColumn,
                      ])}
                    />
                  ),
                },
                userRole.schedule ? columns.scheduleColumn : null,
                {
                  title: 'Additional Context',
                  render: () => (
                    <TableCellDescriptions
                      descriptions={getDescriptionsFromColumns(userRole, [
                        columns.sourceIdColumn,
                        columns.grantMethodColumn,
                        columns.reasonColumn,
                        columns.notesColumn,
                      ])}
                    />
                  ),
                },
                shouldDisplayPropertyPinCode(userRole) ? {
                  title: 'Property PIN Code',
                  render: () => <PropertyPinCodeDescriptions userRole={userRole} />,
                } : null,
              ]}
            />
            {role.key === RoleKey_enum.SMART_LOCK_UNREGISTERED_USER && parentUserRoleId && (
              <div style={{ paddingTop: 25, clear: 'both' }}>
                <Link to={`/assigned-roles/details/${parentUserRoleId}`}>
                  View Property Guest Pass
                </Link>
              </div>
            )}
          </>
        );
      }}
      footer={(userRole) => <UserRoleDetailsFooter userRole={userRole} />}
    />
  );
};
