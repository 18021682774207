import { Form } from '@ant-design/compatible';
import { Input } from 'antd';
import { FormikActions } from 'formik';
import React, { FC } from 'react';

import { FobFlow, ICredentialFormValues } from './typings';

const USBEnrollmentFlow: FC<{
  values: ICredentialFormValues,
  setFieldValue: FormikActions<ICredentialFormValues>['setFieldValue'],
}> = ({ values, setFieldValue }) => {
  return (
    <>
      <Form.Item
        className='label-align-top'
        required={values.fobFlow === FobFlow.ENROLLMENT_USB}
        label={'Credential Identifier'}
      >
        <Input
          required={values.fobFlow === FobFlow.ENROLLMENT_USB}
          style={{ width: '40%', marginBottom: 20 }}
          placeholder='Click here before scanning credential'
          name='identifier'
          value={values.identifier || undefined}
          onChange={e => setFieldValue('identifier', e.target.value)}
        />
      </Form.Item>
    </>
  )
};

export default USBEnrollmentFlow;
