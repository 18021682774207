import { PermissionKey_enum, PermissionScope_enum } from '../../graphql/hasura/generated';

import model from './model';

model.routes.setRoutes({
  defaultRoute: {
    enabled: ({ canRead, currentPermissionScope }) => (
      // @TODO: There's currently a performance issue with loading
      // mobile credentials using the "user_organization_role"
      canRead() && currentPermissionScope !== PermissionScope_enum.ORGANIZATION
    ),
  },
  createRoute: {
    // We should only create credentials through the "Assign New Fob" flow
    enabled: () => false,
  },
  editRoute: {
    enabled: ({ currentPermissionScope, hasPermission }) => (
      currentPermissionScope === PermissionScope_enum.GLOBAL &&
      hasPermission(PermissionKey_enum.Credential_Update)
    ),
  },
});
