import { FormikProps } from 'formik';
import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js';
import { startCase } from 'lodash';
import moment from 'moment-timezone';
import validator from 'validator';

import { UnitLabelFragment as Unit, User } from '../graphql/hasura/generated';

export enum Color {
  Blue = '#2db7f5',
  Gold = '#faad14',
  Green = '#52c41a',
  Orange = '#fa8c16',
  Red = '#f50',
}

const CUSTOM_LABELS: { [key: string]: string } = {
  assignedToAnonymousUserId: 'Anonymous User ID',
  assignedToUser: 'Assigned To',
  dpsAlertDelay: 'DPS Alert Delay',
  dpsAlertInterval: 'DPS Alert Interval',
  uuid: 'UUID',
  createdByUser: 'Created By',
  friendlyName: 'Name',
  imageId: 'Image',
  macAddress: 'MAC Address',
  sourceId: 'Source ID',
  timetable: 'Schedule',
};

export function getFormLabel(field: string): string {
  const customLabel = CUSTOM_LABELS[field];

  if (customLabel) {
    return customLabel;
  }

  let defaultLabel = startCase(field);

  if (defaultLabel.endsWith(' Id')) {
    defaultLabel = defaultLabel.replace(new RegExp(' Id' + '$'), ' ID');
  }

  return defaultLabel;
}

export function getFieldHelpText(formikProps: FormikProps<any>, fieldName: string) {
  return formikProps.submitCount && formikProps.errors[fieldName]
    ? formatFieldErrorMessage(fieldName, formikProps.errors[fieldName])
    : undefined;
}

export function formatFieldErrorMessage(field: string, error: any): string | undefined {
  if (typeof error === 'string') {
    const formLabel = getFormLabel(field);

    return error.replace(new RegExp(field, 'g'), formLabel);
  }

  return undefined;
}

export function formatPrimaryKey(primaryKey: string | number, addPrefix = false): string {
  const prefix = addPrefix ? '#' : '';
  const stringifiedPrimaryKey = String(primaryKey);

  // Shorten UUIDs
  return `${prefix}${stringifiedPrimaryKey.substr(stringifiedPrimaryKey.length - 12)}`;
}

export function formatEnumTag(enumTag: string): string {
  return enumTag.replace(/_/g, ' ');
}

export function formatEnumValue(enumValue: string | null): string | null {
  return enumValue ? startCase(enumValue.toLowerCase()) : null;
}

export function formatUserName(
  user: Pick<User, 'firstName' | 'lastName'> | null | undefined,
  returnDefault = true,
): string {
  const fullName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();

  return fullName || (returnDefault ? 'No Name' : '');
}

// @TODO: Support non-US phone numbers
export function formatPhoneNumberInput(phoneNumber: string | null | undefined): string {
  if (!phoneNumber) {
    return '';
  }

  // Remove all non-numeric characters
  const strippedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
  const hasUsCallingCode = strippedPhoneNumber.startsWith('1');

  const nationalPhoneNumber = hasUsCallingCode
    ? strippedPhoneNumber.replace(/^1/, '')
    : strippedPhoneNumber;

  // Prevent issue with deleting parentheses
  if (nationalPhoneNumber.length <= 3) { // Ex. 281
    return nationalPhoneNumber;
  }

  return (new AsYouType('US').input(nationalPhoneNumber));
}

export function parsePhoneNumber(phoneNumber: string | null | undefined): string {
  if (!phoneNumber) {
    return '';
  }

  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, 'US');

  return parsedPhoneNumber ? String(parsedPhoneNumber.number) : phoneNumber;
}

export function formatPhoneNumber(phoneNumber: string | null | undefined): string {
  if (!phoneNumber) {
    return '';
  }

  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

  return parsedPhoneNumber ? parsedPhoneNumber.formatNational() : phoneNumber;
}

export function formatUnitDisplayName(unit: Unit, addPrefix = false): string {
  // For units like "Maintenance Shop", we don't want to prepend the display name with "Unit"
  const { unitNumber } = unit;
  const prefix = (!addPrefix || !validator.isNumeric(unitNumber)) ? '' : 'Unit ';

  let displayName = `${prefix}${unitNumber}`;

  if (unit.building) {
    displayName += ` (Bldg. ${unit.building})`;
  }

  return displayName;
}

export function truncateText(text: string | null | undefined, limit = 100): string {
  if (typeof text !== 'string') {
    return '';
  }

  if (text.length > limit) {
    return `${text.substring(0, limit)}...`;
  }

  return text;
}

export const USER_TIMEZONE = moment.tz.guess();
export const USER_ABBREVIATED_TIMEZONE = moment.tz(USER_TIMEZONE).zoneAbbr();

export const DATE_FORMAT = 'MMM D, YYYY';
export const TIME_FORMAT = 'h:mma';
export const TIMESTAMP_FORMAT = 'MMM D, YYYY h:mma';

function formatMoment(date: string | null | undefined, format: string, tz?: string): string {
  if (!date) {
    return '';
  }

  // moment.tz does not allow an undefined tz argument, so we have to call separate functions
  return tz ? moment.tz(date, tz).format(format) : moment(date).format(format);
}

export function formatDate(date: string | null | undefined, tz?: string): string {
  return formatMoment(date, DATE_FORMAT, tz);
}

interface ITimezoneOptions {
  timezone: string;
  timezoneAbbr: string;
}

export function formatTimestamp(date: string | null | undefined, tzOptions?: ITimezoneOptions): string {
  const timestamp = formatMoment(date, TIMESTAMP_FORMAT, tzOptions?.timezone);

  return timestamp && tzOptions?.timezoneAbbr ? `${timestamp} (${tzOptions.timezoneAbbr})` : timestamp;
}

export function formatAugustIdentifiers(identifiers: string[], prefix: string): string[] {
  return identifiers
    .filter(i => i.startsWith(prefix))
    .map(i => i.replace(prefix, ''));
}
