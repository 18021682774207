import { v4 as uuid } from 'uuid';

import { AccessPointType_enum } from '../../graphql/hasura/generated';
import { IModel } from '../../models/typings';

export function applyDefaultFormValues(
  values: any,
  model: IModel,
  isNew: boolean
) {
  const modelName = model.names.schemaName;
  const newValues = { ...values };

  // @TODO: Make this configurable. Also, all boolean fields should default to false.

  if (isNew && modelName === 'AccessPoint') {
    newValues.type = AccessPointType_enum.COMMON_AREA;
    newValues.accessTimeout = 5000;
    newValues.dpsAlertDelay = 900000;
    newValues.proximityRestriction = false;
    newValues.spaceshipModeEnabled = true;
  }

  if (isNew && modelName === 'Beacon') {
    newValues.uuid = uuid();
  }

  if (isNew && modelName === 'SmartLock') {
    newValues.enabled = true;
  }

  if (isNew && modelName === 'Relay') {
    newValues.unlockOnInternetFailure = false;
  }

  return newValues;
}

export default applyDefaultFormValues;
