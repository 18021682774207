import { UserModel } from '../User/model';

import model from './model';
import { UserRoleDetailsPage } from './pages/UserRoleDetailsPage';

model.routes.setRoutes({
  detailsRoute: {
    component: UserRoleDetailsPage,
  },
  importRoute: {
    ...UserModel.routes.importRoute, // Redirect
  },
});
