import { Modal, notification } from 'antd';
import React, { useState } from 'react';

import {
  FailurePlan_enum,
  RelayFailurePlanFragment as Relay,
  useupdateRelaysMutation,
} from '../../../graphql/hasura/generated';
import RelayModel from '../../Relay/model';
import AccessPointModel from '../model';

import EditAccessPointFailurePlan from './EditAccessPointFailurePlan';

interface IAccessPointFailurePlanModalProps {
  accessPointId: string;
  failurePlan: FailurePlan_enum | null;
  relays: Relay[];
  canEdit: boolean;
}

const AccessPointFailurePlanModal = (props: IAccessPointFailurePlanModalProps) => {
  const { canEdit } = props;
  const [failurePlan, setFailurePlan] = useState<FailurePlan_enum | null>(props.failurePlan);
  const [affectedRelayIds, setAffectedRelayIds] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [updateRelays] = useupdateRelaysMutation({
    variables: {
      where: {
        relayId: { _in: affectedRelayIds },
      },
      _set: { failurePlan },
    },
  });

  const relayIds = props.relays.map(r => r.relayId);

  return (
    <>
      {canEdit && (AccessPointModel.permissions.canUpdate() || RelayModel.permissions.canUpdate()) && (
        <a
          title='Edit'
          onClick={(e) => {
            e.preventDefault();
            setIsModalVisible(true);
          }}
        >
          Edit
        </a>
      )}
      <Modal
        title={null}
        visible={isModalVisible}
        maskClosable
        width='60%'
        cancelText='Cancel'
        onCancel={() => setIsModalVisible(false)}
        okText='Save'
        okButtonProps={{ disabled: isSaving || !failurePlan || !affectedRelayIds.length }}
        onOk={async () => {
          setIsSaving(true);
          await updateRelays();

          setIsModalVisible(false);
          setIsSaving(false);

          notification.success({
            message: 'The Internet failure plan was saved successfully!',
          });
        }}
      >
        <EditAccessPointFailurePlan
          accessPointId={props.accessPointId}
          relayIds={relayIds}
          failurePlan={failurePlan}
          onChangeFailurePlan={newFailurePlan => setFailurePlan(newFailurePlan)}
          onLoadRelayIds={newRelayIds => setAffectedRelayIds(newRelayIds)}
        />
      </Modal>
    </>
  );
}

export default AccessPointFailurePlanModal;
