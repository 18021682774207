import { Card, Tabs } from 'antd';
import React from 'react';

import {
  PermissionKey_enum,
  PermissionScope_enum,
  RoleKey_enum,
  usegetPropertyDetailsQuery,
} from '../../../graphql/hasura/generated';
import { IModel } from '../../../models/typings';
import { authentication } from '../../../stores';
import { getPropertyFeatureFlags } from '../../../utils';

interface IUserFormTabsProps {
  activeTab: string;
  setActiveTab: (nexActiveTab: string) => any;
  UserModel: IModel
}

export const UserFormTabs: React.FC<IUserFormTabsProps> = (props) => {
  const { activeTab, setActiveTab, UserModel } = props;

  const { permissionScope, scopedId } = authentication.currentDataScope;
  const propertyId = permissionScope === PermissionScope_enum.PROPERTY && scopedId
    ? scopedId
    : null;

  const { loading, data: propertyData } = usegetPropertyDetailsQuery({
    skip: !propertyId,
    variables: { propertyId: propertyId as string },
  });

  const property = propertyData?.property;
  const vendorPinsEnabled = property ? getPropertyFeatureFlags(property).VENDOR_PINS : null;

  const canCreateRegisteredUsers = UserModel.permissions.canCreate();
  const canGrantAnyScopedUserRole = authentication.hasPermission(PermissionKey_enum.UserRole_GrantRevokeScoped);

  const canGrantRole = (roleKey: RoleKey_enum) => {
    return canGrantAnyScopedUserRole ||
    authentication.currentRoleRelationships.some(r => (
      r.relatedRole.key === roleKey &&
      r.canReadUserRole &&
      r.canGrantUserRole
    ));
  };

  const canGrantGuestPasses = canGrantRole(RoleKey_enum.GUEST_PROPERTY_KEY);
  const canGrantSgtUsers = canGrantRole(RoleKey_enum.SMART_LOCK_UNREGISTERED_USER);
  const canGrantVendorPins = (
    vendorPinsEnabled !== false &&
    authentication.hasPermission(PermissionKey_enum.PinCode_Read) &&
    canGrantRole(RoleKey_enum.VENDOR_PIN)
  );

  const tabs = [
    {
      key: 'NEW_USER',
      title: 'New User',
      disabled: !canCreateRegisteredUsers,
      hidden: false,
    },
    {
      key: 'EXISTING_USER',
      title: 'Existing User',
      disabled: false,
      hidden: false,
    },
    {
      key: 'SGT_USER',
      title: 'Self-Guided Tour User',
      // @TODO: Eventually we might allow this flow to be used without
      // the ability to grant smart lock access (i.e., property access only)
      disabled: false,
      hidden: !(canGrantGuestPasses && canGrantSgtUsers),
    },
    {
      key: 'VENDOR_PIN',
      title: 'Vendor PIN',
      disabled: false,
      hidden: !canGrantVendorPins,
    },
  ];

  return (
    <Card
      loading={loading}
      bordered={false}
      style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      bodyStyle={{ paddingBottom: 0 }}
    >
      <Tabs
        size='large'
        animated={false}
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        onChange={(nextActiveTab) => {
          setActiveTab(nextActiveTab);
        }}
        tabBarStyle={{ marginBottom: 0 }}
      >
        {tabs.map(({ key, title, disabled, hidden }) => {
          if (hidden) {
            return null;
          }

          return (
            <Tabs.TabPane
              key={key}
              tab={title}
              disabled={disabled}
            />
          );
        })}
      </Tabs>
    </Card>
  );
};
