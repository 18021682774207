import model from './model';

const formFields = model.createFormFields([
  {
    fieldName: 'name',
    maxLength: 255,
  },
  {
    fieldName: 'parentUnitGroupId',
    disabled: ({ formikProps }) => !formikProps.values.propertyId,
    getQueryFilters: ({ formikProps }) => {
      const { propertyId, unitGroupId } = formikProps.values;

      return {
        propertyId: propertyId ? { _eq: propertyId } : undefined,
        // Do not allow unit group to be parent of itself
        unitGroupId: unitGroupId ? { _neq: unitGroupId } : undefined,
      };
    },
  },
]);

model.setFormOptions({
  fields: formFields,
});
