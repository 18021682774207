import { enumManagers } from '@chirp/enums';
import { Tag } from 'antd';
import moment from 'moment';
import React from 'react';

import { TextWithIconTooltip } from '../../components/TextWithIconTooltip';
import {
  renderUserOrUserRoleString,
  UserOrUserRoleLink,
} from '../../components/UserOrUserRoleLink';
import {
  PermissionScope_enum,
  PinCodeAssignedToFragment,
  PinCodePrivateFragment,
  PinCodeRequiredRowFragment,
  PinCodeType_enum,
  PropertyLabelFragment,
  UserLabelFragment,
} from '../../graphql/hasura/generated';
import { formatTimestamp } from '../../utils';
import { PropertyModel } from '../Property/model';
import { UserModel } from '../User/model';

import model from './model';

export const propertyColumn = model.createColumn<{ property: PropertyLabelFragment }>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'propertyId',
    model: PropertyModel,
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ property }) => PropertyModel.routes.renderRowLink(property),
});

export const typeColumn = model.createColumn<{ type: PinCodeType_enum }>({
  title: 'Type',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'PinCodeType',
  },
  render: ({ type }) => enumManagers.PinCodeType.getLabel(type),
});

export const codeColumn = model.createColumn<{ private?: PinCodePrivateFragment | null }>({
  title: 'Code',
  render: (pinCode) => {
    if (pinCode.private?.code) {
      return pinCode.private.code;
    }

    return '******';
  },
});

export const assignedToColumn = model.createColumn<PinCodeAssignedToFragment>({
  title: 'Assigned To',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'assignedToUserId',
    model: UserModel,
  },
  render: ({ assignedToUserId, assignedToUser, userRoleId }) => {
    return (
      <UserOrUserRoleLink
        userId={assignedToUserId}
        user={assignedToUser}
        userRoleId={userRoleId}
      />
    );
  },
  renderString: async ({ assignedToUserId, assignedToUser, userRoleId }) => {
    return await renderUserOrUserRoleString({
      userRoleId,
      userId: assignedToUserId,
      user: assignedToUser,
    });
  },
});

export const createdByColumn = model.createColumn<{ createdByUser?: UserLabelFragment | null }>({
  title: 'Created By',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'createdByUserId',
    model: UserModel,
  },
  render: ({ createdByUser }) => UserModel.routes.renderRowLink(createdByUser),
});

export const updatedAtColumn = model.createColumn<PinCodeRequiredRowFragment>({
  title: 'Updated',
  dataIndex: 'updatedAt',
  filterOptions: {
    type: 'DATE_RANGE',
    disableFutureDates: true,
  },
  render: ({ userRoleId, updatedAt }) => {
    const formattedTime = formatTimestamp(updatedAt);

    if (!userRoleId) {
      return formattedTime;
    }

    const oneYearAgoTime = moment(new Date()).subtract(365, 'days').toDate().getTime();
    const updatedAtTime = new Date(updatedAt).getTime();

    if (updatedAtTime < oneYearAgoTime) {
      return (
        <>
          <div style={{ marginBottom: 5 }}>{formattedTime}</div>
          <TextWithIconTooltip
            text={(
              <Tag
                style={{ color: 'rgba(0, 0, 0, 0.85)', marginRight: 0 }}
                color='gold'
              >
                Regeneration Recommended
              </Tag>
            )}
            tooltip={{
              title: 'For security purposes, we typically recommend you generate a new vendor PIN code each year.',
            }}
          />
        </>
      );
    }

    return formattedTime;
  },
});
