import { displayErrorMessage } from '../../../../../utils';

import paddingBit0 from './paddingBit0';

const evenPosition = [1, 2, 3, 5, 6, 8, 9, 11, 12, 14, 15, 17, 18, 20, 21, 23, 24, 26, 27, 29, 30, 32, 33];
const odd1stPosition = [1, 2, 4, 5, 7, 8, 10, 11, 13, 14, 16, 17, 19, 20, 22, 23, 25, 26, 28, 29, 31, 32, 34]

export default (facilityCode: string | null, cardNumber: string | null): string | null => {
  if (!facilityCode || !cardNumber) {
    return null;
  }

  if (Number.isInteger(+cardNumber) === false || +cardNumber < 0) {
    displayErrorMessage(new Error(`Card number must be a positive integer. Received "${cardNumber}"`));
    return null;
  }

  if (Number.isInteger(+facilityCode) === false || +facilityCode < 0) {
    displayErrorMessage(new Error(`Facility code must be a positive integer. Received "${facilityCode}"`));
    return null;
  }

  if (+facilityCode > 4095) {
    displayErrorMessage(
      new Error(`Facility Code ${facilityCode} is too large to encode in 35C-bit format. Maximum possible value is 4095.`)
    );

    return null;
  }

  if (+cardNumber > 1048575) {
    displayErrorMessage(
      new Error(`Card number ${cardNumber} is too large to encode in 35C-bit format. Maximum possible value is 1048575.`)
    );

    return null;
  }

  const fcBit = parseInt(facilityCode, 10).toString(2);
  const fullFCBit = paddingBit0(fcBit, 12);

  const cardNumberBit = parseInt(cardNumber, 10).toString(2);
  const fullCardNumberBit = paddingBit0(cardNumberBit, 20);

  const bit = fullFCBit + fullCardNumberBit;
  const bitArray = bit.split('');
  bitArray.unshift('0');
  bitArray.unshift('0');
  bitArray.push('0');

  const evenParity = bitArray.filter((_, index) => evenPosition.includes(index));
  const evenBitCount = evenParity.filter(b => b === '1').length

  if (evenBitCount % 2 === 1) {
    bitArray[1] = '1';
  }

  const odd1stParity = bitArray.filter((_, index) => odd1stPosition.includes(index));
  const odd1stBitCount = odd1stParity.filter(b => b === '1').length

  if (odd1stBitCount % 2 === 0) {
    bitArray[34] = '1'
  }

  const odd2ndBitCount = bitArray.filter(b => b === '1').length

  if (odd2ndBitCount % 2 === 0) {
    bitArray[0] = '1'
  }

  return parseInt(bitArray.join(''), 2).toString(16);
}
