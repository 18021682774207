import { Empty } from 'antd';
import { Axis, Chart, Geom, Legend, Tooltip as ChartTooltip } from 'bizcharts';
import numeral from 'numeral';
import React from 'react';

import { UserActivityOutput } from '../../graphql/api/generated';

import UserActivityCard from './UserActivityCard';

interface IUserActivityChartProps {
  loading: boolean;
  userActivity: UserActivityOutput[];
  startDate?: string;
  endDate?: string;
}

const UserActivityChart = (props: IUserActivityChartProps) => {
  const { loading, userActivity, startDate, endDate } = props;

  const gatewayAccessTotal = userActivity.reduce((acc, tick) => tick.gatewayAccess + acc, 0);
  const homeAccessTotal = userActivity.reduce((acc, tick) => tick.homeAccess + acc, 0);
  const activityTotal = gatewayAccessTotal + homeAccessTotal;
  const empty = !loading && activityTotal <= 0;

  const geomType = userActivity.length === 1 ? 'point' : 'line';

  return (
    <UserActivityCard
      startDate={startDate}
      endDate={endDate}
      extra={(
        <span>
          Total Activity:&nbsp;
          <strong>{!loading ? numeral(activityTotal).format('1,000') : '-'}</strong>
        </span>
      )}
      loading={loading}
    >
      {empty && <Empty description='No activity for this period' />}
      {!empty && (
        <Chart
          animate={false}
          height={300}
          padding='auto'
          data={userActivity}
          forceFit
          scale={{
            type: {
              type: 'cat',
            },
            gatewayAccess: {
              min: 0,
              alias: 'Gateway Access',
            },
            homeAccess: {
              min: 0,
              alias: 'Home Access',
            },
            date: {
              alias: 'Time',
            },
          }}
        >
          <ChartTooltip />
          <Axis />
          <Legend />
          <Geom type={geomType} shape='smooth' position='date*gatewayAccess' size={2} color={'#02c8df'} />
          {homeAccessTotal > 0 && (
            <Geom type={geomType} shape='smooth' position='date*homeAccess' size={2} color={'black'} />
          )}
        </Chart>
      )}
    </UserActivityCard>
  );
};

export default UserActivityChart;
