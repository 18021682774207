import { Input } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import { CsvExportButton } from '../../../components/CsvExport/CsvExportButton';
import RefreshButton from '../../../components/RefreshButton';
import TableActions from '../../../components/TableActions/TableActions';
import { apiClient } from '../../../graphql/api/apiClient';
import {
  ResidentAuditFragment,
  useauditResidentsByPropertyQuery,
} from '../../../graphql/api/generated';
import { usegetPropertyWithPropertySourcesQuery } from '../../../graphql/hasura/generated';
import { useDebouncedSearch } from '../../../hooks/useDebouncedSearch';
import BasicLayout from '../../../layouts/BasicLayout';
import ResidentAuditPage from '../../../pages/ResidentAudit';
import { columns } from '../../../pages/ResidentAudit/columns';
import { displayErrorMessage, RefetchContext } from '../../../utils';
import { syncResidentsAction, syncUnitsAction } from '../actions';
import model from '../model';

function filterResidentAuditsBySearchWords(
  residentAudits: ResidentAuditFragment[],
  searchWords: string[],
) {
  if (!searchWords.length) {
    return residentAudits;
  }

  return residentAudits.filter(({ residentSource, unit, userRole }) => {
    const { unitNumber } = unit;
    const user = userRole?.assignedToUser;

    const searchableFields = [
      unitNumber,
      residentSource.firstName,
      residentSource.lastName,
      residentSource.phoneNumber,
      residentSource.email,
      user?.firstName,
      user?.lastName,
      user?.phoneNumber,
      user?.email,
    ].filter(t => !!t).map(t => t?.toLowerCase()) as string[];

    return searchWords.every((searchWord) => {
      const lowerCaseSearchWord = searchWord.toLowerCase();

      return searchableFields.some(text => text.includes(lowerCaseSearchWord));
    });
  });
}

const PropertyResidentAuditPage: React.FC<RouteComponentProps<{ propertyId: string }>> = (props) => {
  const { propertyId } = props.match.params;
  const history = useHistory();

  const { data: propertyData } = usegetPropertyWithPropertySourcesQuery({
    variables: { propertyId },
    skip: !propertyId,
  });

  const property = propertyData?.property;
  const propertySources = property?.propertySources
    ? property.propertySources.map(p => p.sourceId).join(', ')
    : null;

  const { data: residentAuditsData, loading, refetch } = useauditResidentsByPropertyQuery({
    client: apiClient,
    variables: { propertyId },
    skip: !propertyId,
    onCompleted: ({ auditResidentsByProperty }) => setChangedDataSource(auditResidentsByProperty),
    notifyOnNetworkStatusChange: true,
    onError: error => displayErrorMessage(error),
  });

  const { searchWords, onSearchDebounced } = useDebouncedSearch();

  const residentAudits = residentAuditsData?.auditResidentsByProperty || [];
  const filteredResidentAudits = filterResidentAuditsBySearchWords(residentAudits, searchWords);

  // To export the filtered/sorted rows from the Ant Design table to CSV, we have to track it in state
  // https://github.com/ant-design/ant-design/issues/24022
  const [changedDataSource, setChangedDataSource] = useState<ResidentAuditFragment[]>(residentAudits);
  const csvRows = filterResidentAuditsBySearchWords(changedDataSource, searchWords);

  return (
    <BasicLayout pageTitle='Resident Audit'>
      <ResidentAuditPage
        cardTitle={
          property ?
            <span>
              {model.routes.renderRowLink(property)}{' '}
              ({propertySources ? `${propertySources}` : 'Missing Source ID'})
            </span>
            : '...'
        }
        cardExtra={
          <>
            {property && (
              <RefetchContext.Provider value={refetch}>
                <TableActions
                  actions={[syncUnitsAction, syncResidentsAction]}
                  row={property}
                  style={{ marginBottom: 10 }}
                />
              </RefetchContext.Provider>
            )}
            <div style={{ display: 'flex' }}>
              {/* // @TODO: Add button for clearing filters */}
              <RefreshButton
                disabled={!property || loading}
                refresh={refetch}
                useSubscription={false}
                onError={false}
              />
              <CsvExportButton
                title={`Resident Audit - ${property?.name}`}
                rows={csvRows}
                columns={columns.filter(c => c.title !== 'Actions')}
                disabled={!property || loading}
                buttonStyle={{ marginRight: 8 }}
              />
              <Input.Search
                enterButton
                placeholder='Search...'
                onChange={(e) => onSearchDebounced(e.target.value)}
                onSearch={(value) => onSearchDebounced(value)}
                allowClear={true}
              />
            </div>
          </>
        }
        location={history.location}
        columns={columns}
        loading={loading}
        residentAudits={residentAudits}
        filteredResidentAudits={filteredResidentAudits}
        pagination={{ defaultPageSize: 25 }}
        tableProps={{
          onChange: (pagination, filters, sorter, extra) => {
            setChangedDataSource(extra.currentDataSource);
          },
        }}
      />
    </BasicLayout>
  );
}

export default PropertyResidentAuditPage;
