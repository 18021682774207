import {
  UserRole_bool_exp,
  UserRoleActionFragment,
  UserRoleUniqueLabelFragment,
  UserRoleUniqueLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import { emptySearchConditionsFilter, formatPrimaryKey } from '../../utils';
import { AccessPointModel } from '../AccessPoint/model';
import { Model } from '../Model';
import { OrganizationModel } from '../Organization/model';
import { PropertyModel } from '../Property/model';
import { RoleModel } from '../Role/model';
import { SmartLockModel } from '../SmartLock/model';
import { UnitModel } from '../Unit/model';

import { renderUserRoleAssignedToString } from './helpers';

export const UserRoleModel = new Model<
  UserRole_bool_exp,
  { userRoleId: string },
  UserRoleUniqueLabelFragment,
  UserRoleActionFragment
>({
  names: {
    schemaName: 'UserRole',
    displayName: 'Assigned Role',
    pluralDisplayName: 'Assigned Roles',
  },

  permissionsOptions: {
    canRead: () => true,
  },

  labels: {
    uniqueLabelFragment: UserRoleUniqueLabelFragmentDoc,
    getUniqueLabel: (u: UserRoleUniqueLabelFragment) => {
      const organization = u.scopedOrganization
        ? OrganizationModel.labels.getUniqueLabel(u.scopedOrganization)
        : null;
      const property = u.scopedProperty
        ? PropertyModel.labels.getUniqueLabel(u.scopedProperty)
        : null;
      const unit = u.scopedUnit ? UnitModel.labels.getUniqueLabel(u.scopedUnit) : null;
      const accessPoint = u.scopedAccessPoint
        ? AccessPointModel.labels.getUniqueLabel(u.scopedAccessPoint)
        : null;
      const smartLock = u.scopedSmartLock
        ? SmartLockModel.labels.getUniqueLabel(u.scopedSmartLock)
        : null;

      const role = RoleModel.labels.getLabel(u.role);
      const assignedTo = renderUserRoleAssignedToString(u);

      const hyphenatedWords = [
        organization,
        property,
        unit,
        accessPoint,
        smartLock,
        role,
        assignedTo,
      ].filter(Boolean).join(' - ');

      // Ex. Camden Plaza - Unit 1234 - Resident - Evan Corl (ACTIVE)
      return `${hyphenatedWords} (${u.status})`;
    },
    getBreadCrumbsLabel: ({ userRoleId, role }) => (
      `${role.name} ${formatPrimaryKey(userRoleId, true)}`
    ),
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            // Organization
            {
              managedWithinOrganization: {
                name: { _ilike: `%${word}%` },
              },
            },
            // Property
            {
              managedWithinProperty: {
                name: { _ilike: `%${word}%` },
              },
            },
            // Unit
            {
              scopedUnit: {
                unitNumber: { _ilike: `${word}%` },
              },
            },
            // Access Point
            {
              scopedAccessPoint: {
                name: { _ilike: `%${word}%` },
              },
            },
            // Role
            {
              role: {
                name: { _ilike: `%${word}%` },
              },
            },
            // User
            {
              assignedToUser: {
                firstName: { _ilike: `%${word}%` },
              },
            },
            {
              assignedToUser: {
                lastName: { _ilike: `%${word}%` },
              },
            },
            {
              assignedToUser: {
                email: { _ilike: `%${word}%` },
              },
            },
            // Status
            {
              statusEnum: {
                label: { _ilike: `%${word}%` },
              },
            },
          ].filter(emptySearchConditionsFilter),
        }
      ))
    }),
  },
});

export default UserRoleModel;
