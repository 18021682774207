import { AsYouType } from 'libphonenumber-js';

function getHighlightedSearchWords(searchWords: string[], searchTerm?: string) {
  if (!searchTerm) {
    return searchWords;
  }

  const asYouType = new AsYouType('US');
  const phoneNumber = asYouType.input(searchTerm);

  if (phoneNumber) {
    return [phoneNumber, ...searchWords];
  }

  return searchWords;
}

export default getHighlightedSearchWords;
