import { enumManagers } from '@chirp/enums';

import { SmartLockLogType } from '../../graphql/api/generated';
import type { ColumnFilters, IModelColumn } from '../typings';

// @TODO: Move to model configuration?
// These enums are not currently being used and therefore shouldn't be shown to end users
const HIDDEN_ENUMS: string[] = [
  SmartLockLogType.PIN_CODE_MANAGEMENT_CREATE,
  SmartLockLogType.PIN_CODE_MANAGEMENT_DELETE,
  SmartLockLogType.USER_MANAGEMENT_ADD,
  SmartLockLogType.USER_MANAGEMENT_REMOVE,
];

interface IFormatEnumColumnArgs {
  column: IModelColumn<any>;
  filters?: ColumnFilters;
}

export function formatEnumColumn(args: IFormatEnumColumnArgs): IModelColumn<any> {
  const { column, filters } = args;
  const { filterOptions } = column;

  if (filterOptions?.type !== 'ENUM') {
    return column;
  }

  const { enumTable } = filterOptions;
  const key = filterOptions.key || enumTable;
  const filterValues = filters ? filters[key] : null;

  const enumOptions = enumManagers[enumTable]?.getOptions() || [];
  const filteredEnumOptions = enumOptions.filter(o => !HIDDEN_ENUMS.includes(o.value));

  if (!filteredEnumOptions.length) {
    return column;
  }

  return {
    ...column,
    key,
    filtered: Boolean(filterValues?.length),
    filteredValue: filterValues || null, // null ensures it's treated as unfiltered
    filters: filteredEnumOptions.map(({ value, label }) => ({
      value,
      text: label,
    })),
  };
}
