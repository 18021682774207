import { Checkbox, Divider, Typography } from 'antd';
import React from 'react';

import {
  PermissionKey_enum,
  RoleKey_enum,
  SmartLock_bool_exp,
  usegetPropertyDetailsQuery,
} from '../../../graphql/hasura/generated';
import { SmartLockModel } from '../../../models/SmartLock/model';
import { authentication } from '../../../stores';
import { getPropertyFeatureFlags } from '../../../utils';
import ModelFormSelect from '../../ModelForm/ModelFormSelect';

import { ISgtUserFormMergedProps } from './formik';

export const PinCodesSection: React.FC<ISgtUserFormMergedProps> = (props) => {
  const { setFieldValue, values, errors: formikErrors, isSubmitting, PropertyModel } = props;
  const { scopedIds, enablePropertyPinCode, smartLockIds } = values;

  const propertyId = scopedIds[0];

  const { data: propertyData } = usegetPropertyDetailsQuery({
    skip: !propertyId,
    variables: { propertyId },
    onCompleted: (data) => {
      if (getPropertyFeatureFlags(data.property).GATEWAY_PIN_CODES) {
        setFieldValue('enablePropertyPinCode', true);
      }
    },
  });

  const property = propertyData?.property;
  const featureFlags = getPropertyFeatureFlags(property);

  const canManagePropertyPinCodes = (
    featureFlags.GATEWAY_PIN_CODES &&
    authentication.hasPermission(PermissionKey_enum.PinCode_GenerateRandomByUser)
  );

  const canManageSgtUserRoles = authentication.hasPermission(PermissionKey_enum.User_Read) ||
    authentication.currentRoleRelationships.some(r => (
      r.relatedRole.key === RoleKey_enum.SMART_LOCK_UNREGISTERED_USER &&
      r.canReadUserRole &&
      r.canGrantUserRole
    ));

  const canManageSmartLockPinCodes = (
    featureFlags.HOME_ACCESS &&
    SmartLockModel.permissions.canRead() &&
    canManageSgtUserRoles
  );

  return (
    <>
      <Typography.Title level={4}>Select scope of access</Typography.Title>
      <Typography.Paragraph>
        For eligible properties, a single PIN code can be generated for property and unit access.
      </Typography.Paragraph>
      <ModelFormSelect
        label='Property'
        fieldName='scopedIds'
        formItemProps={{
          className: 'label-align-top',
          style: { marginBottom: 20 },
        }}
        modelSelectProps={{
          style: { width: '100%' },
          model: PropertyModel,
          value: scopedIds[0],
          multiple: false,
          allowClear: false,
          required: true,
          disabled: isSubmitting,
          autoSelectSingleResult: true,
        }}
        setFieldValue={(fieldName, nextScopedIds) => {
          setFieldValue(fieldName, [nextScopedIds]);

          // Reset
          setFieldValue('enablePropertyPinCode', false);
          setFieldValue('smartLockIds', []);
        }}
        errors={formikErrors}
        submitCount={1}
      />
      <Checkbox
        checked={enablePropertyPinCode}
        disabled={!canManagePropertyPinCodes || isSubmitting}
        onChange={(e) => {
          setFieldValue('enablePropertyPinCode', e.target.checked);
        }}
        style={{ marginBottom: 20 }}
      >
        Enable PIN code for property access on entries with keypads
      </Checkbox>
      <ModelFormSelect
        fieldName='smartLockIds'
        formItemProps={{
          className: 'label-align-top',
          style: { marginBottom: 40 },
        }}
        modelSelectProps={{
          style: { width: '100%' },
          model: SmartLockModel,
          value: smartLockIds,
          multiple: true,
          allowClear: true,
          disabled: !canManageSmartLockPinCodes || isSubmitting,
          queryFilters: {
            unit: {
              propertyId: { _eq: propertyId },
            },
          } as SmartLock_bool_exp,
        }}
        setFieldValue={(fieldName, nextScopedIds) => {
          setFieldValue(fieldName, nextScopedIds);
        }}
        errors={formikErrors}
        submitCount={1}
      />
      {scopedIds.length > 0 && <Divider />}
    </>
  );
};
