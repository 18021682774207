import { Checkbox } from 'antd';
import { DocumentNode } from 'graphql';
import pluralize from 'pluralize';
import React, { useContext } from 'react';

import {
  PermissionScope_enum,
  Unit_bool_exp,
  UnitWithOccupantCountFragment,
  UnitWithOccupantCountFragmentDoc,
} from '../../../graphql/hasura/generated';
import { ModelsContext } from '../../../hooks/providers';
import { occupancyColumn } from '../../../models/Unit/columns';
import { UnitModel } from '../../../models/Unit/model';
import { ModelFormSelect } from '../../ModelForm/ModelFormSelect';

import { IRegisteredUserFormMergedProps } from './formik';

export const SelectScopeIds: React.FC<IRegisteredUserFormMergedProps> = (props) => {
  const {
    enabledScopeOptions, setFieldValue, values: formikValues, errors: formikErrors, isSubmitting,
  } = props;
  const { permissionScope, scopedIds, filterByVacants } = formikValues;

  const { getModel } = useContext(ModelsContext);

  const selectedScopeOption = enabledScopeOptions.find(s => s.scope === permissionScope);

  let scopeIdsQueryFilters: any;
  let labelFragment: undefined | DocumentNode;
  let getLabel: undefined | ((option: any) => React.ReactNode);

  if (permissionScope === PermissionScope_enum.UNIT) {
    if (filterByVacants) {
      scopeIdsQueryFilters = {
        occupantCount: { _eq: 0 },
      } as Unit_bool_exp;
    }

    labelFragment = UnitWithOccupantCountFragmentDoc;

    getLabel = (unit: UnitWithOccupantCountFragment) => {
      const occupancy = occupancyColumn.render(unit);

      return (
        <>
          {UnitModel.labels.getUniqueLabel(unit)}
          <span style={{ float: 'right', marginLeft: 8 }}>{occupancy}</span>
        </>
      );
    };
  } else if (permissionScope === PermissionScope_enum.ACCESS_POINT) {
    scopeIdsQueryFilters = {
      archivedAt: { _is_null: true },
    };
  }

  return (
    <>
      {enabledScopeOptions.map(({ schemaName }) => {
        if (
          !selectedScopeOption ||
          !schemaName ||
          selectedScopeOption.schemaName !== schemaName
        ) {
          return null;
        }

        let label: React.ReactNode = pluralize(selectedScopeOption.title);

        if (permissionScope === PermissionScope_enum.UNIT) {
          label = (
            <>
              <span>Units</span>
              <Checkbox
                checked={filterByVacants}
                disabled={isSubmitting}
                onChange={(e) => {
                  setFieldValue('filterByVacants', e.target.checked);
                  setFieldValue('scopedIds', []); // Reset units
                }}
                style={{ float: 'right' }}
              >
                Filter by Vacants
              </Checkbox>
            </>
          );
        }

        return (
          <ModelFormSelect
            key={schemaName}
            label={label}
            fieldName='scopedIds'
            formItemProps={{
              className: 'label-align-top',
              style: { marginBottom: 40 },
            }}
            modelSelectProps={{
              labelFragment,
              getLabel,
              style: { width: '100%' },
              model: getModel(schemaName),
              value: scopedIds,
              multiple: true,
              allowClear: true,
              required: true,
              disabled: isSubmitting,
              queryFilters: scopeIdsQueryFilters,
              autoSelectSingleResult: schemaName === 'Organization' || schemaName === 'Property',
            }}
            setFieldValue={(fieldName, nextScopedIds) => {
              setFieldValue(fieldName, nextScopedIds);

              // Reset
              setFieldValue('role', null);
              setFieldValue('expiresAt', null);
              setFieldValue('reason', null);
              setFieldValue('notes', null);
            }}
            errors={formikErrors}
            submitCount={1}
          />
        );
      })}
    </>
  );
};
