import { ArrayParam, StringParam, useQueryParam } from 'use-query-params';

import { PermissionScope_enum, UserRoleReason_enum } from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';

import { SCOPE_OPTIONS } from './scope-options';

export function usePresetFormValues() {
  const [presetUserId] = useQueryParam('userId', StringParam);
  let [presetPermissionScope] = useQueryParam('permissionScope', StringParam);
  const [presetScopedIds] = useQueryParam('scopedIds', ArrayParam);
  const [presetRoleKey] = useQueryParam('roleKey', StringParam);
  const [presetReason] = useQueryParam('reason', StringParam);

  const enabledScopeOptions = SCOPE_OPTIONS.filter(({ scope }) => (
    authentication.grantableRoleScopes.includes(scope)
  ));

  presetPermissionScope = presetPermissionScope || enabledScopeOptions[0]?.scope || null;

  return {
    enabledScopeOptions,
    presetUserId,
    presetRoleKey,
    presetPermissionScope: presetPermissionScope as PermissionScope_enum | null | undefined,
    presetScopedIds: (presetScopedIds as string[]) || [],
    presetReason: presetReason as UserRoleReason_enum | null | undefined,
  };
}

export type PresetFormValues = ReturnType<typeof usePresetFormValues>;
