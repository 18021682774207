import numeral from 'numeral';
import pluralize from 'pluralize';

import { formatColumns } from '../../models/helpers/formatColumns';
import type { IModelTable } from '../../models/typings';
import { generateWordsFromSearch } from '../../utils';

import type { UseTableData } from './typings';
import { useHasuraTableData } from './useHasuraTableData';
import { useMainApiTableData } from './useMainApiTableData';
import { useTableQueryParams } from './useTableQueryParams';
import { useTableState } from './useTableState';

interface IUseTableManagerArgs {
  tableConfig: IModelTable<any, any, any>;
  tableArgs?: any;
  useQueryParams?: boolean;
  shouldSkipTotal?: boolean;
  shouldSkipList?: boolean;
  disableColumnFilters?: boolean;
}

export function useTableManager(args: IUseTableManagerArgs) {
  const {
    tableConfig, tableArgs, useQueryParams, shouldSkipTotal, shouldSkipList, disableColumnFilters,
  } = args;

  const { model, defaultColumnFilters } = tableConfig;

  const useTableStateArgs = {
    ...tableConfig,
    defaultColumnFilters: disableColumnFilters ? undefined : defaultColumnFilters,
  };

  const { onSearchChange, onTableChange, tableState } = useQueryParams
    ? useTableQueryParams(useTableStateArgs)
    : useTableState(useTableStateArgs);

  const searchWords = tableState.search ? generateWordsFromSearch(tableState.search) : [];

  const formattedColumns = formatColumns({
    model,
    searchWords,
    disableColumnFilters,
    columns: tableConfig.columns,
    filters: tableState.filters,
    orderBy: tableState.orderBy,
  });

  const useTableData: UseTableData = tableConfig.useTableData || useHasuraTableData;

  const tableData = useTableData({
    tableConfig,
    tableState,
    formattedColumns,
    searchWords,
    tableArgs,
    shouldSkipTotal,
    shouldSkipList,
  });

  const { total, totalLoading } = tableData;
  const totalLimit = tableConfig.getTotalLimit();
  const isGteTotalLimit = typeof totalLimit === 'number' && total >= totalLimit;

  const displayedTotal = !totalLoading
    ? `${numeral(total).format('1,000')}${isGteTotalLimit ? '+' : ''} ${pluralize('result', total)}`
    : 'Loading...';

  return {
    tableConfig,
    tableArgs,
    tableData,
    tableState,
    formattedColumns,
    displayedTotal,
    onSearchChange,
    onTableChange,
    title: tableConfig.title,
    rowKey: model.primaryKey,
    expandable: tableConfig.expandable,
    actions: tableConfig.actions.filter(a => a.enabledByModel()),
    hasFiltersApplied: !!Object.values(tableState.filters).filter(Boolean).length,
    useSubscription: tableConfig.useSubscription,
  };
}

export * from './typings';

export type TableManager = ReturnType<typeof useTableManager>;

export { useMainApiTableData };
