import { Button, Input, Modal, Table, Typography } from 'antd';
import { orderBy } from 'lodash';
import React, { useState } from 'react';

import RefreshButton from '../../../components/RefreshButton';
import TabsManager, { ITab } from '../../../components/TabsManager';
import { Timetable } from '../../../components/Timetable';
import { apiClient } from '../../../graphql/api/apiClient';
import { TimetableRuleState, useaccessPointsQuery } from '../../../graphql/api/generated';
import useTabTable from '../../../hooks/useTabTable';
import { displayErrorMessage, generateWordsFromSearch } from '../../../utils';
import * as columns from '../../AccessPoint/columns';
import { AccessPointModel } from '../../AccessPoint/model';
import { AccessPointUserRolesByUserTable } from '../../UserRole/tables';

interface IUserAccessPointsProps {
  userId: string;
}

const UserAccessPoints: React.FC<IUserAccessPointsProps> = ({ userId }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const openModal = () => setVisible(true);
  const closeModal = () => setVisible(false);

  const { tab: accessPointRolesTab } = useTabTable(AccessPointUserRolesByUserTable, userId);

  const { data, loading, refetch } = useaccessPointsQuery({
    client: apiClient,
    variables: {
      input: { userId },
    },
    skip: !visible,
    notifyOnNetworkStatusChange: true,
    onError: error => displayErrorMessage(error),
  });

  const accessPoints = data?.accessPoints || [];

  const sortedAccessPoints = orderBy(accessPoints, [
    a => a.property.name.toLowerCase(),
    a => a.name.toLowerCase(),
  ], ['asc', 'asc']);

  const searchWords = search ? generateWordsFromSearch(search.toLowerCase()) : [];

  const filteredAccessPoints = searchWords.length ? sortedAccessPoints.filter(a => {
    if (
      !(searchWords.every(word => (
        a.property.name.toLowerCase().includes(word) ||
        a.name.toLowerCase().includes(word)
      )))
    ) {
      return false;
    }

    return true;
  }) : sortedAccessPoints;

  const appAccessPointsTab: ITab = {
    loading,
    key: 'APP_ACCESS_POINTS',
    title: 'App Access Points',
    empty: false,
    onTouchedTab: () => {},
    content: () => (
      <>
        <Typography.Paragraph style={{ marginTop: 20, marginBottom: 20 }}>
          The access points below will be visible to this user in the Chirp Access App.
        </Typography.Paragraph>
        <div style={{ display: 'flex', marginBottom: 25 }}>
          <RefreshButton
            disabled={loading}
            refresh={refetch}
            useSubscription={false}
            onError={false}
          />
          <Input.Search
            placeholder='Search...'
            defaultValue={search}
            onChange={e => setSearch(e.target.value)}
            onSearch={v => setSearch(v)}
            enterButton
            allowClear
          />
        </div>
        <Table
          columns={[
            {
              title: 'Property',
              render: (_, accessPoint) => {
                return columns.propertyColumn.render(accessPoint);
              },
            },
            {
              title: 'Name',
              render: (_, accessPoint) => {
                return AccessPointModel.routes.renderRowLink(accessPoint);
              },
            },
            {
              title: 'Image',
              render: (_, accessPoint) => {
                return columns.imageColumn.render(accessPoint);
              },
            },
            {
              title: 'Schedule',
              render: (_, { timetable }) => {
                return (
                  <Timetable
                    timetable={timetable}
                    filter={r => !r.state || r.state === TimetableRuleState.CONTROLLED}
                    twentyFourSevenElement={'24/7 access'}
                  />
                );
              },
            },
          ]}
          loading={loading}
          pagination={false}
          rowKey={a => a.accessPointId}
          dataSource={filteredAccessPoints}
        />
      </>
    ),
  }

  return (
    <>
      <Button
        onClick={() => openModal()}
        style={{ margin: '5px', float: 'left' }}
      >
        Access Points
      </Button>
      <Modal
        title={null}
        visible={visible}
        onCancel={closeModal}
        width='75%'
        footer={null}
        bodyStyle={{ padding: 10 }}
      >
        {visible && <TabsManager tabs={[appAccessPointsTab, accessPointRolesTab]} />}
      </Modal>
    </>
  )
};

export default UserAccessPoints;
