import { Table } from 'antd';
import React from 'react';

import {
  SessionTableFragment,
  usegetSessionDetailsQuery,
} from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import normalizeColumns from '../../helpers/normalizeColumns';
import { SessionsMainTable } from '../tables';

const SessionDetailsLayout = createDetailsLayout<SessionTableFragment>();

// @TODO: Make this the default settings for any model's details page
export const SessionDetailsPage: React.FC<any> = () => {
  return (
    <SessionDetailsLayout
      modelName='Session'
      useQuery={(sessionId) => {
        return usegetSessionDetailsQuery({
          variables: { sessionId },
        });
      }}
      body={(session) => {
        const columns = normalizeColumns(SessionsMainTable.columns);

        return (
          <Table
            columns={columns}
            dataSource={[session]}
            rowKey={u => u.sessionId}
            pagination={false}
            expandable={SessionsMainTable.expandable}
          />
        );
      }}
    />
  );
};

export default SessionDetailsPage;
