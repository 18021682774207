import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import React from 'react';

import { TableManager } from '../../hooks/useTableManager';
import { createActionsColumn } from '../../models/common/columns';
import { normalizeColumns } from '../../models/helpers/normalizeColumns';

interface IPaginatedTableProps extends TableProps<any> {
  tableManager: TableManager;
}

const PaginatedTable = (props: IPaginatedTableProps) => {
  const { tableManager, ...otherProps } = props;
  const { tableData, displayedTotal, tableState, onTableChange, expandable } = tableManager;

  const { total, listLoading, listData, refetch } = tableData;
  const { pageNumber, pageLimit } = tableState;

  const columns = normalizeColumns(tableManager.formattedColumns);
  const extraColumns = [];

  if (tableManager.actions.length) {
    extraColumns.push(createActionsColumn({ refetch, actions: tableManager.actions }));
  }

  return (
    <Table
      loading={{
        size: 'large',
        spinning: listLoading,
      }}
      rowKey={tableManager.rowKey}
      columns={[...columns, ...extraColumns]}
      dataSource={listData}
      scroll={{ x: true }}
      pagination={props.pagination === false ? false : {
        total,
        pageSize: pageLimit,
        pageSizeOptions: ['25', '50', '75', '100'],
        current: pageNumber,
        showTotal: () => displayedTotal,
        showSizeChanger: true,
      }}
      onChange={(pagination, filters, sorter, extra) => {
        onTableChange({ pagination, filters, sorter, extra });
      }}
      expandable={expandable}
      {...otherProps}
    />
  );
}

export default PaginatedTable;
