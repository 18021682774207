import { Table, Typography } from 'antd';
import React from 'react';

import {
  FeatureFlagKey_enum,
  FeatureFlagLabelFragment,
  PermissionScope_enum,
  usegetFeatureFlagsQuery,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';

const { Title } = Typography;

function isFeatureFlagTogglingDisabled(featureFlag: FeatureFlagLabelFragment) {
  return (
    featureFlag.isLocked &&
    authentication.currentDataScope.permissionScope !== PermissionScope_enum.GLOBAL
  );
}

interface IEditPropertyFeatureFlagsProps {
  selectedFeatureFlagKeys: FeatureFlagKey_enum[];
  onToggleFeatureFlagKey(key: FeatureFlagKey_enum): void;
  onSelectAllFeatureFlagKeys(
    selected: boolean,
    selectedRows: FeatureFlagLabelFragment[],
    changeRows: FeatureFlagLabelFragment[],
  ): void | undefined;
}

const EditPropertyFeatureFlags = ({
  selectedFeatureFlagKeys,
  onToggleFeatureFlagKey,
  onSelectAllFeatureFlagKeys,
}: IEditPropertyFeatureFlagsProps) => {
  const { loading, data } = usegetFeatureFlagsQuery({
    fetchPolicy: 'cache-first',
  });

  const columns = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
    },
  ];

  const dataSource = data?.featureFlags ?? [];

  return (
    <>
      <Title level={4}>Edit Property Feature Flags</Title>
      <Table
        loading={loading}
        rowKey='key'
        columns={columns}
        dataSource={dataSource}
        onRow={(row) => {
          return {
            onClick: () => {
              if (!isFeatureFlagTogglingDisabled(row)) {
                onToggleFeatureFlagKey(row.key);
              }
            },
          };
        }}
        rowSelection={{
          type: 'checkbox',
          onSelect: (row, selected) => {
            if (!isFeatureFlagTogglingDisabled(row)) {
              onToggleFeatureFlagKey(row.key);
            }
          },
          onSelectAll: onSelectAllFeatureFlagKeys,
          selectedRowKeys: selectedFeatureFlagKeys,
          getCheckboxProps: (row) => ({ disabled: isFeatureFlagTogglingDisabled(row) }),
        }}
        rowClassName={row => isFeatureFlagTogglingDisabled(row) ? 'disabled-row' : 'selectable-row'}
        style={{ padding: '20px 0px' }}
        pagination={false}
      />
    </>
  );
};

export default EditPropertyFeatureFlags;
