import { Modal, Progress, Table, Typography } from 'antd';
import React, { useEffect } from 'react';

import { HubFilterInput } from '../../../graphql/api/generated';
import { HubLabelFragment } from '../../../graphql/hasura/generated';
import history from '../../../routes/history';
import normalizeColumns from '../../helpers/normalizeColumns';
import * as hubColumns from '../columns';
import { useHubMessenger } from '../hooks/useHubMessenger';
import { HubModel } from '../model';

interface IHubStatusTableProps {
  hubFilter: HubFilterInput;
}

export const HubStatusTable: React.FC<IHubStatusTableProps> = ({ hubFilter }) => {
  const ping = useHubMessenger();

  const { hubs, totalSuccesses, totalFailures } = ping;

  useEffect(() => {
    ping.sendMessageToHubs({
      hubFilter,
      hubMessage: { type: 'PING' },
    });
  }, []);

  if (ping.waiting) {
    return (
      <div style={{ paddingTop: 20 }}>
        <Progress percent={ping.progress} showInfo={false} />
        <Typography.Text type='secondary'>
          Checking online status of Chirp Hubs...
        </Typography.Text>
      </div>
    );
  }

  const normalizedColumns = normalizeColumns([
    {
      ...hubColumns.nameColumn,
      title: 'Hub Name',
      render: (hub: HubLabelFragment) => {
        const path = HubModel.routes.getRowLink(hub);

        if (path) {
          return (
            // The React Router Link component cannot be rendered inside the modal content
            <a
              href={path}
              onClick={(e) => {
                e.preventDefault();
                history.push(path);
                Modal.destroyAll();
              }}
            >
              {HubModel.labels.getLabel(hub)}
            </a>
          );
        }

        return HubModel.labels.getLabel(hub);
      }
    },
    hubColumns.deviceTypeColumn,
    { ...hubColumns.lastDisconnectionColumn, sorter: false },
    { ...hubColumns.pingTestColumn, sorter: false },
  ]);

  let message = '';

  if (ping.error || totalSuccesses === hubs.length) {
    message = (
      `Oops! Something went wrong. Please try again. If this issue persists, contact your certified installer for support.`
    );
  } else {
    if (totalFailures === hubs.length && hubs.length === 1) {
      message = (
        `Oops! This Chirp Hub is currently offline. Please check your property's internet connection and try again. ` +
        `If this issue persists, contact your certified installer for support.`
      );
    } else {
      message = (
        `Oops! One or more of these Chirp Hubs are offline. Please check your property's internet connection and try again. ` +
        `If this issue persists, contact your certified installer for support.`
      );
    }
  }

  return (
    <>
      {message && (
        <Typography.Paragraph style={{ paddingTop: 20, paddingBottom: !!hubs.length ? 20 : 0 }}>
          {message}
        </Typography.Paragraph>
      )}
      {!!hubs.length && (
        <Table
          loading={{ size: 'large', spinning: ping.waiting }}
          rowKey={h => h.hubId}
          columns={normalizedColumns}
          dataSource={hubs}
          scroll={{ x: true }}
          pagination={{ pageSize: 25, hideOnSinglePage: true }}
        />
      )}
    </>
  );
};

export default HubStatusTable;
