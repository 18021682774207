import { Card, Empty, Skeleton, Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';
import React, { useEffect, useState } from 'react';

export interface ITab {
  key: string;
  title: string;
  loading: boolean;
  empty: boolean;
  onTouchedTab: () => any;
  content: () => React.ReactNode;
}

interface ITabsManagerProps {
  tabs: (ITab | null)[];
  tabsProps?: TabsProps;
  cardBodyStyle?: React.CSSProperties;
}

interface ITabsManagerState {
  tabsReady: boolean;
  activeTab: string;
  touchedTabs: { [key: string]: boolean };
}

const defaultTabsState: ITabsManagerState = {
  tabsReady: false,
  activeTab: '-1', // Workaround to prevent first tab from being active by default
  touchedTabs: {},
};

export const TabsManager: React.FC<ITabsManagerProps> = ({ tabs, tabsProps, cardBodyStyle }) => {
  const [tabsState, setTabsState] = useState<ITabsManagerState>({ ...defaultTabsState });
  const { tabsReady, activeTab, touchedTabs } = tabsState;

  const tabsHidden = tabs.every(t => !t);
  const tabsLoading = tabs.some(t => t && t.loading && !t.empty);
  const tabsEmpty = !tabsLoading && tabs.every(t => t && t.empty);

  const defaultTabIndex = !tabsLoading ? tabs.findIndex(t => t && !t.empty) : -1;
  const defaultTab = (defaultTabIndex !== -1) ? tabs[defaultTabIndex] : null;

  useEffect(() => {
    if (!tabsReady && !tabsLoading) {
      setTabsState({
        tabsReady: true, // Avoid hiding/showing all tabs when using pagination
        activeTab: defaultTab ? defaultTab.key : tabsState.activeTab,
        touchedTabs: defaultTab
          ? { ...touchedTabs, [defaultTab.key]: true }
          : touchedTabs,
      });

      if (defaultTab) {
        defaultTab.onTouchedTab(); // Ensure the default tab loads its content
      }
    }
  }, [tabsReady, tabsLoading, defaultTabIndex]);

  useEffect(() => {
    // Reset state if tabs start reloading
    if (tabsReady && tabsLoading) {
      setTabsState({ ...defaultTabsState });
    }
  }, [tabsLoading]);

  if (tabsHidden) {
    return null;
  }

  if (!tabsReady) {
    return (
      <Card bordered bodyStyle={cardBodyStyle}>
        <Skeleton loading active />
      </Card>
    );
  }

  return (
    <Card bordered={false} bodyStyle={cardBodyStyle}>
      <Tabs
        animated={false}
        defaultActiveKey={defaultTab ? defaultTab.key : '-1'}
        activeKey={activeTab}
        onChange={(nextActiveTabKey) => {
          setTabsState({
            ...tabsState,
            activeTab: nextActiveTabKey,
            touchedTabs: { ...touchedTabs, [nextActiveTabKey]: true },
          });

          const nextActiveTab = tabs.find(t => t && t.key === nextActiveTabKey);

          if (nextActiveTab) {
            nextActiveTab.onTouchedTab();
          }
        }}
        {...tabsProps}
      >
        {tabs.map((tab) => {
          if (!tab) {
            return null;
          }

          const { key, empty, title, content } = tab;

          return (
            <Tabs.TabPane
              key={key}
              tab={title}
              disabled={empty}
            >
              {!empty ? content() : null}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
      {tabsEmpty && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </Card>
  );
}

export default TabsManager;
