import React from 'react';
import { Link } from 'react-router-dom';

import { PermissionKey_enum, PermissionScope_enum } from '../../graphql/hasura/generated';

import HubMessenger from './components/HubMessenger';
import HubMonitoring from './components/HubMonitoring';
import { unlockHubRelays } from './helpers';
import model from './model';

export const monitoringAction = model.createAction({
  label: () => 'Monitoring',
  description: 'Check hub status or restart hub',
  enabledByModel: () => true,
  renderModalContent: ({ hubId }) => <HubMonitoring hubId={hubId} />,
  getModalProps: (hub) => ({
    title: model.labels.getUniqueLabel(hub),
    width: '60%',
    footer: null,
    destroyOnClose: true,
  }),
});

export const healthAction = model.createAction({
  label: () => 'Health',
  description: 'View hub health reports',
  enabledByModel: ({ currentPermissionScope, hasPermission }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL &&
    hasPermission(PermissionKey_enum.Hub_Read)
  ),
  render({ hubId }) {
    return (
      <Link to={`${model.routes.basePath}/health/${hubId}`}>
        Health
      </Link>
    );
  },
});

export const messengerAction = model.createAction({
  label: () => 'Messenger',
  description: 'Send and receive messages from hub',
  enabledByModel: ({ hasPermission }) => hasPermission(PermissionKey_enum.Hub_Messenger),
  renderModalContent: (hub) => <HubMessenger hub={hub} />,
  getModalProps: () => ({
    title: 'Hub Messenger',
    width: '75%',
    footer: null,
  }),
});

export const unlockRelaysAction = model.createAction({
  label: () => 'Unlock Relays',
  description: 'Unlock all relays assigned to this hub',
  enabledByModel: ({ hasPermission, currentPermissionScope }) => (
    hasPermission(PermissionKey_enum.Hub_Installation) &&
    currentPermissionScope === PermissionScope_enum.GLOBAL
  ),
  confirmation: {
    title: () => 'Unlock Relays',
    content: hub => (
      `Are you sure you want to unlock ALL relays for ${model.labels.getLabel(hub)}?`
    ),
  },
  async executes(hub) {
    const { hubId } = hub;
    const label = model.labels.getLabel(hub);

    await unlockHubRelays({
      hubId,
      relayPositions: new Array(16).fill(1).map((n, i) => n + i),
      successMessage: `Relays successfully unlocked for ${label}`,
      unknownMessage: `Unlock request sent to ${label}`,
      errorTitle: `Failed to unlock relays for ${label}`,
    });
  },
});
