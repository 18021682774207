import { Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import React from 'react';

import { Color, formatEnumTag } from '../../utils';

interface IEnumTagProps extends TagProps {
  enumValue: string | null | undefined;
  colorMap: { [key: string]: Color };
  iconMap?: { [key: string]: React.ReactNode };
}

export const EnumTag = (props: IEnumTagProps) => {
  const { enumValue, colorMap, iconMap, children, style, ...otherProps } = props;

  if (!enumValue) {
    return null;
  }

  const color = colorMap[enumValue];
  const icon = iconMap ? iconMap[enumValue] : null;

  return (
    <Tag
      color={color}
      style={{ fontWeight: 600, ...style }}
      {...otherProps}
    >
      {icon && <span style={{ marginRight: '5px' }}>{icon}</span>}
      {children || (formatEnumTag(enumValue))}
    </Tag>
  );
};

export default EnumTag;

export { Color } from '../../utils';
