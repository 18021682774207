import * as yup from 'yup';

import getModelType from './get-model-type';
import getValidationForType from './get-validation-for-type';

export default async (model: string) => {
  const typeSchema: any = {};
  const validationSchema: any = {};
  const type = await getModelType(model);
  const fields = type ? (type.fields ?? []) : [];

  for (const field of fields) {
    const mainType = field.type.ofType || field.type;
    const fieldType = mainType.name;

    if (
      // Omit fields like "deprecatedRelayId" (i.e., required, but has a default value)
      field.name.startsWith('deprecated') &&
      field.name !== 'deprecatedType' // We want this field available to edit in the Role form
    ) {
      continue;
    }

    if (mainType.name && mainType.kind === 'OBJECT') {
      continue;
    }

    if (!fieldType) {
      continue;
    }

    const required = field.type.kind === 'NON_NULL';

    typeSchema[field.name] = {
      required,
      type: fieldType
    };

    validationSchema[field.name] = getValidationForType(
      field.name,
      fieldType,
      required,
      model
    );
  }

  return { typeSchema, validationSchema: yup.object().shape(validationSchema) };
};
