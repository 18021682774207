import { camelCase, startCase } from 'lodash';
import pluralize from 'pluralize';

import { formatPrimaryKey } from '../utils';

import ModelActions from './ModelActions';
import { ModelPermissions } from './ModelPermissions';
import ModelRoutes from './ModelRoutes';
import ModelTables from './ModelTables';
import {
  IModel,
  IModelActionOptions,
  IModelColumnOptions,
  IModelFormField,
  IModelFormFieldOptions,
  IModelFormOptions,
  IModelImportOptions,
  IModelIntrospection,
  IModelLabels,
  IModelNames,
  IModelOptions,
  IModelQueryOptions,
  IModelTableOptions,
} from './typings';

export class Model<
  TBoolExp,
  TLabel = any,
  TUniqueLabel extends TLabel = TLabel,
  TRequiredRowData extends TLabel = TLabel,
  TOrderBy = any
> implements IModel<TLabel, TUniqueLabel, TRequiredRowData> {

  public introspection: IModelIntrospection;

  public readonly names: Required<IModelNames>;
  public readonly primaryKey: string;
  public readonly permissions: ModelPermissions<TRequiredRowData>;
  public readonly routes: ModelRoutes<TLabel, TUniqueLabel>;
  public readonly tables: ModelTables<TBoolExp, TRequiredRowData, TOrderBy>;
  public readonly actions: ModelActions<TRequiredRowData>;
  public readonly labels: IModelLabels<TLabel, TUniqueLabel>;
  public readonly queryOptions: IModelQueryOptions<TBoolExp, TOrderBy>;

  // tslint:disable variable-name
  private _formOptions: IModelFormOptions<any> = { fields: [] };
  private _importOptions: IModelImportOptions | undefined = undefined;

  constructor(options: IModelOptions<TBoolExp, TLabel, TUniqueLabel, TRequiredRowData>) {
    const schemaName = options.names.schemaName;
    const displayName = options.names.displayName || startCase(schemaName);
    const pluralDisplayName = options.names.pluralDisplayName || pluralize.plural(displayName);

    this.names = { schemaName, displayName, pluralDisplayName };
    this.primaryKey = options.primaryKey || `${camelCase(this.names.schemaName)}Id`;

    const getIdLabel = (row: any) => (
      `${this.names.displayName} ${formatPrimaryKey(row[this.primaryKey], true) || '?'}`
    );

    const getLabel: (row: any) => string = options.labels?.getLabel
      ? options.labels.getLabel
      : getIdLabel;

    const getUniqueLabel = options.labels?.getUniqueLabel || getLabel;
    const getBreadCrumbsLabel = options.labels?.getBreadCrumbsLabel || getUniqueLabel;

    this.labels = {
      getIdLabel,
      getLabel,
      getUniqueLabel,
      getBreadCrumbsLabel,
      // @TODO: Generate default fragments
      labelFragment: options.labels?.labelFragment,
      uniqueLabelFragment: options.labels?.uniqueLabelFragment || options.labels?.labelFragment,
    };

    const { queryOptions } = options;

    this.queryOptions = {
      getTableCountLimit: queryOptions?.getTableCountLimit,
      getSearchConditions: queryOptions?.getSearchConditions,
      getTableSearchConditions: queryOptions?.getTableSearchConditions === null
        ? null
        : queryOptions?.getTableSearchConditions || queryOptions?.getSearchConditions,
      defaultSelectOrderBy: queryOptions?.defaultSelectOrderBy || { createdAt: 'desc' } as any,
      defaultTableOrderBy: queryOptions?.defaultTableOrderBy || { createdAt: 'desc' } as any,
    };

    this.introspection = {
      canCreate: false,
      canRead: false,
      canUpdate: false,
      canDelete: false,
      fields: [],
      relationships: [],
      getField: (fieldName) => {
        const field = this.introspection.fields.find(f => f.name === fieldName);

        if (!field) {
          throw new Error(`Unable to find field ${fieldName} on model ${schemaName}`);
        }

        return field;
      },
    };

    this.permissions = new ModelPermissions(this.names.schemaName, this.introspection, options.permissionsOptions);
    this.routes = new ModelRoutes(this);
    this.actions = new ModelActions(this);
    this.tables = new ModelTables(this, this.actions);
  }

  public get formOptions() { return this._formOptions; }
  public get importOptions() { return this._importOptions; }

  public createColumn<TRowData>(
    options: IModelColumnOptions<TRowData, TOrderBy>,
  ) {
    return this.tables.createColumn(options);
  }

  public createIdColumn() {
    return this.tables.createIdColumn();
  }

  public createAction<TRowData extends TRequiredRowData>(
    options: IModelActionOptions<TRowData>
  ) {
    return this.actions.createAction(options);
  }

  public createTable<TTableData extends TRequiredRowData, TArgs = any>(
    options: IModelTableOptions<TTableData, TBoolExp, TArgs>
  ) {
    return this.tables.createTable(options);
  }

  public createFormField<TParsedRow = any>(options: IModelFormFieldOptions<TParsedRow>) {
    const [formField] = this.createFormFields([options]);

    return formField;
  }

  public createFormFields<TParsedRow = any>(options: IModelFormFieldOptions<TParsedRow>[]) {
    const formFields: IModelFormField<TParsedRow>[] = options.map(field => {
      return {
        model: this,
        getIntrospectionField: () => {
          return this.introspection.fields.find(f => f.name === field.fieldName) || null;
        },
        ...field,
      };
    });

    return formFields;
  }

  public setFormOptions<TParsedRow, TApplyAllValues, TTransformedRow, TImportDependencies = any>(
    options: IModelFormOptions<TParsedRow, TApplyAllValues, TTransformedRow, TImportDependencies>,
  ) {
    return this._formOptions = options;
  }
}

export default Model;
