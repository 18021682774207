import React from 'react';

import { SplitDescriptions } from '../../../components/SplitDescriptions';
import {
  PropertyTableFragment,
  usegetPropertyDetailsQuery,
} from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import { getPropertyFeatureFlags } from '../../../utils';
import { createdAtColumn, updatedAtColumn } from '../../common/columns';
import * as columns from '../columns';

const PropertyDetailsLayout = createDetailsLayout<PropertyTableFragment>();

export const PropertyDetailsPage: React.FC<any> = () => {
  return (
    <PropertyDetailsLayout
      modelName='Property'
      useQuery={(propertyId) => {
        return usegetPropertyDetailsQuery({
          variables: { propertyId },
        });
      }}
      body={(property) => {
        const virtualDirectoryEnabled = getPropertyFeatureFlags(property).VIRTUAL_DIRECTORY;

        return (
          <SplitDescriptions
            row={property}
            leftSideColumns={[
              columns.organizationColumn,
              columns.nameColumn,
              columns.timezoneColumn,
              columns.propertyFeatureFlagsColumn,
              createdAtColumn,
              updatedAtColumn,
            ]}
            rightSideColumns={[
              columns.imageColumn,
              columns.addressColumn,
              columns.supportPhoneNumberColumn,
              columns.supportEmailColumn,
              virtualDirectoryEnabled ? columns.vdPhoneNumberColumn : null,
              virtualDirectoryEnabled ? columns.vdLeasingOfficeHoursColumn : null,
            ]}
          />
        );
      }}
    />
  );
};

export default PropertyDetailsPage;
