import {
  filterTimetable,
  formatStartAndEndTime,
  getCurrentTimetableRules,
  getTimezonedDate,
  getValidTimetableRulesByDay,
  TimetableRule,
  TimetableRuleState,
} from '@chirp/access-verification';

export function getAvailabilityDescription(
  date = new Date(),
  timetable: TimetableRule[] | null | undefined,
  timezone: string,
) {
  const timezonedDate = getTimezonedDate(date, timezone);
  const filteredTimetable = filterTimetable(timezonedDate, timetable, timezone);

  if (!filteredTimetable) {
    return 'Open all day';
  }

  const currentTimetableRules = getCurrentTimetableRules(timezonedDate, filteredTimetable);

  if (currentTimetableRules.some(rule => rule.state === TimetableRuleState.LOCKDOWN)) {
    return 'LOCKDOWN';
  }

  const timetableRulesByDay = getValidTimetableRulesByDay(timezonedDate, filteredTimetable);

  if (timetableRulesByDay.length > 0) {
    const [timetableRule] = timetableRulesByDay;

    if (timetableRule.start === '00:00' && timetableRule.end === '00:00') {
      return 'Open all day';
    }

    const { startTime, endTime } = formatStartAndEndTime(timezonedDate, timetableRule);

    return `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`;
  }

  return 'Closed all day';
}
