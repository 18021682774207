import { useEffect, useState } from 'react';

interface ITimerOptions {
  timeoutMs: number;
  intervalMs?: number;
}

interface ITimerState {
  running: boolean;
  intervalId: NodeJS.Timeout | null;
  timeElapsed: number;
}

export const useTimer = () => {
  const [options, setOptions] = useState<Partial<ITimerOptions>>({});

  const timeoutMs = options?.timeoutMs;
  const intervalMs = options?.intervalMs || 1000;

  const [state, setState] = useState<ITimerState>({
    running: false,
    intervalId: null,
    timeElapsed: 0,
  });

  const { running, intervalId, timeElapsed } = state;

  const startTimer = (nextOptions: ITimerOptions) => {
    if (!running) {
      setOptions({ ...options, ...nextOptions });
      setState({ running: true, intervalId: null, timeElapsed: 0 });
    }
  };

  const stopTimer = () => {
    if (running && timeoutMs) {
      setState({
        running: false,
        intervalId: state.intervalId,
        timeElapsed: timeoutMs,
      });
    }
  };

  const reset = () => {
    setOptions({});
    setState({ running: false, intervalId: null, timeElapsed: 0 });
  };

  useEffect(() => {
    if (!running || !timeoutMs || !intervalMs) {
      if (intervalId) {
        clearInterval(intervalId);
      }
      return;
    }

    const nextIntervalId = setInterval(() => {
      setState((prevState) => {
        const nextTimeElapsed = prevState.running
          ? prevState.timeElapsed + intervalMs
          : prevState.timeElapsed;

        return {
          ...prevState,
          timeElapsed: nextTimeElapsed,
          running: nextTimeElapsed < timeoutMs,
        };
      });
    }, intervalMs);

    setState({ ...state, intervalId: nextIntervalId });

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [running, timeoutMs, intervalMs]);

  let progress = 0;

  if (typeof timeoutMs === 'number') {
    progress = timeElapsed >= timeoutMs ? 100 : (timeElapsed / timeoutMs) * 100;
  }

  return { running, timeElapsed, progress, startTimer, stopTimer, reset };
};

export default useTimer;
