import { Descriptions } from 'antd';
import React from 'react';

import { Hub } from '../../../graphql/api/generated';

interface IHubDescriptionProps {
  hub: Pick<Hub, 'macAddress' | 'ipAddress'>;
}

export const HubDescriptions: React.FC<IHubDescriptionProps> = ({ hub }) => {
  const { macAddress, ipAddress } = hub;

  return (
    <Descriptions column={1}>
      {macAddress && <Descriptions.Item label='MAC Address'>{macAddress}</Descriptions.Item>}
      {ipAddress && <Descriptions.Item label='IP Address'>{ipAddress}</Descriptions.Item>}
    </Descriptions>
  );
}

export default HubDescriptions;
