import React from 'react';
import { Link } from 'react-router-dom';

import { IModel } from '../../models/typings';

interface IModelBreadcrumbsProps {
  model: IModel;
  rowBreadCrumb?: {
    row?: any;
    isNew?: boolean;
  };
  customBreadCrumbs?: React.ReactNode[];
}

const ModelBreadcrumbs: React.FC<IModelBreadcrumbsProps> = (props) => {
  const { model, customBreadCrumbs = [] } = props;

  const row = props.rowBreadCrumb?.row;
  const isNew = props.rowBreadCrumb?.isNew;

  let rowBreadCrumb: React.ReactNode = null;

  if (!isNew && row) {
    let rowLabel = model.labels.getIdLabel(row);

    try {
      rowLabel = model.labels.getBreadCrumbsLabel(row) || rowLabel;
    } catch {
      rowLabel = rowLabel;
    }

    const rowLink = customBreadCrumbs.length ? model.routes.getRowLink(row) : null;

    rowBreadCrumb = rowLink ? <Link to={rowLink}>{rowLabel}</Link> : rowLabel;
  } else if (isNew) {
    rowBreadCrumb = `New ${model.names.displayName}`;
  }

  return (
    <>
      <Link to={model.routes.defaultRoute.path}>
        {model.names.pluralDisplayName}
      </Link>
      {rowBreadCrumb && (
        <span style={{ padding: '0 8px'}}>
          &#62;
        </span>
      )}
      {rowBreadCrumb}
      {customBreadCrumbs.length > 0 && customBreadCrumbs.map((breadCrumb, index) => (
        <span key={index}>
          <span style={{ padding: '0 8px'}}>
            &#62;
          </span>
          {breadCrumb}
        </span>
      ))}
    </>
  )
};

export default ModelBreadcrumbs;
