// @TODO: In lieu of using the "react-papaparse" package - which is not currently being maintained -
// we are copying the code for the CSVDownloader from the GitHub repository:
//
// File: https://github.com/Bunlong/react-papaparse/blob/df61ae926226435314a47ebacc8f3656d61e889b/src/useCSVDownloader.tsx
//
// Our file also includes a try/catch wrapper, as well as support for an asynchronous "data" callback.
// There is a PR for the latter that is currently unmerged:
//
// https://github.com/Bunlong/react-papaparse/pull/147

import PapaParse, { UnparseConfig } from 'papaparse';
import React from 'react';

import { displayErrorMessage } from '../utils';

const Type = {
  Link: 'link',
  Button: 'button',
} as const;

export interface IProps {
  children: React.ReactNode;
  data: any;
  filename: string;
  type?: 'link' | 'button';
  style?: any;
  className?: string;
  bom?: boolean;
  config?: UnparseConfig;
}

function useCSVDownloaderComponent() {
  const CSVDownloaderComponent = ({
    children,
    data = {},
    filename,
    type = Type.Link,
    style = {},
    className = '',
    bom = false,
    config = {},
  }: IProps) => {
    const download = async () => {
      try {
        const bomCode = bom ? '\ufeff' : '';
        let csvContent = null;
        let csvURL = null;

        if (typeof data === 'function') {
          // tslint:disable-next-line no-parameter-reassignment
          data = await data();
        }

        if (typeof data === 'object') {
          csvContent = PapaParse.unparse(data, config);
        } else {
          csvContent = data;
        }

        const csvData = new Blob([`${bomCode}${csvContent}`], {
          type: 'text/csv;charset=utf-8;',
        });

        const navObj: any = window.navigator;
        if (navObj.msSaveBlob) {
          csvURL = navObj.msSaveBlob(csvData, `${filename}.csv`);
        } else {
          csvURL = window.URL.createObjectURL(csvData);
        }

        const link = document.createElement('a');
        link.href = csvURL as string;
        link.setAttribute('download', `${filename}.csv`);
        link.click();
        link.remove();
      } catch (error) {
        displayErrorMessage(error as Error);
      }
    };

    return (
      <>
        {type === Type.Button ? (
          <button
            onClick={() => download()}
            style={style}
            className={className}
          >
            {children}
          </button>
        ) : (
          <a onClick={() => download()} style={style} className={className}>
            {children}
          </a>
        )}
      </>
    );
  };

  const CSVDownloader = React.useMemo(() => CSVDownloaderComponent, []) as any;

  return CSVDownloader;
}

export function useCSVDownloader() {
  const CSVDownloader = useCSVDownloaderComponent();

  return {
    CSVDownloader,
    Type,
  };
}
