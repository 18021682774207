import { FormikErrors, FormikProps } from 'formik';

import { IModel } from '../../models/typings';

import ModelImportRow from './ModelImportRow';

export interface IModelImportFormValues {
  fileInputKey: string;
  file: FileList[0] | null;
  applyAllValues: { [k: string]: any };
  importDependencies: { [k: string]: any };
  rows: ModelImportRow<any, any>[];
  parsingRows: boolean;
  importingRows: boolean;
  importingProgress: number;
  showImportingProgress: boolean;
}

export interface IModelImportPageProps {
  model: IModel;
}

export interface IModelImportPageMergedProps extends
  IModelImportPageProps, FormikProps<IModelImportFormValues> {}

export function mapPropsToValues(props: IModelImportPageProps): IModelImportFormValues {
  return {
    fileInputKey: Date.now().toString(),
    file: null,
    applyAllValues: {},
    importDependencies: {},
    rows: [],
    parsingRows: false,
    importingRows: false,
    importingProgress: 0,
    showImportingProgress: false,
  };
}

export function validate(values: IModelImportFormValues, { model }: IModelImportPageProps) {
  const errors: FormikErrors<IModelImportFormValues> = {};

  const applyAllFields = model.formOptions.fields.filter(f => !!f.importOptions?.applyAll);

  // @TODO: Support other field validation(?)
  for (const { fieldName, label, importOptions } of applyAllFields) {
    if (importOptions?.required && !values.applyAllValues[fieldName]) {
      errors.applyAllValues = {
        ...errors.applyAllValues,
        [fieldName]: `${label || fieldName} is a required field`,
      };
    }
  }

  return errors;
}
