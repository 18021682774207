import { Form } from '@ant-design/compatible';
import React from 'react';

import PhoneNumberField from '../../components/PhoneNumberField';
import { TimetableField } from '../../components/TimetableField';
import { FORM_ITEM_DEFAULT_LAYOUT } from '../../pages/ModelForm/form-fields';
import { getPropertyFeatureFlags } from '../../utils';
import { validateTimetable } from '../helpers/validate/timetable';
import { IModelFormFieldOptions } from '../typings';

import model from './model';
import PropertyFeatureFlags from './PropertyFeatureFlags';

interface ICreatePhoneNumberFormFieldArgs extends IModelFormFieldOptions<any> {
  fieldName: string;
  helpText?: string;
}

function createPhoneNumberFormField(
  args: ICreatePhoneNumberFormFieldArgs,
): IModelFormFieldOptions<any> {
  const { helpText, ...formFieldOptions } = args;

  return {
    maxLength: 20,
    render: ({ FormItem, formItemProps, formikProps, field, disabled }) => {
      const { values, setFieldValue } = formikProps;

      return (
        <FormItem {...formItemProps}>
          <PhoneNumberField
            value={values[field.name]}
            onChange={(value) => setFieldValue(field.name, value)}
            disabled={disabled || formikProps.isSubmitting}
          />
          {helpText && <p className='help-text'>{helpText}</p>}
        </FormItem>
      );
    },
    ...formFieldOptions,
  };
}

const formFields = model.createFormFields([
  createPhoneNumberFormField({ fieldName: 'supportPhoneNumber' }),
  createPhoneNumberFormField({
    fieldName: 'virtualDirectoryPhoneNumber',
    hidden: ({ formikProps }) => !getPropertyFeatureFlags(formikProps.values).VIRTUAL_DIRECTORY,
    helpText: 'Set the Leasing Office phone number to receive access requests from Virtual Directory.',
  }),
  {
    fieldName: 'name',
    maxLength: 100,
  },
  {
    fieldName: 'supportEmail',
    maxLength: 320,
  },
  {
    fieldName: 'address',
    maxLength: 300,
  },
  {
    fieldName: 'virtualDirectoryStaffHours',
    hidden: ({ formikProps }) => !getPropertyFeatureFlags(formikProps.values).VIRTUAL_DIRECTORY,
    render: ({ field, disabled, formikProps, FormItem, formItemProps }) => {
      const { values, setFieldValue, touched, errors } = formikProps;

      return (
        <FormItem {...formItemProps} label='Virtual Directory Leasing Office Hours'>
          <TimetableField
            values={values}
            fieldName='virtualDirectoryStaffHours'
            modelName={model.names.schemaName}
            onChange={(value) => {
              setFieldValue(field.name, value, true);
            }}
            touched={touched}
            errors={errors}
            disabled={disabled}
          />
          <p className='help-text'>
            Restrict hours that visitors and vendors can request access
            from the Leasing Office via Virtual Directory.
          </p>
        </FormItem>
      );
    },
  },
]);

model.setFormOptions({
  fields: formFields,
  validate: (values) => validateTimetable(values, 'virtualDirectoryStaffHours'),
  renderExtra: (formikProps, isNew) => {
    if (isNew) {
      return null;
    }

    return (
      <Form.Item
        {...FORM_ITEM_DEFAULT_LAYOUT}
        label='Feature Flags'
      >
        <PropertyFeatureFlags property={formikProps.values} />
      </Form.Item>
    );
  },
});
