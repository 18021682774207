import {
  order_by,
  PermissionScope_enum,
  usegetGrantableRolesQuery,
} from '../../../../graphql/hasura/generated';
import { authentication } from '../../../../stores';

import { filterQueriedRoles } from './filterQueriedRoles';
import { getRoleQueryFilters } from './getRoleQueryFilters';
import { getWarningFlags } from './getWarningFlags';
import { IScopeQueriesResult, IUseGrantableRolesArgs } from './typings';

export function useRolesQuery(
  args: IUseGrantableRolesArgs,
  scopeQueriesResult: IScopeQueriesResult,
) {
  const { permissionScope, onRolesChanged } = args;
  const { organizations } = scopeQueriesResult;

  const rolesQueryResult = usegetGrantableRolesQuery({
    fetchPolicy: 'cache-first',
    skip: (
      !permissionScope ||
      (permissionScope !== PermissionScope_enum.GLOBAL && !organizations) ||
      !authentication.currentDataScope.userRoles.length
    ),
    variables: {
      where: {
        permissionScope: { _eq: permissionScope },
        ...getRoleQueryFilters(permissionScope, organizations || []),
      },
      order_by: [
        { rolePermissions_aggregate: { count: order_by.desc } },
        { name: order_by.asc, },
      ],
    },
    onCompleted: ({ roles: queriedRoles }) => {
      const warningFlags = getWarningFlags(args, scopeQueriesResult);
      const filteredRoles = filterQueriedRoles(queriedRoles, args.permissionScope, warningFlags);

      onRolesChanged(filteredRoles);
    },
  });

  return {
    loading: rolesQueryResult.loading,
    roles: rolesQueryResult.data?.roles || [],
  };
}
