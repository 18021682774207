import { ColumnProps } from 'antd/lib/table';
import { orderBy } from 'lodash';
import React from 'react';

import EnumTag, { Color } from '../../components/EnumTag';
import normalizeColumns from '../../models/helpers/normalizeColumns';
import {
  IModel,
  IModelFormField,
  IModelImportRowError,
  IRowImportState,
  RowImportStatus,
} from '../../models/typings';
import { truncateText } from '../../utils';

const ERROR_FONT_COLOR = '#ff4d4f';

const ROW_NUMBER_COLUMN: ColumnProps<any> = {
  title: 'Row #',
  render: (_, { rowNumber }) => rowNumber,
};

export interface IInvalidRow {
  rowNumber: number;
  errors: IModelImportRowError<any>[];
}

export interface IValidRow {
  rowNumber: number
  importState: IRowImportState;
}

export function createInvalidRowColumns(importFields: IModelFormField<any>[]) {
  const columns: ColumnProps<IInvalidRow>[] = [ROW_NUMBER_COLUMN];

  for (const field of importFields) {
    const fieldName = field.importOptions?.importFieldName || field.fieldName;

    columns.push({
      title: fieldName,
      render: (_, row) => {
        const errors = row.errors as IModelImportRowError<any>[];
        const hasErrors = errors.some(e => (e.highlightedFieldNames || []).includes(fieldName));

        return (
          <span style={{ color: hasErrors ? ERROR_FONT_COLOR : undefined }}>
            {truncateText((row as any)[fieldName], 100)}
          </span>
        );
      },
    });
  }

  columns.push({
    title: 'Errors',
    render: (_, { errors }) => {
      const sortedErrors = orderBy(errors, [
        e => e.errorFieldName,
        e => e.message,
      ]);

      return (
        <ul style={{ padding: 0, whiteSpace: 'nowrap' }}>
          {sortedErrors.map((e) => {
            const errorMessage = e.errorFieldName ? `${e.errorFieldName}: ${e.message}` : e.message;

            return (
              <li key={errorMessage}>{errorMessage}</li>
            );
          })}
        </ul>
      );
    },
  });

  return columns;
}

export function createValidRowColumns(model: IModel<any>) {
  const columns: ColumnProps<IValidRow>[] = [
    ROW_NUMBER_COLUMN,
    {
      title: 'Import Status',
      filters: Object.entries(RowImportStatus).map(([value, text]) => {
        return ({ value, text });
      }),
      onFilter: (filter, record) => {
        return record.importState.status === filter;
      },
      render: (_, { importState,  }) => {
        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            <EnumTag
              enumValue={importState.status}
              colorMap={{
                [RowImportStatus.IMPORTED]: Color.Green,
                [RowImportStatus.ERROR]: Color.Red,
              }}
            />
            {importState.errorMessage && (
              <div style={{ color: ERROR_FONT_COLOR, marginTop: 10 }}>
                {importState.errorMessage}
              </div>
            )}
          </div>
        );
      },
    },
    ...normalizeColumns(model.formOptions.importOptions?.columns || []),
  ];

  return columns;
}
