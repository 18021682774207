import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { apiClient } from '../../../graphql/api/apiClient';
import {
  HubActivationStatus,
  HubResponse,
  SendMessageToHubsAsyncInput,
  usesendMessageToHubsAsyncSubscription,
} from '../../../graphql/api/generated';
import useTimer from '../../../hooks/useTimer';
import { displayErrorMessage } from '../../../utils';

interface IHubFragment {
  hubId: string;
  activationStatus?: string;
}

interface IHubSubscriptionState {
  input: SendMessageToHubsAsyncInput | null;
  waiting: boolean;
  completed: boolean;
}

export type IHubWithResponseState<THub extends IHubFragment = IHubFragment> = THub & {
  hubResponse?: {
    state: 'WAITING' | 'ERROR' | 'TIMEOUT' | 'SUCCESS';
    response?: HubResponse['response'];
    responseTime?: HubResponse['responseTime'];
  };
}

function getDefaultSubscriptionState(): IHubSubscriptionState {
  return { input: null, waiting: false, completed: false };
}

export const useHubMessenger = <THub extends IHubFragment>() => {
  const [subscriptionState, setSubscriptionState] = useState(getDefaultSubscriptionState());
  const [hubs, setHubs] = useState<IHubWithResponseState<THub>[]>([]);

  const timer = useTimer();

  const { loading, error } = usesendMessageToHubsAsyncSubscription({
    client: apiClient,
    fetchPolicy: 'network-only',
    skip: !subscriptionState.input,
    variables: {
      input: subscriptionState.input as SendMessageToHubsAsyncInput,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      const hubResponses = subscriptionData?.data?.sendMessageToHubs || [];
      const nextHubs = [...hubs];

      for (const hubResponse of hubResponses) {
        const index = hubs.findIndex(h => h.hubId === hubResponse.hubId);

        const hubWithResponseState: IHubWithResponseState = {
          ...hubResponse.hub,
          hubResponse: {
            state: hubResponse?.response ? 'SUCCESS' : 'TIMEOUT',
            response: hubResponse?.response,
            responseTime: hubResponse?.responseTime,
          },
        };

        if (index !== -1) {
          nextHubs[index] = { ...nextHubs[index], ...hubWithResponseState };
        } else {
          nextHubs.push(hubWithResponseState as THub);
        }
      }

      if (hubResponses.length) {
        setHubs(nextHubs);
      }
    },
    onSubscriptionComplete: () => {
      setSubscriptionState({ input: null, waiting: false, completed: true });
      timer.stopTimer();
    },
  });

  useEffect(() => {
    if (error) {
      const nextHubs = hubs.map((hub): IHubWithResponseState<THub> => {
        if (!hub.hubResponse?.response) {
          return { ...hub, hubResponse: { state: 'ERROR' } };
        }

        return hub;
      });

      displayErrorMessage(error);
      setHubs(nextHubs);
      setSubscriptionState(getDefaultSubscriptionState());
      timer.stopTimer();
    }
  }, [error]);

  const { waiting, completed } = subscriptionState;

  const totalSuccesses = hubs.filter(h => h.hubResponse?.state === 'SUCCESS').length;
  const totalErrors = hubs.filter(h => h.hubResponse?.state === 'ERROR').length;
  const totalTimeouts = hubs.filter(h => h.hubResponse?.state === 'TIMEOUT').length;
  const totalFailures = totalErrors + totalTimeouts;

  // Account for additional latency by not showing progress as 100% if still waiting for responses
  const progress = timer.progress === 100 && waiting
    ? 90
    : timer.progress;

  return {
    loading,
    error,
    progress,
    waiting,
    completed,
    totalSuccesses,
    totalErrors,
    totalTimeouts,
    totalFailures,
    hubs,
    sendMessageToHubs(nextInput: SendMessageToHubsAsyncInput, waitingHubs?: THub[]) {
      // If it's already known which hubs will be messaged, we can track the waiting state
      if (waitingHubs?.length) {
        const hubsWithWaitingState = waitingHubs.map((hub): IHubWithResponseState<THub> => ({
          ...hub,
          hubResponse: hub.activationStatus && hub.activationStatus !== HubActivationStatus.ACTIVATED
            ? undefined
            : { state: 'WAITING' },
        }));

        setHubs(hubsWithWaitingState);
      }

      setSubscriptionState({
        input: {
          ...nextInput,
          hubMessage: {
            ...nextInput.hubMessage,
            messageGroupId: nextInput.hubMessage.messageGroupId || uuid(),
          },
        },
        waiting: true,
        completed: false,
      });

       // Default to 5 seconds (like the server)
      const timeoutMs = nextInput.responseTimeout || 5000;

      timer.startTimer({ timeoutMs });
    },
    reset: () => {
      const hubsWithResetState = hubs.map((hub): IHubWithResponseState<THub> => ({
        ...hub,
        hubResponse: undefined,
      }));

      setHubs(hubsWithResetState);
      setSubscriptionState(getDefaultSubscriptionState());
      timer.reset();
    },
  };
}

export default useHubMessenger;
