import { notification } from 'antd';
import React from 'react';

import {
  PropertyMapTableFragment,
  updatePropertyMapByPkDocument,
  updatePropertyMapByPkMutation,
  updatePropertyMapByPkMutationVariables,
} from '../../graphql/hasura/generated';

import ViewPropertyMapAction from './components/ViewPropertyMapAction';
import model from './model';

export const viewMapAction = model.createAction<PropertyMapTableFragment>({
  description: 'View Engrain map',
  enabledByModel: () => true,
  render(propertyMap) {
    return (
      <ViewPropertyMapAction
        property={propertyMap.property}
        externalMapId={propertyMap.externalMapId}
        externalFloors={propertyMap.externalFloors || []}
        editMode={true}
      />
    );
  },
});

export const togglePublishedAction = model.createAction({
  label: ({ published }) => published ? 'Unpublish' : 'Publish',
  description: 'Publishes or unpublishes the map on the web app',
  enabledByModel: () => true,
  confirmation: {
    title: ({ published }) => published ? 'Unpublish Map' : 'Publish Map',
    content: ({ published }) => (
      `Are you sure you want to ${published ? 'unpublish' : 'publish'} this map?`
    ),
  },
  async executes({ propertyMapId, published }, { hasuraClient }) {
    const { data } = await hasuraClient.mutate<
      updatePropertyMapByPkMutation,
      updatePropertyMapByPkMutationVariables
    >({
      mutation: updatePropertyMapByPkDocument,
      variables: {
        propertyMapId,
        _set: {
          published: !published,
        },
      },
    });

    if (!data?.propertyMap) {
      throw new Error(`Unable to ${published ? 'unpublish' : 'publish'} property map`);
    }

    notification.success({
      message: 'Success',
      description: `The property map was successfully ${published ? 'unpublished' : 'published'}!`,
    });
  },
});
