import { Button, Modal, Spin, Typography } from 'antd';
import React, { useState } from 'react';

import { REALPAGE_EULA_URL } from '../../config';
import { acceptEula } from '../../graphql/api/operations';
import { authentication } from '../../stores';
import { displayErrorMessage } from '../../utils';

const EulaModal: React.FC = () => {
  const [visible, setVisible] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(true);

  const acceptButton = (
    <Button
      type='primary'
      key='accept'
      disabled={disabled}
      onClick={async() => {
        try {
          setDisabled(true);
          await acceptEula();
          await authentication.refetchUserData();
        } catch (error) {
          displayErrorMessage(error);
          setDisabled(false);
        }
      }}
    >
      Accept
    </Button>
  );

  const declineButton = (
    <Button key='decline' onClick={async () => {
      await authentication.logout();
      setVisible(false);
    }}>
      Decline
    </Button>
  );

  return (
    <>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        keyboard={false}
        closable={false}
        maskClosable={false}
        centered
        footer={[declineButton, acceptButton]}
        destroyOnClose={true}
      >
        <div>
          <Typography.Title level={2}>End User License Agreement</Typography.Title>
          <Typography.Paragraph>
            Please scroll through and read this agreement carefully.
          </Typography.Paragraph>
          <div className='iframe-wrapper' style={{ marginBottom: 10 }}>
            {loading && <Spin />}
            <iframe
              src={REALPAGE_EULA_URL}
              title='RealPage Smart Building End User License Agreement'
              width='100%'
              height='500px'
              style={{ border: '3px solid #f0f0f0', background: '#fff' }}
              onLoad={() => {
                setLoading(false);
                window.onmessage = (event: MessageEvent) => {
                  if (event.data === 'bottom_scroll') {
                    setDisabled(false);
                  }
                }
              }}
            />
          </div>
        </div>
        <Typography.Paragraph>
          By accepting, you agree to the RealPage Smart Building EULA and <a href='https://www.realpage.com/privacy-policy/'
           target='_blank'rel='nofollow noopener noreferrer'>
            Privacy Policy
          </a>
        </Typography.Paragraph>
      </Modal>
    </>
  )
}

export default EulaModal;
