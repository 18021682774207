import { Form } from '@ant-design/compatible';
import { Button, notification } from 'antd';
import { Formik, FormikErrors } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import React from 'react';
import validator from 'validator';

import PhoneNumberField from '../../../components/PhoneNumberField';
import { apiClient } from '../../../graphql/api/apiClient';
import {
  updateUserDocument,
  updateUserMutation,
  updateUserMutationVariables,
  UserDetailsFragment as User,
} from '../../../graphql/hasura/generated';
import { hasuraClient } from '../../../graphql/hasura/hasuraClient';
import { FormItem, TextField } from '../../../pages/ModelForm/form-fields';
import { displayErrorMessage } from '../../../utils';
import { UserModel } from '../model';

type FormValues = User;

// @TODO: Centralize validation logic and format phone number.
const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {};

  // Both phone number and email cannot be cleared for activated accounts
  if (!values.phoneNumber && !values.email && (values.augustUserId || values.augustSubUserId)) {
    errors.phoneNumber = errors.email = 'Please enter either a phone number or an email';
  }

  if (values.phoneNumber && !(parsePhoneNumberFromString(values.phoneNumber || '', 'US')?.isValid())) {
    errors.phoneNumber = 'Please enter a valid phone number';
  }

  if (values.email && !validator.isEmail(values.email)) {
    errors.email = 'Please enter a valid email address';
  }

  return errors;
}

interface IEditUserFormProps {
  user: User;
  onFinish: () => any,
}

const EditUserForm = (props: IEditUserFormProps) => {
  const { user, onFinish } = props;

  const initialValues = { ...user };

  return (
    <Formik<FormValues>
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={async (values, form) => {
        try {
          form.setSubmitting(true);

          const { data } = await hasuraClient
            .mutate<updateUserMutation, updateUserMutationVariables>({
              mutation: updateUserDocument,
              variables: {
                userId: user.userId,
                _set: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phoneNumber: values.phoneNumber,
                  email: values.email,
                },
              },
            });

          if (!data?.custom_updateUser.user) {
            throw new Error('Failed to edit profile');
          }

          form.setSubmitting(false);

          notification.success({
            message: 'Success',
            description: 'The user was saved successfully!',
          });

          onFinish();

          await hasuraClient.resetStore();
          await apiClient.resetStore();
        } catch (error) {
          form.setSubmitting(false);
          displayErrorMessage(error as Error);
        }
      }}
      validate={(values) => validate(values)}
      render={(formikProps) => {
        const {
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
        } = formikProps;

        const handleCancelClick = () => {
          onFinish();
          resetForm();
        };

        const renderUserFormFields = () => {
          // Users editing their own profile can change first/last name
          const formFields = ['firstName', 'lastName'];

          // Permission is required for updating phone number and email
          if (UserModel.permissions.canUpdate()) {
            formFields.push(...['phoneNumber', 'email']);
          }

          return formFields.map(fieldName => {
            const field = UserModel.introspection.fields.find((f) => f.name === fieldName);
            const formOptions = UserModel.formOptions?.fields.find(f => f.fieldName === fieldName);

            if (!field) {
              return null;
            }

            if (fieldName === 'phoneNumber') {
              return (
                <FormItem {...formikProps} isNew={false} field={field} key={fieldName}>
                  <PhoneNumberField
                    value={values.phoneNumber}
                    onChange={(value) => setFieldValue(field.name, value)}
                    disabled={formikProps.isSubmitting}
                  />
                </FormItem>
              );
            }

            return (
              <TextField {...{
                field,
                setFieldValue,
                isSubmitting,
                values,
                errors,
                handleChange,
                isNew: false,
                key: fieldName,
                maxLength: formOptions?.maxLength,
              }} />
            );
          });
        }

        return (
          <Form style={{ marginTop: 8 }} onSubmit={handleSubmit}>
            {renderUserFormFields()}
            <section style={{ display: 'flex', justifyContent: 'center' }}>
              <Button type='primary' htmlType='submit' loading={isSubmitting} style={{ marginRight: 10 }}>
                {isSubmitting && ' ' || false}
                {isSubmitting ? 'Saving' : 'Save'}
              </Button>

              <Button
                loading={isSubmitting}
                onClick={handleCancelClick}
              >
                Cancel
              </Button>

            </section>
          </Form>
        )}
      }
    />
  )
}

export default EditUserForm;
