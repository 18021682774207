import '@ant-design/compatible/assets/index.css';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/client';
import 'antd/dist/antd.less';
import { Provider as MobxProvider } from 'mobx-react';
import React, { Component } from 'react';

import './App.css';
import AppError from './components/AppError';
import AppLoading from './components/AppLoading';
import { hasuraClient } from './graphql';
import introspectModels from './graphql/introspectSchema/introspectModels';
import { AdminPanelRouter } from './routes/router'
import { appData, authentication, ready as readyStore } from './stores';

// tslint:disable-next-line ordered-imports
import { ModelsContext } from './hooks/providers';
import { getModel, models } from './models/models';
import './models/setup';

class App extends Component {

  state = {
    ready: false,
    error: false,
  }

  async componentWillMount() {
    if (!readyStore.isReady) {
      try {
        await readyStore.promise;

        if (authentication.isAuthenticated) {
          await introspectModels();
        }
      } catch (error) {
        console.error(error);
        /* Ignore errors from initializing the store, we just want to wait for it */
        return this.setState({
          error,
          ready: false,
        });
      }

      this.setState({
        error: false,
        ready: true,
      });
    }
  }

  render() {
    const { ready, error } = this.state;

    if (error) {
      return <AppError />;
    }

    if (!ready) {
      return <AppLoading />;
    }

    return (
      <ModelsContext.Provider value={{ models, getModel }}>
        <ApolloHooksProvider client={hasuraClient}>
          <MobxProvider {...{ appData, authentication }}>
            <AdminPanelRouter />
          </MobxProvider>
        </ApolloHooksProvider>
      </ModelsContext.Provider>
    );
  }
}

export default App;
