import Dropdown, { DropDownProps } from 'antd/lib/dropdown';
import classNames from 'classnames';
import React from 'react';

import styles from './index.module.less';

export interface IHeaderDropdownProps extends DropDownProps {
  overlayClassName?: string;
}

const HeaderDropdown: React.FC<IHeaderDropdownProps> = (props) => {
  const { overlayClassName, ...otherProps } = props;

  return (
    <Dropdown
      overlayClassName={classNames(styles.container, overlayClassName)}
      {...otherProps}
    />
  );
};

export default HeaderDropdown;
