import { useEffect, useState } from 'react';

import { apiClient } from '../../../graphql/api/apiClient';
import { HubDeviceType, usesendMessageToHubsMutation } from '../../../graphql/api/generated';

import { useHubMessenger } from './useHubMessenger';

interface IUseHubRestartState {
  waiting: boolean;
  error: boolean;
  commandSent: boolean;
  delayTimeElapsed: number;
}

interface IUseHubRestartArgs {
  hubId: string;
  deviceType?: HubDeviceType;
}

export const useHubRestart = ({ hubId, deviceType }: IUseHubRestartArgs) => {
  // Edge readers and DMUs take longer than Raspberry Pis to restart
  const RESTART_WAIT_MS = deviceType === HubDeviceType.RASPBERRY_PI
    ? 30000 // 30 seconds
    : 60000; // 1 minute

  const [state, setState] = useState<IUseHubRestartState>({
    waiting: false,
    error: false,
    commandSent: false,
    delayTimeElapsed: 0,
  });

  const ping = useHubMessenger();

  useEffect(() => {
    if (ping.error || ping.totalTimeouts || !!ping.completed) {
      setState(prevState => ({ ...prevState, waiting: false }));
    }
  }, [ping.error, ping.totalTimeouts, ping.completed]);

  const onError = () => {
    setState(prevState => ({ ...prevState, waiting: false, error: true }));
  };

  const onCompleted = () => {
    setState(prevState => ({ ...prevState, commandSent: true }));

    const interval = setInterval(() => {
      setState(prevState => ({
        ...prevState, delayTimeElapsed: prevState.delayTimeElapsed + 1000,
      }));
    }, 1000);

    // Wait for hub to finish restarting and then attempt to ping it for confirmation
    setTimeout(() => {
      clearInterval(interval);

      ping.sendMessageToHubs({
        hubFilter: { hubIds: [hubId] },
        hubMessage: { type: 'PING' },
      }, [{ hubId }]);
    }, RESTART_WAIT_MS);
  };

  const [restartHubService] = usesendMessageToHubsMutation({
    onError,
    onCompleted,
    client: apiClient,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        hubIds: [hubId],
        type: 'RESTART_SERVICE',
        payload: { service: 'chirp-hub' },
      },
    },
  });

  const commandSentProgress = state.commandSent ? 100 : 0;
  const delayProgress = (state.delayTimeElapsed / RESTART_WAIT_MS) * 100;
  const pingProgress = ping.progress;

  const totalProgress = (state.error || ping.error || ping.completed)
    ? 100
    : (commandSentProgress * .10) + (delayProgress * .8) + (pingProgress * .10);

  let statusText: string | null = null;

  if (state.waiting) {
    if (!state.commandSent) {
      statusText = 'Sending restart command to hub...';
    } else if (delayProgress !== 100) {
      statusText = 'Command sent. Waiting for hub to restart...';
    } else if (pingProgress !== 100) {
      statusText = 'Confirming whether hub restarted successfully...';
    }
  }

  if (state.error) {
    statusText = 'Failed to restart hub';
  } else if (ping.error || ping.totalTimeouts) {
    statusText = 'Unable to confirm whether hub restarted successfully';
  } else if (ping.completed && !ping.totalTimeouts) {
    statusText = 'Hub successfully restarted!';
  }

  return {
    statusText,
    progress: totalProgress,
    waiting: state.waiting,
    error: state.error || ping.error || ping.totalTimeouts,
    restartHub: () => {
      ping.reset();

      setState({
        waiting: true,
        error: false,
        commandSent: false,
        delayTimeElapsed: 0,
      });

      restartHubService();
    },
  };
};

export default useHubRestart;
