import {
  getRoleDetailsDocument,
  getRoleDetailsQuery,
  getRoleDetailsQueryVariables,
  RoleDetailsFragment,
  usedeleteRolePermissionsMutation,
  useupsertRolePermissionsMutation,
} from '../../../graphql/hasura/generated';
import { displayErrorMessage } from '../../../utils';
import { ICategorizedPermission } from '../components/typings';

export function useToggleRolePermissions(role: RoleDetailsFragment) {
  const [upsertRolePermissions] = useupsertRolePermissionsMutation({
    update: (cache, { data }) => {
      const newRolePermissions = (data?.rolePermissions?.returning || []).filter(newRp => (
        !role.rolePermissions.some(oldRp => oldRp.permission.key === newRp.permission.key)
      ));

      const nextRolePermissions = [
        ...role.rolePermissions,
        ...newRolePermissions.map(rp => ({
          ...rp,
          rolePermissionId: rp.permission.key, // Temp ID
        })),
      ];

      cache.writeQuery<getRoleDetailsQuery, getRoleDetailsQueryVariables>({
        query: getRoleDetailsDocument,
        data: {
          role: {
            ...role,
            rolePermissions: nextRolePermissions,
            // @ts-ignore
            __typename: 'Role',
          },
        },
        variables: { roleId: role.roleId },
      });
    },
  });

  const [deleteRolePermissions] = usedeleteRolePermissionsMutation({
    update: (cache, { data }) => {
      const newRolePermissions = (data?.rolePermissions?.returning || []);

      const nextRolePermissions = role.rolePermissions.filter(oldRp => (
        !newRolePermissions.some(newRp => newRp.permission.key === oldRp.permission.key)
      ));

      cache.writeQuery<getRoleDetailsQuery, getRoleDetailsQueryVariables>({
        query: getRoleDetailsDocument,
        data: {
          role: {
            ...role,
            rolePermissions: [...nextRolePermissions],
            // @ts-ignore
            __typename: 'Role',
          },
        },
        variables: { roleId: role.roleId },
      });
    },
  });

  const toggleRolePermissions = async (permissions: ICategorizedPermission[], selected: boolean) => {
    const optimisticResponse = {
      rolePermissions: {
        returning: permissions.map((p) => ({
          rolePermissionId: p.key, // Temp ID
          permission: {
            permissionId: p.permissionId,
            key: p.key,
            scope: role.permissionScope,
            isLocked: role.isLocked,
          },
          // @ts-ignore
          __typename: 'RolePermission',
        })),
        // @ts-ignore
        __typename: 'RolePermission_mutation_response',
      },
    };

    try {
      if (selected) {
        await upsertRolePermissions({
          optimisticResponse,
          variables: {
            objects: permissions.map(p => ({
              roleId: role.roleId,
              permissionId: p.permissionId,
            })),
          },
        });
      } else {
        if (!role.roleId) {
          throw new Error('Missing role ID'); // Precaution
        }

        await deleteRolePermissions({
          optimisticResponse,
          variables: {
            where: {
              roleId: { _eq: role.roleId },
              permissionId: { _in: permissions.map(p => p.permissionId) },
            },
          },
        });
      }
    } catch (error) {
      displayErrorMessage(error);
    }
  }

  return toggleRolePermissions;
}

export default useToggleRolePermissions;
