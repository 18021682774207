import { Modal } from 'antd';
import React from 'react';

import ChangeDataScope from './ChangeDataScope';

interface IChangeDataScopeModalProps {
  visible: boolean;
  onCancel: () => any;
}

const ChangeDataScopeModal: React.FC<IChangeDataScopeModalProps> = ({ visible, onCancel }) => {
  return (
    <Modal
      title='Change Scope'
      visible={visible}
      maskClosable
      width='50%'
      onCancel={onCancel}
      footer={null}
    >
      <ChangeDataScope />
    </Modal>
  );
}

export default ChangeDataScopeModal;
