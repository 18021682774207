import { Spin } from 'antd';
import React from 'react';

import logo from '../../assets/chirp_logo_dark.png';

const AppLoading = () => (
  <div className='app loading'>
    <img alt='logo' src={logo} width={100} />
    <Spin className='spinner' />
  </div>
);

export default AppLoading;
