import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Modal, Table, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ResidentAuditFragment } from '../../graphql/api/generated';
import { UserModel } from '../../models/User/model';
import { Color, formatPhoneNumber } from '../../utils';

interface IComparisonRow {
  fieldName: string;
  pmsValue: string | null | undefined;
  chirpValue: string | null | undefined;
}

interface IResidentAuditComparisonProps {
  residentAudit: ResidentAuditFragment;
}

export const ResidentAuditComparison: React.FC<IResidentAuditComparisonProps> = (props) => {
  const { residentAudit } = props;
  const { residentSource, userRole } = residentAudit;
  const chirpUser = userRole?.assignedToUser;

  if (!chirpUser) {
    return null;
  }

  const rows: IComparisonRow[] = [
    {
      fieldName: 'First Name',
      pmsValue: residentSource.firstName,
      chirpValue: chirpUser.firstName,
    },
    {
      fieldName: 'Last Name',
      pmsValue: residentSource.lastName,
      chirpValue: chirpUser.lastName,
    },
    {
      fieldName: 'Phone Number',
      pmsValue: formatPhoneNumber(residentSource.phoneNumber),
      chirpValue: formatPhoneNumber(chirpUser.phoneNumber),
    },
    {
      fieldName: 'Email',
      pmsValue: residentSource.email,
      chirpValue: chirpUser.email,
    },
  ];

  const userDetailsLink = UserModel.routes.getRowLinkById(chirpUser.userId);

  return (
    <>
      {userDetailsLink && (
        <>
          <Typography.Paragraph>
            Most profile information is not re-synchronized from the PMS to Chirp after account creation.
            <br />
            If needed, this information can be manually edited on the&nbsp;
            <Link to={userDetailsLink}>user details page</Link>.
          </Typography.Paragraph>
        </>
      )}
      <Table
        columns={[
          {
            render: (_, { fieldName }) => {
              return (
                <span style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 500 }}>
                  {fieldName}
                </span>
              );
            },
          },
          {
            render: (_, { pmsValue, chirpValue }) => {
              const iconStyle = { fontSize: '18px' };

              return pmsValue === chirpValue
                ? <CheckOutlined style={{ color: Color.Green, ...iconStyle }} />
                : <CloseOutlined style={{ color: Color.Red, ...iconStyle }} />;
            },
          },
          {
            title: 'PMS',
            render: (_, { pmsValue }) => pmsValue,
          },
          {
            title: 'Chirp',
            render: (_, { pmsValue, chirpValue }) => {
              return pmsValue === chirpValue
                ? chirpValue
                : <span style={{ color: '#ff4d4f' }}>{chirpValue}</span>;
            },
          },
        ]}
        rowKey={r => r.fieldName}
        dataSource={rows}
        pagination={false}
      />
    </>
  );
}

export const useResidentAuditComparisonModal = (residentAudit: ResidentAuditFragment) => {
  const [visible, setVisible] = useState<boolean>(false);

  const modal = (
    <Modal
      title='PMS vs. Chirp'
      visible={visible}
      maskClosable
      width='60%'
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <ResidentAuditComparison residentAudit={residentAudit} />
    </Modal>
  );

  return { modal, openModal: () => setVisible(true) };
};
