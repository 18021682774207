import { debounce, DebounceSettings } from 'lodash';
import { useState } from 'react';

import { generateWordsFromSearch } from '../utils';

interface IUseDebouncedSearchArgs {
  wait?: number;
  debounceSettings?: DebounceSettings;
}

export function useDebouncedSearch(args?: IUseDebouncedSearchArgs) {
  const wait = typeof args?.wait === 'number' ? args.wait : 300;
  const [searchWords, setSearchWords] = useState<string[]>([]);

  const onSearch = (value: string | null) => {
    const nextSearchWords = generateWordsFromSearch(value);

    setSearchWords(nextSearchWords);
  };

  const onSearchDebounced = debounce((value: string | null) => {
    onSearch(value);
  }, wait, { ...args?.debounceSettings });

  return {
    searchWords,
    onSearch,
    onSearchDebounced,
  };
}
