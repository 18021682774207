import {
  AccessPoint_bool_exp,
  Organization_bool_exp,
  Unit_bool_exp,
  usegetAccessPointsWithTypeQuery,
  usegetOrganizationsQuery,
  usegetUnitsAggregateQuery,
} from '../../../../graphql/hasura/generated';

import { IScopeQueriesResult, IScopeQueryFilters } from './typings';

const fetchPolicy = 'cache-first';

export function useScopeQueries(filters: IScopeQueryFilters) {
  const organizationsQueryResult = usegetOrganizationsQuery({
    fetchPolicy,
    skip: !filters.organizationFilter,
    variables: {
      where: filters.organizationFilter as Organization_bool_exp,
    },
  });

  const unitsAggregateQueryResult = usegetUnitsAggregateQuery({
    fetchPolicy,
    skip: !filters.unitAggregateFilter,
    variables: {
      where: filters.unitAggregateFilter as Unit_bool_exp,
    },
  });

  const accessPointsQueryResult = usegetAccessPointsWithTypeQuery({
    fetchPolicy,
    skip: !filters.accessPointFilter,
    variables: {
      where: filters.accessPointFilter as AccessPoint_bool_exp,
    },
  });

  const scopeQueriesResult: IScopeQueriesResult = {
    organizations: organizationsQueryResult.data?.organizations,
    unitsAggregate: unitsAggregateQueryResult.data?.units || {},
    accessPoints: accessPointsQueryResult.data?.accessPoints || [],
  };

  return {
    ...scopeQueriesResult,
    loading: (
      organizationsQueryResult.loading ||
      unitsAggregateQueryResult.loading ||
      accessPointsQueryResult.loading
    ),
  };
}
