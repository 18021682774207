import {
  OrganizationPropertyTabFragment,
  OrganizationPropertyTabFragmentDoc,
  PropertyActionsFragmentDoc,
  PropertyTableFragment,
  PropertyTableFragmentDoc,
} from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';

import * as actions from './actions';
import * as columns from './columns';
import model from './model';

model.actions.setDefaultActions([
  actions.moreActions,
], PropertyActionsFragmentDoc);

const commonColumns = [
  columns.nameColumn,
  columns.imageColumn,
  columns.supportPhoneNumberColumn,
  columns.supportEmailColumn,
  columns.timezoneColumn,
  columns.propertyFeatureFlagsColumn,
  createdAtColumn,
];

export const PropertiesMainTable = model.createTable<PropertyTableFragment>({
  fragment: PropertyTableFragmentDoc,
  columns: [
    columns.organizationColumn,
    ...commonColumns,
  ],
  fetchPolicy: 'cache-first',
});

export const PropertiesByOrganizationTable = model.createTable<OrganizationPropertyTabFragment, string>({
  fragment: OrganizationPropertyTabFragmentDoc,
  fixedQueryFilters: (organizationId) => ({
    organizationId: { _eq: organizationId },
  }),
  columns: [
    ...commonColumns,
  ],
  fetchPolicy: 'cache-first',
});
