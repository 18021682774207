import { enumManagers } from '@chirp/enums';
import { Button, Card, Progress, Row, Table, Typography } from 'antd';
import { orderBy } from 'lodash';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { CsvExportButton } from '../../../components/CsvExport/CsvExportButton';
import ModelBreadcrumbs from '../../../components/ModelBreadcrumbs';
import { apiClient } from '../../../graphql/api/apiClient';
import {
  HubActivationStatus,
  HubTableFragment as Hub,
  usepropertyWithHubsQuery,
} from '../../../graphql/api/generated';
import BasicLayout from '../../../layouts/BasicLayout';
import ErrorLayout from '../../../layouts/ErrorLayout';
import { createActionsColumn } from '../../common/columns';
import { normalizeColumns } from '../../helpers/normalizeColumns';
import * as actions from '../../Hub/actions';
import * as hubColumns from '../../Hub/columns';
import { IHubWithResponseState, useHubMessenger } from '../../Hub/hooks/useHubMessenger';
import { HubModel } from '../../Hub/model';
import { IModelColumn } from '../../typings';
import { PropertyModel } from '../model';

type HubRow = IHubWithResponseState<Hub>;

const PropertyHubMonitoringPage: React.FC<RouteComponentProps<{ propertyId: string }>> = (props) => {
  const { propertyId } = props.match.params;

  const ping = useHubMessenger<Hub>();

  const pingHubs = (hubs: HubRow[]) => {
    const hubIds = hubs
      .filter(h => h.activationStatus === HubActivationStatus.ACTIVATED)
      .map(h => h.hubId);

    if (hubIds.length) {
      ping.sendMessageToHubs({
        hubFilter: { hubIds },
        hubMessage: { type: 'PING' },
      }, hubs);
    }
  }

  const { data, loading: queryLoading, error: queryError } = usepropertyWithHubsQuery({
    client: apiClient,
    fetchPolicy: 'network-only',
    variables: { propertyId },
    onCompleted: (queryData) => pingHubs(queryData.property.hubs),
  });

  const property = data?.property;
  const pageTitle = 'Hub Monitoring';

  if (queryError) {
    return <ErrorLayout pageTitle={pageTitle} error={queryError} />;
  }

  const columns: IModelColumn<HubRow>[] = [
    hubColumns.nameColumn,
    hubColumns.deviceTypeColumn,
    hubColumns.portColumn,
    hubColumns.ipAddressColumn,
    hubColumns.lastDisconnectionColumn,
    hubColumns.pingTestColumn,
  ];

  const loading = queryLoading || ping.loading;
  const waiting = ping.waiting;

  const sortedHubs = orderBy(ping.hubs, [
    h => enumManagers.HubActivationStatus.getLabel(h.activationStatus),
    h => enumManagers.HubDeviceType.getValueIndex(h.deviceType),
    h => h.name,
  ], ['asc', 'asc', 'asc']);

  const pingableHubs = sortedHubs.filter(h => !!h.hubResponse?.state);
  const badPingState = !!ping.error || !!ping.totalErrors || !!ping.totalTimeouts;

  return (
    <BasicLayout pageTitle={pageTitle}>
      <Card
        title={(
          <ModelBreadcrumbs
            model={PropertyModel}
            rowBreadCrumb={{ row: property }}
            customBreadCrumbs={[pageTitle]}
          />
        )}
        bordered={false}
        style={{ marginBottom: '20px' }}
        loading={!property}
        extra={(
          <>
            <Button
              onClick={() => pingHubs(sortedHubs)}
              disabled={loading || waiting || !sortedHubs.length}
              loading={loading || waiting}
              style={{ marginRight: 8 }}
            >
              Refresh
            </Button>
            <CsvExportButton
              title={`${pageTitle} - ${property?.name}`}
              rows={sortedHubs}
              columns={columns}
              disabled={loading || waiting}
            />
          </>
        )}
      >
        {!!sortedHubs.length && (
          <Row style={{ marginBottom: '20px' }}>
            <Progress
              percent={ping.progress}
              status={badPingState ? 'exception' : undefined}
              showInfo={badPingState || ping.completed}
            />
            <Typography.Text type='secondary'>
              {ping.totalSuccesses} out of {pingableHubs.length} hubs responded
            </Typography.Text>
          </Row>
        )}
        <Table
          loading={{ size: 'large', spinning: queryLoading }}
          rowKey={h => h.hubId}
          dataSource={sortedHubs}
          scroll={{ x: true }}
          columns={[
            ...normalizeColumns(columns),
            createActionsColumn({
              actions: [
                HubModel.actions.detailsAction,
                actions.healthAction,
                actions.messengerAction,
              ],
            }),
          ]}
          showSorterTooltip={false}
          pagination={false}
        />
      </Card>
    </BasicLayout>
  );
}

export default PropertyHubMonitoringPage;
