import React from 'react';

import { TableCellDescriptions } from '../../components/TableCellDescriptions';
import {
  SessionRequiredRowFragment,
  SessionRequiredRowFragmentDoc,
  SessionTableFragment,
  SessionTableFragmentDoc,
  UserSessionFragment,
  UserSessionFragmentDoc,
} from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';
import { IModelTableExpandable } from '../typings';

import * as actions from './actions';
import * as columns from './columns';
import model from './model';

model.actions.setDefaultActions([
  actions.revokeAction,
], SessionRequiredRowFragmentDoc);

export const expandable: IModelTableExpandable<SessionRequiredRowFragment> = {
  rowExpandable: ({ deviceFingerprint }) => !!deviceFingerprint,
  expandedRowRender: ({ deviceFingerprint }) => {
    return (
      <TableCellDescriptions
        descriptions={{ 'Device Fingerprint': deviceFingerprint }}
      />
    );
  },
};

const commonColumns = [
  columns.typeColumn,
  columns.authMethodColumn,
  columns.deviceInfoColumn,
  columns.expirationDateColumn,
  columns.renewedAtColumn,
];

export const SessionsMainTable = model.createTable<SessionTableFragment>({
  expandable,
  fragment: SessionTableFragmentDoc,
  columns: [
    columns.idColumn,
    columns.userColumn,
    ...commonColumns,
    columns.revokedAtColumn,
    columns.revokedByUserColumn,
    createdAtColumn,
  ],
});

export const SessionsByUserTable = model.createTable<UserSessionFragment, string>({
  expandable,
  fragment: UserSessionFragmentDoc,
  fixedQueryFilters: (userId) => ({
    userId: { _eq: userId },
    revokedAt: { _is_null: true },
  }),
  columns: [
    ...commonColumns,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});
