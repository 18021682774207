import { Form } from '@ant-design/compatible';
import { ContainerOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React from 'react';

import {
  AccessPointRowFragment,
  PermissionKey_enum,
  usetoggleAccessPointArchivedMutation,
} from '../../../graphql/hasura/generated';
import { FORM_ITEM_DEFAULT_LAYOUT } from '../../../pages/ModelForm/form-fields';
import { authentication } from '../../../stores';
import { displayErrorMessage, formatTimestamp } from '../../../utils';
import UserModel from '../../User/model';

interface IAccessPointArchiveSectionProps {
  accessPoint: AccessPointRowFragment;
}

const AccessPointArchiveSection: React.FC<IAccessPointArchiveSectionProps> = ({ accessPoint }) => {
  const { accessPointId, archivedAt, archivedByUser } = accessPoint;
  const disabled = !authentication.hasPermission(PermissionKey_enum.AccessPoint_Archive);

  const [toggleAccessPointArchived, { loading }] = usetoggleAccessPointArchivedMutation({
    variables: {
      accessPointId,
      archived: !archivedAt,
    },
  });

  return (
    <>
      {archivedAt && (
        <Form.Item
          {...FORM_ITEM_DEFAULT_LAYOUT}
          label='Archived At'
        >
          {formatTimestamp(archivedAt)}
        </Form.Item>
      )}
      {archivedByUser && (
        <Form.Item
          {...FORM_ITEM_DEFAULT_LAYOUT}
          label='Archived By'
        >
          {UserModel.routes.renderRowLink(archivedByUser)}
        </Form.Item>
      )}
      {!disabled && (
        <Form.Item
          {...FORM_ITEM_DEFAULT_LAYOUT}
          label={<div />} // Workaround to align the table within the layout
          colon={false}
        >
          <Button
            icon={<ContainerOutlined />}
            loading={loading}
            disabled={disabled || loading}
            onClick={async () => {
              Modal.confirm({
                title: archivedAt ? 'Unarchive Access Point' : 'Archive Access Point',
                content: archivedAt
                  ? 'Are you sure you want to unarchive this Access Point?'
                  : 'Are you sure you want to archive this Access Point?',
                okType: archivedAt ? 'default' : 'danger',
                centered: true,

                async onOk() {
                  try {
                    await toggleAccessPointArchived();
                  } catch (error) {
                    displayErrorMessage(error);
                  }
                },
              });
            }}
          >
            {archivedAt ? 'Unarchive' : 'Archive'}
          </Button>
        </Form.Item>
      )}
    </>
  );
};

export default AccessPointArchiveSection;
