import React from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import RefreshButton from '../../../components/RefreshButton';
import TableActions from '../../../components/TableActions/TableActions';
import TabsManager from '../../../components/TabsManager';
import { apiClient } from '../../../graphql';
import { useauditResidentsByUnitQuery } from '../../../graphql/api/generated';
import { usegetUnitWithPropertyQuery } from '../../../graphql/hasura/generated';
import useTabTable from '../../../hooks/useTabTable';
import BasicLayout from '../../../layouts/BasicLayout';
import ResidentAuditPage from '../../../pages/ResidentAudit';
import { columns } from '../../../pages/ResidentAudit/columns';
import { displayErrorMessage, RefetchContext } from '../../../utils';
import { UnitResidentAuditTable } from '../../UserRole/tables';
import { createKeyAction, syncResidentsAction } from '../actions';
import UnitModel from '../model';

const filteredColumns = columns.filter(c => c.title !== 'Unit');

const UnitResidentAuditPage: React.FC<RouteComponentProps<{ unitId: string }>> = (props) => {
  const { unitId } = props.match.params;
  const history = useHistory();

  const { data: unitData } = usegetUnitWithPropertyQuery({ variables: { unitId } });
  const unit = unitData?.unit;

  const { data: residentAuditsData, loading, refetch } = useauditResidentsByUnitQuery({
    client: apiClient,
    variables: { unitId },
    skip: !unitId,
    notifyOnNetworkStatusChange: true,
    onError: error => displayErrorMessage(error),
  });

  const { tab } = useTabTable(UnitResidentAuditTable, unitId);

  const residentAudits = residentAuditsData?.auditResidentsByUnit || [];

  return (
    <BasicLayout pageTitle='Resident Audit'>
      <ResidentAuditPage
        cardTitle={
          unit ?
            <span>
              {UnitModel.routes.renderUniqueRowLink(unit)} ({unit.sourceId || 'Missing Source ID'})
            </span>
            : '...'
        }
        cardExtra={unit && (
          <>
            <RefetchContext.Provider value={refetch}>
              <TableActions
                actions={[syncResidentsAction, createKeyAction]}
                row={unit}
              />
            </RefetchContext.Provider>
            <RefreshButton
              disabled={loading}
              refresh={refetch}
              useSubscription={false}
              onError={false}
              buttonStyle={{ marginTop: '10px', marginRight: 0, float: 'right' }}
            />
          </>
        )}
        location={history.location}
        columns={filteredColumns}
        loading={loading}
        residentAudits={residentAudits}
        filteredResidentAudits={residentAudits}
        pagination={false}
      >
        <TabsManager tabs={[tab]} />
      </ResidentAuditPage>
    </BasicLayout>
  );
};

export default UnitResidentAuditPage;
