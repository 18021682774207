import AppData from './app-data';
import Authentication from './authentication';

export const authentication = new Authentication();
export const appData = new AppData();

export const ready: {
  isReady: boolean;
  promise: Promise<boolean>;
} = {
  isReady: false,
  promise: Promise.all([authentication.ready(), appData.ready()]).then(
    () => (ready.isReady = true)
  )
};

if (process.env.NODE_ENV !== 'production') {
  // @ts-ignore
  window.stores = { authentication, appData };
}
