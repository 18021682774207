import React from 'react';

import { apiClient } from '../../graphql/api/apiClient';
import { UserActivityInterval, useuserActivityQuery } from '../../graphql/api/generated';
import { displayErrorMessage } from '../../utils';

import UserActivityChart from './UserActivityChart';

interface IUserActivityDayIntervalProps {
  startDate: string;
  endDate: string;
  propertyId: string;
  roleIds: string[];
}

const UserActivityDayInterval = React.memo((props: IUserActivityDayIntervalProps) => {
  const { startDate, endDate, propertyId, roleIds } = props;

  const { data, loading } = useuserActivityQuery({
    client: apiClient,
    fetchPolicy: 'cache-first',
    skip: !propertyId,
    variables: {
      input: {
        startDate,
        endDate,
        roleIds,
        propertyId: props.propertyId as string,
        interval: UserActivityInterval.DAY,
      },
    },
    onError: error => displayErrorMessage(error),
  });

  const userActivity = data?.userActivity || [];

  return (
    <UserActivityChart
      startDate={props.startDate}
      endDate={props.endDate}
      loading={loading}
      userActivity={userActivity}
    />
  );
});

export default UserActivityDayInterval;
