import {
  HubTableFragment,
  HubTableFragmentDoc,
  HubUniqueLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';

import * as actions from './actions';
import * as columns from './columns';
import model from './model';

// @TODO: Make this easier to configure
const tableCustomActions = [
  actions.monitoringAction,
  actions.healthAction,
  actions.messengerAction,
];

model.actions.setDefaultActions([
  ...tableCustomActions,
  actions.unlockRelaysAction,
], HubUniqueLabelFragmentDoc);

const commonColumns = [
  columns.nameColumn,
  columns.deviceTypeColumn,
  columns.activationStatusColumn,
  columns.portColumn,
  columns.macAddressColumn,
  columns.ipAddressColumn,
  createdAtColumn,
];

export const HubsMainTable = model.createTable<HubTableFragment>({
  fragment: HubTableFragmentDoc,
  columns: [
    columns.propertyColumn,
    ...commonColumns,
  ],
  customActions: [...tableCustomActions],
});

export const HubsByPropertyTable = model.createTable<HubTableFragment, string>({
  fragment: HubTableFragmentDoc,
  fixedQueryFilters: (propertyId) => ({
    propertyId: { _eq: propertyId },
  }),
  columns: [
    ...commonColumns,
  ],
  customActions: [...tableCustomActions],
});
