import { camelCase } from 'lodash';

import { IModel } from '../../models/typings';
import { __TypeKind } from '../hasura/generated';

import getFieldTypes from './get-field-types';
import introspectionQuery from './introspect';

export async function introspectModels() {
  const schema = await introspectionQuery();

  const mutationRoot = schema.types.find(
    type => type.kind === __TypeKind.OBJECT && type.name === 'mutation_root'
  );

  const queryRoot = schema.types.find(
    type => type.kind === __TypeKind.OBJECT && type.name === 'query_root'
  );

  const objectTypes = schema.types.filter(
    type => type.kind === __TypeKind.OBJECT && !type.name?.match(/_/)
  );

  for (const objectType of objectTypes) {
    let model: IModel | undefined;

    try {
      // We're using require instead of import to avoid circular dependencies
      // with the rest of the app
      model = require(`../../models/${objectType.name}/model`).default;
    } catch {
      // Fail silently
    }

    if (!model) {
      continue;
    }

    // When changing roles, we need to reset the fields and relationships
    model.introspection.fields = [];
    model.introspection.relationships = [];

    const modelName = model.names.schemaName;
    const queryFields = queryRoot?.fields ?? [];
    const mutationFields = mutationRoot?.fields ?? [];

    const insertType = mutationFields.some(t => t.name === `insert_${modelName}`);
    const queryType = queryFields.some(t => t.name === modelName);
    const updateType = mutationFields.some(t => t.name === `update_${modelName}`);
    const deleteType = mutationFields.some(t => t.name === `delete_${modelName}`);

    model.introspection.canCreate = !!insertType;
    model.introspection.canRead = !!queryType;
    model.introspection.canUpdate = !!updateType;
    model.introspection.canDelete = !!deleteType;

    // Types that will help us determine which fields appears in each view
    const insertInputType = schema.types.find(type => type.name === `${modelName}_insert_input`);
    const updateInputType = schema.types.find(type => type.name === `${modelName}_set_input`);

    const fields = objectType.fields || [];

    for (const field of fields) {
      const fieldName = field.name;

      if (
        // Omit fields like "deprecatedRelayId"
        fieldName.indexOf('deprecated') === 0 &&
        fieldName !== 'deprecatedType' // We want this field available to edit in the Role form
      ) {
        continue;
      }

      const fieldTypes = getFieldTypes(field);
      const fieldKinds = fieldTypes.map(f => f.kind);
      const scalarName = fieldTypes.find(f => f.kind === __TypeKind.SCALAR);

      model.introspection.fields.push({
        name: fieldName,
        kinds: fieldKinds,
        // @TODO: Why are we converting to lowercase?
        scalarName: (scalarName?.name || '').toLowerCase(),
        required: fieldKinds.includes(__TypeKind.NON_NULL),
        canRead: true,
        canCreate: (insertInputType?.inputFields ?? []).some(f => f.name === fieldName) || false,
        canUpdate: (updateInputType?.inputFields ?? []).some(f => f.name === fieldName) || false,
      });

      const objectFieldType = fieldTypes.find(ft => ft.kind === __TypeKind.OBJECT);

      // Check for relationships
      if (objectFieldType && !fieldName.match('aggregate')) {
        const targetModelName = objectFieldType.name;

        if (!targetModelName) {
          continue;
        }

        const type = fieldTypes.some(ft => ft.kind === __TypeKind.LIST) ? 'ARRAY' : 'OBJECT';

        const sourceField = type === 'ARRAY'
          ? model.primaryKey
          : getSourceField(fieldName, fields.map(f => f.name));

        model.introspection.relationships.push({
          type,
          targetModelName,
          sourceField,
          sourceModelName: model.names.schemaName,
          relationshipName: fieldName,
        });
      }
    }
  }
}

function getSourceField(fieldName: string, fieldNames: string[]): string | null {
  // Example: "user"
  let sourceField = `${fieldName}Id`; // "userId"

  // Example: statusEnum
  if (fieldName.endsWith('Enum')) {
    sourceField = fieldName.replace('Enum', '');
  }

  // Example: "userBySolvedByUserId"
  if (fieldName.endsWith('Id')) {
    // Split: user | By | Solved | By | UserId
    const [firstOccurrence, ...otherOccurrences] = fieldName.split('By');
    // Joined: "SolvedByUserId"
    const lastOccurrence = otherOccurrences.join('By');

    if (firstOccurrence && lastOccurrence) {
      // Final: "solvedByUserId"
      sourceField = camelCase(lastOccurrence);
    }
  }

  return fieldNames.includes(sourceField) ? sourceField : null;
}

export default introspectModels;
