import { GrantableRoleFragment, RoleKey_enum } from '../../../graphql/hasura/generated';

export function getRoleRequirements(role?: GrantableRoleFragment | null) {
  const reasonToGrantRequired = (
    role?.key === RoleKey_enum.INTEGRATOR_GATEWAY ||
    role?.key === RoleKey_enum.STAFF_PROPERTY_KEY ||
    role?.key === RoleKey_enum.STAFF_UNIT_KEY ||
    role?.key === RoleKey_enum.GUEST_PROPERTY_KEY ||
    role?.key === RoleKey_enum.GUEST_UNIT_KEY
  );

  const expirationDateRequired = (
    role?.key === RoleKey_enum.STAFF_PROPERTY_KEY ||
    role?.key === RoleKey_enum.STAFF_UNIT_KEY ||
    role?.key === RoleKey_enum.GUEST_PROPERTY_KEY ||
    role?.key === RoleKey_enum.GUEST_UNIT_KEY
  );

  return {
    reasonToGrantRequired,
    expirationDateRequired: expirationDateRequired || !!role?.maxAccessDurationSeconds,
    maxAccessDurationSeconds: role?.maxAccessDurationSeconds || null,
  };
}
