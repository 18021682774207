import type { IDescriptions } from '../../components/TableCellDescriptions';
import type { IChirpTableColumn } from '../typings';

// Use columns to get descriptions that can be passed to TableCellDescriptions component
export function getDescriptionsFromColumns<TRowData = any>(
  row: TRowData,
  columns: IChirpTableColumn<TRowData>[],
) {
  const descriptions: IDescriptions = {};

  for (const column of columns) {
    const title = column.titleString || column.title;

    if (typeof title === 'string') {
      descriptions[title] = column.render(row);
    }
  }

  return descriptions;
}
