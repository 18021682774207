import { Form } from '@ant-design/compatible';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';

import { DatePickerWithFormItem } from '../../components/DatePicker';
import { ModelSelect } from '../../components/ModelSelect';
import { PermissionScope_enum, Role_bool_exp } from '../../graphql/hasura/generated';
import { ModelsContext } from '../../hooks/providers';

import { useFiltersContext } from './hooks';

const UserMetricsFilters = React.memo(() => {
  const { getModel } = useContext(ModelsContext);
  const PropertyModel = getModel('Property');
  const RoleModel = getModel('Role');

  const {
    datePickerUnit, startDate, setSelectedStartDate, propertyId,
    setPropertyId, roleIds, setRoleIds,
  } = useFiltersContext();

  return (
    <Row gutter={48}>
      <Col span={6}>
        <DatePickerWithFormItem
          unit={datePickerUnit}
          startDate={startDate}
          setSelectedStartDate={setSelectedStartDate}
          required
        />
      </Col>
      <Col span={6}>
        <Form.Item label='Property' style={{ marginBottom: 0 }} className='label-align-top' required>
          <ModelSelect
            model={PropertyModel}
            value={propertyId}
            onChange={(value) => {
              setRoleIds([]);
              setPropertyId(value);
            }}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label='Role' style={{ marginBottom: 0 }} className='label-align-top'>
          <ModelSelect
            model={RoleModel}
            // @TODO: Support multiple
            value={roleIds[0]}
            onChange={(value) => {
              if (value) {
                setRoleIds([value]);
              } else {
                setRoleIds([]);
              }
            }}
            queryFilters={{
              permissionScope: {
                _neq: PermissionScope_enum.GLOBAL,
              },
              _or: [
                {
                  organizationId: { _is_null: true },
                },
                {
                  organization: {
                    properties: {
                      propertyId: { _eq: propertyId },
                    },
                  },
                },
              ]
            } as Role_bool_exp}
            style={{ width: '100%' }}
            disabled={!propertyId}
          />
        </Form.Item>
      </Col>
    </Row>
  );
});

export default UserMetricsFilters;
