import { useDebounceCallback } from '@react-hook/debounce';
import { Alert, Card, Input, Table } from 'antd';
import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { CsvExportButton } from '../../../components/CsvExport/CsvExportButton';
import ModelBreadcrumbs from '../../../components/ModelBreadcrumbs';
import RefreshButton from '../../../components/RefreshButton';
import TabsManager, { ITab } from '../../../components/TabsManager';
import { apiClient } from '../../../graphql/api/apiClient';
import {
  usevirtualDirectoryAuditQuery,
  VirtualDirectoryUser,
} from '../../../graphql/api/generated';
import BasicLayout from '../../../layouts/BasicLayout';
import ErrorLayout from '../../../layouts/ErrorLayout';
import normalizeColumns from '../../helpers/normalizeColumns';
import { IChirpTableColumn } from '../../typings';
import { UnitModel } from '../../Unit/model';
import { UserModel } from '../../User/model';
import { PropertyModel } from '../model';

const VirtualDirectoryPreviewPage: React.FC<RouteComponentProps<{ propertyId: string }>> = (props) => {
  const { propertyId } = props.match.params;
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSetSearch = useDebounceCallback(setSearch, 300);
  const [currentTab, setCurrentTab] = useState<'LISTED' | 'UNLISTED'>('LISTED')

  const { previousData, data, error, loading, refetch } = usevirtualDirectoryAuditQuery({
    client: apiClient,
    variables: {
      input: { propertyId, search: search || null },
    },
  });

  const virtualDirectoryAudit = data?.virtualDirectoryAudit || previousData?.virtualDirectoryAudit;
  const property = virtualDirectoryAudit?.property;
  const unitsDisplayed = virtualDirectoryAudit?.unitsDisplayed;
  const listedUsers = virtualDirectoryAudit?.listedUsers || [];
  const unlistedUsers = virtualDirectoryAudit?.unlistedUsers || [];
  const totalListedUsers = virtualDirectoryAudit?.totalListedUsers || 0;
  const totalUnlistedUsers = virtualDirectoryAudit?.totalUnlistedUsers || 0;

  const pageTitle = 'Virtual Directory Preview';

  if (error) {
    return <ErrorLayout pageTitle={pageTitle} error={error} />;
  }

  const sharedColumns: IChirpTableColumn<VirtualDirectoryUser>[] = [
    {
      title: 'Resident',
      render: ({ userId, userDisplayName }) => {
        const userLink = UserModel.routes.getRowLinkById(userId);

        return userLink
          ? <Link to={userLink}>{userDisplayName}</Link>
          : userDisplayName;
      },
    },
  ];

  if (unitsDisplayed || currentTab === 'UNLISTED') {
    sharedColumns.push({
      title: 'Unit',
      render: ({ unitId, unitDisplayName }) => {
        if (!unitId || !unitDisplayName) {
          return null;
        };

        const unitLink = UnitModel.routes.getRowLinkById(unitId);

        return unitLink
          ? <Link to={unitLink}>{unitDisplayName}</Link>
          : unitDisplayName;
      },
    });
  }

  const listedUserColumns = sharedColumns;
  const unlistedUserColumns: IChirpTableColumn<VirtualDirectoryUser>[] = [
    ...sharedColumns,
    {
      title: 'Unlisted Reasons',
      render: ({ unlistedReasons }) => {
        return (
          <>
            {unlistedReasons.map((reason, index) => (
              <Alert
                key={reason}
                showIcon
                type='warning'
                message={reason}
                style={{ marginBottom: index !== unlistedReasons.length + 1 ? 10 : 0 }}
              />
            ))}
          </>
        );
      },
      renderString: ({ unlistedReasons }) => unlistedReasons.join(', '),
    },
  ];

  const tabs: ITab[] = [
    {
      loading: false, // Prevent losing focus from search
      key: 'LISTED',
      title: `Listed Residents (${totalListedUsers})`,
      empty: false,
      onTouchedTab: () => setCurrentTab('LISTED'),
      content: () => (
        <>
          <Input.Search
            placeholder='Search...'
            onChange={e => debouncedSetSearch(e.target.value)}
            onSearch={value => debouncedSetSearch(value)}
            allowClear
            style={{ marginBottom: 25 }}
          />
          <Table
            loading={{ size: 'large', spinning: loading }}
            rowKey={u => u.userId + u.unitId}
            dataSource={listedUsers}
            scroll={{ x: true }}
            columns={normalizeColumns(listedUserColumns)}
            showSorterTooltip={false}
            pagination={false}
          />
        </>
      ),
    },
    {
      loading: false,
      key: 'UNLISTED',
      title: `Unlisted Residents (${totalUnlistedUsers})`,
      empty: !totalUnlistedUsers,
      onTouchedTab: () => setCurrentTab('UNLISTED'),
      content: () => (
        <Table
          loading={{ size: 'large', spinning: loading }}
          rowKey={u => `${u.userId}:${u.unitId}`}
          dataSource={unlistedUsers}
          scroll={{ x: true }}
          columns={normalizeColumns(unlistedUserColumns)}
          showSorterTooltip={false}
          pagination={false}
        />
      ),
    },
  ];

  return (
    <BasicLayout pageTitle={pageTitle}>
      <Card
        title={(
          <ModelBreadcrumbs
            model={PropertyModel}
            rowBreadCrumb={{ row: property }}
            customBreadCrumbs={[pageTitle]}
          />
        )}
        bordered={false}
        style={{ marginBottom: '20px' }}
        extra={(
          <>
            <RefreshButton
              disabled={loading}
              refresh={refetch}
              useSubscription={false}
            />
            <CsvExportButton
              title={`virtual_directory_${currentTab}_${property?.name}`}
              rows={currentTab === 'LISTED' ? listedUsers : unlistedUsers}
              columns={currentTab === 'LISTED' ? listedUserColumns : unlistedUserColumns}
              disabled={loading}
            />
          </>
        )}
      >
        <TabsManager tabs={tabs} cardBodyStyle={{ padding: 0 }} />
      </Card>
    </BasicLayout>
  );
}

export default VirtualDirectoryPreviewPage;
