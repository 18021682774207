import {
  PermissionKey_enum,
  PermissionScope_enum,
  RoleKey_enum,
  UnitUserRoleAuditTableFragment,
  UnitUserRoleAuditTableFragmentDoc,
  UnitUserRolesTableFragment,
  UnitUserRolesTableFragmentDoc,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { createdAtColumn } from '../../common/columns';
import PERMISSION_SCOPE_ICON_MAP from '../../helpers/permission-scopes';
import * as actions from '../actions';
import * as columns from '../columns';
import model from '../model';

import { commonTableConfig } from './common';

export const isUnitScopeReadable = () => (
  authentication.hasPermission(PermissionKey_enum.User_Read) ||
  authentication.readableRoleScopes.includes(PermissionScope_enum.UNIT)
);

export const UnitUserRolesTable = model.createTable<UnitUserRolesTableFragment>({
  ...commonTableConfig,
  title: 'Unit Roles',
  enabled: isUnitScopeReadable,
  icon: PERMISSION_SCOPE_ICON_MAP[PermissionScope_enum.UNIT],
  fragment: UnitUserRolesTableFragmentDoc,
  fixedQueryFilters: () => ({
    scopedUnitId: { _is_null: false },
  }),
  columns: [
    columns.statusColumn,
    columns.unitRoleColumn,
    columns.managedWithinPropertyColumn,
    columns.unitColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});

export const UnitUserRolesByUserTable = model.createTable<UnitUserRolesTableFragment, string>({
  ...commonTableConfig,
  title: 'Unit Roles',
  enabled: isUnitScopeReadable,
  icon: PERMISSION_SCOPE_ICON_MAP[PermissionScope_enum.UNIT],
  fragment: UnitUserRolesTableFragmentDoc,
  fixedQueryFilters: (assignedToUserId) => ({
    assignedToUserId: { _eq: assignedToUserId },
    scopedUnitId: { _is_null: false },
    status: {
      _in: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
    },
  }),
  // defaultColumnFilters: {
  //   UserRoleStatus: [UserRoleStatus_enum.ACTIVE],
  // },
  columns: [
    columns.statusColumn,
    columns.unitRoleColumn,
    columns.managedWithinPropertyColumn,
    columns.unitColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});

export const GuestsByUserTable = model.createTable<UnitUserRolesTableFragment, string>({
  ...commonTableConfig,
  title: 'Guests',
  enabled: () => (
    authentication.hasPermission(PermissionKey_enum.User_Read) ||
    authentication.currentRoleRelationships.some(r => (
      r.canReadUserRole && r.relatedRole.key === RoleKey_enum.GUEST_UNIT_KEY
    )) || false
  ),
  fragment: UnitUserRolesTableFragmentDoc,
  fixedQueryFilters: (userId) => ({
    role: {
      key: { _eq: RoleKey_enum.GUEST_UNIT_KEY },
    },
    status: {
      _in: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
    },
    scopedUnit: {
      userRoles: {
        role: {
          key: { _eq: RoleKey_enum.RESIDENT }
        },
        status: { _eq: UserRoleStatus_enum.ACTIVE },
        assignedToUserId: { _eq: userId },
      },
    },
  }),
  columns: [
    {
      ...columns.assignedToColumn,
      title: 'Guest',
    },
    columns.managedWithinPropertyColumn,
    columns.unitColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});

export const UnitUserRolesByUnitTable = model.createTable<UnitUserRolesTableFragment, string>({
  ...commonTableConfig,
  title: 'Unit Roles',
  enabled: isUnitScopeReadable,
  fragment: UnitUserRolesTableFragmentDoc,
  fixedQueryFilters: (unitId) => ({
    scopedUnitId: { _eq: unitId },
    status: {
      _in: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
    },
  }),
  columns: [
    columns.statusColumn,
    columns.unitRoleColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});

export const UnitUserRolesBySmartLockTable = model.createTable<UnitUserRolesTableFragment, string>({
  ...commonTableConfig,
  title: 'Unit Roles',
  enabled: isUnitScopeReadable,
  fragment: UnitUserRolesTableFragmentDoc,
  fixedQueryFilters: (smartLockId) => ({
    scopedUnit: {
      smartLocks: {
        smartLockId: { _eq: smartLockId },
      },
    },
    status: {
      _in: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
    },
  }),
  columns: [
    columns.statusColumn,
    columns.unitRoleColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});

export const UnitResidentAuditTable = model.createTable<UnitUserRoleAuditTableFragment, string>({
  ...commonTableConfig,
  title: 'Unit Roles',
  enabled: isUnitScopeReadable,
  fragment: UnitUserRoleAuditTableFragmentDoc,
  fixedQueryFilters: (unitId) => ({
    scopedUnitId: { _eq: unitId },
    status: {
      _in: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
    },
  }),
  columns: [
    columns.statusColumn,
    columns.unitRoleColumn,
    {
      ...columns.assignedToColumn,
      title: 'Name',
      filterOptions: undefined,
    },
    columns.phoneNumberColumn,
    columns.emailColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    columns.accountStatusColumn,
  ],
  customActions: [actions.revokeAction, actions.updateUserAccountStatusAction],
  useSubscription: true,
});
