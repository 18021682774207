import model from './model';

const formFields = model.createFormFields([
  {
    fieldName: 'name',
    maxLength: 50,
  },
  {
    fieldName: 'macAddress',
    maxLength: 17,
  },
  {
    fieldName: 'location',
    maxLength: 500,
    textArea: true,
  },
]);

model.setFormOptions({
  fields: formFields,
});
