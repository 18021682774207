import { createHash } from 'crypto';
import Fingerprint2 from 'fingerprintjs2';
import mem from 'mem';

const get = mem(Fingerprint2.getPromise);

async function getDeviceFingerprint(): Promise<string> {
  const data = await get();
  const hash = createHash('sha256')
    .update(JSON.stringify(data))
    .digest('hex');

  return `web:${hash}`;
}

export default getDeviceFingerprint;
