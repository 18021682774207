import { Button, Card, Tabs } from 'antd';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';

import ModelBreadcrumbs from '../../../components/ModelBreadcrumbs';
import TableActions from '../../../components/TableActions/TableActions';
import { PermissionScope_enum, usegetRoleDetailsQuery } from '../../../graphql/hasura/generated';
import BasicLayout from '../../../layouts/BasicLayout';
import ErrorLayout from '../../../layouts/ErrorLayout';
import LoadingLayout from '../../../layouts/LoadingLayout';
import RolePermissionManager from '../../RolePermission/components/RolePermissionManager';
import RoleRelationshipManager from '../../RoleRelationship/components/RoleRelationshipManager';
import RoleDescriptions from '../components/RoleDescriptions';
import RoleForm from '../components/RoleForm';
import model from '../model';

interface IEditRolePageProps extends RouteComponentProps<{ id: string }> {}

const RoleDetailsPage: React.FC<IEditRolePageProps> = (props) => {
  const [roleEditMode, setRoleEditMode] = useState(false);
  const [permissionsEditMode, setPermissionsEditMode] = useState(false);

  const { id } = props.match.params;
  const pageTitle = 'Role Details';

  const { data, error, loading } = usegetRoleDetailsQuery({
    fetchPolicy: 'network-only',
    variables: { roleId: id },
  });

  if (error) {
    return <ErrorLayout pageTitle={pageTitle} error={error} />
  }

  if (loading || !data?.role) {
    return <LoadingLayout pageTitle={pageTitle} />;
  }

  const { role } = data;
  const canEditRole = model.permissions.canUpdateRow(role);

  return (
    <BasicLayout pageTitle={pageTitle}>
      <Card
        title={<ModelBreadcrumbs model={model} rowBreadCrumb={{ row: role }} />}
        bordered={false}
        style={{ marginBottom: 20 }}
        extra={
          <TableActions
            row={role}
            actions={[model.actions.deleteAction, ...model.actions.defaultActions]}
          />
        }
      >
        <div style={{ display: !roleEditMode ? 'block' : 'none' }}>
          <RoleDescriptions role={role} />

          <div style={{ clear: 'both' }} />

          {canEditRole && (
            <Button onClick={() => setRoleEditMode(true)} style={{ marginTop: 15 }}>
              Edit
            </Button>
          )}
        </div>

        {canEditRole && (
          <div style={{ display: roleEditMode ? 'block' : 'none' }}>
            <RoleForm
              isNew={false}
              role={role}
              onCancel={() => setRoleEditMode(false)}
              onSubmit={() => setRoleEditMode(false)}
            />
          </div>
        )}
      </Card>
      <Card bordered={false} style={{ marginBottom: 20 }}>
        <Tabs animated={false} defaultActiveKey='RolePermission' size='large'>
          <Tabs.TabPane key='RolePermission' tab='Permissions'>
            <RolePermissionManager
              role={role}
              editMode={permissionsEditMode}
              setEditMode={setPermissionsEditMode}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            key='RoleRelationship'
            tab='Managed Roles'
            disabled={
              ![
                PermissionScope_enum.GLOBAL,
                PermissionScope_enum.ORGANIZATION,
                PermissionScope_enum.PROPERTY,
              ].includes(role.permissionScope) &&
              !role.roleRelationshipsByRole.length
            }
          >
            <RoleRelationshipManager
              role={role}
              editMode={permissionsEditMode}
              setEditMode={setPermissionsEditMode}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </BasicLayout>
  );
};

export default withRouter(RoleDetailsPage);
