import { Form } from '@ant-design/compatible';
import { Col, DatePicker, Row } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';

import { TIMESTAMP_FORMAT } from '../../utils';

interface ITimetableDatePickersProps {
  startDate: string | null | undefined;
  endDate: string | null | undefined;
  onChange: (date: Moment | null, key: 'startDate' | 'endDate') => any;
  errors?: any;
  disabled?: boolean;
}

const TimetableDatePickers: React.FC<ITimetableDatePickersProps> = (props) => {
  const { startDate, endDate, onChange, errors, disabled } = props;

  const startDateError = errors ? errors.startDate : undefined;
  const endDateError = errors ? errors.endDate : undefined;

  return (
    <Row justify='space-between' gutter={.5}>
      {[startDate, endDate].map((date, index) => {
        const label = index === 0 ? 'Start Date' : 'End Date';
        const error = index === 0 ? startDateError : endDateError;
        const key = index === 0 ? 'startDate' : 'endDate';

        return (
          <Col span={11} key={index}>
            <Form.Item
              label={label}
              colon
              validateStatus={error ? 'error' : undefined}
              help={error}
            >
              <DatePicker
                style={{ width: '100%' }}
                value={typeof date === 'string' ? moment(date) : date}
                format={TIMESTAMP_FORMAT}
                showTime={{
                  use12Hours: true,
                  secondStep: 60,
                }}
                onChange={(value => onChange(value, key))}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        );
      })}
    </Row>
  );
};

export default TimetableDatePickers;
