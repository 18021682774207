import { Session_bool_exp, SessionRequiredRowFragment } from '../../graphql/hasura/generated';
import Model from '../Model';

export const SessionModel = new Model<
  Session_bool_exp,
  SessionRequiredRowFragment,
  SessionRequiredRowFragment,
  SessionRequiredRowFragment
>({
  names: {
    schemaName: 'Session',
  },
});

export default SessionModel;
