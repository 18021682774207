import { EngrainClient, EngrainFloor, EngrainMap } from '@chirp/engrain-client';

import { ENGRAIN_API_BASE_URL, ENGRAIN_API_KEY } from '../../config';
import { hasuraClient } from '../../graphql';
import {
  Custom_AccessPointMapLocationInput as AccessPointMapLocation,
  syncEngrainPropertyMapDocument,
  syncEngrainPropertyMapMutation,
  syncEngrainPropertyMapMutationVariables,
  updateAccessPointMapLocationDocument,
  updateAccessPointMapLocationMutation,
  updateAccessPointMapLocationMutationVariables,
} from '../../graphql/hasura/generated';

export interface ILoadedEngrainMaps {
  assetId: string;
  maps: EngrainMap[];
  floors: EngrainFloor[];
}

const engrainClient = new EngrainClient({
  apiKey: ENGRAIN_API_KEY,
  baseUrl: ENGRAIN_API_BASE_URL,
});

export async function getPropertyMapsFromEngrain(
  sourceId: string,
): Promise<ILoadedEngrainMaps | null> {
  const assetsByProperty = await engrainClient.getAssetByIntegration('realpage-onesite');
  const [asset] = assetsByProperty.filter(a => a.value === `${sourceId.replace('RP-', '')}`);

  if (asset) {
    const assetId = asset.asset_id;
    const maps = await engrainClient.getMapsByAsset(assetId);
    const floors = await engrainClient.getFloorsByAsset(assetId);

    return { maps, assetId, floors };
  }

  return null;
}

export const syncMapByExternalMapId = async (
  externalAssetId: string,
  externalMapId: string,
  propertyId: string,
) => {
  await hasuraClient.mutate<
    syncEngrainPropertyMapMutation,
    syncEngrainPropertyMapMutationVariables
  >({
    mutation: syncEngrainPropertyMapDocument,
    variables: {
      input: {
        externalAssetId,
        propertyId,
        externalMapId,
      },
    },
  });
}

export const updateAccessPointMapLocation = async (
  accessPointId: string,
  mapLocation: AccessPointMapLocation | null,
) => {
  const { data } = await hasuraClient.mutate<
    updateAccessPointMapLocationMutation,
    updateAccessPointMapLocationMutationVariables
  >({
    mutation: updateAccessPointMapLocationDocument,
    variables: {
      accessPointId,
      mapLocation,
    },
  });

  if (!data?.custom_updateAccessPointMapLocation.accessPoint) {
    throw new Error('Unable to save location');
  }

  return data.custom_updateAccessPointMapLocation.accessPoint;
}
