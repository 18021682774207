import type { DocumentNode } from 'graphql';

import { apiClient } from '../../../graphql';
import type { CreatePaginatedCsvFetcherArgs, PaginatedCsvFetcher } from '../typings';

import { formatListQueryOutput } from './formatQueryOutput';
import { formatQueryVariables } from './formatQueryVariables';

export function createPaginatedCsvFetcher(
  args: CreatePaginatedCsvFetcherArgs,
  listOperation: DocumentNode,
): PaginatedCsvFetcher {
  return async ({ pageLimit, pageNumber }) => {
    const { tableConfig, tableState, formattedColumns, tableArgs } = args;

    const { listQueryVariables } = formatQueryVariables({
      tableConfig,
      formattedColumns,
      tableArgs,
      searchWords: [],
      tableState: { ...tableState, pageNumber, pageLimit },
    });

    const { data } = await apiClient.query({
      query: listOperation,
      variables: listQueryVariables,
    });

    return formatListQueryOutput(data);
  };
}
