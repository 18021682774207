import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { get } from 'lodash';

import { IModel } from '../models/typings';

import { __TypeKind } from './hasura/generated';

function getReturnFields(model: IModel): string {
  return model.introspection.fields
    .filter(field => {
      return field.kinds.includes(__TypeKind.SCALAR) || field.kinds.includes(__TypeKind.ENUM);
    })
    .map(field => `${field.name}`)
    .join('\n');
}

function getReturnFragment(fragment?: DocumentNode): string {
  return typeof fragment === 'object' ? `...${get(fragment, 'definitions[0].name.value')}` : '';
}

function getFragmentDeclaration(fragment?: DocumentNode): string {
  return fragment?.loc?.source?.body || '';
}

function getPrimaryKeyArgument(model: IModel) {
  const { primaryKey } = model;
  const primaryKeyField = model.introspection.fields.find(f => f.name === primaryKey);

  if (!primaryKeyField) {
    throw new Error(`No primary key found for ${model.names.schemaName}`);
  }

  // @TODO: Why are the scalar names lowercase? (e.g., "int" should be "Int")
  const scalarName = primaryKeyField.scalarName === 'int' ? 'Int' : primaryKeyField.scalarName;

  return { primaryKey, scalarName };
}

export function generateTotalOperation(model: IModel, operationType: 'query' | 'subscription') {
  const modelName = model.names.schemaName;

  return gql(`
    ${operationType} ($where: ${modelName}_bool_exp, $limit: Int) {
      ${modelName}_aggregate(where: $where, limit: $limit) {
        aggregate {
          count
        }
      }
    }
  `);
}

export function generateListSubscription(model: IModel, fragment?: DocumentNode) {
  return generateListOperation(model, 'subscription', fragment);
}

export function generateListQuery(model: IModel, fragment?: DocumentNode) {
  return generateListOperation(model, 'query', fragment);
}

export function generateListOperation(
  model: IModel,
  operationType: 'query' | 'subscription',
  listFragmentDoc?: DocumentNode,
) {
  const modelName = model.names.schemaName;

  const listReturnFields = typeof listFragmentDoc === 'object'
    ? getReturnFragment(listFragmentDoc)
    : getReturnFields(model);

  return gql(`
    ${getFragmentDeclaration(listFragmentDoc)}

    ${operationType} (
      $where: ${modelName}_bool_exp
      $offset: Int! = 0
      $limit: Int! = 100
      $order_by: [${modelName}_order_by!]
    ) {
      ${modelName} (
        where: $where
        limit: $limit
        offset: $offset
        order_by: $order_by
      ) {
        ${listReturnFields}
      }
    }
  `);
}

export function generateReadQuery(model: IModel) {
  const modelName = model.names.schemaName;
  const returnFields = getReturnFields(model);

  const fragments = [
    model.labels.uniqueLabelFragment,
    model.formOptions.requiredRowFragment,
    model.actions.actionsFragment,
  ].filter(Boolean) as DocumentNode[];

  const fragmentDeclarations = fragments.map(getFragmentDeclaration).join(' ');
  const returnFragments = fragments.map(getReturnFragment).join(' ');

  const { primaryKey, scalarName } = getPrimaryKeyArgument(model);

  return gql(`
    ${fragmentDeclarations}

    query ($${primaryKey}: ${scalarName}!) {
      ${modelName}_by_pk(${primaryKey}: $${primaryKey}) {
        ${returnFields}
        ${returnFragments}
      }
    }
`);
}

export function generateCreateMutation(model: IModel) {
  const modelName = model.names.schemaName;

  return gql(`
    mutation ($data: ${modelName}_insert_input!) {
      insert_${modelName}_one(object: $data) {
        ${getReturnFields(model)}
      }
    }
  `);
}

export function generateUpdateMutation(model: IModel) {
  const modelName = model.names.schemaName;
  const { primaryKey, scalarName } = getPrimaryKeyArgument(model);

  return gql(`
    mutation (
      $${primaryKey}: ${scalarName}!
      $data: ${modelName}_set_input!
    ) {
      update_${modelName}_by_pk(
        pk_columns: { ${primaryKey}: $${primaryKey} }
        _set: $data
      ) {
        ${getReturnFields(model)}
      }
    }
  `);
}

export function generateDeleteMutation(model: IModel) {
  const modelName = model.names.schemaName;
  const { primaryKey, scalarName } = getPrimaryKeyArgument(model);

  return gql(`
    mutation ($${primaryKey}: ${scalarName}!) {
      delete_${modelName}_by_pk(${primaryKey}: $${primaryKey}) {
        ${getReturnFields(model)}
      }
    }
  `);
}

export const sanitizeInsertInput = (
  model: IModel,
  data: { [k: string]: any }
) => sanitizeInput('insert', model, data);

export const sanitizeUpdateInput = (
  model: IModel,
  data: { [k: string]: any }
) => sanitizeInput('update', model, data);

export function sanitizeInput(
  permission: 'insert' | 'update',
  model: IModel,
  data: { [k: string]: any }
) {
  const inputData: { [k: string]: any } = {};

  const fields = model.introspection.fields.filter(field => (
    (field.kinds.includes(__TypeKind.SCALAR) || field.kinds.includes(__TypeKind.ENUM)) &&
    (permission === 'insert' ? field.canCreate : field.canUpdate)
  ));

  for (const field of fields) {
    if (typeof data[field.name] !== 'undefined') {
      inputData[field.name] = data[field.name];
    }
  }

  return inputData;
}
