import constate from 'constate';
import moment, { DurationInputArg2, Moment } from 'moment';
import { useState } from 'react';

function getDateRange(selectedStartDate: Moment, unit: DurationInputArg2) {
  const startDate = selectedStartDate.clone().startOf(unit);
  const endDate = moment.min(moment(new Date()).endOf('day'), startDate.clone().endOf(unit));

  return {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  };
}

function useFilters() {
  const [datePickerUnit, setDatePickerUnit] = useState<DurationInputArg2>('day');
  const [propertyId, setPropertyId] = useState<string | undefined>();
  const [roleIds, setRoleIds] = useState<string[]>([]);

  const [selectedStartDates, setSelectedStartDates] = useState<{ [key: string]: Moment }>({
    day: moment(new Date()).startOf('day'),
    week: moment(new Date()).startOf('week'),
    month: moment(new Date()).startOf('month'),
  });

  const setSelectedStartDate = (newStartDate: Moment) => {
    setSelectedStartDates({
      ...selectedStartDates,
      [datePickerUnit]: newStartDate,
    });
  }

  const selectedStartDate = selectedStartDates[datePickerUnit];
  const dateRange = getDateRange(selectedStartDate, datePickerUnit);

  return {
    datePickerUnit,
    setDatePickerUnit,
    propertyId,
    setPropertyId,
    roleIds,
    setRoleIds,
    ...dateRange,
    setSelectedStartDate,
  };
}

const [UseFiltersProvider, useFiltersContext] = constate(useFilters);

export {
  UseFiltersProvider,
  useFiltersContext,
};
