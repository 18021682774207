import { enumManagers } from '@chirp/enums';
import React from 'react';

import { EnumTag } from '../../components/EnumTag';
import {
  renderUserOrUserRoleString,
  UserOrUserRoleLink,
} from '../../components/UserOrUserRoleLink';
import { RelayLogTableFragment, UnlockResult } from '../../graphql/api/generated';
import { PermissionKey_enum, PermissionScope_enum } from '../../graphql/hasura/generated';
import { Color } from '../../utils';
import { AccessPointModel } from '../AccessPoint/model';
import { PropertyModel } from '../Property/model';
import { RelayModel } from '../Relay/model';
import { UserModel } from '../User/model';

import model from './model';

export const idColumn = model.createIdColumn();

export const propertyColumn = model.createColumn<RelayLogTableFragment>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'propertyId',
    model: PropertyModel,
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ property }) => PropertyModel.routes.renderRowLink(property),
});

export const accessPointColumn = model.createColumn<RelayLogTableFragment>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'accessPointId',
    model: AccessPointModel,
  },
  render: ({ accessPoint }) => AccessPointModel.routes.renderRowLink(accessPoint),
});

export const relayColumn = model.createColumn<RelayLogTableFragment>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'relayId',
    model: RelayModel,
  },
  render: ({ relay }) => RelayModel.routes.renderRowLink(relay),
});

export const userColumn = model.createColumn<RelayLogTableFragment>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'userId',
    model: UserModel,
  },
  enabled: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.RelayLogPrivate_Read)
  ),
  render: ({ restricted }) => <UserOrUserRoleLink {...restricted} />,
  renderString: async ({ restricted }) => {
    return await renderUserOrUserRoleString(restricted || {});
  },
});

export const unlockMethodColumn = model.createColumn<RelayLogTableFragment>({
  title: 'Unlock Method',
  filterOptions: {
    type: 'ENUM',
    key: 'unlockMethod',
    enumTable: 'UnlockMethod',
  },
  render: ({ unlockMethod }) => {
    return unlockMethod ? enumManagers.UnlockMethod.getLabel(unlockMethod) : null;
  },
});

export const unlockResultColumn = model.createColumn<RelayLogTableFragment>({
  title: 'Result',
  filterOptions: {
    type: 'ENUM',
    key: 'unlockResult',
    enumTable: 'UnlockResult',
  },
  render: ({ unlockResult }) => {
    if (!unlockResult) {
      return null;
    }

    return (
      <EnumTag
        enumValue={unlockResult}
        colorMap={{
          [UnlockResult.ACCESS_DENIED]: Color.Red,
          [UnlockResult.ACCESS_GRANTED]: Color.Green,
        }}
      />
    );
  },
  renderString: ({ unlockResult }) => {
    return unlockResult ? (enumManagers.UnlockResult.getLabel(unlockResult) || '') : '';
  },
});
