import { Card } from 'antd';
import { CardProps } from 'antd/lib/card';
import moment from 'moment-timezone';
import React from 'react';

import { TextWithIconTooltip } from '../../components/TextWithIconTooltip';

interface IUserActivityProps extends CardProps {
  startDate?: string;
  endDate?: string;
}

const tooltipTitle = `User activity includes locking or unlocking an access point or unit by an ` +
                     `Eligible User. Activity by anonymous web app users is not included.`;

export const UserActivityCard = (props: IUserActivityProps) => {
  const { startDate, endDate, ...cardProps } = props;

  let chartTitle = 'User Activity';

  if (startDate && endDate) {
    const formattedStartDate = moment(startDate).format('MMM D');
    const formattedEndDate = moment(endDate).format('MMM D');

    chartTitle += ` (${formattedStartDate} - ${formattedEndDate})`;
  } else if (startDate) {
    chartTitle += ` (${moment(startDate).format('MMMM D')})`;
  }

  return (
    <Card
      title={(
        <TextWithIconTooltip
          text={chartTitle}
          tooltip={{ title: tooltipTitle, placement: 'right' }}
        />
      )}
      style={{ marginBottom: 20 }}
      bordered={false}
      {...cardProps}
    />
  );
};

export default UserActivityCard;
