import { ExternalFloor } from '@chirp/engrain-client';
import {
  AccessPointWithLocation,
  EngrainMap,
  EngrainMapViewMode,
  Marker,
  MarkerEvent,
  PointLocation,
} from '@chirp/react-engrain-map';
import { Modal, notification } from 'antd';
import { sortBy } from 'lodash';
import React, { useState } from 'react';

import { ENGRAIN_API_KEY, PUBLIC_URL } from '../../../config';
import { hasuraClient } from '../../../graphql';
import { usegetAccessPointsWithMapLocationQuery } from '../../../graphql/hasura/generated';
import { displayErrorMessage } from '../../../utils';
import { updateAccessPointMapLocation } from '../helpers';

import { AccessPointMapLocationForm } from './AccessPointMapLocationForm';

interface IPropertyMapModalProps {
  mapId: string;
  name: string;
  propertyId: string;
  floors: ExternalFloor[];
  editMode: boolean;
}

interface IPropertyMapModalState {
  point: number[];
  viewPort: number[];
  viewAccessPointList: boolean;
  floor: string;
}

const PropertyMapModal: React.FC<IPropertyMapModalProps> = (props) => {
  const { floors, mapId, propertyId, name, editMode } = props;
  const [tooltip, setTooltip] = useState({
    title: '',
    location: [0, 0],
  });
  const [state, setState] = useState<IPropertyMapModalState>({
    point: [0, 0],
    viewPort: [0, 0],
    viewAccessPointList: false,
    floor: '',
  });

  const { data: accessPointsData } = usegetAccessPointsWithMapLocationQuery({
    fetchPolicy: 'network-only',
    client: hasuraClient,
    variables: {
      where: {
        propertyId: { _eq: propertyId },
        archivedAt: { _is_null: true },
      },
    },
    skip: !propertyId,
  });

  const accessPoints = accessPointsData?.accessPoints || [];
  const accessPointFormOptions = sortBy(accessPoints, 'name').filter(ap => !ap.mapLocation);

  const accessPointsWithLocation: AccessPointWithLocation[] = accessPoints
    .filter(ap => ap.mapLocation)
    .map((ap) => {
      return {
        accessPointId: ap.accessPointId,
        name: ap.name,
        ...ap.mapLocation,
      };
    });

  return (
    <div>
      <h2 style={{ position: 'absolute', top: 50, left: 50, zIndex: 10, color: '#001529' }}>Map {mapId}</h2>
      <EngrainMap
        propertyName={name}
        viewMode={editMode ? EngrainMapViewMode.EDIT : EngrainMapViewMode.VIEW}
        mapId={mapId}
        apiKey={ENGRAIN_API_KEY}
        floorsData={floors}
        markerIcon={{
          url: `${PUBLIC_URL}/qr-code-icon.svg`,
          size: [32, 32],
          anchor: [16, 16],
        }}
        unitsData={[]}
        bookedUnitIds={[]}
        unitColor='#687987'
        selectedUnitColor='#0076CC'
        accessPoints={accessPointsWithLocation}
        hideLocationButton={true}
        onMapRightClick={(location: PointLocation) => {
          setState({
            point: location.point,
            viewPort: location.viewPort,
            viewAccessPointList: true,
            floor: location.floorId,
          });
        }}
        onMarkerClick={(marker: Marker) => {
          if (!editMode) {
            return;
          }

          const accessPoint = accessPoints.find(a => a.accessPointId === marker.id);

          if (!accessPoint) {
            displayErrorMessage(new Error('Unable to find access point'));
            return;
          }

          Modal.confirm({
            centered: true,
            title: `Do you want to remove ${accessPoint.name} from the map?`,
            async onOk() {
              try {
                await updateAccessPointMapLocation(marker.id, null);

                notification.success({
                  message: 'Success',
                  description: `${accessPoint.name} was successfully removed from the map!`,
                });
              } catch (error) {
                displayErrorMessage(error);
              }
            },
          });
        }}
        onMarkerMouseEnter={(event: MarkerEvent) => {
          const { marker, originalEvent } = event;
          const accessPoint = accessPoints.find(e => e.accessPointId === marker.id);
          const markerPosition = (originalEvent.target as any).getBoundingClientRect();

          if (accessPoint) {
            setTooltip({
              title: accessPoint.name,
              location: [markerPosition.x, markerPosition.y - 64],
            });
          }
        }}
        onMarkerMouseOut={(event: MarkerEvent) => {
          setTooltip({
            title: '',
            location: [0, 0],
          });
        }}
      />
      {tooltip.title &&
        <div style={{
          top: tooltip.location[1],
          left: tooltip.location[0],
          background: 'white',
          position: 'absolute',
          padding: 10,
          border: '1px solid rgba(0, 0, 0, 0.2)',
        }}>
          {tooltip.title}
        </div>
      }
      {state.viewAccessPointList &&
        <div style={{
          position: 'fixed',
          left: state.viewPort[0],
          top: state.viewPort[1],
          width: 500,
        }}>
          <AccessPointMapLocationForm
            accessPoints={accessPointFormOptions}
            onSaveLocation={async (accessPointId) => {
              try {
                const accessPoint = await updateAccessPointMapLocation(accessPointId, {
                  location: state.point,
                  externalFloorId: state.floor,
                });

                notification.success({
                  message: 'Success',
                  description: `Location of ${accessPoint.name} was successfully saved!`,
                });

                setState({ ...state, viewAccessPointList: false });
              } catch (error) {
                displayErrorMessage(error);
              }
            }}
            closeForm={() => {
              setState({ ...state, viewAccessPointList: false });
            }}
          />
        </div>
      }
    </div>
  )
};

export default PropertyMapModal;
