import { PermissionKey_enum } from '../../graphql/hasura/generated';

import model from './model';
import PropertyDetailsPage from './pages/PropertyDetailsPage';
import PropertyDpsMonitoringPage from './pages/PropertyDpsMonitoringPage';
import PropertyHubMonitoringPage from './pages/PropertyHubMonitoringPage';
import PropertyMapsPage from './pages/PropertyMapsPage';
import PropertyResidentAuditPage from './pages/PropertyResidentAuditPage';
import PropertySmartLocksReportPage from './pages/PropertySmartLocksReportPage';
import VirtualDirectoryPreviewPage from './pages/VirtualDirectoryPreviewPage';

model.routes.setRoutes({
  detailsRoute: {
    component: PropertyDetailsPage,
  },
  customRoutes: [
    {
      component: PropertyResidentAuditPage,
      path: `${model.routes.basePath}/resident-audit/:propertyId`,
      enabled: ({ hasPermission }) => (
        hasPermission(PermissionKey_enum.Property_PmsResidentAudit)
      ),
    },
    {
      component: PropertyHubMonitoringPage,
      path: `${model.routes.basePath}/hub-monitoring/:propertyId`,
      enabled: ({ hasPermission }) => (
        hasPermission(PermissionKey_enum.Hub_Read)
      ),
    },
    {
      component: PropertySmartLocksReportPage,
      path: `${model.routes.basePath}/smart-locks-report/:propertyId`,
      enabled: ({ hasPermission }) => (
        hasPermission(PermissionKey_enum.SmartLock_PropertyReport)
      ),
    },
    {
      component: PropertyDpsMonitoringPage,
      path: `${model.routes.basePath}/dps-monitoring/:propertyId`,
      enabled: ({ hasPermission }) => (
        hasPermission(PermissionKey_enum.AccessPoint_Read)
      ),
    },
    {
      component: PropertyMapsPage,
      path: `${model.routes.basePath}/maps/:propertyId`,
      enabled: ({ hasPermission }) => (
        hasPermission(PermissionKey_enum.Property_ManageMaps)
      ),
    },
    {
      component: VirtualDirectoryPreviewPage,
      path: `${model.routes.basePath}/virtual-directory/:propertyId`,
      enabled: ({ canRead }) => canRead(),
    },
  ],
});
