import { Button, Divider, Steps, Tooltip } from 'antd';
import React, { useState } from 'react';

import {
  AccountStatus_enum,
  PermissionKey_enum,
  PermissionScope_enum,
  UserDetailsFragment,
  UserDetailsWithGlobalRolesFragment,
  useuserDetailsSubscription,
  useuserDetailsWithGlobalRolesSubscription,
} from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import { authentication } from '../../../stores';
import { formatTimestamp } from '../../../utils';
import { AccessPointModel } from '../../AccessPoint/model';
import { CredentialModel } from '../../Credential/model';
import { SessionModel } from '../../Session/model';
import * as actions from '../actions';
import { UserModel } from '../model';

import AssignNewFob from './assign-new-fob/AssignNewFob';
import EditUserForm from './EditUserForm';
import GenerateNewPinCode from './GenerateNewPinCode';
import UserAccessPoints from './UserAccessPoints';
import UserDescriptions from './UserDescriptions';
import { VerificationCodes } from './VerificationCodes';

const UserDetailsLayout = createDetailsLayout<
  UserDetailsFragment | UserDetailsWithGlobalRolesFragment
>();

const UserDetailsPage = () => {
  const [editMode, setEditMode] = useState(false);

  const canViewAccessPoints = AccessPointModel.permissions.canRead();
  const canAssignFob = CredentialModel.permissions.canCreate();

  const canGeneratePinCode = (
    !authentication.limitStratisPermissions &&
    authentication.hasPermission(PermissionKey_enum.PinCode_GenerateRandomByUser)
  );

  const canViewSessions = SessionModel.permissions.canRead();

  return (
    <UserDetailsLayout
      modelName='User'
      useQuery={(userId) => {
        return authentication.readableRoleScopes.includes(PermissionScope_enum.GLOBAL)
          ? useuserDetailsWithGlobalRolesSubscription({ variables: { userId } })
          : useuserDetailsSubscription({ variables: { userId } });
      }}
      actions={[
        actions.grantAccessAction,
        actions.sendInvitationAction,
        actions.updateAccountStatusAction,
      ]}
      body={(user) => {
        const { accountStatus, augustUserId, augustSubUserId, hasAugustPassword, password } = user;
        const isAccountDisabled = accountStatus === AccountStatus_enum.DISABLED;

        const canEditUser = !authentication.limitStratisPermissions && (
          user.userId === authentication.chirpUserId ||
          UserModel.permissions.canUpdate()
        );

        return (
          <>
            <div style={{ display: !editMode ? 'block' : 'none' }}>
              <UserDescriptions user={user} style={{ width: '66.67%', float: 'left' }} />
              <Steps direction='vertical' style={{ width: '33.33%', float: 'right' }}>
                <Steps.Step
                  status={(augustUserId || augustSubUserId) ? 'finish' : 'wait'}
                  title={(augustUserId || augustSubUserId) ? 'Has August account' : 'No August account'}
                  description={(
                    <>
                      <div>{augustUserId ? `Legacy User ID: ${augustUserId}` : null}</div>
                      <div>{augustSubUserId ? `Sub-User ID: ${augustSubUserId}` : null}</div>
                    </>
                  )}
                />
                <Steps.Step
                  status={(!!password || hasAugustPassword) ? 'finish' : 'wait'}
                  title={(!!password || hasAugustPassword)
                    ? (
                      <Tooltip title={password ? `Created: ${formatTimestamp(password.createdAt)}` : null}>
                        <span>Has Password</span>
                      </Tooltip>
                    )
                    : 'No password'
                  }
                  description={(
                    <>
                      {password ? `Last updated: ${formatTimestamp(password.updatedAt)}` : null}
                    </>
                  )}
                />
              </Steps>

              <div style={{ clear: 'both' }} />

              {(canEditUser || canAssignFob || canGeneratePinCode)  && (
                <Divider style={{ marginTop: 15 }} />
              )}

              {canEditUser && (
                <Button
                  onClick={() => setEditMode(true)}
                  style={{ margin: '5px', float: 'left' }}
                >
                  Edit Profile
                </Button>
              )}

              {!isAccountDisabled && canAssignFob && <AssignNewFob user={user} />}
              {!isAccountDisabled && canGeneratePinCode && <GenerateNewPinCode user={user} />}
              {canViewAccessPoints && <UserAccessPoints userId={user.userId} />}
              {canViewSessions && <VerificationCodes userId={user.userId} />}
            </div>

            {canEditUser && (
              <div style={{ display: editMode ? 'block' : 'none' }}>
                <EditUserForm
                  user={user}
                  onFinish={() => setEditMode(false)}
                />
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default UserDetailsPage;
