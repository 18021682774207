import { Form } from '@ant-design/compatible';
import { EnumTable } from '@chirp/enums';
import { Button, Checkbox, DatePicker, Input, InputNumber } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { FormikProps } from 'formik';
import moment from 'moment';
import React from 'react';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';

import ImageField from '../../components/ImageField';
import { IIntrospectionField, IModel } from '../../models/typings';
import { getFieldHelpText, getFormLabel, TIMESTAMP_FORMAT } from '../../utils';

import { EnumFormSelect } from './form-fields';

export interface IFormItemProps extends FormItemProps {
  fieldName: string;
  disabled?: boolean;
}

export const FormItem: React.FC<IFormItemProps> = (props) => {
  const { children, help, required, fieldName, disabled, ...formItemProps } = props;

  return (
    <Form.Item
      validateStatus={help ? 'error' : undefined}
      help={help}
      label={getFormLabel(fieldName)}
      key={fieldName}
      required={!disabled && required}
      {...formItemProps}
    >
      {children}
    </Form.Item>
  );
};

// @TODO: Standardize Formik props that get passed to every custom form field
interface ICustomFormFieldProps {
  formikProps: FormikProps<any>;
  canUpdateRow: boolean;
  typeSchema: { [fieldName: string]: any };
  isNew: boolean;
  model: IModel;
  field: IIntrospectionField;
  formItemLayout: { [k: string]: any };
}

export const CustomFormField: React.FC<ICustomFormFieldProps> = (props) => {
  const {
    formikProps,
    canUpdateRow,
    typeSchema,
    isNew,
    model,
    field,
    formItemLayout,
  } = props;

  const {
    values,
    setFieldValue,
    handleChange,
    isSubmitting,
    dirty,
    submitCount,
  } = formikProps;

  const { name: fieldName } = field;
  const fieldType = typeSchema[fieldName].type;

  const value = values[fieldName];
  const help = getFieldHelpText(formikProps, fieldName);
  const required = typeSchema[fieldName].required;

  let disabled = (
    !!isSubmitting ||
    (isNew && !field.canCreate) ||
    (!isNew && !field.canUpdate) ||
    (!isNew && !canUpdateRow) ||
    fieldName === model.primaryKey ||
    fieldName === 'createdAt' ||
    fieldName === 'updatedAt'
  );

  const formItemProps: IFormItemProps = { ...formItemLayout, fieldName, help, required, disabled };
  const formOptions = model.formOptions.fields.find(f => f.fieldName === fieldName);

  const maxLength = formOptions?.maxLength;
  const minValue = formOptions?.minValue;
  const maxValue = formOptions?.maxValue;
  const formOptionProps = { formikProps, field, disabled };

  if (typeof formOptions?.disabled === 'function') {
    disabled = formOptions.disabled(formOptionProps);
  }

  if (typeof formOptions?.hidden === 'function' && formOptions.hidden(formOptionProps)) {
    return null;
  }

  if (typeof formOptions?.render === 'function') {
    return formOptions.render({
      formikProps,
      field,
      disabled,
      FormItem,
      formItemProps,
    });
  }

  const enumRelationship = model.introspection.relationships.find(r => (
    r.sourceField === fieldName &&
    r.relationshipName.includes('Enum')
  ));

  if (enumRelationship) {
    return (
      <EnumFormSelect
        enumSelectProps={{
          value,
          enumTable: enumRelationship.targetModelName as EnumTable,
          onChange: (nextValue) => {
            setFieldValue(fieldName, nextValue || null, true);
          },
        }}
        formItemProps={{ ...formikProps, isNew, field, disabled, required }}
      />
    );
  }

  switch (fieldType) {
    case 'Int':
      if (fieldName === model.primaryKey) {
        return null;
      }

      return (
        <FormItem {...formItemProps}>
          <InputNumber
            name={fieldName}
            type='number'
            value={value}
            onChange={(nextValue) => {
              setFieldValue(fieldName, nextValue, true);
            }}
            style={{ width: '100%' }}
            min={minValue}
            // Default to highest maximum int4 value for PostgreSQL
            max={typeof maxValue === 'number' ? maxValue : 2147483647}
            onKeyDown={async (e) => {
              if (e.keyCode === 69) { // Prevent "e" from being entered
                e.preventDefault();
              }
            }}
            disabled={disabled}
          />
        </FormItem>
      );
    case 'String':
    case 'uuid':
      if (fieldName === model.primaryKey && isNew) {
        return null;
      }

      if (formOptions?.textArea) {
        return (
          <FormItem {...formItemProps}>
            <Input.TextArea
              name={field.name}
              value={value}
              onChange={handleChange}
              maxLength={maxLength}
              rows={4}
              disabled={disabled}
            />
          </FormItem>
        );
      }

      // @TODO: Move to model configuration
      if (fieldName === 'imageId') {
        return (
          <ImageField
            error={help}
            label={getFormLabel(fieldName)}
            keyProp={`${fieldName}_${fieldType}`}
            value={value}
            onChange={(nextValue) => setFieldValue(fieldName, nextValue, true)}
            required={required}
            submitCount={submitCount}
            dirty={dirty}
            fieldName={fieldName}
            disabled={disabled}
          />
        );
      }

      // @TODO: Format/parse all phone number fields

      return (
        <FormItem {...formItemProps}>
          <Input
            name={fieldName}
            value={value}
            onChange={handleChange}
            type='text'
            disabled={disabled}
            maxLength={fieldType === 'uuid' ? 36 : maxLength}
          />
        </FormItem>
      );
    case 'Boolean':
      return (
        <FormItem {...formItemProps}>
          <Checkbox
            disabled={disabled}
            name={fieldName}
            checked={value || false}
            onChange={handleChange}
            defaultChecked={false}
          />
        </FormItem>
      );
    case 'timestamptz':
      // @TODO: Move to model configuration
      if (fieldName === 'revokedAt') {
        return (
          <FormItem {...formItemProps}>
            <DatePicker
              showTime
              name={fieldName}
              disabled
              style={{ width: '100%' }}
              value={value && moment(value)}
            />
            <Button
              type='primary'
              onClick={() => value ?
                setFieldValue(fieldName, null, false) :
                setFieldValue(fieldName, new Date(), false)}
            > {value ? 'Set as Active' : 'Set as Revoked'}
            </Button>
          </FormItem>
        );
      }

      return (
        <FormItem {...formItemProps}>
          <DatePicker
            showTime
            name={fieldName}
            disabled={disabled}
            style={{ width: '100%' }}
            format={TIMESTAMP_FORMAT}
            value={value && moment(value)}
            onChange={time => setFieldValue(fieldName, time && time.toDate() || null, false)}
          />
        </FormItem>
      );
    case 'jsonb':
      if (!value) {
        return null;
      }

      return (
        <FormItem {...formItemProps}>
          <SyntaxHighlighter language='json' style={coy}>
            {JSON.stringify(value, null, 2)}
          </SyntaxHighlighter>
        </FormItem>
      );
    default:
      return null;
  }
};

export default CustomFormField;
