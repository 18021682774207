import { Modal, Table } from 'antd';
import React from 'react';

import { PermissionKey_enum } from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { alphanumericSorter } from '../../../utils';
import { lockedColumn } from '../../Role/columns';

import { ICategorizedPermission } from './typings';

interface IRolePermissionTableProps {
  categorizedPermissions: ICategorizedPermission[];
  selectedRowKeys?: string[];
  readOnly: boolean;
  toggleRolePermissions?: (permissions: ICategorizedPermission[], selected: boolean) => Promise<any>;
}

const RolePermissionTable: React.FC<IRolePermissionTableProps> = (props) => {
  const { categorizedPermissions, selectedRowKeys, readOnly } = props;

  const toggleRolePermissions = typeof props.toggleRolePermissions === 'function'
    ? props.toggleRolePermissions
    : async () => {};

  const canBypassIsLocked = authentication.hasPermission(PermissionKey_enum.Role_ManageUnrestricted);

  return (
    <Table
      columns={[
        {
          render: (_, { isLocked }) => lockedColumn.render({ isLocked }),
        },
        {
          title: 'Category',
          render: (_, { categoryName }) => categoryName,
          sorter: (permissionA, permissionB) => {
            return alphanumericSorter(
              permissionA.categoryName.toLowerCase(),
              permissionB.categoryName.toLowerCase(),
            );
          },
        },
        {
          title: 'Permission',
          render: (_, { name }) => name,
          sorter: (permissionA, permissionB) => {
            return alphanumericSorter(
              permissionA.name.toLowerCase(),
              permissionB.name.toLowerCase(),
            );
          },
        },
        {
          title: 'Description',
          render: (_, { description }) => description,
        },
        {
          render: (_, { extra }) => extra,
        },
      ]}
      dataSource={categorizedPermissions}
      onRow={(permission) => {
        return {
          onClick: async () => {
            const permissionIsLocked = permission.isLocked && !canBypassIsLocked;

            if (!readOnly && !permissionIsLocked) {
              const selected = !(selectedRowKeys || []).includes(permission.permissionId);

              await toggleRolePermissions([permission], selected);
            }
          },
        };
      }}
      rowSelection={Array.isArray(selectedRowKeys) ? {
        selectedRowKeys,
        onSelect: async (permission, selected) => {
          await toggleRolePermissions([permission], selected);
        },
        onSelectAll: async (selected, selectedPermissions, changedPermissions) => {
          Modal.confirm({
            centered: true,
            title: `${selected ? 'Enable' : 'Disable'} All Permissions`,
            content: (
              `Are you sure you want to ${selected ? 'ENABLE' : 'DISABLE'} ` +
              `these ${changedPermissions.length} permission(s)?`
            ),

            async onOk() {
              await toggleRolePermissions(changedPermissions, selected);
            },
          });
        },
        getCheckboxProps: (permission) => {
          const permissionIsLocked = permission.isLocked && !canBypassIsLocked;

          return { disabled: readOnly || permissionIsLocked };
        },
      } : undefined}
      rowKey={p => p.permissionId}
      rowClassName={(permission) => {
        const permissionIsLocked = permission.isLocked && !canBypassIsLocked;

        if (!readOnly) {
          return permissionIsLocked ? 'disabled-row' : 'selectable-row';
        }

        return '';
      }}
      style={{ paddingTop: 20, marginBottom: 40 }}
      pagination={false}
    />
  );
};

export default RolePermissionTable;
