import React from 'react';

import { ModelSelect } from '../../components/ModelSelect';
import type { ColumnFilters, IModelColumn } from '../typings';

interface IFormatModelSelectColumnArgs {
  column: IModelColumn<any>;
  filters?: ColumnFilters;
}

export function formatModelSelectColumn(args: IFormatModelSelectColumnArgs): IModelColumn<any> {
  const { column, filters } = args;
  const { filterOptions } = column;

  if (filterOptions?.type !== 'MODEL_SELECT') {
    return column;
  }

  const { model, key } = filterOptions;
  const filterValues = filters ? filters[key] : null;

  return {
    ...column,
    key,
    filtered: Boolean(filterValues?.length),
    filteredValue: filterValues || null, // null ensures it's treated as unfiltered
    filterDropdown({ selectedKeys, setSelectedKeys, confirm }) {
      return (
        <div style={{ padding: 8 }}>
          <ModelSelect
            {...filterOptions.modelSelectProps}
            model={model}
            value={selectedKeys as string[]}
            onChange={(value) => {
              const values = value ? [value] : [];

              setSelectedKeys(values);

              if (confirm) {
                confirm();
              }
            }}
            allowClear
            style={{ width: 450 }}
          />
        </div>
      );
    }
  };
}
