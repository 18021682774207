import { Select } from 'antd';
import React from 'react';

import model from './model';

const formFields = model.createFormFields([
  {
    fieldName: 'name',
    maxLength: 50,
  },
  {
    fieldName: 'host',
    maxLength: 100,
  },
  {
    fieldName: 'macAddress',
    maxLength: 17,
  },
  {
    fieldName: 'ipAddress',
    maxLength: 45,
  },
  {
    fieldName: 'channels',
    render: ({ formikProps, field, disabled, FormItem, formItemProps }) => {
      const options = [4, 8, 16];
      const { values, setFieldValue } = formikProps;

      return (
        <FormItem {...formItemProps}>
          <Select
            allowClear
            value={values[field.name]}
            onChange={(value) => {
              const convertedValue = Number(value) || null;

              setFieldValue(field.name, convertedValue, true);
            }}
            options={options.map(value => ({ value, label: value }))}
            virtual={false} // There is a visual bug with white space when reselecting
            disabled={disabled}
          />
        </FormItem>
      );
    },
  },
  {
    fieldName: 'location',
    maxLength: 500,
    textArea: true,
  },
]);

model.setFormOptions({
  fields: formFields,
});
