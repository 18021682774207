import { enumManagers } from '@chirp/enums';

import {
  CredentialTableFragment,
  CredentialType_enum,
  PermissionScope_enum,
} from '../../graphql/hasura/generated';
import { PropertyModel } from '../Property/model';
import { UserModel } from '../User/model';

import model from './model';

export const propertyColumn = model.createColumn<CredentialTableFragment>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'ownedByPropertyId',
    model: PropertyModel,
  },
  enabled: ({ currentPermissionScope }) => (
    currentPermissionScope === PermissionScope_enum.GLOBAL ||
    currentPermissionScope === PermissionScope_enum.ORGANIZATION
  ),
  render: ({ ownedByProperty }) => PropertyModel.routes.renderRowLink(ownedByProperty) || 'N/A',
});

export const assignedToColumn = model.createColumn<CredentialTableFragment>({
  title: 'Assigned To',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'assignedToUserId',
    model: UserModel,
  },
  render: ({ assignedToUser }) => UserModel.routes.renderRowLink(assignedToUser),
});

export const provisionedByColumn = model.createColumn<CredentialTableFragment>({
  title: 'Provisioned By',
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'provisionedByUserId',
    model: UserModel,
  },
  render: ({ provisionedByUser }) => UserModel.routes.renderRowLink(provisionedByUser),
});

export const typeColumn = model.createColumn<{ type: CredentialType_enum }>({
  title: 'Type',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'CredentialType',
  },
  render: ({ type }) => enumManagers.CredentialType.getLabel(type),
});

export const identifierColumn = model.createColumn<{ identifier: string }>({
  dataIndex: 'identifier',
});

export const facilityCodeColumn = model.createColumn<{ facilityCode?: number | null }>({
  dataIndex: 'facilityCode',
});

export const cardNumberColumn = model.createColumn<{ cardNumber?: number | null }>({
  dataIndex: 'cardNumber',
});
