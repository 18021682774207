import { DeleteTwoTone } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React from 'react';

import { TimetableRule, TimetableRuleState } from '../../graphql/api/generated';

import { DEFAULT_TIMETABLE_RULE } from './constants';
import TimetableDatePickers from './TimetableDatePickers';
import TimetableDaysSelect from './TimetableDaysSelect';
import TimetableStateSelect from './TimetableStateSelect';
import TimetableTimePickers from './TimetableTimePickers';
import useTimetableField from './useTimetableField';

interface ITimetableFieldProps {
  values: any;
  fieldName: string;
  modelName: string;
  onChange: (timetable: TimetableRule[] | null) => any;
  errors: any;
  touched: any;
  disabled?: boolean;
  limit?: number; // Default to 7 (1 schedule per day of the week)
}

export const TimetableField: React.FC<ITimetableFieldProps> = (props) => {
  const { values, modelName, fieldName = 'timetable', errors, touched, disabled, limit = 7 } = props;
  const {
    timetable,
    update,
    updateState,
    updateDays,
    updateTime,
    updateDate,
  } = useTimetableField(values[fieldName], props.onChange);

  const fieldErrors = errors[fieldName] || [];
  const fieldTouched = touched[fieldName] || [];

  const isAccessPoint = modelName === 'AccessPoint';

  return (
    <div className='timetable-container'>
      {isAccessPoint && (
        <div style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '13px', marginBottom: 5 }}>
          If no schedules are set, the access point will be available 24/7 (with valid credentials).
        </div>
      )}
      {timetable.map((rule, ruleIndex: number) => {
        const { start, end, state, startDate, endDate } = rule;

        const ruleErrors = fieldErrors[ruleIndex] || {};
        const ruleTouched = Boolean(fieldTouched[ruleIndex]);

        return (
          <Card
            key={ruleIndex}
            style={{ marginBottom: '15px' }}
            headStyle={{ background: '#fbfbfb' }}
            bodyStyle={{ paddingBottom: '0' }}
            title={isAccessPoint ? (
              <TimetableStateSelect
                value={state}
                onChange={value => updateState(value, ruleIndex)}
                disabled={disabled}
              />
            ) : <span style={{ fontSize: 14 }}>Schedule {ruleIndex + 1}</span>}
            extra={!disabled && (
              <DeleteTwoTone
                onClick={() => {
                  if (!disabled) {
                    const updatedTable = [...timetable];
                    updatedTable.splice(ruleIndex, 1);
                    update(updatedTable);
                  }
                }}
                disabled={disabled}
              />
            )}
          >
            {state !== TimetableRuleState.LOCKDOWN && (
              <>
                <TimetableDaysSelect
                  value={rule.days}
                  onChange={days => updateDays(days, ruleIndex)}
                  error={ruleTouched ? ruleErrors.days : undefined}
                  disabled={disabled}
                />
                <TimetableTimePickers
                  startTime={start}
                  endTime={end}
                  onChange={(time, position) => updateTime(time, ruleIndex, position)}
                  errors={ruleTouched ? ruleErrors : undefined}
                  disabled={disabled}
                />
              </>
            )}
            {/* Lockdown has 24/7 recurring schedule with start/end dates */}
            {state === TimetableRuleState.LOCKDOWN && (
              <TimetableDatePickers
                startDate={startDate}
                endDate={endDate}
                onChange={(date, key) => updateDate(date, ruleIndex, key)}
                errors={ruleTouched ? ruleErrors : undefined}
                disabled={disabled}
              />
            )}
          </Card>
        );
      })}
      {timetable.length < limit && (
        <Button
          type='dashed'
          onClick={() => {
            update([
              ...timetable,
              {
                ...DEFAULT_TIMETABLE_RULE,
                state: isAccessPoint ? TimetableRuleState.CONTROLLED : undefined,
              },
            ]);
          }}
          disabled={disabled}
        >
          + Add Schedule
        </Button>
      )}
    </div>
  );
}

export default TimetableField;
