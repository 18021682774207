import { FormikProps } from 'formik';

import { apiClient } from '../../../graphql/api/apiClient';
import { hasuraClient } from '../../../graphql/hasura/hasuraClient';
import { IModel, RowImportStatus } from '../../../models/typings';
import { IModelImportFormValues } from '../formik';

interface IImportRowArgs {
  values: IModelImportFormValues;
  model: IModel<any>;
  formikProps: FormikProps<IModelImportFormValues>;
}

export async function importRows(args: IImportRowArgs) {
  const { model, values, formikProps } = args;
  const { setFieldValue } = formikProps;
  const { pluralDisplayName } = model.names;

  const { importOptions } = model.formOptions;
  const fakeProgress = importOptions?.fakeProgress !== false;

  const importableRows = values.rows.filter(r => (
    !r.errors.length && r.importState.status !== RowImportStatus.IMPORTED
  ));

  try {
    if (!importOptions) {
      throw new Error(
        `Importing is not allowed for ${pluralDisplayName.toLowerCase()}`,
      );
    }

    setFieldValue('importingProgress', 0);
    setFieldValue('showImportingProgress', true);
    setFieldValue('importingRows', true);

    const importableRowsCount = importableRows.length;

    if (!fakeProgress) {
      importableRows.forEach((row) => {
        row.setOnImportStateChange((importState) => {
          if (importState.status === RowImportStatus.IMPORTED) {
            const finishedRowsCount = importableRows.filter(r => (
              r.importState.status === RowImportStatus.IMPORTED)
            ).length;

            const nextImportingProgress = (finishedRowsCount / importableRowsCount) * 100;

            setFieldValue('importingProgress', nextImportingProgress);
          }
        });
      });
    }

    if (fakeProgress) {
      await new Promise(r => setTimeout(r, 250));
      setFieldValue('importingProgress', 50);
    }

    await importOptions.importRows(importableRows, values.applyAllValues, values.importDependencies);

    if (fakeProgress) {
      // Assume all rows could be imported since no error was thrown
      importableRows.forEach((row) => row.setImportState({ status: RowImportStatus.IMPORTED }));
    }

    setFieldValue('rows', values.rows); // Updated import status

    // Ensure that cached tables refetch data when redirected
    await hasuraClient.resetStore();
    await apiClient.resetStore();
  } catch (error) {
    if (fakeProgress) {
      // Assume no rows could be imported since an error was thrown
      importableRows.forEach((row) => {
        row.setImportState({ status: RowImportStatus.ERROR, errorMessage: error.message });
      });
    }
  } finally {
    const validRows = values.rows.filter(r => !r.errors.length);

    const validRowsCount = validRows.length;
    const importedRowsCount = validRows.filter(r => (
      r.importState.status === RowImportStatus.IMPORTED
    )).length;

    setFieldValue('importingProgress', (importedRowsCount / validRowsCount) * 100);
    setFieldValue('importingRows', false);
  }
}

export default importRows;
