import React from 'react';

import { PropertyActionsFragment } from '../../../graphql/hasura/generated';
import { propertyFeatureFlagsColumn } from '../columns';

import PropertyFeatureFlagModal from './PropertyFeatureFlagModal';

interface IPropertyFeatureFlagsProps {
  property: PropertyActionsFragment;
}

const PropertyFeatureFlags = ({ property }: IPropertyFeatureFlagsProps) => {
  const { propertyId, propertyFeatureFlags } = property;

  return (
    <>
      {propertyFeatureFlags.length
        ? propertyFeatureFlagsColumn.render({ propertyFeatureFlags })
        : <div>None</div>
      }
      <PropertyFeatureFlagModal
        propertyId={propertyId}
        propertyFeatureFlags={propertyFeatureFlags}
      />
    </>
  );
}

export default PropertyFeatureFlags;
