import {
  RoleKey_enum,
  UserRoleAssignedToFragment as UserRole,
} from '../../graphql/hasura/generated';
import { UserModel } from '../User/model';

interface IRenderUserRoleAssignedToOptions {
  toString: boolean;
}

function renderUserRoleAssignedTo(
  userRole: UserRole,
  { toString }: IRenderUserRoleAssignedToOptions,
) {
  const {
    assignedToUser, assignedToAnonymousUserId, role, sourceId, scopedUnitId, nickname,
  } = userRole;

  if (assignedToUser) {
    return toString
      ? (UserModel.labels.getLabel(assignedToUser) || '')
      : UserModel.routes.renderRowLink(assignedToUser);
  }

  if (role.key === RoleKey_enum.RESIDENT && sourceId) {
    return 'NOT SYNCHRONIZED'; // PMS Sync
  }

  if (nickname) {
    return nickname; // Guest Pass
  }

  if (assignedToAnonymousUserId) {
    return 'Anonymous Guest'; // Callbox Killer
  }

  if (role.key === RoleKey_enum.GUEST_PROPERTY_KEY && !scopedUnitId) {
    return 'Unclaimed'; // Guest Pass
  }

  return 'UNASSIGNED';
}

export function renderUserRoleAssignedToString(userRole: UserRole): string {
  return renderUserRoleAssignedTo(userRole, { toString: true }) as string;
}

export function renderUserRoleAssignedToLink(userRole: UserRole) {
  return renderUserRoleAssignedTo(userRole, { toString: false });
}
