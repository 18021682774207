import { Alert, Button, Card, Divider } from 'antd';
import { withFormik } from 'formik';
import { isEmpty, partition } from 'lodash';
import React from 'react';

import ModelBreadcrumbs from '../../components/ModelBreadcrumbs';
import BasicLayout from '../../layouts/BasicLayout';

import {
  IModelImportFormValues,
  IModelImportPageMergedProps,
  IModelImportPageProps,
  mapPropsToValues,
  validate,
} from './formik';
import { parseCsvFile } from './helpers';
import { ModelImportAllowedFields } from './ModelImportAllowedFields';
import ModelImportApplyAllFields from './ModelImportApplyAllFields';
import ModelImportTables from './ModelImportTables';

export const ModelImportPage: React.FC<IModelImportPageMergedProps> = (props) => {
  const { model, ...formikProps } = props;
  const { values, validateForm, setErrors, setFieldValue } = formikProps;
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

  const { pluralDisplayName } = model.names;
  const pageTitle = `Import ${pluralDisplayName}`;

  const formFields = model.formOptions.fields.filter(f => !!f.importOptions);
  const [applyAllFields, importFields] = partition(formFields, f => !!f.importOptions?.applyAll);

  const isFormValid = isEmpty(formikProps.errors);
  const applyAllErrors = formikProps.errors.applyAllValues || {};
  const totalRows = values.rows.length;

  const submitting = formikProps.isSubmitting || values.parsingRows || values.importingRows;

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file) {
      // Force file input to re-render so files can continue to be selected
      setFieldValue('fileInputKey', Date.now().toString());

      // Store file for later in case it needs to be re-parsed
      const onSuccess = () => setFieldValue('file', file);

      parseCsvFile({ file, model, importFields, formikProps, onSuccess });
    }
  };

  return (
    <BasicLayout pageTitle={pageTitle}>
      <Card
        bordered={false}
        title={
          <ModelBreadcrumbs
            model={model}
            customBreadCrumbs={[pageTitle]}
          />
        }
        style={{ marginBottom: 20 }}
      >
        <ModelImportApplyAllFields
          fields={applyAllFields}
          formikProps={formikProps}
          onChange={() => {
            // Parse/transform the same file again
            if (values.rows.length && values.file) {
              parseCsvFile({ model, importFields, formikProps, file: values.file });
            }
          }}
        />
        {applyAllFields.length > 0 && <Divider />}
        <ModelImportAllowedFields model={model} importFields={importFields} />
        <div style={{
          marginTop: 15,
          marginBottom: 10,
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
        }}>
          {formikProps.touched && !isEmpty(applyAllErrors) && (
            <Alert
              type='error'
              showIcon
              style={{ marginBottom: 20 }}
              message='Unable to select CSV file'
              description='Please correct any errors with the form above'
            />
          )}
          <Button
            type='default'
            size='large'
            onClick={async () => {
              const errors = await validateForm(values);

              if (!isEmpty(errors)) {
                return setErrors(errors);
              }

              if (hiddenFileInput.current) {
                hiddenFileInput.current.click();
              }
            }}
            disabled={submitting}
          >
            {values.rows.length ? 'Replace CSV File' : 'Select CSV File'}
          </Button>
          <input
            type='file'
            ref={hiddenFileInput}
            accept='.csv'
            key={values.fileInputKey}
            disabled={!isFormValid || submitting}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <div className='help-text' style={{ marginTop: 15 }}>
            <em>If you're using Excel or Numbers, be sure to export your file as a CSV.</em>
          </div>
        </div>
      </Card>
      {(values.parsingRows || totalRows > 0) && (
        <ModelImportTables
          model={model}
          importFields={importFields}
          formikProps={formikProps}
          submitting={submitting}
        />
      )}
    </BasicLayout>
  );
};

const ModelImportPageWithFormik = withFormik<IModelImportPageProps, IModelImportFormValues>({
  mapPropsToValues,
  validate,
  // Handle submission in the component. (We need arguments that are not available in the callback)
  handleSubmit: () => {},
  enableReinitialize: false,
  validateOnChange: false,
  validateOnBlur: false,
})(ModelImportPage);

export default ModelImportPageWithFormik;
