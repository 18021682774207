import { enumManagers } from '@chirp/enums';

import {
  AccessPointLabelFragment,
  AccessPointType_enum,
  PropertyLabelFragment,
  RoleUniqueLabelFragment,
} from '../../graphql/hasura/generated';
import * as accessPointColumns from '../AccessPoint/columns';
import { AccessPointModel } from '../AccessPoint/model';
import { PropertyModel } from '../Property/model';
import { RoleModel } from '../Role/model';

import model from './model';

export const propertyColumn = model.createColumn<{
  accessPoint: { property: PropertyLabelFragment } }
>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'accessPoint.propertyId',
    model: PropertyModel,
  },
  render: ({ accessPoint }) => PropertyModel.routes.renderRowLink(accessPoint.property),
});

export const accessPointColumn = model.createColumn<{ accessPoint: AccessPointLabelFragment }>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'accessPointId',
    model: AccessPointModel,
  },
  render: ({ accessPoint }) => AccessPointModel.routes.renderRowLink(accessPoint),
});

export const roleColumn = model.createColumn<{ role: RoleUniqueLabelFragment }>({
  filterOptions: {
    type: 'MODEL_SELECT',
    key: 'roleId',
    model: RoleModel,
  },
  render: ({ role }) => RoleModel.routes.renderUniqueRowLink(role),
});

export const typeColumn = model.createColumn<{ accessPoint: { type: AccessPointType_enum } }>({
  title: 'Type',
  render: ({ accessPoint }) => accessPointColumns.typeColumn.render(accessPoint),
  renderString: ({ accessPoint }) => enumManagers.AccessPointType.getLabel(accessPoint.type),
});
