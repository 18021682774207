import { Card, Empty, Radio } from 'antd';
import React from 'react';

import { TextWithIconTooltip } from '../../components/TextWithIconTooltip';

import { useFiltersContext } from './hooks';
import UserActivityCard from './UserActivityCard';
import UserActivityDayInterval from './UserActivityDayInterval';
import UserActivityHourInterval from './UserActivityHourInterval';
import UserMetricsFilters from './UserMetricsFilters';
import UserMetricsStatistics from './UserMetricsStatistics';

const UserMetrics = () => {
  const filters = useFiltersContext();

  const {
    datePickerUnit,
    setDatePickerUnit,
    startDate,
    endDate,
    propertyId,
    roleIds,
  } = filters;

  return <>
    <Card
      title={
        <TextWithIconTooltip
          text='User Metrics'
          tooltip={{
            title: 'These metrics only include Eligible Users. Anonymous web app users are not included.',
            placement: 'right',
          }}
        />
      }
      extra={
        <Radio.Group
          defaultValue='day'
          buttonStyle='solid'
          onChange={e => setDatePickerUnit(e.target.value)}
        >
          <Radio.Button value='day'>By Day</Radio.Button>
          <Radio.Button value='week'>By Week</Radio.Button>
          {/* @TODO: Temporarily(?) disabled for performance reasons */}
          {/* <Radio.Button value='month'>By Month</Radio.Button> */}
        </Radio.Group>
      }
      bordered={false}
      style={{ marginBottom: '20px' }}
    >
      <UserMetricsFilters />
    </Card>
    {/* @TODO: Temporarily(?) require property selection for performance reasons */}
    {!propertyId && (
      <UserActivityCard
        startDate={startDate}
        endDate={datePickerUnit !== 'day' ? endDate : undefined}
      >
        <Empty
          description={(
            <span className='ant-legacy-form-item-required'>Please select a property</span>
          )}
        />
      </UserActivityCard>
    )}
    {propertyId && (
      <div style={{ marginBottom: '20px' }}>
        <UserMetricsStatistics
          startDate={startDate}
          endDate={endDate}
          propertyId={propertyId}
          roleIds={roleIds}
        />
      </div>
    )}
    {propertyId && datePickerUnit === 'day' && (
      <UserActivityHourInterval
        startDate={startDate}
        endDate={endDate}
        propertyId={propertyId}
        roleIds={roleIds}
      />
    )}
    {propertyId && (datePickerUnit === 'week' || datePickerUnit === 'month') && (
      <UserActivityDayInterval
        startDate={startDate}
        endDate={endDate}
        propertyId={propertyId}
        roleIds={roleIds}
      />
    )}
  </>;
}

export default UserMetrics;
