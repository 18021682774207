import model from './model';

const formFields = model.createFormFields([
  {
    fieldName: 'enabled',
    hidden: () => true, // We're not currently using this field
  },
  {
    fieldName: 'name',
    maxLength: 50, // @TODO: Change from "text" to "varchar(50)" in DB
  },
  {
    fieldName: 'serialNumber',
    maxLength: 50,
  },
]);

model.setFormOptions({
  fields: formFields,
});
