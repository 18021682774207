import { CheckOutlined } from '@ant-design/icons';
import { enumManagers } from '@chirp/enums';
import React from 'react';

import EnumTag from '../../components/EnumTag';
import { PropertyMapType_enum } from '../../graphql/hasura/generated';

import model from './model';

export const mapIdColumn = model.createColumn<{ externalMapId: string }>({
  title: 'Map ID',
  render: ({ externalMapId }) => externalMapId,
});

export const typeColumn = model.createColumn<{ type: PropertyMapType_enum }>({
  title: 'Type',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'PropertyMapType',
  },
  render: ({ type }) => <EnumTag colorMap={{}} enumValue={type} />,
  renderString: ({ type }) => enumManagers.PropertyMapType.getLabel(type),
});

export const publishedColumn = model.createColumn<{ published: boolean }>({
  title: 'Published',
  render: ({ published }) => published ? <CheckOutlined /> : null,
  renderString: ({ published }) => published ? 'Yes' : 'No',
});
