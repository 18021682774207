import { IChirpTableColumn } from '../typings';

// Convert columns to be usable by Ant Design Table component
export function normalizeColumns(columns: IChirpTableColumn<any>[]) {
  return columns.map((column) => ({
    ...column,
    // Our model columns are configured to take the row as the only argument
    render: (_: any, row: any) => column.render(row),
  }));
}

export default normalizeColumns;
