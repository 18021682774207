import React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';

import background from '../assets/background.svg';
import logo from '../assets/chirp_logo_dark.png';

import styles from './UnauthenticatedLayout.module.less';

interface IUnauthenticatedLayoutProps {
  children: React.ReactNode;
}

const UnauthenticatedLayout: React.FC<IUnauthenticatedLayoutProps> = ({ children }) => {
  return (
    <DocumentTitle title='Chirp Admin Panel'>
      <div
        className={styles.container}
        style={{ backgroundImage: `url('${background}')` }}
      >
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.header}>
              <Link to='/'>
                <img alt='logo' className={styles.logo} src={logo} />
              </Link>
            </div>
            <div className={styles.desc}>Chirp Admin Panel</div>
          </div>
          {children}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default UnauthenticatedLayout;
