import { Form } from '@ant-design/compatible';
import { Button, notification, Radio, Tag } from 'antd';
import { FormikErrors, FormikProps, withFormik } from 'formik';
import React from 'react';

import TimetableField from '../../../components/TimetableField';
import {
  AccessPoint,
  AccessPointRoleTableFragment,
  AccessPointType_enum,
  RoleWithStaffPermissionsFragment,
} from '../../../graphql/hasura/generated';
import { FORM_ITEM_DEFAULT_LAYOUT, FormItem } from '../../../pages/ModelForm/form-fields';
import ModelFormSelect from '../../../pages/ModelForm/ModelFormSelect';
import { onSubmitForm } from '../../../pages/ModelForm/submit';
import { Color, DEFAULT_ERROR_MESSAGE, getErrorInfo } from '../../../utils';
import model from '../../AccessPointRole/model';
import { validateTimetable } from '../../helpers/validate/timetable';
import { RoleModel } from '../../Role/model';
import { UnitGroupModel } from '../../UnitGroup/model';

import { getRoleAttributes, getSelectableRolesByAccessPoint } from './helpers';

type FormValues = Partial<AccessPointRoleTableFragment> & {
  accessPointId: string;
  scheduleType: 'DEFAULT' | 'CUSTOM';
};

interface IAccessPointRoleFormProps {
  isNew: boolean;
  accessPoint: AccessPoint;
  hasAccess: boolean;
  selectedRoleId?: string;
  accessPointRole?: AccessPointRoleTableFragment | null;
  roles: RoleWithStaffPermissionsFragment[];
  onCancel: () => any;
  onSubmit: (roleId: string) => any;
}

interface IAccessPointRoleFormWrapperProps extends IAccessPointRoleFormProps, FormikProps<FormValues> {}

const AccessPointRoleForm: React.FC<IAccessPointRoleFormWrapperProps> = (props) => {
  const {
    isNew, accessPoint, accessPointRole, roles, onCancel, onSubmit, selectedRoleId, ...formikProps
  } = props;

  const { values, setFieldValue, resetForm, isSubmitting, handleSubmit } = formikProps;

  const handleCancelClick = () => {
    onCancel();
    resetForm();
  };

  const {
    isUnitRole, isAccessPointRole, hasStaffAccess, canBypassSchedule, customScheduleRequired,
  } = getRoleAttributes(values.roleId, roles);

  const showUnitGroup = (
    !hasStaffAccess &&
    (isUnitRole || isNew) &&
    !isAccessPointRole
  );

  return (
    <Form style={{ marginTop: 8 }}>
      <ModelFormSelect
        {...formikProps}
        fieldName='roleId'
        formItemProps={{ ...FORM_ITEM_DEFAULT_LAYOUT }}
        modelSelectProps={{
          value: values.roleId,
          model: RoleModel,
          disabled: !isNew || !!selectedRoleId,
          required: true,
          queryFilters: (isNew && !selectedRoleId)
            ? getSelectableRolesByAccessPoint(values.accessPointId)
            : undefined,
          fetchPolicy: 'network-only',
          onChange: () => {
            setFieldValue('unitGroupId', null);
          },
        }}
      />

      {showUnitGroup && (
        <ModelFormSelect
          {...formikProps}
          fieldName='unitGroupId'
          formItemProps={{ ...FORM_ITEM_DEFAULT_LAYOUT }}
          modelSelectProps={{
            value: values.unitGroupId,
            model: UnitGroupModel,
            disabled: !isUnitRole,
            // Allow unit groups from other properties to be whitelisted.
            // Only allow unit groups within the AP's property to be blacklisted.
            queryFilters: !values.hasAccess && accessPoint.propertyId ? {
              propertyId: { _eq: accessPoint.propertyId },
            } : undefined,
          }}
          extra={
            <div className='help-text'>
              <em>
                Optionally limit this configuration to a specific unit group
                (only applicable to unit roles).
                <br />
                <br />
                Note that whitelisting/blacklisting will also apply to ALL child unit groups.
                <br/>
                (e.g., Floor 1 and Floor 2 unit groups within Building 23 unit group).
              </em>
            </div>
          }
        />
      )}

      {values.hasAccess && (
        <FormItem
          {...formikProps}
          isNew={isNew}
          field={model.introspection.getField('schedule')}
          label='Schedule'
        >
          {canBypassSchedule && (
            <Tag color={Color.Green} style={{ fontWeight: 'bold' }}>
              BYPASS SCHEDULE
            </Tag>
          )}
          {!canBypassSchedule && (
            <>
              <Radio.Group
                value={values.scheduleType}
                onChange={(e) => {
                  setFieldValue('scheduleType', e.target.value);

                  if (e.target.value === 'DEFAULT') {
                    setFieldValue('schedule', null);
                  }
                }}
                disabled={customScheduleRequired}
              >
                <Radio value='DEFAULT'>Default</Radio>
                <Radio value='CUSTOM'>Custom</Radio>
              </Radio.Group>
              {values.scheduleType === 'CUSTOM' && (
                <TimetableField
                  values={values}
                  fieldName='schedule'
                  modelName='AccessPointRole'
                  onChange={(value) => {
                    setFieldValue('schedule', value);
                  }}
                  errors={formikProps.errors}
                  touched={formikProps.touched}
                />
              )}
            </>
          )}
        </FormItem>
      )}

      <section style={{ marginTop: 50, display: 'flex', justifyContent: 'center' }}>
        <Button
          type='primary'
          loading={isSubmitting}
          disabled={isSubmitting}
          style={{ marginRight: 10 }}
          onClick={() => {
            // Normally we would trigger this in the onSubmit of the Form component,
            // but that is not working properly since this is a nested form.
            handleSubmit();
          }}
        >
          {isSubmitting ? 'Saving' : 'Save'}
        </Button>

        <Button onClick={handleCancelClick} loading={isSubmitting} disabled={isSubmitting}>
          Cancel
        </Button>

      </section>
    </Form>
  );
}

const AccessPointRoleFormWithFormik = withFormik<IAccessPointRoleFormProps, FormValues>({
  mapPropsToValues: ({ accessPoint, accessPointRole, hasAccess, selectedRoleId, roles }) => {
    const { accessPointId } = accessPoint;
    const { customScheduleRequired } = getRoleAttributes(selectedRoleId, roles);

    return accessPointRole
      ? {
        ...accessPointRole,
        accessPointId,
        scheduleType: accessPointRole.schedule || customScheduleRequired ? 'CUSTOM' : 'DEFAULT',
      }
      : {
        accessPointId,
        hasAccess,
        scheduleType: customScheduleRequired ? 'CUSTOM' : 'DEFAULT',
        roleId: selectedRoleId,
      };
  },
  validate: (values, props) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.roleId) {
      errors.roleId = 'Please select a role';
    }

    const { isUnitRole } = getRoleAttributes(values.roleId, props.roles);

    if (!isUnitRole && values.unitGroupId) {
      errors.unitGroupId = 'Unit groups are not allowed for this role';
    }

    if (values.scheduleType === 'CUSTOM' && !values.schedule?.length) {
      errors.schedule = 'Please add at least one schedule';
    }

    if (
      props.accessPoint.type === AccessPointType_enum.COMMON_AREA &&
      (values.roleId && !values.unitGroupId && !values.schedule?.length)
    ) {
      errors.schedule = 'Roles should only be added to common areas to configure custom ' +
                        'schedules or enable cross-property access via unit groups';
    }

    return {
      ...errors,
      ...validateTimetable(values, 'schedule'),
    };
  },
  handleSubmit: async (values, formikBag) => {
    try {
      const data = await onSubmitForm(values, formikBag, model, formikBag.props.isNew, (options) => ({
        ...options,
        refetchQueries: [],
      }));

      const accessPointRoleId = (data as any)[Object.keys(data)[0]].roleId;

      formikBag.props.onSubmit(accessPointRoleId);
    } catch (error) {
      // @TODO: Try to reuse code from ModelForm page
      const { friendlyErrorMessage, formErrors } = getErrorInfo(error);

      if (formErrors) {
        formikBag.setErrors(formErrors);
      }

      notification.error({
        message: 'Error',
        description: friendlyErrorMessage || DEFAULT_ERROR_MESSAGE,
      });
    }
  },
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
})(AccessPointRoleForm);

export default AccessPointRoleFormWithFormik;
