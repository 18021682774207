import { Form } from '@ant-design/compatible';
import { enumManagers } from '@chirp/enums';
import React from 'react';

import DurationField from '../../components/DurationField';
import { DurationUnit } from '../../components/DurationField/helpers';
import TimetableField from '../../components/TimetableField';
import { AccessPoint_insert_input, AccessPointType_enum } from '../../graphql/hasura/generated';
import { upsertAccessPoints } from '../../graphql/hasura/operations';
import { FORM_ITEM_DEFAULT_LAYOUT } from '../../pages/ModelForm/form-fields';
import { getPropertyFeatureFlags } from '../../utils';
import combineValidators from '../helpers/validate/combineValidators';
import { validateTimetable } from '../helpers/validate/timetable';
import { IModelFormFieldRenderProps } from '../typings';

import AccessPointFailurePlan from './AccessPointFailurePlan';
import * as columns from './columns';
import AccessPointArchiveSection from './components/AccessPointArchiveSection';
import AccessPointTypeManager from './components/AccessPointTypeManager';
import model from './model';
import validateAccessPoint from './validate';

interface IParsedRow {
  Name: string;
  Type?: string;
  'Access Timeout'?: string;
  'Spaceship Mode Enabled'?: 'Yes' | 'No';
  'Proximity Restriction'?: 'Yes' | 'No';
}

interface IApplyAllValues {
  propertyId: string;
}

interface ITransformedRow {
  propertyId: string;
  name: string;
  type: AccessPointType_enum;
  accessTimeout: number | undefined;
  spaceshipModeEnabled: boolean;
  proximityRestriction: boolean;
}

interface IAccessPointDurationFieldProps extends IModelFormFieldRenderProps {
  durationUnits: DurationUnit[];
  maxDurationInSeconds?: number;
  helpText: string;
}

const AccessPointDurationField: React.FC<IAccessPointDurationFieldProps> = (props) => {
  const { field, disabled, formikProps, helpText, FormItem, formItemProps } = props;
  const { values, setFieldValue } = formikProps;

  return (
    <FormItem {...formItemProps}>
      <DurationField
        valueInSeconds={(values[field.name] / 1000)} // Stored in milliseconds
        onChange={(value) => setFieldValue(field.name, (value ? value * 1000 : undefined))}
        disabled={disabled}
        durationUnits={props.durationUnits}
        maxDurationInSeconds={props.maxDurationInSeconds}
      />
      <div className='help-text'>
        <em>{helpText}</em>
      </div>
    </FormItem>
  );
}

const formFields = model.createFormFields<IParsedRow>([
  {
    fieldName: 'propertyId',
    label: 'Property',
    importOptions: {
      applyAll: true,
      required: true,
    },
  },
  {
    fieldName: 'name',
    maxLength: 50,
    importOptions: {
      importFieldName: 'Name',
      required: true,
      rules: [
        {
          description: 'Must be unique',
        },
      ],
    },
  },
  {
    fieldName: 'type',
    hidden: () => true,
    importOptions: {
      importFieldName: 'Type',
      defaultValue: {
        value: () => 'Common Area',
      },
      getAllowedValues: () => {
        return enumManagers.AccessPointType.getOptions()
          .filter(o => o.value !== AccessPointType_enum.RESERVATION_ONLY)
          .map(o => o.label);
      },
    },
  },
  {
    fieldName: 'accessTimeout',
    // These min/max values only apply properly to the CSV import because
    // the units are expected to be in seconds instead of milliseconds
    minValue: 1,
    maxValue: 60,
    render: (props) => {
      return (
        <AccessPointDurationField
          {...props}
          helpText='The length of time the entrance will remain unlocked before automatically re-locking.'
          durationUnits={[DurationUnit.SECOND]}
          maxDurationInSeconds={60}
        />
      );
    },
    importOptions: {
      importFieldName: 'Access Timeout',
      defaultValue: {
        value: () => '5',
      },
      rules: [
        {
          description: 'Number is expected to be in seconds (whole numbers only)',
          errorMessage: 'Must be a whole number',
          validate: (parsedRow) => {
            const accessTimeout = parsedRow['Access Timeout'] ? +parsedRow['Access Timeout'] : null;

            return typeof accessTimeout === 'number' ? accessTimeout % 1 === 0 : true;
          },
        },
      ],
    },
  },
  {
    fieldName: 'spaceshipModeEnabled',
    importOptions: {
      importFieldName: 'Spaceship Mode Enabled',
      getAllowedValues: () => ['Yes', 'No'],
      defaultValue: {
        value: () => 'Yes',
      },
    },
  },
  {
    fieldName: 'proximityRestriction',
    importOptions: {
      importFieldName: 'Proximity Restriction',
      getAllowedValues: () => ['Yes', 'No'],
      defaultValue: {
        value: () => 'No',
      },
    },
  },
  {
    fieldName: 'archivedAt',
    hidden: () => true,
  },
  {
    fieldName: 'archivedByUserId',
    hidden: () => true,
  },
  {
    fieldName: 'dpsAlertDelay',
    hidden: ({ formikProps }) => !getPropertyFeatureFlags(formikProps.values.property).DPS,
    render: (props) => {
      return (
        <AccessPointDurationField
          {...props}
          helpText='The length of time a door must remain open before the first alert is sent.'
          durationUnits={[DurationUnit.MINUTE, DurationUnit.HOUR]}
        />
      );
    },
  },
  {
    fieldName: 'dpsAlertInterval',
    hidden: ({ formikProps }) => !getPropertyFeatureFlags(formikProps.values.property).DPS,
    render: (props) => {
      return (
        <AccessPointDurationField
          {...props}
          helpText='The interval at which alerts should be repeated while a door remains open. Leave blank to only send a single alert.'
          durationUnits={[DurationUnit.MINUTE, DurationUnit.HOUR]}
        />
      );
    },
  },
  {
    fieldName: 'mapLocation',
    hidden: () => true,
  },
  {
    fieldName: 'maxOccupancy',
    hidden: () => true,
  },
  {
    fieldName: 'timetable',
    render: ({ field, disabled, formikProps, FormItem, formItemProps }) => {
      const { values, setFieldValue, touched, errors } = formikProps;

      return (
        <FormItem {...formItemProps}>
          <TimetableField
            values={values}
            fieldName='timetable'
            modelName={model.names.schemaName}
            onChange={(value) => {
              setFieldValue(field.name, value, true);
            }}
            touched={touched}
            errors={errors}
            disabled={disabled}
          />
        </FormItem>
      );
    },
  },
]);

model.setFormOptions<IParsedRow, IApplyAllValues, ITransformedRow>({
  fields: formFields,
  validate: combineValidators(validateTimetable, validateAccessPoint),
  renderExtra: (formikProps, isNew) => {
    const { values } = formikProps;

    return (
      <>
        <AccessPointTypeManager formikProps={formikProps} isNew={isNew} />
        {!isNew && (
          <Form.Item
            {...FORM_ITEM_DEFAULT_LAYOUT}
            label='Internet Failure Plan'
          >
            <AccessPointFailurePlan accessPointId={values.accessPointId} canEdit={!values.archivedAt} />
          </Form.Item>
        )}
        {!isNew && <AccessPointArchiveSection accessPoint={values} />}
      </>
    );
  },
  importOptions: {
    rowLimit: 200,
    uniqueKeys: ['Name'],
    columns: [
      columns.nameColumn,
      columns.typeColumn,
      columns.accessTimeoutColumn,
      columns.spaceshipModeEnabledColumn,
      columns.proximityRestrictionColumn,
    ],
    transformRows: async (rows, { propertyId }) => {
      for (const row of rows) {
        const { parsedRow } = row;

        const type = enumManagers.AccessPointType.getValue(parsedRow.Type || 'Common Area') as AccessPointType_enum;
        const accessTimeoutInSeconds = parsedRow['Access Timeout'] || undefined;
        const spaceshipModeEnabled = parsedRow['Spaceship Mode Enabled'] === 'Yes';
        const proximityRestriction = parsedRow['Proximity Restriction'] === 'Yes';

        row.setTransformedRow({
          propertyId,
          type,
          spaceshipModeEnabled,
          proximityRestriction,
          name: parsedRow.Name,
          accessTimeout: accessTimeoutInSeconds ? (+accessTimeoutInSeconds * 1000) : undefined,
        });
      }

      return rows;
    },
    importRows: async (validRows) => {
      const accessPoints = await upsertAccessPoints({
        objects: validRows.map(({ transformedRow }) => {
          const {
            propertyId, name, type, accessTimeout, spaceshipModeEnabled, proximityRestriction,
          } = transformedRow;

          const input: AccessPoint_insert_input =  {
            propertyId,
            type,
            name,
            accessTimeout,
            spaceshipModeEnabled,
            proximityRestriction,
          };

          return input;
        }),
      });

      if (!accessPoints) {
        throw new Error('Unable to import access points');
      }

      return accessPoints;
    },
    getRedirectPath: ({ routes }, { propertyId }) => {
      const stringifiedParams = JSON.stringify({ property: [propertyId]});
      const encodedParams = encodeURIComponent(stringifiedParams);

      return `${routes.basePath}?filters=${encodedParams}`;
    },
    sampleCsvData: [
      {
        Name: 'Drive Gate',
        Type: 'Common Area',
        'Access Timeout': '10',
        'Spaceship Mode Enabled': 'No',
        'Proximity Restriction': 'Yes',
      },
      {
        Name: 'Pool',
        Type: 'Common Area',
        'Access Timeout': '5',
        'Spaceship Mode Enabled': 'Yes',
        'Proximity Restriction': 'Yes',
      },
      {
        Name: 'Bike Storage Room',
        Type: 'Amenity',
        'Access Timeout': '5',
        'Spaceship Mode Enabled': 'Yes',
        'Proximity Restriction': 'No',
      },
      {
        Name: 'Fitness Center',
        Type: 'Restricted (Blacklist)',
        'Access Timeout': '5',
        'Spaceship Mode Enabled': 'Yes',
        'Proximity Restriction': 'No',
      },
      {
        Name: 'Staff Lounge',
        Type: 'Restricted (Whitelist)',
        'Access Timeout': '5',
        'Spaceship Mode Enabled': 'No',
        'Proximity Restriction': 'Yes',
      },
    ],
  },
});
