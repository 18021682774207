import { Card, DatePicker, Empty } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { range } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { TextWithIconTooltip } from '../../../components/TextWithIconTooltip';
import { usegetHubHealthReportsQuery } from '../../../graphql/hasura/generated';
import BasicLayout from '../../../layouts/BasicLayout';
import {
  displayErrorMessage,
  TIMESTAMP_FORMAT,
  USER_ABBREVIATED_TIMEZONE,
  USER_TIMEZONE,
} from '../../../utils';
import HealthChart from '../components/HealthChart';
import { HubDescriptions } from '../components/HubDescriptions';
import model from '../model';

const { RangePicker } = DatePicker;

function formatTimestamp(date: string | Moment) {
  return moment(new Date(moment(date).utc(true).toString())).format(TIMESTAMP_FORMAT);
}

const HubHealthReportsPage: React.FC<RouteComponentProps<{ hubId: string }>> = (props) => {
  const { hubId } = props.match.params;
  const [startDate, setStartDate] = useState<Moment>(moment().subtract(1, 'hour'));
  const [endDate, setEndDate] = useState<Moment>(moment());

  const { loading, data } = usegetHubHealthReportsQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        hubId,
        startDateTime: startDate.utc().format('YYYY-MM-D H:m:s'),
        endDateTime: endDate.utc().format('YYYY-MM-D H:m:s'),
      },
    },
    onError: error => displayErrorMessage(error),
  });

  const healthReports = data?.custom_getHubHealthReports.healthReports || [];
  const hub = data?.custom_getHubHealthReports.hub;

  const latencyData = healthReports.map(health => ({
    date: formatTimestamp(health.time),
    latency: +health.latency,
  }));

  const temperatureData = healthReports.map(health => ({
    date: formatTimestamp(health.time),
    temperature: +health.temperature,
  }));

  // only show max 12 dates on x-axis
  let ticks: string[] = [];
  if (healthReports.length > 0) {
    const count = Math.round(healthReports.length / 12);
    const indexes = range(0, healthReports.length, count);

    if (count > 1) {
      indexes.forEach(index => {
        if (healthReports[index]) {
          ticks.push(formatTimestamp(healthReports[index].time));
        }
      });
    } else {
      ticks = healthReports.map(h => formatTimestamp(h.time));
    }
  }

  const onChange: RangePickerProps['onChange'] = (dates, dateStrings) => {
    if (dates) {
      setStartDate(moment(dates[0]));
      setEndDate(moment(dates[1]))
    }
  };

  return (
    <BasicLayout pageTitle='Hub Health Reports'>
       <Card
        loading={loading}
        title={(hub ? model.labels.getUniqueLabel(hub) : '...')}
        style={{ marginBottom: 20 }}
        extra={
          <>
            <RangePicker
              // have to do this to show correct date in current timezone and not throwing deprecated moment warning
              defaultValue={[moment(new Date(startDate.toString())), moment(new Date(endDate.toString()))]}
              showTime={{
                use12Hours: true,
                secondStep: 60,
              }}
              onChange={onChange}
              format={TIMESTAMP_FORMAT}
              disabledDate={(d: Moment) => (
                d.isAfter(moment(new Date())) || d.isBefore(moment(new Date()).subtract(6, 'months'))
              )}
              style={{ marginBottom: 10 }}
            />
            <div className='help-text' style={{ marginLeft: 'auto' }}>
              Timezone: {USER_TIMEZONE} ({USER_ABBREVIATED_TIMEZONE})
            </div>
          </>
        }
      >
      {hub && <HubDescriptions hub={hub} />}
      </Card>
      {[
          {
            title: 'Network Latency',
            tooltip: 'Network Latency is the time, in milliseconds, it takes to ping google.com. A ping rate of 1- 150 ms is acceptable.',
            dataType: 'latency',
            data: latencyData,
          },
          {
            title: 'Temperature',
            tooltip: 'CPU temperature, in Celsius, should generally fluctuate and fall under 65°C.',
            dataType: 'temperature',
            data: temperatureData,
          },
        ].map(({ title, tooltip, dataType, data: chartData }) => (
          <Card
            key={title}
            loading={loading}
            title={
              <TextWithIconTooltip
                text={title}
                tooltip={{ title: tooltip, placement: 'right' }}
              />
            }
            style={{ marginBottom: 20 }}
          >
            {chartData.length === 0 && <Empty description='No activity for this period' />}
            {chartData.length > 0 &&
              <HealthChart
                dataType={dataType}
                data={chartData}
                ticks={ticks}
              />
            }
          </Card>
        ))}
    </BasicLayout>
  );
};

export default HubHealthReportsPage;
