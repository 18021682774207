import wiegand from 'wiegand-encoder';

import { displayErrorMessage } from '../../../../../utils';

export default (facilityCode: string | null, cardNumber: string | null): string | null => {
  if (!facilityCode || !cardNumber) {
    return null;
  }

  try {
    const binary = wiegand.encode(+cardNumber, +facilityCode);

    return parseInt(binary, 2).toString(16);
  } catch (error) {
    displayErrorMessage(error);
    return null;
  }
}
