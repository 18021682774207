import { Cloudinary, Transformation } from 'cloudinary-core';

const cloudinary = Cloudinary.new({ cloud_name: 'chirpsystems' });

export function getImageUrl(cloudId: string | null, options?: Transformation.Options): string {
  if (!cloudId) {
    return '';
  }

  return cloudinary.url(cloudId, { quality: 100, ...options, secure: true });
}
