import React from 'react';

import { PermissionKey_enum } from '../../../graphql/hasura/generated';

export interface IPermissionCategory {
  categoryName: string;
  permissions: {
    name: string;
    description: string;
    key: PermissionKey_enum;
    extra?: React.ReactNode;
    dependencies?: PermissionKey_enum[];
  }[];
}

export interface ICategorizedPermission {
  permissionId: string;
  isLocked: boolean;
  key: PermissionKey_enum;
  categoryName: string;
  name: string;
  description: string;
  extra?: React.ReactNode;
  dependencies?: PermissionKey_enum[];
}

export enum PermissionFilter {
  SHOW_ALL = 'Show All Permissions',
  SHOW_ENABLED = 'Show Enabled Permissions',
  SHOW_DISABLED = 'Show Disabled Permissions',
}
