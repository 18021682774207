import { InfoCircleTwoTone } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';

import { UserLabelFragment } from '../../graphql/hasura/generated';
import { userRoleLoader } from '../../graphql/hasura/hasuraClient';
import { UserModel } from '../../models/User/model';
import { renderUserRoleAssignedToString } from '../../models/UserRole/helpers';
import { UserRoleModel } from '../../models/UserRole/model';
import { formatPrimaryKey } from '../../utils';
import { ModelIdNoPermission } from '../ModelIdNoPermission';

interface IUserOrUserRoleProps {
  userId?: string | null;
  user?: UserLabelFragment | null;
  userRoleId?: string | null;
}

export async function renderUserOrUserRoleString(props: IUserOrUserRoleProps): Promise<string> {
  const { userId, user, userRoleId } = props;

  if (user) {
    return UserModel.labels.getLabel(user);
  }

  if (!user && userId) {
    return `${UserModel.names.displayName} ${formatPrimaryKey(userId, true)}`;
  }

  if (userRoleId) {
    const userRole = await userRoleLoader.getUserRoleByPk(userRoleId);

    if (userRole) {
      return renderUserRoleAssignedToString(userRole);
    }

    return `${UserRoleModel.names.displayName} ${formatPrimaryKey(userRoleId, true)}`;
  }

  return '';
}

export const UserOrUserRoleLink: React.FC<IUserOrUserRoleProps> = (props) => {
  const { userId, user, userRoleId } = props;

  if (user) {
    return <>{UserModel.routes.renderRowLink(user)}</>;
  }

  if (!user && userId) {
    return <ModelIdNoPermission model={UserModel} id={userId} />;
  }

  if (userRoleId) {
    const {
      loading, value: userRole,
    } = useAsync(() => userRoleLoader.getUserRoleByPk(userRoleId), [userRoleId]);

    if (loading) {
      return <><Spin style={{ marginRight: '10px' }} /> Loading...</>;
    }

    if (userRole) {
      const userRoleLink = UserRoleModel.routes.getRowLink(userRole);

      const userRoleAssignedTo = (
        <span style={{ marginRight: '5px' }}>
          {renderUserRoleAssignedToString(userRole)}
        </span>
      );

      const tooltip = (
        <Tooltip title='Unregistered user' style={{ marginLeft: '5px' }}>
          <InfoCircleTwoTone style={{ fontSize: '14px' }} />
        </Tooltip>
      );

      return userRoleLink
        ? <><Link to={userRoleLink}>{userRoleAssignedTo}</Link>{tooltip}</>
        : <>{userRoleAssignedTo}{tooltip}</>;
    }

    return <ModelIdNoPermission model={UserRoleModel} id={userRoleId} />;
  }

  return null;
}

export default UserOrUserRoleLink;
