import { Image_bool_exp } from '../../graphql/hasura/generated';
import Model from '../Model';

export const ImageModel = new Model<Image_bool_exp>({
  names: {
    schemaName: 'Image',
  },
});

export default ImageModel;
