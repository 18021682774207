import { ApolloQueryResult } from '@apollo/client';
import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';

import TableActions from '../../../components/TableActions/TableActions';
import { Timetable } from '../../../components/Timetable';
import { TimetableRuleState } from '../../../graphql/api/generated';
import {
  AccessPoint,
  AccessPointRoleTableFragment,
  getAccessPointRolesQuery,
  PermissionKey_enum,
  PermissionScope_enum,
  RoleWithStaffPermissionsFragment,
} from '../../../graphql/hasura/generated';
import { Color, RefetchContext } from '../../../utils';
import { AccessPointRoleModel } from '../../AccessPointRole/model';
import { permissionScopeColumn } from '../../Role/columns';
import { RoleModel } from '../../Role/model';
import { IModelAction } from '../../typings';
import { UnitGroupModel } from '../../UnitGroup/model';

import { getRoleAttributes } from './helpers';

export interface IAccessPointRoleRow {
  accessPointRole?: AccessPointRoleTableFragment | null;
  role: RoleWithStaffPermissionsFragment;
}

export interface IAccessPointRoleEditArgs {
  selectedRoleId?: string;
  accessPointRole?: AccessPointRoleTableFragment | null;
}

interface IAccessPointRoleTableProps {
  accessPoint: AccessPoint;
  rows: IAccessPointRoleRow[];
  readOnly?: boolean;
  onEditRow: (args: IAccessPointRoleEditArgs) => any;
  filterColumns?: (column: ColumnsType<IAccessPointRoleRow>[0]) => boolean;
  refetch(): Promise<ApolloQueryResult<getAccessPointRolesQuery>>;
}

export const AccessPointRoleTable: React.FC<IAccessPointRoleTableProps> = (props) => {
  const { accessPoint, rows, readOnly, onEditRow, refetch, filterColumns } = props;

  const columns: ColumnsType<IAccessPointRoleRow> = [
    {
      title: 'Role',
      render: (_, { role }) => {
        return RoleModel.routes.renderUniqueRowLink(role);
      },
    },
    {
      title: 'Role Scope',
      render: (_, { role }) => {
        return permissionScopeColumn.render(role);
      },
    },
    {
      title: 'Unit Group',
      render: (_, { accessPointRole }) => {
        return UnitGroupModel.routes.renderUniqueRowLink(accessPointRole?.unitGroup);
      },
    },
    {
      title: 'Schedule',
      render: (_, { accessPointRole, role }) => {
        const canBypassSchedule = role.rolePermissions.some(rp => (
          rp.permission.key === PermissionKey_enum.AccessPoint_BypassSchedule
        ));

        if (canBypassSchedule) {
          return (
            <Tag color={Color.Green} style={{ fontWeight: 'bold' }}>
              BYPASS SCHEDULE
            </Tag>
          );
        }

        const isCustomSchedule = !!accessPointRole?.schedule;

        return (
          <>
            <div style={{ marginBottom: isCustomSchedule ? 5 : 0 }}>
              <Tag
                color={isCustomSchedule ? Color.Blue : undefined}
                style={{ fontWeight: 'bold' }}
              >
                {isCustomSchedule ? 'CUSTOM SCHEDULE' : 'DEFAULT SCHEDULE'}
              </Tag>
            </div>
            {isCustomSchedule && (
              <Timetable
                timetable={accessPointRole?.schedule || accessPoint.timetable}
                filter={r => !r.state || r.state === TimetableRuleState.CONTROLLED}
                twentyFourSevenElement={'24/7 access'}
              />
            )}
          </>
        );
      },
    },
    {
      title: 'Actions',
      render: (_, { accessPointRole, role }) => {
        const {
          isUnitRole, canBypassSchedule, hasStaffAccess,
        } = getRoleAttributes(role.roleId, [role]);

        const actions: IModelAction<IAccessPointRoleRow>[] = [
          {
            enabledByModel: () => true,
            enabledByRow: () => isUnitRole || !canBypassSchedule,
            render() {
              return (
                <a onClick={(e) => {
                  e.preventDefault();
                  onEditRow({ accessPointRole, selectedRoleId: role.roleId });
                }}>
                  Edit
                </a>
              );
            },
          },
        ];

        if (accessPointRole) {
          actions.push({
            ...AccessPointRoleModel.actions.deleteAction,
            label: () => hasStaffAccess || role.permissionScope === PermissionScope_enum.ACCESS_POINT
              ? 'Remove Custom Schedule'
              : 'Delete',
          });
        }

        return (
          <RefetchContext.Provider value={refetch}>
            <TableActions
              row={accessPointRole}
              actions={readOnly ? [] : actions}
            />
          </RefetchContext.Provider>
        );
      },
    }
  ];

  const filteredColumns = typeof filterColumns === 'function'
    ? columns.filter(filterColumns)
    : columns;

  return (
    <Table<IAccessPointRoleRow>
      rowKey={({ accessPointRole, role }) => (
        accessPointRole?.accessPointRoleId || role.roleId
      )}
      dataSource={rows}
      columns={filteredColumns}
      pagination={false}
    />
  );
};

export default AccessPointRoleTable;
