import {
  PermissionKey_enum,
  PermissionScope_enum,
  SmartLockUserRolesTableFragment,
  SmartLockUserRolesTableFragmentDoc,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { createdAtColumn } from '../../common/columns';
import PERMISSION_SCOPE_ICON_MAP from '../../helpers/permission-scopes';
import * as columns from '../columns';
import model from '../model';

import { commonTableConfig } from './common';

export const isSmartLockScopeReadable = () => (
  authentication.hasPermission(PermissionKey_enum.User_Read) ||
  authentication.readableRoleScopes.includes(PermissionScope_enum.SMART_LOCK)
);

export const SmartLockUserRolesTable = model.createTable<SmartLockUserRolesTableFragment>({
  ...commonTableConfig,
  title: 'Smart Lock Roles',
  enabled: isSmartLockScopeReadable,
  icon: PERMISSION_SCOPE_ICON_MAP[PermissionScope_enum.SMART_LOCK],
  fragment: SmartLockUserRolesTableFragmentDoc,
  fixedQueryFilters: () => ({
    scopedSmartLockId: { _is_null: false },
  }),
  columns: [
    columns.statusColumn,
    columns.smartLockRoleColumn,
    columns.managedWithinPropertyColumn,
    columns.smartLockUnitColumn,
    columns.smartLockColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});

export const SmartLockUserRolesBySmartLockTable = model.createTable<
  SmartLockUserRolesTableFragment, string
>({
  ...commonTableConfig,
  title: 'Smart Lock Roles',
  enabled: isSmartLockScopeReadable,
  fragment: SmartLockUserRolesTableFragmentDoc,
  fixedQueryFilters: (smartLockId) => ({
    scopedSmartLockId: { _eq: smartLockId },
    status: {
      _in: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
    },
  }),
  columns: [
    columns.statusColumn,
    columns.smartLockRoleColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});

export const SmartLockUserRolesByUnitTable = model.createTable<
  SmartLockUserRolesTableFragment, string
>({
  ...commonTableConfig,
  title: 'Smart Lock Roles',
  enabled: isSmartLockScopeReadable,
  fragment: SmartLockUserRolesTableFragmentDoc,
  fixedQueryFilters: (unitId) => ({
    scopedSmartLock: {
      unitId: { _eq: unitId },
    },
    status: {
      _in: [UserRoleStatus_enum.PENDING, UserRoleStatus_enum.ACTIVE],
    },
  }),
  columns: [
    columns.statusColumn,
    columns.smartLockRoleColumn,
    columns.smartLockColumn,
    columns.assignedToColumn,
    columns.grantedByColumn,
    columns.activationDateColumn,
    columns.expirationDateColumn,
    createdAtColumn,
  ],
});
