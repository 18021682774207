import { Alert, Card, Table, Tabs, Typography } from 'antd';
import { FormikProps } from 'formik';
import { orderBy, partition } from 'lodash';
import pluralize from 'pluralize';
import React from 'react';

import { IModel, IModelFormField, RowImportStatus } from '../../models/typings';

import { createInvalidRowColumns, createValidRowColumns, IInvalidRow, IValidRow } from './columns';
import { IModelImportFormValues } from './formik';
import { ModelImportProgress } from './ModelImportProgress';

interface IModelImportTableProps {
  model: IModel<any>;
  importFields: IModelFormField<any>[];
  formikProps: FormikProps<IModelImportFormValues>;
  submitting: boolean;
}

export const ModelImportTables: React.FC<IModelImportTableProps> = (props) => {
  const { model, importFields, formikProps, submitting } = props;
  const { values } = formikProps;

  if (!model.formOptions.importOptions) {
    return null;
  }

  const invalidRowColumns = createInvalidRowColumns(importFields);
  const validRowColumns = createValidRowColumns(model);

  const [invalidRows, validRows] = partition(values.rows, r => !!r.errors.length);

  const invalidRowsDataSource: IInvalidRow[] = invalidRows.map(({ rowNumber, parsedRow, errors }) => ({
    rowNumber,
    ...parsedRow,
    errors,
  }));

  const validRowsDataSource: IValidRow[] = validRows.map(({ rowNumber, transformedRow, importState }) => ({
    rowNumber,
    importState,
    ...transformedRow,
  }));


  const sortedValidRowsDataSource = orderBy(validRowsDataSource, [
    r => r.importState.status === RowImportStatus.ERROR ? 0 : 1,
    r => r.rowNumber,
  ], ['asc', 'asc']);

  const totalRows = values.rows.length || 0;
  const invalidRowsCount = invalidRowsDataSource.length;
  const validRowsCount = validRowsDataSource.length;

  return (
    <Card
      title={values.parsingRows ? 'Loading CSV file...' : `Total Rows: ${totalRows}`}
      loading={values.parsingRows}
      bordered={false}
      extra={values.file?.name}
    >
      {invalidRowsCount > 0 && (
        <Alert
          showIcon
          type='error'
          message={(
            `${invalidRowsCount} invalid ${pluralize('row', invalidRowsCount)} found in the selected file`
          )}
          style={{ marginBottom: 25 }}
        />
      )}
      {validRowsCount > 0 && (
        <Alert
          showIcon
          type='success'
          message={
            validRowsCount === totalRows
              ? 'All rows in the selected file are valid!'
              : `${validRowsCount} valid ${pluralize('row', validRowsCount)} found in the selected file`
          }
          style={{ marginBottom: 25 }}
        />
      )}
      <Tabs
        animated={false}
        defaultActiveKey={invalidRowsCount > 0 ? 'INVALID_ROWS' : 'VALID_ROWS'}
        size='large'
      >
        <Tabs.TabPane
          key='INVALID_ROWS'
          tab='Invalid Rows'
          disabled={invalidRowsCount === 0}
        >
          <Typography.Paragraph style={{ marginTop: 15, marginBottom: 25 }}>
            Please correct the following rows in the CSV and re-upload the file.
            If we find an existing record in Chirp, the matching CSV row will be skipped during the import.
          </Typography.Paragraph>
          <Table
            columns={invalidRowColumns}
            dataSource={invalidRowsDataSource}
            rowKey={(row) => row.rowNumber}
            scroll={{ x: true }}
            pagination={{ defaultPageSize: 25 }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key='VALID_ROWS'
          tab='Valid Rows'
          disabled={validRowsCount === 0}
        >
          <ModelImportProgress
            model={model}
            validRows={validRows}
            formikProps={formikProps}
            submitting={submitting}
          />
          <Table
            columns={validRowColumns}
            dataSource={sortedValidRowsDataSource}
            rowKey={(row) => row.rowNumber}
            scroll={{ x: true }}
            pagination={{ defaultPageSize: 25 }}
          />
        </Tabs.TabPane>
      </Tabs>
    </Card>
  );
};

export default ModelImportTables;
