import { Alert, Button, Checkbox, Divider } from 'antd';
import { FormikProps } from 'formik';
import { startCase } from 'lodash';
import React from 'react';

type SubmitFormSectionProps = FormikProps<{
  acknowledged: boolean;
}>;

export const SubmitFormSection: React.FC<SubmitFormSectionProps> = (props) => {
  const { setFieldValue, values: formikValues, errors: formikErrors, isSubmitting } = props;
  const { acknowledged } = formikValues;

  return (
    <section style={{
      overflow: 'hidden',
      marginBottom: 20,
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'center',
    }}>
      <Divider />
      {acknowledged && !!Object.values(formikErrors).length && (
        <Alert
          type='error'
          showIcon
          style={{ marginBottom: 20 }}
          message='Unable to submit. Please revise:'
          description={
            Object.entries(formikErrors).map(([key, errorMessage]: [string, any], i) => {
              const CUSTOM_LABELS: { [key: string]: string } = {
                userId: 'User',
                nickname: 'Name',
                permissionScope: 'Scope',
                scopedIds: 'Scope',
                smartLockIds: 'Smart Locks',
                activatesAt: 'Activation Date',
                expiresAt: 'Expiration Date',
              };

              const label = CUSTOM_LABELS[key] || startCase(key);

              return (
                <div key={i}>
                  <strong>{label}:</strong> {errorMessage}
                </div>
              );
            })
          }
        />
      )}

      <Checkbox
        checked={acknowledged}
        disabled={isSubmitting}
        onChange={(e) => setFieldValue('acknowledged', e.target.checked)}
        style={{ marginBottom: 20 }}
      >
        I've reviewed my selections and verified that all the provided information is correct
      </Checkbox>

      <Button
        type='primary'
        size='large'
        onClick={() => props.handleSubmit()}
        loading={isSubmitting}
        disabled={!!Object.values(formikErrors).length || !acknowledged || isSubmitting}
      >
        {isSubmitting ? 'Submitting' : 'Submit'}
      </Button>
    </section>
  );
};
