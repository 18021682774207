import { CredentialRequiredRowFragmentDoc } from '../../graphql/hasura/generated';

import model from './model';

const formFields = model.createFormFields([
  {
    fieldName: 'identifier',
    maxLength: 255,
  },
]);

model.setFormOptions({
  fields: formFields,
  requiredRowFragment: CredentialRequiredRowFragmentDoc,
});
