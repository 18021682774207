import { uniq } from 'lodash';

export default function generateWordsFromSearch(search?: string | null): string[] {
  const searchstr = typeof search === 'string' && !!search && search || '';

  if (!searchstr) {
    return [];
  }

  const searchWords = searchstr
    // clean non valid chars of entire query
    // only allow words with:
    // a-z (lower case letters)
    // A-Z (upper case letters)
    // 0-9 numbers
    // spaces and tabs (we'll then remove extra spaces)
    // special characters: @ & * ( ) - _ + : ; " ' , . /
    .replace(/[^a-zA-Z0-9\s\@\&\*\(\)\-\_\+\:\;\"\'\,\.\/]/g, '')

    // Avoid hexadecimal injections through xss
    .replace(/0x[0-9A-F]+/ig, '')

    // Strip extra spaces
    .replace(/\s/g, ' ')

    // trim side spaces
    .trim()

    // split intro words
    .split(' ')

    // remove empty entries
    .filter((wd: string) => !!wd.trim())

    // replace certain words
    .map((word: string) => {
      if (word.match(/^[0-9a-z]{32}$/)) {
        return `${word.substr(0, 8)}-${word.substr(8, 4)}-${word.substr(12, 4)}-${word.substr(16, 4)}-${word.substr(20)}`;
      }
      return word;
    });

  return uniq(searchWords);
}
