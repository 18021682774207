import { useQuery, WatchQueryFetchPolicy } from '@apollo/client';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { DocumentNode } from 'graphql';
import { lowerCase } from 'lodash';
import React, { useEffect, useState } from 'react';

import { generateListQuery } from '../../graphql';
import { IModel } from '../../models/typings';
import { generateWordsFromSearch } from '../../utils';

export interface IModelSelectProps extends SelectProps<any> {
  model: IModel;
  value: string | string[] | null | undefined;
  queryFilters?: { [key: string]: any };
  eagerLoading?: boolean;
  required?: boolean;
  multiple?: boolean;
  readonly?: boolean;
  labelFragment?: DocumentNode;
  getLabel?: (option: any) => React.ReactNode;
  fetchPolicy?: WatchQueryFetchPolicy;
  autoSelectSingleResult?: boolean;
}

export const ModelSelect: React.FC<IModelSelectProps> = (props) => {
  const {
    model, value, queryFilters, eagerLoading, required, multiple, labelFragment, getLabel,
    allowClear, readonly, disabled, onChange, fetchPolicy, autoSelectSingleResult, ...otherProps
  } = props;
  const modelName = model.names.schemaName;

  const [search, setSearch] = useState('');
  const [touched, setTouched] = useState(false);

  const searchBoolExpressions: any[] = queryFilters ? [queryFilters] : [];

  const getOptionLabel = (option: any) => {
    try {
      return typeof getLabel === 'function'
        ? getLabel(option)
        : model.labels.getUniqueLabel(option);
    } catch {
      // Use this as a fallback in case there's an issue with the
      // label fragment/callback (to prevent the entire app from breaking)
      return option[model.primaryKey];
    }
  };

  if (search && model.queryOptions.getSearchConditions) {
    const searchWords = generateWordsFromSearch(search);

    searchBoolExpressions.push(model.queryOptions.getSearchConditions(searchWords));
  }

  const listQuery = generateListQuery(model, labelFragment || model.labels.uniqueLabelFragment);

  if (value) {
    const operator = Array.isArray(value) ? '_nin' : '_neq';

    searchBoolExpressions.push({
      [model.primaryKey]: { [operator]: value },
    });
  }

  const selectedIds = Array.isArray(value) ? value : (value ? [value] : []);
  const skipSearchQuery = !eagerLoading && !autoSelectSingleResult && !touched;
  const skipSelectionsQuery = !selectedIds.length;

  // Get search options
  const searchQueryResult = useQuery(listQuery, {
    fetchPolicy: fetchPolicy || 'cache-first',
    skip: skipSearchQuery,
    variables: {
      limit: 20,
      offset: 0,
      where: { _and: searchBoolExpressions },
      order_by: model.queryOptions.defaultSelectOrderBy,
    },
  });

  // Get selected options
  const selectionsQueryResult = useQuery(listQuery, {
    fetchPolicy: fetchPolicy || 'cache-first',
    skip: skipSelectionsQuery,
    variables: {
      where: {
        [model.primaryKey]: { _in: selectedIds },
      },
    },
  });

  const selectionsData = skipSelectionsQuery
    ? undefined // Avoid pulling from previous cache when the data should be skipped
    : selectionsQueryResult.data || selectionsQueryResult.previousData

  const searchData = skipSearchQuery
    ? undefined // Avoid pulling from previous cache when the data should be skipped
    : searchQueryResult.data || searchQueryResult.previousData;

  const selectedOptions: any[] = (selectionsData && selectionsData[modelName]) || [];
  const searchOptions: any[] = (searchData && searchData[modelName]) || [];

  const loading = searchQueryResult.loading || selectionsQueryResult.loading;

  const placeholder = readonly
    ? ''
    : `Search for ${lowerCase(multiple ? model.names.pluralDisplayName : model.names.displayName)}`;

  useEffect(() => {
    if (!touched && autoSelectSingleResult && searchOptions.length === 1) {
      const [option] = searchOptions;
      const id = option[model.primaryKey];
      const nextValue = multiple ? [id] : id;

      if (typeof onChange === 'function') {
        onChange(nextValue, option);
        setTouched(true);
      }
    }
  }, [searchOptions.length]);

  return (
    <Select
      {...otherProps}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      value={value}
      searchValue={search}
      placeholder={loading ?  'Loading...' : placeholder}
      loading={loading}
      disabled={disabled}
      notFoundContent={(searchQueryResult.loading && !disabled) ? <Spin /> : null}
      onFocus={() => setTouched(true)}
      onChange={(nextValue, option) => {
        if (typeof onChange === 'function') {
          onChange(nextValue, option);
        }

        setSearch('');
      }}
      allowClear={allowClear === true || !required}
      filterOption={false}
      onSearch={setSearch}
      virtual={false}
    >
      {selectedOptions.map((option, index) => {
        const id = option[model.primaryKey] || index;

        // We need to fetch and render the selected option(s) to keep the labels available.
        // But it will be easier to select multiple options when the selected options are hidden.
        const optionStyle = multiple ? { display: 'none' } : undefined;

        return (
          <Select.Option key={`selected-option-${id}`} value={id} style={optionStyle}>
            {getOptionLabel(option)}
          </Select.Option>
        );
      })}
      {searchOptions.map((option, index) => {
        const id = option[model.primaryKey] || index;

        // This helps multiple selection feel immediate and prevents an issue where Ant Design
        // renders the option as selected but without a label (i.e., just the ID)
        const optionStyle = multiple && selectedIds.includes(id) ? { display: 'none' } : undefined;

        return (
          <Select.Option key={`search-option-${id}`} value={id} style={optionStyle}>
            {getOptionLabel(option)}
          </Select.Option>
        );
      })}
    </Select>
  );
}
