import { ExportOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { snakeCase } from 'lodash';
import React from 'react';

import { useCSVDownloader } from '../../hooks/useCsvDownloader';
import { IChirpTableColumn } from '../../models/typings';

import { buildCsv } from './helpers';

interface ICsvExportButtonProps {
  title: string;
  rows: any[];
  columns: IChirpTableColumn[];
  disabled?: boolean;
  buttonStyle?: React.CSSProperties;
}

export const CsvExportButton = (props: ICsvExportButtonProps) => {
  const { title, rows, columns, disabled, buttonStyle } = props;
  const { CSVDownloader, Type } = useCSVDownloader();

  // We can't pass button attributes like "disabled" to CSVDownloader,
  // so we have to render a separate button to show a disabled state
  if (disabled || !rows.length) {
    return (
      <Button
        disabled
        icon={<ExportOutlined />}
        style={buttonStyle}
      >
        Export to CSV
      </Button>
    );
  }

  return (
    <CSVDownloader
      bom={true} /* Excel support */
      type={Type.Button}
      data={async () => {
        const csvString = await buildCsv({ rows, columns });

        return csvString;
      }}
      filename={snakeCase(title)}
      className='ant-btn'
      disabled={disabled}
      style={buttonStyle}
    >
      <ExportOutlined />
      <span>Export to CSV</span>
    </CSVDownloader>
  );
}

export default CsvExportButton;
