import { Tag } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { PermissionKey_enum, RoleLabelFragment } from '../../../../graphql/hasura/generated';
import { authentication } from '../../../../stores';
import { Color } from '../../../../utils';
import { RoleModel } from '../../../Role/model';
import { UserRoleModel } from '../../../UserRole/model';

export function hasGlobalUserReadPermission() {
  return authentication.hasPermission(PermissionKey_enum.User_Read);
}

interface IRenderChirpRoleArgs {
  role: RoleLabelFragment | null | undefined;
  userRole: { userRoleId: string } | null | undefined;
}

export function renderChirpRole(args: IRenderChirpRoleArgs) {
  const { role, userRole } = args;

  if (!role) {
    return <Tag color={Color.Red} style={{ fontWeight: 600 }}>NONE</Tag>;
  }

  // Display role instead of userRole.role in case UserRole cannot be read
  const roleLabel = RoleModel.labels.getLabel(role);
  const userRoleLink = userRole ? UserRoleModel.routes.getRowLink(userRole) : null;

  return userRoleLink ? <Link to={userRoleLink}>{roleLabel}</Link> : roleLabel;
}
