import { Modal, notification } from 'antd';
import React, { useState } from 'react';

import {
  FeatureFlagKey_enum,
  FeatureFlagLabelFragment,
  PermissionKey_enum,
  PropertyFeatureFlagLabelFragment as PropertyFeatureFlag,
  usedeletePropertyFeatureFlagsMutation,
  useupsertPropertyFeatureFlagsMutation,
} from '../../../graphql/hasura/generated';
import { hasuraClient } from '../../../graphql/hasura/hasuraClient';
import { authentication } from '../../../stores';
import { displayErrorMessage } from '../../../utils';

import EditPropertyFeatureFlags from './EditPropertyFeatureFlags';

interface IPropertyFeatureFlagModalProps {
  propertyId: string;
  propertyFeatureFlags: PropertyFeatureFlag[];
}

const PropertyFeatureFlagModal = (props: IPropertyFeatureFlagModalProps) => {
  const { propertyFeatureFlags, propertyId } = props;
  const currentFeatureFlagKeys = propertyFeatureFlags.map(p => (
    p.featureFlagKey
  )) as FeatureFlagKey_enum[];

  const [selectedFeatureFlagKeys, setSelectedFeatureFlagKeys] = useState<FeatureFlagKey_enum[]>([
    ...currentFeatureFlagKeys,
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [upsertPropertyFeatureFlags] = useupsertPropertyFeatureFlagsMutation({
    variables: {
      objects: selectedFeatureFlagKeys.map(key => ({ propertyId, featureFlagKey: key })),
    },
  });

  const featureFlagKeysToDelete = currentFeatureFlagKeys.filter(k => (
    !selectedFeatureFlagKeys.includes(k) // Feature flags that were deselected
  ));

  const [deletePropertyFeatureFlags] = usedeletePropertyFeatureFlagsMutation({
    variables: {
      where: {
        propertyId: { _eq: propertyId },
        featureFlagKey: { _in: featureFlagKeysToDelete },
      },
    },
  });

  const onSelectAllFeatureFlagKeys = (
    selected: boolean,
    selectedRows: FeatureFlagLabelFragment[],
  ) => {
    const nextSelectedFeatureFlagKeys = selected ? selectedRows.map(s => s.key) : [];

    setSelectedFeatureFlagKeys(nextSelectedFeatureFlagKeys);
  };

  const onToggleFeatureFlagKey = (key: FeatureFlagKey_enum) => {
    setSelectedFeatureFlagKeys(prevKeys => {
      const prevSelected = prevKeys.some(k => k === key);
      const nextSelected = !prevSelected; // Toggle selection

      return nextSelected ? [...prevKeys, key] : prevKeys.filter(k => k !== key);
    });
  };

  const canManage = authentication.hasPermission(PermissionKey_enum.Property_ManageFeatureFlags);

  return (
    <>
      {canManage && (
        <a
          title='Edit'
          onClick={(e) => {
            e.preventDefault();
            setIsModalVisible(true);
          }}
        >
          Edit
        </a>
      )}
      <Modal
        title={null}
        visible={isModalVisible}
        maskClosable
        width='60%'
        cancelText='Cancel'
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedFeatureFlagKeys([...currentFeatureFlagKeys]); // Reset selection
        }}
        okText='Save'
        okButtonProps={{ disabled: isSaving }}
        onOk={async () => {
          setIsSaving(true);

          if (
            selectedFeatureFlagKeys.includes(FeatureFlagKey_enum.GATEWAY_PIN_CODES) &&
            !selectedFeatureFlagKeys.includes(FeatureFlagKey_enum.GATEWAY_ACCESS_MANAGER)
          ) {
            displayErrorMessage(new Error('Gateway PIN Codes cannot be enabled without Gateway Access Manager'));
            setIsSaving(false);
            return;
          }

          if (
            selectedFeatureFlagKeys.includes(FeatureFlagKey_enum.GATEWAY_ACCESS_MANAGER) &&
            selectedFeatureFlagKeys.includes(FeatureFlagKey_enum.GATEWAY_MOBILE_ENHANCER)
          ) {
            displayErrorMessage(new Error('Gateway Access Manager and Gateway Mobile Enhancer cannot both be selected'));
            setIsSaving(false);
            return;
          }

          if (propertyId && featureFlagKeysToDelete.length) {
            await deletePropertyFeatureFlags();
          }

          await upsertPropertyFeatureFlags();
          await hasuraClient.resetStore();

          setIsModalVisible(false);
          setIsSaving(false);

          notification.success({
            message: `The property's feature flags were saved successfully!`,
          });
        }}
      >
        <EditPropertyFeatureFlags
          selectedFeatureFlagKeys={selectedFeatureFlagKeys}
          onToggleFeatureFlagKey={onToggleFeatureFlagKey}
          onSelectAllFeatureFlagKeys={onSelectAllFeatureFlagKeys}
        />
      </Modal>
    </>
  );
}

export default PropertyFeatureFlagModal;
