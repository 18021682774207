import { UnitGroupTableFragment, UnitGroupTableFragmentDoc } from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';

import * as columns from './columns';
import model from './model';

export const UnitGroupsMainTable = model.createTable<UnitGroupTableFragment>({
  fragment: UnitGroupTableFragmentDoc,
  columns: [
    columns.propertyColumn,
    columns.parentUnitGroupColumn,
    columns.nameColumn,
    columns.sourceIdColumn,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});

export const ChildUnitGroupsTable = model.createTable<UnitGroupTableFragment, string>({
  title: 'Child Unit Groups',
  fixedQueryFilters: (unitGroupId) => ({
    parentUnitGroupId: { _eq: unitGroupId },
  }),
  fragment: UnitGroupTableFragmentDoc,
  columns: [
    columns.propertyColumn,
    columns.nameColumn,
    columns.sourceIdColumn,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});
