import { PermissionScope_enum } from '../../graphql/hasura/generated';

import model from './model';

model.routes.setRoutes({
  defaultRoute: {
    enabled: ({ currentPermissionScope }) => (
      currentPermissionScope === PermissionScope_enum.GLOBAL ||
      currentPermissionScope === PermissionScope_enum.ORGANIZATION
    ),
  },
});
