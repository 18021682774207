import { FormikBag, FormikErrors, FormikProps } from 'formik';

import { apiClient } from '../../../graphql/api/apiClient';
import { PermissionScope_enum, UserRoleReason_enum } from '../../../graphql/hasura/generated';
import { hasuraClient } from '../../../graphql/hasura/hasuraClient';
import { createGuestPass } from '../../../graphql/hasura/operations';
import { IModel } from '../../../models/typings';
import history from '../../../routes/history';
import { displayErrorMessage } from '../../../utils';
import { ISharedFormValues, validateSharedFormValues } from '../shared-form/formik';

const MAX_SMART_LOCKS_PER_REQUEST = 25;

export interface ISgtUserFormProps {
  UserRoleModel: IModel;
  PropertyModel: IModel;
}

export interface ISgtUserFormValues extends ISharedFormValues {
  nickname?: string | null;

  enablePropertyPinCode: boolean;
  smartLockIds: string[];
}

export interface ISgtUserFormMergedProps extends
  ISgtUserFormProps, FormikProps<ISgtUserFormValues> {}

export function mapPropsToValues(props: ISgtUserFormProps): ISgtUserFormValues {
  return {
    permissionScope: PermissionScope_enum.PROPERTY, // Satisfy props for AdditionalContextSection
    scopedIds: [],
    enablePropertyPinCode: false,
    smartLockIds: [],
    activatesAt: new Date(),
    reason: UserRoleReason_enum.PROSPECT_TOUR,
    acknowledged: false,
  };
}

export function validate(values: ISgtUserFormValues, props: ISgtUserFormProps) {
  const errors: FormikErrors<ISgtUserFormValues> = {};

  if (!values.acknowledged) {
    return errors;
  }

  if (!values.nickname) {
    errors.nickname = `Please enter the guest's name`;
  }

  if (!values.scopedIds.length) {
    // @ts-ignore (ModelFormSelect does not handle array)
    errors.scopedIds = `Please select a property`;
  }

  if (values.smartLockIds.length > MAX_SMART_LOCKS_PER_REQUEST) {
    // @ts-expect-error (ModelFormSelect does not handle array)
    errors.smartLockIds = `A maximum of ${MAX_SMART_LOCKS_PER_REQUEST} smart locks may be selected`;
  }

  return { ...errors, ...validateSharedFormValues(values) };
}

export async function handleSubmit(
  values: ISgtUserFormValues,
  formikBag: FormikBag<ISgtUserFormProps, ISgtUserFormValues>
) {
  try {
    const userRoleId = await createGuestPass({
      input: {
        scopedPropertyId: values.scopedIds[0] as string,
        nickname: values.nickname as string,
        activatesAt: values.activatesAt.toISOString(),
        expiresAt: values.expiresAt?.toISOString() as string,
        reason: values.reason,
        notes: values.notes,
        enablePropertyPinCode: values.enablePropertyPinCode,
        scopedSmartLockIds: values.smartLockIds,
      },
    });

    // Ensure that cached tables refetch data when redirected
    await hasuraClient.resetStore();
    await apiClient.resetStore();

    if (userRoleId) {
      history.push(`/assigned-roles/details/${userRoleId}`);
    }
  } catch (error) {
    formikBag.setSubmitting(false);
    displayErrorMessage(error);
  }
}
