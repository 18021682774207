import validator from 'validator';

import {
  Beacon_bool_exp,
  BeaconLabelFragment,
  BeaconLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import { emptySearchConditionsFilter } from '../../utils';
import AccessPointModel from '../AccessPoint/model';
import Model from '../Model';

export const BeaconModel = new Model<
  Beacon_bool_exp,
  BeaconLabelFragment
>({
  names: {
    schemaName: 'Beacon',
  },

  labels: {
    getLabel: ({ name, accessPoint }) => {
      let label = AccessPointModel.labels.getUniqueLabel(accessPoint);

      if (name && name !== accessPoint.name) {
        label += ` - ${name}`;
      }

      return label;
    },
    labelFragment: BeaconLabelFragmentDoc,
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            {
              accessPoint: {
                property: {
                  name: { _ilike: `%${word}%` },
                },
              },
            },
            {
              accessPoint: {
                name: { _ilike: `%${word}%` },
              },
            },
            {
              name: { _ilike: `%${word}%` },
            },
            {
              uuid: { _eq: (validator.isUUID(word) ? word : undefined) },
            },
            {
              macAddress: { _ilike: word },
            },
            {
              location: { _ilike: `%${word}%` },
            },
          ].filter(emptySearchConditionsFilter),
        }
      ))
    }),
  }
});

export default BeaconModel;
