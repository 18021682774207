import {
  GrantableRoleFragment,
  PermissionScope_enum,
  RoleKey_enum,
} from '../../../../graphql/hasura/generated';

import { IWarningFlags } from './typings';

export function filterQueriedRoles(
  queriedRoles: GrantableRoleFragment[],
  permissionScope: PermissionScope_enum | null | undefined,
  warningFlags: IWarningFlags,
) {
  let roles = [...queriedRoles];

  if (permissionScope === PermissionScope_enum.PROPERTY) {
    // Vendor PINs should not be assigned to registered users
    roles = roles.filter(r => r.key !== RoleKey_enum.VENDOR_PIN);
  } else if (permissionScope === PermissionScope_enum.ACCESS_POINT) {
    // Reservations should not be managed via the Chirp Admin Panel
    roles = roles.filter(r => r.key !== RoleKey_enum.RESERVATION);
  }

  if (warningFlags.controlledByPms) {
    // Resident access should not be manually managed for PMS property
    roles = roles.filter(r => r.key !== RoleKey_enum.RESIDENT);
  }

  if (warningFlags.someReservationOnly) {
    // Reservations should not be managed via the Chirp Admin Panel
    roles = [];
  } else if (!warningFlags.allAmenities) {
    // Cannot have a mix of amenity access
    roles = roles.filter(r => r.key !== RoleKey_enum.AMENITY_ACCESS);
  }

  return roles;
}
