import { useState } from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

interface IUseTableSelectorArgs {
  tableTitles: string[];
  useQueryParams?: boolean;
}

export function useTableSelector(args: IUseTableSelectorArgs) {
  const { tableTitles } = args;

  return args.useQueryParams
    ? useTableSelectorWithQueryParams(tableTitles)
    : useTableSelectorWithState(tableTitles);
}

function useTableSelectorWithState(tableTitles: string[]) {
  const [selectedTableTitle, selectTable] = useState<string>(tableTitles[0]);
  const selectedTable = tableTitles.find(title => title === selectedTableTitle);

  return { tableTitles, selectedTable, selectTable };
}

function useTableSelectorWithQueryParams(tableTitles: string[]) {
  const [query] = useQueryParams({ table: withDefault(StringParam, tableTitles[0]) });
  const history = useHistory();

  const selectedTable = tableTitles.find(title => title === query.table);

  const selectTable = (title: string) => {
    history.push(`${history.location.pathname}?table=${title}`);
  };

  return { tableTitles, selectedTable, selectTable };
}

export default useTableSelector;
