import { SortOrder } from 'antd/lib/table/interface';

import { IModel, IModelColumn } from '../typings';

interface IFormatSortableColumnArgs {
  column: IModelColumn<any>;
  model: IModel;
  orderBy?: { [key: string]: SortOrder } | null;
}

function isColumnSortable(column: IModelColumn<any>, model: IModel): boolean {
  if (column.sorter === false) {
    return false;
  }

  if (column.orderBy) {
    return true;
  }

  const schemaField = model.introspection.fields.find(f => f.name === column.dataIndex);

  if (schemaField) {
    const isNotPrimaryKey = schemaField.name !== model.primaryKey;
    const isSortableKind = ['int', 'string', 'timestamptz', 'boolean', 'uuid'].includes(schemaField.scalarName);

    if (isNotPrimaryKey && isSortableKind) {
      return true;
    }
  }

  return false;
}

export function formatSortableColumn(args: IFormatSortableColumnArgs): IModelColumn<any> {
  const { column, model, orderBy } = args;
  const sortOrder = orderBy && column.dataIndex ? orderBy[column.dataIndex] : undefined;

  if (isColumnSortable(column, model)) {
    return {
      ...column,
      sortOrder,
      sorter: true,
    };
  }

  return column;
}
