import {
  PermissionKey_enum,
  PermissionScope_enum,
  UserActionFragmentDoc,
  UsersMainTableFragment,
  UsersMainTableFragmentDoc,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import { createdAtColumn } from '../common/columns';

import * as actions from './actions';
import * as columns from './columns';
import model from './model';

model.actions.setDefaultActions([
  actions.grantAccessAction,
], UserActionFragmentDoc);

export const UsersMainTable = model.createTable<UsersMainTableFragment>({
  fragment: UsersMainTableFragmentDoc,
  columns: [
    columns.accountStatusColumn,
    columns.nameColumn,
    columns.phoneNumberColumn,
    columns.emailColumn,
    columns.hasAugustAccountColumn,
    columns.hasPasswordColumn,
    columns.assignedRolesColumn,
    createdAtColumn,
  ],
  fixedQueryFilters: () => {
    if (
      // Filter users for global roles, if necessary (since it will not be filtered by "admin" role)
      authentication.currentDataScope.permissionScope === PermissionScope_enum.GLOBAL &&
      !authentication.hasPermission(PermissionKey_enum.User_Read)
    ) {
      return {
        userId: { _eq: authentication.chirpUserId },
      };
    }

    return {};
  },
});
