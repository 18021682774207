/**
 * Used as a filter to deep search through an object to see any keys have an undefined property.
 * @param obj
 * @returns `true` if nested object has any values of undefined
 */
export default function emptySearchConditionsFilter(obj: { [k: string]: any }): boolean | undefined {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value: any = obj[key];
      if (typeof value === 'object') {
        return emptySearchConditionsFilter(value)
      }

      return (value !== undefined && value !== null);
    }
  }
}
