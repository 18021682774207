import { Card, Col, Row, Statistic, Tag } from 'antd';
import numeral from 'numeral';
import React from 'react'

import { ResidentAuditFragment as ResidentAudit } from '../../graphql/api/generated';

import { ELIGIBILITY_COLORS, STATISTIC_COL_STYLE } from './constants';

interface IResidentAuditStatisticsProps {
  residentAudits: ResidentAudit[];
  loading: boolean;
}

const ResidentAuditStatistics = (props: IResidentAuditStatisticsProps) => {
  const { residentAudits, loading } = props;

  const eligibleResidents = residentAudits.filter(r => r.eligibility === 'Eligible');
  const dataScrubResidents = residentAudits.filter(r => r.eligibility === 'Data Scrub');
  const ineligibleResidents = residentAudits.filter(r => r.eligibility === 'Ineligible');

  return (
    <Card bordered={false} loading={loading}>
      <Row>
        <Col style={{ ...STATISTIC_COL_STYLE }}>
          <Statistic title='Total Residents' value={residentAudits.length} />
        </Col>
        {[
          {
            title: 'Eligible Residents',
            color: ELIGIBILITY_COLORS.Eligible,
            residents: eligibleResidents,
            alwaysShowSuffix: true,
          },
          {
            title: 'Data Scrub Required',
            color: ELIGIBILITY_COLORS['Data Scrub'],
            residents: dataScrubResidents,
          },
          {
            title: 'Ineligible Residents',
            residents: ineligibleResidents,
          },
        ].map(({ title, color, residents, alwaysShowSuffix }) => {
          const importedResidents = residents.filter(r => Boolean(r.userRole?.assignedToUser));
          const suffix = residentAudits.length && (importedResidents.length || alwaysShowSuffix)
            ? `(${numeral(importedResidents.length).format('1,000')} imported)`
            : null;

          return (
            <Col key={title} style={{ ...STATISTIC_COL_STYLE }}>
              <Statistic
                title={(
                  <Tag color={color} style={{ fontWeight: 'bold' }}>
                    {title}
                  </Tag>
                )}
                value={residents.length}
                suffix={suffix ? <span style={{ fontSize: '13px' }}>{suffix}</span> : null}
              />
            </Col>
          );
        })}
      </Row>
    </Card>
  );
}

export default ResidentAuditStatistics;
