import React from 'react';

import { IModel } from '../../models/typings';
import { TextWithIconTooltip } from '../TextWithIconTooltip';

interface IModelIdNoPermissionProps {
  model: IModel;
  id: string;
}

export const ModelIdNoPermission: React.FC<IModelIdNoPermissionProps> = (props) => {
  const { model, id } = props;

  return (
    <TextWithIconTooltip
      text={model.labels.getIdLabel({ [model.primaryKey]: id })}
      tooltip={{
        title: `You do not have permission to view this ${model.names.displayName.toLowerCase()}`,
      }}
    />
  );
}

export default ModelIdNoPermission;
