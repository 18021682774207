import { Permission_bool_exp } from '../../graphql/hasura/generated';
import Model from '../Model';

export const PermissionModel = new Model<Permission_bool_exp>({
  names: {
    schemaName: 'Permission',
  },

  permissionsOptions: {
    canRead: () => true,
  },
});

export default PermissionModel;
