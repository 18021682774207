import { Button, Col, List, Result, Row, Typography } from 'antd';
import React from 'react';

import PERMISSION_SCOPE_ICON_MAP from '../../../models/helpers/permission-scopes';

import { IRegisteredUserFormMergedProps } from './formik';

const NUM_SCOPES_TO_SPAN_MAP: { [key: number]: number } = {
  1: 24,
  2: 12,
  3: 8,
  4: 6,
  5: 8,
};

export const SelectPermissionScope: React.FC<IRegisteredUserFormMergedProps> = (props) => {
  const {
    presetPermissionScope, presetScopedIds, enabledScopeOptions, setFieldValue,
    values: formikValues, isSubmitting,
  } = props;
  const { permissionScope } = formikValues;

  return (
    <>
      <Typography.Title level={4}>Select scope of access</Typography.Title>
      <Typography.Paragraph>
        Please select an appropriate scope of access for the user based on the desired role/permissions.
      </Typography.Paragraph>
      <Row justify='center' align='middle'>
        {enabledScopeOptions.map(({ title, scope, description }) => (
          <Col span={NUM_SCOPES_TO_SPAN_MAP[enabledScopeOptions.length]} key={title}>
            <Result
              icon={PERMISSION_SCOPE_ICON_MAP[scope]}
              status='info'
              title={title}
              subTitle={
                <List
                  dataSource={description}
                  renderItem={d => (
                    <List.Item style={{ border: 'none', padding: '0' }}>
                      <List.Item.Meta description={d} />
                    </List.Item>
                  )}
                />
              }
              extra={[
                <Button
                  type={permissionScope === scope ? 'primary' : 'default'}
                  key='console'
                  disabled={isSubmitting}
                  onClick={() => {
                    if (scope !== permissionScope) {
                      let nextScopedIds: string[] = [];

                      if (scope === presetPermissionScope && presetScopedIds) {
                        nextScopedIds = presetScopedIds;
                      }

                      // Reset
                      setFieldValue('scopedIds', nextScopedIds);
                      setFieldValue('role', null);
                      setFieldValue('expiresAt', null);
                      setFieldValue('reason', null);
                      setFieldValue('notes', null);

                      setFieldValue('permissionScope', scope);
                    }
                  }}
                >
                  {permissionScope === scope ? 'Selected' : 'Select'}
                </Button>
              ]}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};
