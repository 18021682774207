import { SortOrder } from 'antd/lib/table/interface';

export function alphanumericSorter<TValue = string | number>(
  valueA: TValue | null | undefined,
  valueB: TValue | null | undefined,
): number {
  if (valueA === valueB) {
    return 0;
  }

  if (valueA === null || valueA === undefined || Number.isNaN(valueA)) {
    return -1;
  }

  if (valueB === null || valueB === undefined || Number.isNaN(valueB)) {
    return 1;
  }

  return valueA > valueB ? 1 : -1;
}

export function reverseAlphanumericSorter<TValue = string | number>(
  valueA: TValue | null | undefined,
  valueB: TValue | null | undefined,
  sortOrder: SortOrder | undefined,
): number {
  if (valueA === valueB) {
    return 0;
  }

  if (valueA === null || valueA === undefined || Number.isNaN(valueA)) {
    return sortOrder === 'ascend' ? 1 : -1;
  }

  if (valueB === null || valueB === undefined || Number.isNaN(valueB)) {
    return sortOrder === 'ascend' ? -1 : 1;
  }

  return valueA > valueB ? -1 : 1;
}
