import { MailOutlined, MobileOutlined, NumberOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import classnames from 'classnames';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import validator from 'validator';

import { apiClient } from '../../graphql/api/apiClient';
import {
  sendLoginCodeDocument,
  sendLoginCodeMutation,
  sendLoginCodeMutationVariables,
  SessionType,
  UserIdentifier,
  UserIdentifierType,
  verifyLoginCodeDocument,
  verifyLoginCodeMutation,
  verifyLoginCodeMutationVariables,
} from '../../graphql/api/generated';
import UnauthenticatedLayout from '../../layouts/UnauthenticatedLayout';
import Authentication from '../../stores/authentication';
import { displayErrorMessage, formatPhoneNumberInput, parsePhoneNumber } from '../../utils';

import styles from './styles.module.less';

interface IProps extends RouteComponentProps {
  authentication: Authentication;
}

interface ILoginPageState {
  userIdentifier: UserIdentifier;
  code: string;
  resending: boolean;
  submitting: boolean;
  waitingForCode: boolean;
  verificationToken: string | null;
}

const VERIFICATION_CODE_LENTH = 6;

@inject('authentication')
@observer
class LoginPage extends Component<IProps, ILoginPageState> {
  state: ILoginPageState = {
    userIdentifier: {
      type: UserIdentifierType.PHONE_NUMBER,
      value: '',
    },
    code: '',
    resending: false,
    submitting: false,
    waitingForCode: false,
    verificationToken: null,
  };

  getFormattedIdentifier = () => {
    const { type, value } = this.state.userIdentifier;

    if (type === UserIdentifierType.PHONE_NUMBER) {
      return formatPhoneNumberInput(value);
    }

    return value;
  }

  isCodeValid = (): boolean => this.state.code.length === VERIFICATION_CODE_LENTH;

  isIdentifierValid = () => {
    const { type, value } = this.state.userIdentifier;

    if (type === UserIdentifierType.PHONE_NUMBER) {
      return !!(parsePhoneNumberFromString(value, 'US')?.isValid());
    }

    if (type === UserIdentifierType.EMAIL) {
      return validator.isEmail(value);
    }

    return false;
  }

  validateIdentifier = () => {
    const { userIdentifier } = this.state;

    if (userIdentifier.type === UserIdentifierType.PHONE_NUMBER && !this.isIdentifierValid()) {
      throw new Error('Please provide a valid phone number');
    }

    if (userIdentifier.type === UserIdentifierType.EMAIL && !this.isIdentifierValid()) {
      throw new Error('Please provide a valid email');
    }
  }

  sendCode = async (resending = false) => {
    try {
      this.validateIdentifier();
      this.setState({ resending, submitting: !resending });

      const { type, value } = this.state.userIdentifier;

      const userIdentifier = {
        type,
        value: type === UserIdentifierType.PHONE_NUMBER ? parsePhoneNumber(value) : value,
      };

      const { data } = await apiClient.mutate<
        sendLoginCodeMutation,
        sendLoginCodeMutationVariables
      >({
        mutation: sendLoginCodeDocument,
        variables: {
          input: {
            userIdentifier,
            sessionType: SessionType.ADMIN_PANEL,
          },
        }
      });

      if (data?.verificationToken) {
        this.setState({ verificationToken: data?.verificationToken, code: '' });
      }
    } catch (error) {
      displayErrorMessage(error as Error);

      this.setState({
        resending: false,
        submitting: false,
        waitingForCode: false,
      });

      return;
    }

    this.setState({
      resending: false,
      submitting: false,
      waitingForCode: true,
    });
  }

  verifyCode = async () => {
    const { authentication } = this.props;
    const { code, verificationToken } = this.state;
    const { type, value } = this.state.userIdentifier;

    try {
      this.validateIdentifier();
      this.setState({ submitting: true });

      if (!verificationToken) {
        throw new Error('Missing verification token');
      }

      const identifier = type === UserIdentifierType.PHONE_NUMBER ? parsePhoneNumber(value) : value;

      const { data } = await apiClient.mutate<
        verifyLoginCodeMutation,
        verifyLoginCodeMutationVariables
      >({
        mutation: verifyLoginCodeDocument,
        variables: {
          input: {
            identifier,
            code,
            verificationToken,
          },
        }
      });

      const session = data?.verifyLoginCode.session;

      if (session) {
        await authentication.logIn(session);
      }
    } catch (error) {
      authentication.loading = false;
      displayErrorMessage(error as Error);
      this.setState({ submitting: false });
    }
  }

  render() {
    const { authentication } = this.props;
    const { userIdentifier, code, resending, submitting, waitingForCode } = this.state;

    if (authentication.isAuthenticated) {
      return <Redirect to='/dashboard' />;
    }

    const submitDisabled = resending || submitting;

    return (
      <UnauthenticatedLayout>
        <div className={styles.main}>
          <form className={styles.formContainer} onSubmit={e => e.preventDefault()}>
            <Input.Group compact style={{ marginBottom: 20 }} size='large'>
              <Select
                style={{ width: '30%' }}
                value={userIdentifier.type === UserIdentifierType.PHONE_NUMBER ? 'SMS' : 'EMAIL'}
                size='large'
                onChange={(method) => {
                  const type = method === 'SMS' ? UserIdentifierType.PHONE_NUMBER : UserIdentifierType.EMAIL;

                  this.setState({
                    userIdentifier: { type, value: '' },
                    waitingForCode: false,
                  })
                }}
                virtual={false} // There is a visual bug with white space when reselecting
              >
                <Select.Option value='SMS'><MobileOutlined /> SMS</Select.Option>
                <Select.Option value='EMAIL'><MailOutlined /> Email</Select.Option>
              </Select>
              <Input
                style={{ width: '70%' }}
                disabled={waitingForCode}
                placeholder={userIdentifier.type === UserIdentifierType.PHONE_NUMBER
                  ? 'Please enter your phone number'
                  : 'Please enter your email'
                }
                value={this.getFormattedIdentifier()}
                onChange={({ target: { value } }) => {
                  this.setState({
                    userIdentifier: {
                      value,
                      type: userIdentifier.type,
                    },
                  });
                }}
                className={classnames({ 'has-error': !this.isIdentifierValid() })}
              />
            </Input.Group>

            {!waitingForCode && (
              <Button
                htmlType='submit'
                className={styles.submit}
                size='large'
                loading={submitting}
                disabled={!this.isIdentifierValid() || submitDisabled}
                onClick={() => this.sendCode()}
                type='primary'
                block
              >
                Send Verification Code
              </Button>
            )}

            {waitingForCode && (
              <div className={styles.inline}>
                <Input
                  className={styles.input}
                  placeholder='Verification Code'
                  maxLength={VERIFICATION_CODE_LENTH}
                  onChange={({ target: { value } }) => {
                    // Strip all non-numeric characters
                    this.setState({ code: value.replace(/\D/g, '') })
                  }}
                  size='large'
                  prefix={<NumberOutlined />}
                  value={code}
                />
                <Button
                  loading={resending}
                  disabled={!this.isIdentifierValid() || submitDisabled}
                  className={styles.button}
                  onClick={() => this.sendCode(true)}
                  size='large'
                >
                  Resend Code
                </Button>
              </div>
            )}

            {waitingForCode && (
              <div className={styles.inline}>
                <Button
                  className={styles.submit}
                  size='large'
                  disabled={submitDisabled}
                  onClick={() => this.setState({ waitingForCode: false })}
                  block
                >
                  Cancel
                </Button>

                <Button
                  htmlType='submit'
                  className={`${styles.submit} ${styles.button}`}
                  size='large'
                  loading={submitting}
                  disabled={!this.isCodeValid() || submitDisabled}
                  onClick={this.verifyCode}
                  type='primary'
                  block
                >
                  Submit
                </Button>
              </div>
            )}
          </form>
        </div>
      </UnauthenticatedLayout>
    );
  }
}

export default LoginPage;
