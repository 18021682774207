import { Table } from 'antd';
import React from 'react';

import { HubTableFragment, usegetHubDetailsQuery } from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import normalizeColumns from '../../helpers/normalizeColumns';
import { HubsMainTable } from '../tables';

const HubDetailsLayout = createDetailsLayout<HubTableFragment>();

// @TODO: Make this the default settings for any model's details page
export const HubDetailsPage: React.FC<any> = () => {
  return (
    <HubDetailsLayout
      modelName='Hub'
      useQuery={(hubId) => {
        return usegetHubDetailsQuery({
          variables: { hubId },
        });
      }}
      body={(hub) => {
        const columns = normalizeColumns(HubsMainTable.columns);

        return (
          <Table
            columns={columns}
            dataSource={[hub]}
            rowKey={u => u.hubId}
            pagination={false}
            expandable={HubsMainTable.expandable}
          />
        );
      }}
    />
  );
};

export default HubDetailsPage;
