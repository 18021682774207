import { Modal, notification } from 'antd';
import React from 'react';

import {
  PermissionKey_enum,
  unlockHubRelaysDocument,
  unlockHubRelaysMutation,
  unlockHubRelaysMutationVariables,
} from '../../graphql/hasura/generated';
import { hasuraClient } from '../../graphql/hasura/hasuraClient';
import { authentication } from '../../stores';
import { getErrorCode } from '../../utils';
import { HubStatusTable } from '../Hub/components/HubStatusTable';

interface IUnlockHubRelayArgs {
  hubId: string;
  relayPositions: number[];
  successMessage: string;
  unknownMessage: string;
  errorTitle: string;
}

export async function unlockHubRelays(args: IUnlockHubRelayArgs) {
  const { hubId, relayPositions, successMessage, unknownMessage, errorTitle } = args;

  try {
    const { data } = await hasuraClient.mutate<
      unlockHubRelaysMutation,
      unlockHubRelaysMutationVariables
    >({
      mutation: unlockHubRelaysDocument,
      variables: {
        input: {
          hubId,
          relayPositions,
        },
      },
    });

    const response = data?.custom_unlockHubRelays.response;
    const message = !!response ? successMessage : unknownMessage;

    notification.success({
      message: 'Success',
      description: message,
    });
  } catch (error) {
    const errorCode = getErrorCode(error as Error);

    if (
      authentication.hasPermission(PermissionKey_enum.Hub_Read) && (
        errorCode === 'TIMEOUT' || // Timed out waiting for acknowledgment
        errorCode === 'unexpected' || // Hasura is unable to reach the action endpoint on the main API
        errorCode === 'UNEXPECTED' // Unexpected error within mutation resolver
      )
    ) {
      Modal.error({
        title: errorTitle,
        centered: true,
        maskClosable: true,
        okButtonProps: {
          style: { display: 'none' },
        },
        okCancel: true,
        cancelText: 'Close',
        width: '60%',
        content: (
          <HubStatusTable hubFilter={{ hubIds: [hubId] }} />
        ),
      });
    } else {
      throw error;
    }
  }
}
