import { FormikErrors } from 'formik';
import moment from 'moment';

import { UserRoleScheduleRuleInput } from '../../../graphql/api/generated';
import {
  GrantableRoleFragment,
  PermissionScope_enum,
  UserRoleReason_enum,
} from '../../../graphql/hasura/generated';
import { getRoleRequirements } from '../shared-form/getRoleRequirements';

export interface ISharedFormValues {
  permissionScope?: PermissionScope_enum | null;
  scopedIds: string[];

  role?: GrantableRoleFragment | null;

  activatesAt: Date;
  expiresAt?: Date | null;
  schedule?: UserRoleScheduleRuleInput[] | null;

  reason?: UserRoleReason_enum | null;
  notes?: string | null;

  acknowledged: boolean;
}

export function validateSharedFormValues(values: ISharedFormValues) {
  const errors: FormikErrors<ISharedFormValues> = {};

  if (!values.role) {
    errors.role = 'Please select a role';
  }

  if (!values.activatesAt) {
    errors.activatesAt = `Please select an activation date`;
  }

  const {
    maxAccessDurationSeconds,
    expirationDateRequired,
    reasonToGrantRequired,
  } = getRoleRequirements(values.role);

  if ((expirationDateRequired || maxAccessDurationSeconds) && !values.expiresAt) {
    errors.expiresAt = `Please select an expiration date`;
  }

  if (maxAccessDurationSeconds && values.expiresAt) {
    const maxExpiresAt = moment(values.activatesAt || new Date())
      .add(maxAccessDurationSeconds, 'seconds');

    if (moment(values.expiresAt).isAfter(maxExpiresAt)) {
      errors.expiresAt = 'Expiration date exceeds the maximum duration of access';
    }
  }

  if (
    values.activatesAt && values.expiresAt &&
    values.activatesAt.getTime() >= values.expiresAt.getTime()
  ) {
    errors.expiresAt = 'Please ensure the expiration date is after the activation date';
  }

  if (values.expiresAt && moment(values.expiresAt).isSameOrBefore(moment(new Date()))) {
    errors.expiresAt = 'Please ensure the expiration date is not in the past';
  }

  if (reasonToGrantRequired && !values.reason) {
    errors.reason = `Please select a reason`;
  }

  return errors;
}
