import validator from 'validator';

import {
  PermissionKey_enum,
  User_bool_exp,
  UserActionFragment,
  UserLabelFragment,
  UserLabelFragmentDoc,
  UserUniqueLabelFragment,
  UserUniqueLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import { emptySearchConditionsFilter, formatPhoneNumber, formatUserName } from '../../utils';
import Model from '../Model';

export const UserModel = new Model<
User_bool_exp,
  UserLabelFragment,
  UserUniqueLabelFragment,
  UserActionFragment
>({
  names: {
    schemaName: 'User',
  },

  permissionsOptions: {
    canRead: () => true,

    canCreate: ({ limitStratisPermissions, hasPermission }) => (
      !limitStratisPermissions && hasPermission(PermissionKey_enum.User_Create)
    ),

    canUpdate: ({ limitStratisPermissions, hasPermission }) => (
      !limitStratisPermissions && hasPermission(PermissionKey_enum.User_UpdateProfile)
    ),
  },

  labels: {
    getLabel: (u) => formatUserName(u),
    getUniqueLabel: (u) => {
      const userName = formatUserName(u);
      const { email } = u;
      const phoneNumber = formatPhoneNumber(u.phoneNumber);

      return email ? `${userName} (${email})` : `${userName} ${phoneNumber}`;
    },
    getBreadCrumbsLabel: () => null,
    labelFragment: UserLabelFragmentDoc,
    uniqueLabelFragment: UserUniqueLabelFragmentDoc,
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => {
        // Strip special characters for phone number search: ( ) - +
        const phoneNumberWord = word.replace(/[\(\)\-\+]/g, '');

        return {
          _or: [
            {
              firstName: { _ilike: `%${word}%` },
            },
            {
              lastName: { _ilike: `%${word}%` },
            },
            {
              phoneNumber: { _ilike: phoneNumberWord ? `%${phoneNumberWord}%` : undefined },
            },
            {
              email: { _ilike: `%${word}%` },
            },
            {
              augustUserId: { _eq: (validator.isUUID(word) ? word : undefined) },
            },
            {
              augustSubUserId: { _eq: (validator.isUUID(word) ? word : undefined) },
            },
          ].filter(emptySearchConditionsFilter),
        };
      }),
    }),
  },
});

export default UserModel;
