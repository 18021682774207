import { ApolloError } from '@apollo/client';
import { notification } from 'antd';
import { GraphQLError } from 'graphql';
import { get } from 'lodash';

export const DEFAULT_ERROR_MESSAGE = 'An unexpected error occurred';

// @TODO: Refactor functions as a wrapper class (ErrorHandler) with methods?

export function selectError(error: ApolloError | Error) {
  const { graphQLErrors, networkError } = error as ApolloError;

  if (graphQLErrors && graphQLErrors.length) {
    return graphQLErrors[0];
  }

  if (networkError) {
    // @TODO: Is this still an issue?
    // NOTE: In some cases the correct error is inside of networkError.result.errors
    // Sometimes a unique constraint violation from a GraphQL error might show up here
    // https://github.com/apollographql/apollo-client/issues/2810#issuecomment-654226300
    return networkError;
  }

  return error;
}

export function isConstraintViolationError(error: Error | ApolloError | GraphQLError): boolean {
  return get(error as GraphQLError, 'extensions.code') === 'constraint-violation';
}

function getErrorFieldName(error: Error): string | null {
  // @TODO: How do we handle multi-column constraints?
  if (isConstraintViolationError(error)) {
    const [, , fieldName] = error.message.match(new RegExp('([a-zA-Z]+)_([a-zA-Z]+)_key')) || [];

    return fieldName;
  }

  return null;
}

function getFriendlyErrorMessage(error: ApolloError | Error): string | null {
  if (isConstraintViolationError(error)) {
    if (error.message.includes('Foreign')) {
      return 'Conflict: There are other items in the system that depend on this data.';
    }

    return (
      `Conflict: There's already an item with this value in the system. Please enter another.`
    );
  }

  return null;
}

export function getErrorInfo(error: ApolloError | Error) {
  const selectedError = selectError(error);
  const friendlyErrorMessage = getFriendlyErrorMessage(selectedError);
  const errorFieldName = getErrorFieldName(selectedError);

  const formErrors = (errorFieldName && friendlyErrorMessage)
    ? { [errorFieldName]: friendlyErrorMessage }
    : {};

  return { selectedError, friendlyErrorMessage, formErrors };
}

export function getErrorCode(error: GraphQLError | Error) {
  const selectedError = selectError(error);

  return (selectedError as GraphQLError)?.extensions?.code as string | null | undefined;
}

export function getDisplayedErrorMessage(error: ApolloError | Error) {
  const selectedError = selectError(error);
  const friendlyErrorMessage = getFriendlyErrorMessage(selectedError);

  return friendlyErrorMessage || selectedError.message || DEFAULT_ERROR_MESSAGE;
}

export function displayErrorMessage(error: ApolloError | Error) {
  const displayedErrorMessage = getDisplayedErrorMessage(error);

  notification.error({
    message: 'Error',
    // NOTE: This could display potentially unfriendly error messages for Hasura
    description: displayedErrorMessage,
  });
}

export class AbortError extends Error {
  constructor(message: string) {
    super(message);

    this.name = 'AbortError';
    this.message = message;

    Error.captureStackTrace(this, AbortError);
  }
}
