import { Divider, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { apiClient, hasuraClient } from '../../graphql';
import { IModelAction } from '../../models/typings';
import { displayErrorMessage, RefetchContext } from '../../utils';

export interface ITableActionProps<TRow extends object = any> {
  action: IModelAction<TRow>;
  row: TRow;
  divider?: boolean;
}

const TableAction: React.FC<ITableActionProps> = ({ action, row, divider }) => {
  const history = useHistory();
  const location = useLocation();
  const refetchContext = useContext(RefetchContext);
  const [isModalVisible, setIsModalVisible] = useState(false);

  if (!action.enabledByRow(row)) {
    return null;
  }

  if (typeof action.render === 'function') {
    return (
      <>
        {divider && <Divider type='vertical' />}
        <span title={action.description}>
          {action.render(row)}
        </span>
      </>
    );
  }

  const label = typeof action.label === 'function' ? action.label(row) : null;

  const executeAction = async () => {
    if (typeof action.executes === 'function') {
      try {
        return await action.executes(row, {
          history,
          location,
          apiClient,
          hasuraClient,
          refetchContext,
          resetStores: async () => {
            await hasuraClient.resetStore();
            await apiClient.resetStore();
          },
        });
      } catch (error) {
        displayErrorMessage(error as Error);
      }
    }
  }

  return (
    <>
      {divider && <Divider type='vertical' />}
      <a
        title={action.description}
        onClick={async (e) => {
          e.preventDefault();

          // If using a custom modal let's open it instead
          if (action.renderModalContent) {
            setIsModalVisible(true);
            return;
          }

          if (!action.confirmation) {
            return await executeAction();
          }

          const { title, content, okText, cancelText } = action.confirmation;

          Modal.confirm({
            okText: typeof okText === 'function' ? okText(row) : 'OK',
            cancelText: typeof cancelText === 'function' ? cancelText(row) : 'Cancel',

            centered: true,
            title: typeof title === 'function' ? title(row) : label,
            content: content(row),

            async onOk() {
              return await executeAction();
            },
          });
        }}
      >
        {label}
      </a>
      {action.renderModalContent && (
        <Modal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          {...(typeof action.getModalProps === 'function' ? action.getModalProps(row) : {})}
        >
          {action.renderModalContent(row)}
        </Modal>
      )}
    </>
  )
}

export default TableAction;
