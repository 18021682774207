import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';

import { TableCellDescriptions } from '../../../components/TableCellDescriptions';
import { AugustLockReportFragment as AugustLock } from '../../../graphql/api/generated';
import { Color } from '../../../utils';

export function getAugustFirmwareDescriptions(augustLock: AugustLock | null | undefined) {
  const currentVersion = augustLock?.chipFirmware.currentVersion;

  let desiredVersion = augustLock?.chipFirmware.desiredVersion;

  if (currentVersion && !desiredVersion) {
    desiredVersion = 'Unknown';
  } else if (currentVersion && currentVersion === desiredVersion) {
    desiredVersion = null; // Hide the desired version if the current version matches
  }

  return {
    'Current Version': currentVersion,
    'Desired Version': desiredVersion,
  };
}

export const AugustFirmwareDescriptions: React.FC<{ augustLock: AugustLock | null | undefined }> = (props) => {
  const descriptions = getAugustFirmwareDescriptions(props.augustLock);
  const { 'Current Version': currentVersion, 'Desired Version': desiredVersion } = descriptions;

  if (currentVersion && !desiredVersion) {
    descriptions['Current Version'] = (
      // No updates available
      <>{currentVersion} <CheckOutlined style={{ color: Color.Green }} /></>
    ) as any;
  } else if (currentVersion && desiredVersion !== 'Unknown' && currentVersion !== desiredVersion) {
    // Updates available
    descriptions['Current Version'] = (
      <>{currentVersion} <ExclamationCircleOutlined style={{ color: Color.Gold }} /></>
    ) as any;
  }

  return <TableCellDescriptions descriptions={descriptions} />;
};
