import { Form } from '@ant-design/compatible';
import { Select } from 'antd';
import React from 'react';

import { DAYS } from './constants';

interface ITimetableDaysSelectProps {
  value: number[];
  onChange: (days: number[]) => any;
  error?: any;
  disabled?: boolean;
}

const TimetableDaysSelect: React.FC<ITimetableDaysSelectProps> = (props) => {
  const { value, onChange, error, disabled } = props;

  return (
    <Form.Item
      validateStatus={error ? 'error' : undefined}
      help={error}
    >
      <Select
        mode='multiple'
        style={{ width: '100%' }}
        placeholder='Please select one or more days'
        onChange={(values) => onChange(values)}
        value={value.map((day: number) => day - 1)}
        virtual={false} // There is a visual bug with white space when reselecting
        disabled={disabled}
      >
        {DAYS.map((day, dayIndex) => (
          <Select.Option key={dayIndex} value={dayIndex}>
            {day}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default TimetableDaysSelect;
