import { Select } from 'antd';
import React from 'react';

import { TimetableRuleState } from '../../graphql/api/generated';

import { TIMETABLE_STATE_DESCRIPTIONS, TIMETABLE_STATE_SELECT_OPTIONS } from './constants';

interface ITimetableStateSelectProps {
  value: TimetableRuleState | null | undefined;
  onChange: (state: TimetableRuleState) => any;
  disabled?: boolean;
}

const TimetableStateSelect: React.FC<ITimetableStateSelectProps> = (props) => {
  const { value, onChange, disabled } = props;
  const description = value ? TIMETABLE_STATE_DESCRIPTIONS[value] : null;

  return (
    <>
      <Select
        value={value || undefined}
        onChange={state => onChange(state)}
        style={{ fontWeight: 'bold', width: '160px' }}
        virtual={false} // There is a visual bug with white space when reselecting
        disabled={disabled}
      >
        {TIMETABLE_STATE_SELECT_OPTIONS
        .map((option) => (
          <Select.Option
            key={option.state}
            value={option.state}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
      {description && (
        <div style={{ paddingTop: 10, fontSize: '13px' }}>
          {description}
        </div>
      )}
    </>
  );
}

export default TimetableStateSelect;
