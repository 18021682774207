import { ApolloError, QueryResult, SubscriptionResult, useQuery, useSubscription } from '@apollo/client';
import { useState } from 'react';

import { generateListOperation, generateTotalOperation } from '../../../graphql';
import { displayErrorMessage, Refetch } from '../../../utils';
import { ITableData, IUseTableDataArgs } from '../typings';

import { createPaginatedCsvFetcher } from './createPaginatedCsvFetcher';
import { formatListOperationOutput, formatTotalOperationOutput } from './formatOperationOutput';
import { formatOperationVariables } from './formatOperationVariables';

type OperationResult = QueryResult | SubscriptionResult;

export function useHasuraTableData(args: IUseTableDataArgs): ITableData {
  const { tableConfig, shouldSkipTotal, shouldSkipList } = args;
  const [refetching, setRefetching] = useState<boolean>(false);

  const { model } = tableConfig;
  const modelName = model.names.schemaName;

  const operationType = tableConfig.useSubscription ? 'subscription' : 'query';
  const { totalOperationVariables, listOperationVariables } = formatOperationVariables(args);

  const totalOperation = generateTotalOperation(model, operationType);
  const listOperation = generateListOperation(model, operationType, tableConfig.fragment);

  const totalOptions = {
    variables: totalOperationVariables,
    skip: shouldSkipTotal,
    onError: (error: ApolloError) => displayErrorMessage(error),
  };

  const listOptions = {
    variables: listOperationVariables,
    skip: shouldSkipList,
    onError: (error: ApolloError) => displayErrorMessage(error),
  };

  let totalResult: OperationResult = { loading: true };
  let listResult: OperationResult = { loading: true };
  let refetch: Refetch = async () => {};

  if (operationType === 'subscription') {
    totalResult = useSubscription(totalOperation, totalOptions);
    listResult = useSubscription(listOperation, listOptions);
  } else {
    totalResult = useQuery(totalOperation, {
      ...totalOptions,
      fetchPolicy: tableConfig.fetchPolicy || 'network-only',
    });

    listResult = useQuery(listOperation, {
      ...listOptions,
      fetchPolicy: tableConfig.fetchPolicy || 'network-only',
    });

    refetch = async () => {
      setRefetching(true);
      await Promise.all([
        (listResult as QueryResult).refetch(),
        (totalResult as QueryResult).refetch(),
      ]);
      setRefetching(false);
    };
  }

  const totalLoading = !shouldSkipTotal && totalResult.loading;
  const total = formatTotalOperationOutput(modelName, totalResult.data);

  const listLoading = !shouldSkipList && (listResult.loading || refetching);
  const listData = formatListOperationOutput(modelName, listResult.data);

  return {
    refetch,
    total,
    totalLoading,
    listLoading,
    listData,
    paginatedCsvFetcher: createPaginatedCsvFetcher(args),
  };
}
