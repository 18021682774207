import { enumManagers, EnumTable } from '@chirp/enums';
import Select, { SelectProps } from 'antd/lib/select';
import React from 'react';

import { generateWordsFromSearch } from '../../utils';

export interface IOption {
  value: string;
  label: string;
}

export interface IEnumSelectProps extends SelectProps<any> {
  enumTable: EnumTable;
  value: string | string[] | null | undefined;
  filterOptions?: (options: IOption[]) => IOption[];
}

export const EnumSelect: React.FC<IEnumSelectProps> = (props) => {
  const {
    enumTable,
    value,
    disabled,
    filterOptions,
    onChange,
    ...otherProps
  } = props;

  const enumOptions = enumManagers[enumTable as EnumTable]?.getOptions() || [];

  const filteredOptions = typeof filterOptions === 'function'
    ? filterOptions(enumOptions)
    : enumOptions;

  return (
    <Select
      {...otherProps}
      value={value}
      disabled={disabled}
      onChange={(nextValue, option) => {
        if (typeof onChange === 'function') {
          onChange(nextValue, option);
        }
      }}
      showSearch
      filterOption={(inputValue, option) => {
        if (!inputValue) {
          return true;
        }

        const searchWords = generateWordsFromSearch(inputValue);

        return option
          ? searchWords.every(word => String(option.label).toLowerCase().includes(word.toLowerCase()))
          : false;
      }}
      options={filteredOptions}
      virtual={false} // There is a visual bug with white space when reselecting
    />
  );
}

export default EnumSelect;
