import { CloseCircleFilled } from '@ant-design/icons';
import { InputNumber } from 'antd';
import Select from 'antd/lib/select';
import React, { useState } from 'react';

import {
  convertSecondsToLargestUnit,
  convertSecondsToUnit,
  convertUnitToSeconds,
  DurationUnit,
  getDisplayedDurationUnit,
  MAX_DURATION_IN_SECONDS,
  UNIT_IN_SECONDS,
} from './helpers';

interface IDurationFieldProps {
  valueInSeconds?: number | null;
  onChange: (value: number | null) => any;
  durationUnits?: DurationUnit[] | null;
  disabled?: boolean;
  maxDurationInSeconds?: number;
}

function getDefaultDurationUnit(
  valueInSeconds: number | undefined | null,
  allowedDurationUnits?: DurationUnit[] | null,
) {
  if (valueInSeconds) {
    return convertSecondsToLargestUnit(valueInSeconds, allowedDurationUnits).durationUnit;
  }

  return allowedDurationUnits ? allowedDurationUnits[0] : DurationUnit.SECOND;
}

const DurationField: React.FC<IDurationFieldProps> = (props) => {
  const { valueInSeconds, onChange, durationUnits, disabled } = props;
  const defaultDurationUnit = getDefaultDurationUnit(valueInSeconds, durationUnits);
  const [durationUnit, setDurationUnit] = useState<DurationUnit>(defaultDurationUnit);

  const valueInUnits = convertSecondsToUnit(valueInSeconds, durationUnit);
  const maxDurationInSeconds = props.maxDurationInSeconds || MAX_DURATION_IN_SECONDS;
  const maxValueInUnits = Math.floor(maxDurationInSeconds / UNIT_IN_SECONDS[durationUnit]);

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <InputNumber
        type='number'
        value={valueInUnits}
        onKeyDown={async (e) => {
          if (
            e.keyCode === 69 || // Prevent "e" from being entered
            e.keyCode === 189 || // Prevent negative numbers from being entered
            e.keyCode === 190 // Prevent decimals from being entered
          ) {
            e.preventDefault();
          }
        }}
        formatter={(v) => {
          if (v === undefined || v === 0 || v === '' || v === '0') {
            return '';
          }

          return `${Math.floor(+v)}`; // Remove decimal, if applicable
        }}
        parser={(v) => {
          if (v === undefined) {
            return 0;
          }

          const integerValue = Math.floor(+v); // Remove decimal, if applicable

          return Math.min(integerValue, maxValueInUnits); // Prevent max from being exceeded
        }}
        onChange={async (v) => {
          const nextConvertedValue = typeof v === 'number' && v !== 0 ? Math.floor(v) : undefined;
          const nextValueInSeconds = convertUnitToSeconds(nextConvertedValue, durationUnit);

          await onChange(nextValueInSeconds || null);
        }}
        min={0}
        max={maxValueInUnits}
        disabled={disabled}
      />
      <Select
        value={durationUnit}
        onChange={async (nextDurationUnit) => {
          const nextValueInSeconds = convertUnitToSeconds(valueInUnits, nextDurationUnit);

          setDurationUnit(nextDurationUnit);
          await onChange(nextValueInSeconds || null);
        }}
        options={Object.values(durationUnits ? durationUnits : DurationUnit).map(u => ({
          label: getDisplayedDurationUnit(u),
          value: u,
        }))}
        virtual={false} // There is a visual bug with white space when reselecting
        style={{ marginLeft: 10, width: 150 }}
        disabled={disabled}
      />
      {!disabled && typeof valueInSeconds === 'number' && (
        <span style={{ marginLeft: 15 }}>
          <CloseCircleFilled
            onClick={async () => {
              await onChange(null);
            }}
          />
        </span>
      )}
    </div>
  );
};

export default DurationField;
