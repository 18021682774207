import { Card, Tabs } from 'antd';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

import ModelBreadcrumbs from '../../../components/ModelBreadcrumbs';
import { PermissionKey_enum, PermissionScope_enum } from '../../../graphql/hasura/generated';
import { ModelsContext } from '../../../hooks/providers';
import BasicLayout from '../../../layouts/BasicLayout';
import { authentication } from '../../../stores';
import PresetRolesForm from '../components/PresetRolesForm';
import RoleForm from '../components/RoleForm';

const CreateRolePage: React.FC = () => {
  const history = useHistory();
  const { getModel } = useContext(ModelsContext);
  const model = getModel('Role');

  const canManagePresetRoles = (
    authentication.hasPermission(PermissionKey_enum.Role_ManagePermissions) &&
    authentication.currentDataScope.permissionScope === PermissionScope_enum.GLOBAL
  );

  const roleForm = (
    <RoleForm
      isNew
      onCancel={() => history.goBack()}
      onSubmit={(roleId) => history.push(`/roles/details/${roleId}`)}
    />
  );

  return (
    <BasicLayout pageTitle='Create Role'>
      <Card
        title={<ModelBreadcrumbs model={model} rowBreadCrumb={{ isNew: true }} />}
        bordered={false}
        style={{ marginBottom: 20 }}
      >
      {!canManagePresetRoles && roleForm}
      {canManagePresetRoles && (
        <Tabs animated={false}>
          <Tabs.TabPane
            tab='Custom Role'
            key='CUSTOM_ROLE'
          >
            {roleForm}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab='Preset Roles (Quick Add)'
            key='PRESET_ROLES'
          >
            <PresetRolesForm />
          </Tabs.TabPane>
        </Tabs>
      )}
      </Card>
    </BasicLayout>
  );
};

export default CreateRolePage;
