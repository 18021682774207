import { Card } from 'antd';
import { TablePaginationConfig } from 'antd/lib/table';
import { Location } from 'history';
import React from 'react';

import { ChirpTable, IChirpTableProps } from '../../components/ChirpTable';
import { ResidentAuditFragment as ResidentAudit } from '../../graphql/api/generated';

import { ResidentAuditColumn } from './columns';
import ResidentAuditStatistics from './ResidentAuditStatistics';

interface IResidentAuditPageProps {
  cardTitle: React.ReactNode;
  cardExtra?: React.ReactNode;
  location: Location;
  columns: ResidentAuditColumn[];
  loading: boolean;
  residentAudits: ResidentAudit[];
  filteredResidentAudits: ResidentAudit[];
  pagination?: TablePaginationConfig | false;
  children?: React.ReactNode;
  tableProps?: Partial<IChirpTableProps>;
}

const ResidentAuditPage = (props: IResidentAuditPageProps) => {
  return (
    <>
      <Card
        title={props.cardTitle}
        extra={props.cardExtra}
        bordered={false}
        style={{ marginBottom: 20 }}
      >
        <ResidentAuditStatistics
          loading={props.loading}
          residentAudits={props.residentAudits}
        />
        <ChirpTable
          columns={props.columns}
          loading={props.loading}
          pagination={props.pagination}
          rowKey={record => record.residentSource.residentSourceId}
          dataSource={props.filteredResidentAudits}
          {...props.tableProps}
        />
      </Card>
      {props.children}
    </>
  );
}

export default ResidentAuditPage;
