import { Layout, Typography } from 'antd';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { Component, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/chirp_logo.png';
import GlobalHeader from '../components/GlobalHeader';
import SideMenu from '../components/Menu';
import AppData from '../stores/app-data';

import styles from './BasicLayout.module.less';

const { Header, Content, Footer, Sider } = Layout;

interface IBasicLayoutProps {
  pageTitle?: string;
  pageDescription?: React.ReactNode;
  children?: ReactNode;
  appData?: AppData;
}

interface IBasicLayoutState {
  collapsed: boolean;
}

@inject('appData')
@observer
class BasicLayout extends Component<IBasicLayoutProps, IBasicLayoutState> {

  onCollapse = () => {
    const { appData } = this.props;
    if (appData) { appData.toggleMenu(); }
  }

  render() {
    const { pageTitle, pageDescription, children, appData } = this.props;

    if (!appData) {
      return null;
    }

    const { collapsedMenu } = appData;

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsed={collapsedMenu}>
          <Link to='/dashboard' className={styles.logo}>
            <img src={logo} alt='chrip-logo' className={styles.img} />
          </Link>
          <SideMenu />
        </Sider>
        <Layout>
          <Header style={{ background: '#fff', padding: 0 }}>
            <GlobalHeader
              collapsed={collapsedMenu}
              onCollapse={this.onCollapse}
              {...this.props}
            />
          </Header>
          <Content>
            {pageTitle && (
              <div className={styles.pageHeader}>
                <Typography.Title level={4} style={{ margin: 0, padding: 0, lineHeight: 2 }}>
                  {pageTitle}
                </Typography.Title>
                {pageDescription && (
                  <Typography.Paragraph style={{ paddingTop: 5, paddingBottom: 5 }}>
                    {pageDescription}
                  </Typography.Paragraph>
                )}
              </div>
            )}
            <div
              className={styles.content}
              style={!pageTitle ? { marginTop: '32px' } : undefined}
            >
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            Chirp Systems, Inc. {moment(new Date()).format('YYYY')}
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default BasicLayout;
