import { SortOrder } from 'antd/lib/table/interface';

import { order_by } from '../../../graphql/hasura/generated';
import type { IUseTableDataArgs } from '../typings';

import { formatOperationFilters } from './formatOperationFilters';

export function formatOperationVariables(args: IUseTableDataArgs) {
  const { tableConfig, tableState, searchWords, formattedColumns, tableArgs } = args;
  const { model, fixedQueryFilters, getTotalLimit } = tableConfig;
  const { getTableSearchConditions } = model.queryOptions;

  const { pageNumber, pageLimit } = tableState;
  const offset = (pageNumber - 1) * pageLimit;

  const fixedFilters = fixedQueryFilters ? fixedQueryFilters(tableArgs) : null;
  const boolExpressions = fixedFilters ? [fixedFilters] : [];

  if (searchWords.length && getTableSearchConditions) {
    boolExpressions.push(getTableSearchConditions(searchWords));
  }

  boolExpressions.push(...formatOperationFilters({
    columns: formattedColumns,
    columnFilters: tableState.filters,
    model: tableConfig.model,
  }));

  const where = boolExpressions.length
    ? {
      where: { _and: boolExpressions },
    }
    : {};

  let orderBy = null;

  if (tableState.orderBy) {
    const [fieldName, sortOrder] = Object.entries<SortOrder>(tableState.orderBy)[0];

    if (model.introspection.fields.some(f => f.name === fieldName)) {
      orderBy = { [fieldName]: sortOrder === 'ascend' ? order_by.asc : order_by.desc };
    }
  }

  const totalOperationVariables = { ...where, limit: getTotalLimit() };
  const listOperationVariables = { ...where, offset, limit: pageLimit, order_by: orderBy };

  return { totalOperationVariables, listOperationVariables };
}
