import { SettingOutlined } from '@ant-design/icons';
import React from 'react';

import {
  PermissionScope_enum,
  RoleCommonTableFragment,
  RoleCommonTableFragmentDoc,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import { createdAtColumn } from '../common/columns';
import PERMISSION_SCOPE_ICON_MAP from '../helpers/permission-scopes';

import * as columns from './columns';
import model from './model';

const commonColumns = [
  columns.nameColumn,
  columns.permissionScopeColumn,
  columns.permissionsColumn,
  columns.readableRolesColumn,
  columns.grantableRolesColumn,
  columns.revocableRolesColumn,
];

export const CustomRolesMainTable = model.createTable<RoleCommonTableFragment>({
  title: 'Custom Roles',
  icon: PERMISSION_SCOPE_ICON_MAP[PermissionScope_enum.ORGANIZATION],
  fragment: RoleCommonTableFragmentDoc,
  fixedQueryFilters: () => {
    return {
      organizationId: { _is_null: false },
      permissionScope: authentication.currentDataScope.permissionScope === PermissionScope_enum.GLOBAL
        ? undefined
        : { _neq: PermissionScope_enum.GLOBAL },
    };
  },
  columns: [
    columns.organizationColumn,
    ...commonColumns,
    createdAtColumn,
  ],
});

export const DefaultRolesMainTable  = model.createTable<RoleCommonTableFragment>({
  title: 'Default Roles',
  icon: <SettingOutlined />,
  fragment: RoleCommonTableFragmentDoc,
  fixedQueryFilters: () => {
    return {
      organizationId: { _is_null: true },
      permissionScope: authentication.currentDataScope.permissionScope === PermissionScope_enum.GLOBAL
        ? undefined
        : { _neq: PermissionScope_enum.GLOBAL },
    };
  },
  columns: [
    ...commonColumns,
    columns.lockedColumn,
    createdAtColumn,
  ],
});

export const RolesByOrganizationTable = model.createTable<RoleCommonTableFragment, string>({
  title: 'Custom Roles',
  fragment: RoleCommonTableFragmentDoc,
  fixedQueryFilters: (organizationId) => ({
    organizationId: { _eq: organizationId },
  }),
  columns: [
    ...commonColumns,
    createdAtColumn,
  ],
});
