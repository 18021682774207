import React from 'react';

import { AccessPointType_enum } from '../../../graphql/hasura/generated';

const DESCRIPTIONS: { [key in AccessPointType_enum]: string } = {
  COMMON_AREA: 'Accessible to all roles with property access.',
  AMENITY: 'Accessible to residents with amenity access.',
  RESERVATION_ONLY: 'Accessible to residents only by booking a reservation.',
  RESTRICTED_WHITELIST: 'Accessible only to whitelisted roles.',
  RESTRICTED_BLACKLIST: 'Accessible to all roles other than blacklisted roles.',
};

interface IAccessPointTypeDescriptionProps {
  type: AccessPointType_enum;
  style?: React.CSSProperties;
}

const AccessPointTypeDescription: React.FC<IAccessPointTypeDescriptionProps> = (props) => {
  const { type, style } = props;
  const description = DESCRIPTIONS[type];

  return (
    <div className='help-text' style={style}>
      {description}
    </div>
  );
};

export default AccessPointTypeDescription;
