import React from 'react';
import { Redirect } from 'react-router';

import { PermissionKey_enum } from '../../graphql/hasura/generated';
import BasicLayout from '../../layouts/BasicLayout';
import { authentication } from '../../stores';

import AugustSDKFeesReport from './AugustSDKFeesReport';
import { UseFiltersProvider } from './hooks';
import UserMetrics from './UserMetrics';

const DashboardPage: React.FC = () => {
  if (
    !authentication.hasPermission(PermissionKey_enum.User_Metrics) &&
    !authentication.hasPermission(PermissionKey_enum.SmartLock_AugustSdkFeesReport)
  ) {
    return <Redirect to='/properties' />;
  }

  return (
    <UseFiltersProvider>
      <BasicLayout pageTitle='Dashboard'>
        {authentication.hasPermission(PermissionKey_enum.User_Metrics) && <UserMetrics />}
        {authentication.hasPermission(PermissionKey_enum.SmartLock_AugustSdkFeesReport) && (
          <AugustSDKFeesReport />
        )}
      </BasicLayout>
    </UseFiltersProvider>
  );
}

export default DashboardPage;
