import { Divider } from 'antd';
import { FormikProps } from 'formik';
import React, { useContext } from 'react';

import { ModelsContext } from '../../hooks/providers';
import { IModel } from '../../models/typings';
import { getFieldHelpText } from '../../utils';

import { FormItem, IFormItemProps } from './CustomFormField';
import { FORM_ITEM_DEFAULT_LAYOUT } from './form-fields';
import ModelFormSelect from './ModelFormSelect';

interface IRelationShipFieldsProps {
  formikProps: FormikProps<any>;
  canUpdateRow: boolean;
  model: IModel;
  isNew: boolean;
}

const RelationshipFields: React.FC<IRelationShipFieldsProps> = (props) => {
  const { formikProps, canUpdateRow, model, isNew } = props;
  const { errors, setFieldValue, submitCount, values } = formikProps;

  const { getModel } = useContext(ModelsContext);

  const relationships = model.introspection.relationships.filter(r => (
    r.type === 'OBJECT' &&
    !r.relationshipName.includes('Enum') &&
    r.relationshipName !== 'image' // @TODO: Configure this elsewhere
  ));

  if (!relationships.length) {
    return null;
  }

  return (
    <div>
      <Divider>Related Data</Divider>

      {relationships.map((relationship) => {
        if (!relationship.sourceField) {
          return null;
        }

        const field = model.introspection.fields.find(f => f.name === relationship.sourceField);

        if (!field || (isNew && !field.canCreate)) {
          return null;
        }

        const relatedModel = getModel(relationship.targetModelName);
        const value = values[field.name];

        let disabled = (!isNew && (!field.canUpdate || !canUpdateRow));

        const formOptions = model.formOptions.fields.find(f => f.fieldName === field.name);
        const formOptionProps = { formikProps, field, disabled };

        if (typeof formOptions?.disabled === 'function' && formOptions.disabled(formOptionProps)) {
          disabled = true;
        }

        if (typeof formOptions?.render === 'function') {
          const formItemProps: IFormItemProps = {
            disabled,
            fieldName: field.name,
            required: field.required,
            help: getFieldHelpText(formikProps, field.name),
            ...FORM_ITEM_DEFAULT_LAYOUT,
          };

          return formOptions.render({
            field,
            formikProps,
            disabled,
            FormItem,
            formItemProps,
          });
        }

        if (typeof formOptions?.hidden === 'function' && formOptions.hidden(formOptionProps)) {
          return null;
        }

        return (
          <ModelFormSelect
            key={field.name}
            fieldName={field.name}
            formItemProps={{ ...FORM_ITEM_DEFAULT_LAYOUT }}
            modelSelectProps={{
              value,
              disabled,
              readonly: disabled,
              model: relatedModel,
              required: field.required,
              queryFilters: typeof formOptions?.getQueryFilters === 'function'
                ? formOptions.getQueryFilters(formOptionProps)
                : undefined,
            }}
            setFieldValue={setFieldValue}
            errors={errors}
            submitCount={submitCount}
          />
        );
      })}
    </div>
  );
}

export default RelationshipFields;
