import { Alert, Button, Modal, Progress } from 'antd';
import { FormikProps } from 'formik';
import pluralize from 'pluralize';
import React from 'react';
import { Link } from 'react-router-dom';

import { IModel, RowImportStatus } from '../../models/typings';

import { IModelImportFormValues } from './formik';
import { importRows } from './helpers';
import ModelImportRow from './ModelImportRow';

interface IModelImportProgressProps {
  model: IModel<any>;
  validRows: ModelImportRow<any, any>[];
  formikProps: FormikProps<IModelImportFormValues>;
  submitting: boolean;
}

export const ModelImportProgress: React.FC<IModelImportProgressProps> = (props) => {
  const { model, validRows, formikProps, submitting } = props;
  const { values } = formikProps;
  const { applyAllValues, importDependencies} = values;

  const importableRowsCount = validRows.filter(r => r.importState.status !== RowImportStatus.IMPORTED).length;
  const importErrorRowsCount = validRows.filter(r => r.importState.status === RowImportStatus.ERROR).length;
  const allValidRowsImported = validRows.every(r => r.importState.status === RowImportStatus.IMPORTED);

  if (allValidRowsImported) {
    const redirectLabel = typeof model.formOptions.importOptions?.getRedirectLabel === 'function'
      ? model.formOptions.importOptions.getRedirectLabel(model, applyAllValues, importDependencies)
      : `View Imported ${model.names.pluralDisplayName}`;

    const redirectPath = typeof model.formOptions.importOptions?.getRedirectPath === 'function'
      ? model.formOptions.importOptions.getRedirectPath(model, applyAllValues, importDependencies)
      : null;

    const redirectLink = redirectPath ? <Link to={redirectPath}>{redirectLabel}</Link> : null;

    return (
      <Alert
        showIcon
        type='success'
        message={
          <span>
            All valid rows have been successfully imported! {redirectLink}
          </span>
        }
        style={{ marginTop: 15, marginBottom: 25 }}
      />
    );
  }

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: 25 }}>
        <Button
          type='primary'
          size='large'
          onClick={() => {
            Modal.confirm({
              centered: true,
              title: (
                `Import ${importableRowsCount} Valid ${pluralize('Row', importableRowsCount)}`
              ),
              content: (
                importableRowsCount > 1
                  ? 'Are you sure you want to import these rows?'
                  : 'Are you sure you want to import this row?'
              ),
              okText: 'Import',
              onOk() {
                // Do not await. We want the modal to close immediately
                importRows({ model, values, formikProps });
              },
            });
          }}
          disabled={importableRowsCount === 0 || submitting}
          loading={submitting}
          style={{ marginTop: 15, marginBottom: 10 }}
        >
          {values.importingRows
            ? 'Importing...'
            : `Import ${importableRowsCount} ${pluralize('Row', importableRowsCount)}`}
        </Button>
        <div className='help-text'>
          <em>You can import the same CSV file multiple times without worrying about duplicating records.</em>&nbsp;
          <em>If we find an existing record in Chirp, the matching CSV row will be skipped during the import.</em>
        </div>
      </div>
      {values.showImportingProgress && (
        <Progress
          showInfo
          percent={Math.ceil(values.importingProgress)}
          status={
            !values.importingRows && importErrorRowsCount > 0
              ? 'exception'
              : undefined}
          style={{ marginBottom: 25 }}
        />
      )}
      {importErrorRowsCount > 0 && (
        <Alert
          showIcon
          type='error'
          message={(
            `${importErrorRowsCount} ${pluralize('row', importErrorRowsCount)} ` +
            'failed to import'
          )}
          style={{ marginBottom: 25 }}
        />
      )}
    </>
  )
}

export default ModelImportProgress;
