import { UnitTableFragment, UnitTableFragmentDoc } from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';

import * as actions from './actions';
import * as columns from './columns';
import model from './model';

model.actions.setDefaultActions([
  actions.residentAuditAction,
  actions.createKeyAction,
]);

const commonColumns = [
  columns.unitNumberColumn,
  columns.buildingColumn,
  columns.typeColumn,
  columns.sourceIdColumn,
  columns.unitGroupColumn,
  columns.occupancyColumn,
  columns.totalSmartLocksColumn,
  columns.smartLocksColumn,
  createdAtColumn,
];

export const UnitsMainTable = model.createTable<UnitTableFragment>({
  fragment: UnitTableFragmentDoc,
  columns: [
    columns.propertyColumn,
    ...commonColumns,
  ],
  fetchPolicy: 'cache-first',
});

export const UnitsByPropertyTable = model.createTable<UnitTableFragment, string>({
  fragment: UnitTableFragmentDoc,
  fixedQueryFilters: (propertyId) => ({
    propertyId: { _eq: propertyId },
  }),
  columns: [
    ...commonColumns,
  ],
  fetchPolicy: 'cache-first',
});

export const UnitsByUnitGroupTable = model.createTable<UnitTableFragment, string>({
  fragment: UnitTableFragmentDoc,
  fixedQueryFilters: (unitGroupId) => ({
    unitGroupId: { _eq: unitGroupId },
  }),
  columns: [
    columns.unitNumberColumn,
    columns.buildingColumn,
    columns.sourceIdColumn,
    columns.occupancyColumn,
    columns.totalSmartLocksColumn,
    columns.smartLocksColumn,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});
