import { FormikProps } from 'formik';
import { isEmpty, orderBy } from 'lodash';
import React from 'react';

import { IModel } from '../../models/typings';

import CustomFormField from './CustomFormField';
import { FORM_ITEM_DEFAULT_LAYOUT } from './form-fields'

export interface IModelFieldProps {
  formikProps: FormikProps<any>;
  canUpdateRow: boolean;
  isNew: boolean;
  model: IModel;
  typeSchema: any;
}

export const ModelFields: React.FC<IModelFieldProps> = ({
  formikProps,
  canUpdateRow,
  isNew,
  model,
  typeSchema,
}) => {
  const { values } = formikProps;

  const weightedFieldNames = Object.keys(typeSchema).map(fieldName => {
    let weight = 4; // Default

    if (fieldName === model.primaryKey) {
      weight = 1;
    } else if (fieldName === 'createdAt') {
      weight = 2;
    } else if (fieldName === 'updatedAt') {
      weight = 3;
    }

    return { fieldName, weight };
  });

  const sortedFieldNames = orderBy(weightedFieldNames, f => f.weight, 'asc').map(f => f.fieldName);

  return (
    <>
      {sortedFieldNames.map((fieldName) => {
        if (
          !fieldName.match(/Id/) ||
          model.primaryKey === fieldName ||
          fieldName === 'assignedToAnonymousUserId' ||
          fieldName === 'imageId' ||
          fieldName === 'sourceId'
        ) {
          const field = model.introspection.fields.find(f => f.name === fieldName);

          if (!field || (isNew && !field.canCreate)) {
            return null; // Do not render field for "New" form
          }

          return (
            <CustomFormField
              formikProps={formikProps}
              canUpdateRow={canUpdateRow}
              formItemLayout={FORM_ITEM_DEFAULT_LAYOUT}
              key={fieldName}
              model={model}
              field={field}
              typeSchema={typeSchema}
              isNew={isNew}
            />
          );
        }

        return null;
      })}
      {model.formOptions?.renderExtra && values && !isEmpty(values) ? (
        model.formOptions.renderExtra(formikProps, isNew)
      ) : null}
    </>
  )
}

export default ModelFields;
