import React from 'react';
import { Link } from 'react-router-dom';

import {
  PermissionScope_enum,
  PropertySmartLockFragment,
  PropertySmartLockFragmentDoc,
  SmartLockActionFragmentDoc,
  SmartLockTableFragment,
  SmartLockTableFragmentDoc,
  UnitSmartLockFragment,
  UnitSmartLockFragmentDoc,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import { createdAtColumn } from '../common/columns';

import * as actions from './actions';
import * as columns from './columns';
import model from './model';

model.actions.setDefaultActions([
  actions.createKeyAction,
], SmartLockActionFragmentDoc);

const commonColumns = [
  columns.nameColumn,
  columns.sourceIdColumn,
  columns.serialNumberColumn,
  columns.createdByUserColumn,
  createdAtColumn,
];

const description = () => {
  const { permissionScope, scopedId } = authentication.currentDataScope;

  const smartLockReportsElement = scopedId && permissionScope === PermissionScope_enum.PROPERTY
    ? <Link to={`/properties/smart-locks-report/${scopedId}`}>Smart Locks Report</Link>
    : <>Smart Locks Report</>;

  return (
    <>
      Battery info, available firmware updates, and alerts can be found in
      the Details page or the property's {smartLockReportsElement}.
    </>
  );
};

export const SmartLocksMainTable = model.createTable<SmartLockTableFragment>({
  description,
  fragment: SmartLockTableFragmentDoc,
  columns: [
    columns.propertyColumn,
    columns.unitColumn,
    ...commonColumns,
  ],
});

export const SmartLocksByPropertyTable = model.createTable<PropertySmartLockFragment, string>({
  fragment: PropertySmartLockFragmentDoc,
  fixedQueryFilters: (propertyId) => ({
    unit: {
      propertyId: { _eq: propertyId },
    },
  }),
  columns: [
    columns.unitColumn,
    ...commonColumns,
  ],
});

export const SmartLocksByUnitTable = model.createTable<UnitSmartLockFragment, string>({
  fragment: UnitSmartLockFragmentDoc,
  fixedQueryFilters: (unitId) => ({
    unitId: { _eq: unitId },
  }),
  columns: [
    ...commonColumns,
  ],
});

export const RelatedSmartLocksTable = model.createTable<UnitSmartLockFragment, string>({
  title: 'Related Smart Locks',
  fragment: UnitSmartLockFragmentDoc,
  fixedQueryFilters: (smartLockId) => ({
    smartLockId: { _neq: smartLockId },
    unit: {
      smartLocks: {
        smartLockId: { _eq: smartLockId },
      },
    },
  }),
  columns: [
    ...commonColumns,
  ],
});
