import mem from 'mem';

import introspect from './introspect';

async function getModelType(model: string) {
  const introspection = await introspect();
  return (
    introspection.types.find((typeDef) => typeDef.name === model) || false
  );
}

export default mem(getModelType);
