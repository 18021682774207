
import { Typography } from 'antd';
import React from 'react';

import { ModelFormSelect } from '../../ModelForm/ModelFormSelect';

import { IRegisteredUserFormMergedProps } from './formik';

export const ExistingUserSection: React.FC<IRegisteredUserFormMergedProps> = (props) => {
  const { values, setFieldValue, isSubmitting, UserModel } = props;
  const canCreateRegisteredUsers = UserModel.permissions.canCreate();

  return (
    <>
      <Typography.Paragraph>
        Search for an existing Chirp user to grant access to.
        {canCreateRegisteredUsers && <>  If you cannot find who you're looking for, try entering them as a new user.</>}
      </Typography.Paragraph>
      <ModelFormSelect
        label='User'
        fieldName='userId'
        formItemProps={{ className: 'label-align-top' }}
        modelSelectProps={{
          style: { width: '100%' },
          model: UserModel,
          value: values.userId,
          allowClear: true,
          required: true,
          disabled: isSubmitting,
        }}
        setFieldValue={setFieldValue}
        errors={props.errors}
        submitCount={1}
      />
    </>
  );
};
