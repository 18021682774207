import { orderBy } from 'lodash';

import {
  AugustLockAccessAuditFragment,
  AugustLockUserFragment,
  AugustPinCodeFragment,
} from '../../../../graphql/api/generated';
import {
  AugustAccessUserRoleFragment,
  Custom_AugustLockUserRoleFragment,
  RoleLabelFragment,
} from '../../../../graphql/hasura/generated';
import { formatUserName } from '../../../../utils';

export interface IAttachedChirpRoleData {
  role: RoleLabelFragment | null | undefined;
  userRole: AugustAccessUserRoleFragment | null | undefined;
}

export interface IAugustMobileAccessUser extends IAttachedChirpRoleData {
  augustLockUser: AugustLockUserFragment;
}

export interface IAugustPinCodeUser extends IAttachedChirpRoleData {
  augustPinCode: AugustPinCodeFragment;
}

interface ISelectAugustAccessDataArgs {
  augustLock: AugustLockAccessAuditFragment | null | undefined;
  augustLockUserRoles: Custom_AugustLockUserRoleFragment[];
}

export function selectAugustAccessData(args: ISelectAugustAccessDataArgs) {
  const { augustLock, augustLockUserRoles } = args;

  if (!augustLock) {
    return {
      mobileAccessUsers: [],
      augustPinCodeUsers: [],
      noAccessUserRoles: [],
    };
  }

  const { usersList, pinCodes } = augustLock;

  const mobileAccessUsers: IAugustMobileAccessUser[] = usersList.map((user) => {
    const augustLockUserRole = augustLockUserRoles.find(u => (
      u.augustUserId && u.augustUserId === user.userId
    ));

    return {
      augustLockUser: user,
      role: augustLockUserRole?.role,
      userRole: augustLockUserRole?.userRole,
    };
  });

  const augustPinCodeUsers: IAugustPinCodeUser[] = [];

  for (const pinCode of pinCodes) {
    if (pinCode.unverified && pinCode.partnerUserID) {
      const augustLockUserRole = augustLockUserRoles.find(u => u.userRoleId === pinCode.partnerUserID);

      augustPinCodeUsers.push({
        augustPinCode: pinCode,
        role: augustLockUserRole?.role,
        userRole: augustLockUserRole?.userRole,
      });
    } else {
      const augustLockUserRole = augustLockUserRoles.find(u => u.augustUserId === pinCode.userID);
      const hasMobilePermission = !!augustLockUserRole?.permissions.includes('MOBILE');

      if (!hasMobilePermission) {
        augustPinCodeUsers.push({
          augustPinCode: pinCode,
          role: augustLockUserRole?.role,
          userRole: augustLockUserRole?.userRole,
        });
      }
    }
  }

  const noAccessUserRoles = augustLockUserRoles
    .filter(({ userRole, permissions }) => {
      if (!userRole) {
        return false;
      }

      const hasMobilePermission = !!permissions.includes('MOBILE');
      const hasMobileAccess = mobileAccessUsers.some(u => u.userRole?.userRoleId === userRole.userRoleId);

      return !permissions.length || (hasMobilePermission && !hasMobileAccess);
    })
    .map(u => u.userRole) as AugustAccessUserRoleFragment[];

  return {
    mobileAccessUsers: orderBy(mobileAccessUsers, u => (
      u.userRole?.assignedToUser
        ? formatUserName(u.userRole.assignedToUser, false)
        : formatUserName({ firstName: u.augustLockUser.FirstName, lastName: u.augustLockUser.LastName })
    )),
    augustPinCodeUsers: orderBy(augustPinCodeUsers, u => (
      u.userRole?.assignedToUser
        ? formatUserName(u.userRole.assignedToUser, false)
        : formatUserName(u.augustPinCode, false)
    )),
    noAccessUserRoles: orderBy(noAccessUserRoles, u => formatUserName(u.assignedToUser, false)),
  };
}

export default selectAugustAccessData;
