import { create } from 'mobx-persist';

export const hydrate = create({
  debounce: 100,
  jsonify: true,
});

export default hydrate;

export class Hydrated {
  // Allow us to wait for the store to be ready
  private hydratePromise: Promise<any> | null = null;

  constructor(className?: string) {
    if (className) {
      this.hydratePromise = hydrate(className, this);
    }
  }
  public ready() {
    return this.hydratePromise;
  }
}
