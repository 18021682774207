import {
  AllowedPermissionFragment as Permission,
  PermissionKey_enum,
} from '../graphql/hasura/generated';
import { authentication } from '../stores';

import {
  IModelIntrospection,
  IModelPermissionChecks,
  IModelPermissions,
  IModelPermissionsOptions,
} from './typings';

export class ModelPermissions<TRequiredRowData> implements IModelPermissions<TRequiredRowData> {

  public readonly introspection: IModelIntrospection;
  public readonly defaultPermissionChecks: IModelPermissionChecks;

  private readonly modelName: string;
  private readonly options?: IModelPermissionsOptions<TRequiredRowData>;

  constructor(
    modelName: string,
    introspection: IModelIntrospection,
    options?: IModelPermissionsOptions<TRequiredRowData>,
  ) {
    this.modelName = modelName;
    this.introspection = introspection;
    this.options = options;

    this.defaultPermissionChecks = {
      canCreate: () => this.introspection.canCreate && this.hasCrudPermission('Create'),
      canRead: () => this.introspection.canRead && this.hasCrudPermission('Read'),
      canUpdate: () => this.introspection.canUpdate && this.hasCrudPermission('Update'),
      canDelete: () => this.introspection.canDelete && this.hasCrudPermission('Delete'),
    };
  }

  // @TODO: Pass authentication to the class to decouple

  public get currentPermissions(): Permission[] {
    return authentication.currentPermissions;
  }

  public get currentPermissionScope() {
    return authentication.currentDataScope.permissionScope;
  }

  public get limitStratisPermissions(): boolean {
    return authentication.limitStratisPermissions;
  }

  public hasPermission(permissionKey: PermissionKey_enum): boolean {
    return authentication.hasPermission(permissionKey);
  }

  public canCreate = (): boolean => {
    return typeof this.options?.canCreate === 'function'
      ? this.options.canCreate(this)
      : this.defaultPermissionChecks.canCreate();
  }

  public canRead = (): boolean => {
    return typeof this.options?.canRead === 'function'
      ? this.options.canRead(this)
      : this.defaultPermissionChecks.canRead();
  }

  public canUpdate = (): boolean => {
    return typeof this.options?.canUpdate === 'function'
      ? this.options.canUpdate(this)
      : this.defaultPermissionChecks.canUpdate();
  }

  public canUpdateRow = (row: TRequiredRowData): boolean => {
    return typeof this.options?.canUpdateRow === 'function'
      ? this.canUpdate() && this.options.canUpdateRow(this, row)
      : this.canUpdate();
  }

  public canDelete = (): boolean => {
    return typeof this.options?.canDelete === 'function'
      ? this.options.canDelete(this)
      : this.defaultPermissionChecks.canDelete();
  }

  public canDeleteRow = (row: TRequiredRowData): boolean => {
    return typeof this.options?.canDeleteRow === 'function'
      ? this.canDelete() && this.options.canDeleteRow(this, row)
      : this.canDelete();
  }

  private hasCrudPermission(operation: 'Create' | 'Read' | 'Update' | 'Delete') {
    const hasPermission = this.currentPermissions.some(p => (
      p.key === `${this.modelName}_${operation}` // Ex. AccessPoint_Update
    ));

    return hasPermission;
  }
}

export default ModelPermissions;
