import { AccessPointRelay_bool_exp } from '../../graphql/hasura/generated';
import Model from '../Model';

export const AccessPointRelayModel = new Model<AccessPointRelay_bool_exp>({
  names: {
    schemaName: 'AccessPointRelay',
  },
});

export default AccessPointRelayModel;
