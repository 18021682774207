import {
  order_by,
  PermissionKey_enum,
  PermissionScope_enum,
  Role_bool_exp,
  RoleLabelFragment,
  RoleLabelFragmentDoc,
  RoleRequiredRowFragment,
  RoleUniqueLabelFragment,
  RoleUniqueLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import Model from '../Model';

export const RoleModel = new Model<
  Role_bool_exp,
  RoleLabelFragment,
  RoleUniqueLabelFragment,
  RoleRequiredRowFragment
>({
  names: {
    schemaName: 'Role',
  },

  permissionsOptions: {
    canRead: () => true,
    canUpdateRow: ({ hasPermission }, role) => {
      const withinPermissionScope = (
        (role.permissionScope !== PermissionScope_enum.GLOBAL && !!role.organization) ||
        hasPermission(PermissionKey_enum.Role_ManageUnrestricted)
      );

      return !role.isLocked && withinPermissionScope;
    },
    canDeleteRow: ({ hasPermission }, role) => {
      const withinPermissionScope = (
        (role.permissionScope !== PermissionScope_enum.GLOBAL && !!role.organization) ||
        hasPermission(PermissionKey_enum.Role_ManageUnrestricted)
      );

      return !role.key && !role.isLocked && withinPermissionScope;
    },
  },

  labels: {
    getLabel: ({ name }) => name,
    getUniqueLabel: ({ name, uniqueName, organization }) => {
      if (organization) {
        return `${organization.name} - ${uniqueName || name}`;
      }

      return uniqueName || name;
    },
    labelFragment: RoleLabelFragmentDoc,
    uniqueLabelFragment: RoleUniqueLabelFragmentDoc,
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            {
              organization: {
                name: { _ilike: `%${word}%` },
              },
            },
            {
              name: { _ilike: `%${word}%` },
            },
          ],
        }
      ))
    }),

    defaultSelectOrderBy: [
      {
        organization: {
          name: order_by.asc_nulls_first,
        },
      },
      { name: order_by.asc },
    ],
  },
});

export default RoleModel;
