import React from 'react';

import { TableCellDescriptions } from '../../components/TableCellDescriptions';
import { BeaconTableFragment, BeaconTableFragmentDoc, PermissionScope_enum } from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import { formatTimestamp } from '../../utils';
import { createdAtColumn } from '../common/columns';
import { IModelTableExpandable } from '../typings';

import * as columns from './columns';
import model from './model';

export const expandable: IModelTableExpandable<BeaconTableFragment> = {
  rowExpandable: ({ passwordRotatedAt }) => (
    authentication.currentDataScope.permissionScope === PermissionScope_enum.GLOBAL &&
    !!passwordRotatedAt
  ),
  expandedRowRender: ({ passwordRotatedAt }) => {
    const descriptions = {
      'Password Rotated At': formatTimestamp(passwordRotatedAt),
    };

    return <TableCellDescriptions descriptions={descriptions} />
  },
};

export const BeaconsMainTable = model.createTable<BeaconTableFragment>({
  expandable,
  fragment: BeaconTableFragmentDoc,
  columns: [
    columns.propertyColumn,
    columns.accessPointColumn,
    columns.nameColumn,
    columns.uuidColumn,
    columns.macAddressColumn,
    columns.locationColumn,
    createdAtColumn,
  ],
});

export const BeaconsByAccessPointTable = model.createTable<BeaconTableFragment, string>({
  expandable,
  fragment: BeaconTableFragmentDoc,
  fixedQueryFilters: (accessPointId) => ({
    accessPointId: { _eq: accessPointId },
  }),
  columns: [
    columns.nameColumn,
    columns.uuidColumn,
    columns.macAddressColumn,
    columns.locationColumn,
    createdAtColumn,
  ],
});

export const BeaconsByPropertyTable = model.createTable<BeaconTableFragment, string>({
  expandable,
  fragment: BeaconTableFragmentDoc,
  fixedQueryFilters: (propertyId) => ({
    accessPoint: {
      propertyId: { _eq: propertyId },
    },
  }),
  columns: [
    columns.accessPointColumn,
    columns.nameColumn,
    columns.uuidColumn,
    columns.macAddressColumn,
    columns.locationColumn,
    createdAtColumn,
  ],
});
