import { enumManagers } from '@chirp/enums';
import { Alert, Descriptions } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React from 'react';

import { TextWithIconTooltip } from '../../../../components/TextWithIconTooltip';
import {
  formatAugustIdentifiers,
  formatPhoneNumber,
  formatTimestamp,
  formatUserName,
} from '../../../../utils';
import { IModelTableExpandable } from '../../../typings';
import { UserModel } from '../../../User/model';
import { AugustPinCodeStatus } from '../../components/AugustPinCodeStatus';

import { hasGlobalUserReadPermission, renderChirpRole } from './column-helpers';
import { IAugustMobileAccessUser } from './select-august-access-data';

export const mobileAccessColumns: ColumnProps<IAugustMobileAccessUser>[] = [
  {
    title: 'Name',
    render: (_, { augustLockUser, userRole }) => {
      const userLink = UserModel.routes.renderRowLink(userRole?.assignedToUser);

      if (userLink) {
        return userLink;
      }

      const { FirstName: firstName, LastName } = augustLockUser;

      // Hide last name if they're not allowed to view the Chirp user
      const lastName = !userRole?.assignedToUser && !hasGlobalUserReadPermission()
        ? LastName.charAt(0)
        : LastName;

      return formatUserName({ firstName, lastName }, false);
    },
  },
  {
    title: 'Role',
    render: (_, { role, userRole }) => {
      return renderChirpRole({ role, userRole });
    },
  },
  {
    title: 'Phone Number',
    render: (_, { userRole }) => {
      return formatPhoneNumber(userRole?.assignedToUser?.phoneNumber);
    },
  },
  {
    title: 'Email',
    render: (_, { userRole }) => {
      return userRole?.assignedToUser?.email;
    },
  },
  {
    title: 'August User Type',
    render: (_, { augustLockUser }) => augustLockUser.UserType,
  },
  {
    title: 'August User ID',
    render: (_, { augustLockUser, userRole }) => {
      if (userRole?.assignedToUser?.augustUserId === augustLockUser.userId) {
        return (
          <TextWithIconTooltip
            text={augustLockUser.userId}
            tooltip={{ title: 'Legacy User ID' }}
          />
        );
      }

      return augustLockUser.userId;
    },
  },
  {
    title: 'PIN Code',
    render: (_, { augustLockUser }) => {
      return augustLockUser.pinCodeStatus
        ? <AugustPinCodeStatus status={augustLockUser.pinCodeStatus} />
        : null;
    },
  },
  {
    title: 'Alerts',
    render: (_, { augustLockUser, role, userRole }) => {
      const alerts: string[] = [];

      const renderAlerts = (type: 'warning' | 'error' = 'warning') => {
        if (alerts.length) {
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              {alerts.map((warning, index) => (
                <Alert
                  key={warning}
                  showIcon
                  type={type}
                  message={warning}
                  style={{ marginBottom: index !== alerts.length + 1 ? 10 : 0 }}
                />
              ))}
            </div>
          );
        }

        return null;
      };

      // Use role instead of userRole in case UserRole cannot be read
      if (!role) {
        alerts.push('This user has no active Chirp Role');

        return renderAlerts('error');
      }

      const { identifiers } = augustLockUser;
      const user = userRole?.assignedToUser;

      if (!user || user.augustSubUserId === augustLockUser.userId) {
        return null; // August sub-user accounts will not have phone numbers or emails
      }

      const { phoneNumber, email } = user;

      const phoneNumbers = formatAugustIdentifiers(identifiers, 'phone:');
      const emails = formatAugustIdentifiers(identifiers, 'email:');

      if (
        (phoneNumber && !phoneNumbers.includes(phoneNumber)) ||
        (!phoneNumber && phoneNumbers.length)
      ) {
        alerts.push('August phone number does not match Chirp');
      }

      if (
        (email && !emails.includes(email)) ||
        (!email && emails.length)
      ) {
        alerts.push('August email does not match Chirp');
      }

      return renderAlerts();
    },
  }
];

const { Item } = Descriptions;

export const mobileAccessExpandable: IModelTableExpandable<IAugustMobileAccessUser> = {
  rowExpandable: ({ userRole }) => !!userRole,
  expandedRowRender: ({ userRole, augustLockUser }) => {
    if (!userRole) {
      return null;
    }

    const { activatesAt, expiresAt, reason } = userRole;

    let augustPhoneNumbers = '';
    let augustEmails = '';

    if (userRole?.assignedToUser?.augustUserId === augustLockUser.userId) {
      const { identifiers } = augustLockUser;
      const phoneNumbers = formatAugustIdentifiers(identifiers, 'phone:');
      const emails = formatAugustIdentifiers(identifiers, 'email:');

      augustPhoneNumbers = phoneNumbers.map((phoneNumber) => (
        !userRole?.assignedToUser && !hasGlobalUserReadPermission()
          // Mask phone number if they're not allowed to view the Chirp user
          ? '(***) ***-****'
          : formatPhoneNumber(phoneNumber)
      )).join(', ');

      augustEmails = emails.map((email) => (
        !userRole?.assignedToUser && !hasGlobalUserReadPermission()
          // Mask email if they're not allowed to view the Chirp user
          ? email.replace(new RegExp('[^@.]', 'g'), '*')
          : formatPhoneNumber(email)
      )).join(', ');
    }

    return (
      <Descriptions column={1} style={{ width: '100%' }}>
        {augustPhoneNumbers && (
          <Item span={1} label='August Phone Number'>{augustPhoneNumbers}</Item>
        )}
        {augustEmails && (
          <Item span={1} label='August Email'>{augustEmails}</Item>
        )}
        {activatesAt && (
          <Item span={1} label='Access Activation Date'>{formatTimestamp(activatesAt)}</Item>
        )}
        {expiresAt && (
          <Item span={1} label='Access Expiration Date'>{formatTimestamp(expiresAt)}</Item>
        )}
        {reason && (
          <Item span={1} label='Reason for Granting'>
            {enumManagers.UserRoleReason.getLabel(reason)}
          </Item>
        )}
      </Descriptions>
    );
  },
};
