import { Color } from '../../utils';

export const ELIGIBILITY_FILTERS = [
  'Eligible',
  'Data Scrub',
  'Ineligible',
];

export const DATA_SCRUB_REQUIREMENT_FILTERS = [
  'Unknown age',
  'Missing move-in date',
  'Missing mobile phone number',
  'Invalid mobile phone number',
  'Non-unique mobile phone number',
];

export const DATA_SCRUB_WARNING_FILTERS = [
  'Chirp profile does not match PMS',
];

export const ELIGIBILITY_COLORS: { [key: string]: Color } = {
  'Data Scrub': Color.Red,
  Eligible: Color.Green,
};

// https://github.com/ant-design/ant-design/issues/18104#issuecomment-518658522
export const STATISTIC_COL_STYLE = {
  flexBasis: '25%',
  width: '25%',
};
