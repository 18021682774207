import { CheckOutlined } from '@ant-design/icons';
import { enumManagers } from '@chirp/enums';
import { Checkbox, Table } from 'antd';
import { orderBy } from 'lodash';
import React from 'react';

import {
  PermissionScope_enum,
  RelatableRoleFragment,
  RoleDetailsFragment,
  RoleKey_enum,
  RoleRelationshipDetailsFragment,
} from '../../../graphql/hasura/generated';
import { PERMISSION_SCOPE_WEIGHTS } from '../../helpers/permission-scopes';
import OrganizationModel from '../../Organization/model';
import RoleModel from '../../Role/model';
import { IModelColumn } from '../../typings';
import useEditRoleRelationship from '../hooks/useEditRoleRelationship';

interface IRoleRelationshipTableProps {
  role: RoleDetailsFragment;
  relatableRoles: RelatableRoleFragment[];
  readOnly: boolean;
}

interface IRoleWithRoleRelationship {
  role: RelatableRoleFragment;
  roleRelationship?: RoleRelationshipDetailsFragment;
  disabled: boolean;
  weight: number;
}

export const RoleRelationshipTable: React.FC<IRoleRelationshipTableProps> = (props) => {
  const { relatableRoles, readOnly } = props;
  const { roleRelationshipsByRole } = props.role;

  // First get role relationships that user is able to manage
  const rolesWithRelationships: IRoleWithRoleRelationship[] = relatableRoles.map((relatableRole) => {
    return {
      role: relatableRole,
      disabled: false,
      // Attach existing role relationship, if applicable
      roleRelationship: roleRelationshipsByRole.find(r => (
        r.relatedRole.roleId === relatableRole.roleId
      )),
      weight: PERMISSION_SCOPE_WEIGHTS[relatableRole.permissionScope],
    };
  });

  for (const roleRelationship of roleRelationshipsByRole) {
    // Attach role relationships that cannot be managed by user
    if (!rolesWithRelationships.some(r => r.role.roleId === roleRelationship.relatedRole.roleId)) {
      rolesWithRelationships.push({
        roleRelationship,
        role: roleRelationship.relatedRole,
        disabled: true,
        weight: PERMISSION_SCOPE_WEIGHTS[roleRelationship.relatedRole.permissionScope],
      });
    }
  }

  const editRoleRelationship = useEditRoleRelationship(props.role);

  const sortedRolesWithRelationships = orderBy(rolesWithRelationships, [
    r => r.role.organization?.name,
    r => r.weight,
    r => r.role.rolePermissions_aggregate.aggregate?.count,
  ], ['asc', 'asc', 'desc']);

  const isGlobalRole = props.role.permissionScope === PermissionScope_enum.GLOBAL;

  const columns: IModelColumn<IRoleWithRoleRelationship>[] = [
    {
      title: 'Organization',
      enabled: () => true,
      render: ({ role }) => {
        const organization = role.organization;

        return OrganizationModel.routes.renderRowLink(organization);
      },
    },
    {
      title: 'Managed Role',
      enabled: () => true,
      render: ({ role }) => RoleModel.routes.renderRowLink(role),
    },
    {
      title: 'Permission Scope',
      enabled: () => true,
      render: ({ role }) => enumManagers.PermissionScope.getLabel(role.permissionScope),
    },
    {
      title: 'Can Read',
      enabled: () => !isGlobalRole,
      render: ({ role, roleRelationship, disabled }) => {
        const canReadUserRole = roleRelationship?.canReadUserRole;

        if (readOnly) {
          return canReadUserRole ? <CheckOutlined /> : null;
        }

        return (
          <Checkbox
            checked={canReadUserRole}
            disabled={disabled}
            onChange={(e) => {
              const { checked } = e.target;

              editRoleRelationship(role, {
                ...roleRelationship,
                canReadUserRole: checked,
                canRevokeUserRole: !checked ? false : roleRelationship?.canRevokeUserRole,
              });
            }}
          />
        );
      },
    },
    {
      title: 'Can Grant',
      enabled: () => true,
      render: ({ role, roleRelationship, disabled }) => {
        const canGrantUserRole = roleRelationship?.canGrantUserRole;

        if (readOnly) {
          return canGrantUserRole ? <CheckOutlined /> : null;
        }

        return (
          <Checkbox
            checked={canGrantUserRole}
            // Right now reservations can only be created via the app by residents
            disabled={disabled || role.key === RoleKey_enum.RESERVATION}
            onChange={(e) => {
              const { checked } = e.target;

              editRoleRelationship(role, {
                ...roleRelationship,
                canGrantUserRole: checked,
              });
            }}
          />
        );
      },
    },
    {
      title: 'Can Revoke',
      enabled: () => true,
      render: ({ role, roleRelationship, disabled }) => {
        const canRevokeUserRole = roleRelationship?.canRevokeUserRole;
        const canReadUserRole = roleRelationship?.canReadUserRole;

        if (readOnly) {
          return canRevokeUserRole ? <CheckOutlined /> : null;
        }

        return (
          <Checkbox
            checked={canRevokeUserRole}
            disabled={disabled || (!canReadUserRole && !isGlobalRole)}
            onChange={(e) => {
              editRoleRelationship(role, {
                ...roleRelationship,
                // Toggle Read/Revoke together for global roles since Read access is actually
                // controlled through the User_Read permission rather than RoleRelationships
                canReadUserRole: isGlobalRole ? e.target.checked : roleRelationship?.canReadUserRole,
                canRevokeUserRole: e.target.checked,
              });
            }}
          />
        );
      },
    },
  ];

  const normalizedColumns = columns.filter(c => c.enabled());

  return (
    <Table
      rowKey={r => r.role.roleId}
      dataSource={sortedRolesWithRelationships}
      columns={normalizedColumns}
      scroll={{ x: true }}
      pagination={false}
    />
  );
};

export default RoleRelationshipTable;
