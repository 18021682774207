import { PermissionKey_enum, PermissionScope_enum } from '../../graphql/hasura/generated';

import model from './model';
import HubDetailsPage from './pages/HubDetailsPage';
import HubHealthReportsPage from './pages/HubHealthReportsPage';

model.routes.setRoutes({
  detailsRoute: {
    component: HubDetailsPage,
  },
  customRoutes: [
    {
      component: HubHealthReportsPage,
      path: `${model.routes.basePath}/health/:hubId`,
      enabled: ({ currentPermissionScope, hasPermission }) => (
        currentPermissionScope === PermissionScope_enum.GLOBAL &&
        hasPermission(PermissionKey_enum.Hub_Read)
      ),
    },
  ],
});
