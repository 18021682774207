import {
  OrganizationsMainTableFragment,
  OrganizationsMainTableFragmentDoc,
} from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';

import * as columns from './columns';
import model from './model';

export const OrganizationsMainTable = model.createTable<OrganizationsMainTableFragment>({
  fragment: OrganizationsMainTableFragmentDoc,
  columns: [
    columns.nameColumn,
    columns.identifierColumn,
    columns.totalPropertiesColumn,
    columns.totalRolesColumn,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});
