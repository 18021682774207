import { HistoryOutlined, ReloadOutlined } from '@ant-design/icons';
import { ApolloError } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';

import { displayErrorMessage } from '../../utils';

interface IRefreshButtonProps {
  disabled: boolean;
  refresh?: () => any;
  useSubscription?: boolean;
  onError?: ((error: ApolloError | Error) => any) | false;
  buttonText?: string;
  buttonStyle?: React.CSSProperties;
}

const refreshButtonStyle = {
  marginRight: 8,
};

export const RefreshButton = (props: IRefreshButtonProps) => {
  const { disabled, refresh, useSubscription, buttonText, buttonStyle, onError } = props;
  const [loading, setLoading] = useState(false);

  if (useSubscription) {
    return (
      <div style={{ ...refreshButtonStyle, padding: '5px 0', ...buttonStyle }}>
        <Tooltip title='Updates to this data will be displayed in real time' placement='left'>
          <div>
            <HistoryOutlined /> Live Updates Enabled
          </div>
        </Tooltip>
      </div>
    );
  }

  return (
    <Button
      icon={<ReloadOutlined />}
      loading={loading}
      disabled={disabled || loading}
      style={{ ...refreshButtonStyle, ...buttonStyle }}
      onClick={async () => {
        if (typeof refresh === 'function') {
          setLoading(true);

          try {
            await refresh();
          } catch (error) {
            if (onError === undefined) {
              displayErrorMessage(error as Error); // Default behavior
            } else if (typeof onError === 'function') {
              onError(error as Error);
            }
          }

          setLoading(false);
        }
      }}
    >
      {buttonText || 'Refresh'}
    </Button>
  );
}

export default RefreshButton;
