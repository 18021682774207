import React from 'react';

import { TableActions } from '../../components/TableActions/TableActions';
import { Refetch, RefetchContext } from '../../utils';
import { formatTimestamp } from '../../utils/formatters';
import { IModelAction, IModelColumn } from '../typings';

export const createdAtColumn: IModelColumn<{ createdAt: string }> = {
  title: 'Created',
  dataIndex: 'createdAt',
  enabled: () => true,
  render: ({ createdAt }) => formatTimestamp(createdAt),
  filterOptions: {
    type: 'DATE_RANGE',
    disableFutureDates: true,
  },
};

export const updatedAtColumn: IModelColumn<{ updatedAt: string }> = {
  title: 'Updated',
  dataIndex: 'updatedAt',
  enabled: () => true,
  render: ({ updatedAt }) => formatTimestamp(updatedAt),
  filterOptions: {
    type: 'DATE_RANGE',
    disableFutureDates: true,
  },
};

interface ICreateActionsColumnArgs<TRow> {
  actions: IModelAction<TRow>[];
  refetch?: Refetch;
}

export function createActionsColumn<TRow = any>(args: ICreateActionsColumnArgs<TRow>) {
  const { actions, refetch } = args;

  return {
    title: 'Actions',
    key: 'actions',
    render: (_: any, row: any) => {
      if (typeof refetch === 'function') {
        return (
          <RefetchContext.Provider value={refetch}>
            <TableActions actions={actions} row={row} />
          </RefetchContext.Provider>
        );
      }

      return <TableActions actions={actions} row={row} />;
    },
  };
}
