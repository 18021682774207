import {
  CredentialTableFragment,
  CredentialTableFragmentDoc,
  PropertyCredentialFragment,
  PropertyCredentialFragmentDoc,
  UserCredentialFragment,
  UserCredentialFragmentDoc,
} from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';

import * as columns from './columns';
import model from './model';

export const CredentialsMainTable = model.createTable<CredentialTableFragment>({
  fragment: CredentialTableFragmentDoc,
  columns: [
    columns.propertyColumn,
    columns.typeColumn,
    columns.identifierColumn,
    columns.facilityCodeColumn,
    columns.cardNumberColumn,
    columns.assignedToColumn,
    columns.provisionedByColumn,
    createdAtColumn,
  ],
});

export const CredentialsByUserTable = model.createTable<UserCredentialFragment, string>({
  fragment: UserCredentialFragmentDoc,
  fixedQueryFilters: (userId) => ({
    assignedToUserId: { _eq: userId },
  }),
  columns: [
    columns.propertyColumn,
    columns.typeColumn,
    columns.identifierColumn,
    columns.facilityCodeColumn,
    columns.cardNumberColumn,
    columns.provisionedByColumn,
    createdAtColumn,
  ],
  useSubscription: true,
});

export const FobsByPropertyTable = model.createTable<PropertyCredentialFragment, string>({
  title: 'Fobs',
  fragment: PropertyCredentialFragmentDoc,
  fixedQueryFilters: (propertyId) => ({
    ownedByPropertyId: { _eq: propertyId },
  }),
  columns: [
    columns.identifierColumn,
    columns.facilityCodeColumn,
    columns.cardNumberColumn,
    columns.assignedToColumn,
    columns.provisionedByColumn,
    createdAtColumn,
  ],
});
