import { set } from 'lodash';

import type { ColumnFilters, IModel, IModelColumn } from '../../../models/typings';

interface IFormatOperationFiltersArgs {
  columns: IModelColumn<any>[];
  columnFilters: ColumnFilters;
  model: IModel;
}

export function formatOperationFilters(args: IFormatOperationFiltersArgs) {
  const { columns, columnFilters, model } = args;

  return Object.keys(columnFilters).reduce<any[]>((boolExpressions, filterKey) => {
    const values = columnFilters[filterKey];

    if (!values?.length) {
      return boolExpressions;
    }

    const column = columns.find(c => c.key === filterKey);
    const filterOptions = column?.filterOptions;

    if (filterOptions?.type === 'MODEL_SELECT') {
      return [...boolExpressions, set({}, filterKey, { _in: values })];
    }

    if (filterOptions?.type === 'ENUM') {
      const enumFieldName = model.introspection.relationships
        .find(r => r.targetModelName === filterOptions.enumTable)?.sourceField;

      if (enumFieldName) {
        return [...boolExpressions, { [enumFieldName]: { _in: values } }];
      }
    }

    if (filterOptions?.type === 'DATE_RANGE') {
      const [startDate, endDate] = values;
      const dateRange = { _gte: startDate, _lte: endDate };

      if (startDate && endDate) {
        return [...boolExpressions, { [filterKey]: dateRange }];
      }
    }

    if (filterOptions?.type === 'RADIO') {
      const { options: radioOptions } = filterOptions;
      const [value] = values;

      if (radioOptions?.length) {
        const radioQueryFilters = radioOptions.find(o => o.label === value)?.queryFilters;

        // Use custom radio options
        if (radioQueryFilters) {
          return [...boolExpressions, radioQueryFilters];
        }
      } else {
        // Default to boolean field behavior
        return [...boolExpressions, {
          [filterKey]: { _eq: value === 'Yes' ? true : false },
        }];
      }
    }

    return boolExpressions;
  }, []);
}
