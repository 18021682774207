import { Button, Select } from 'antd';
import React, { useState } from 'react';

import { AccessPointWithMapLocationFragment } from '../../../graphql/hasura/generated';
import { generateWordsFromSearch } from '../../../utils';

interface IAccessPointMapLocationFormProps {
  accessPoints: AccessPointWithMapLocationFragment[];
  closeForm: () => void;
  onSaveLocation: (accessPointId: string) => Promise<any>;
}

export const AccessPointMapLocationForm: React.FC<IAccessPointMapLocationFormProps> = (props) => {
  const { accessPoints, closeForm, onSaveLocation } = props;
  const [accessPointId, setAccessPointId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: 20,
        border: '1px solid rgba(0, 0, 0, 0.2)',
      }}
    >
      <Select
        style={{ width: '100%' }}
        showSearch
        allowClear
        placeholder='Select access point to place in this location'
        onChange={(value: string) => {
          setAccessPointId(value);
        }}
        filterOption={(inputValue, option) => {
          if (!inputValue) {
            return true;
          }

          const searchWords = generateWordsFromSearch(inputValue);

          return option
            ? searchWords.every(word => String(option.label).toLowerCase().includes(word))
            : false;
        }}
        options={accessPoints.map(ap => ({ label: ap.name, value: ap.accessPointId }))}
      />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <Button
          type='primary'
          loading={loading}
          onClick={async () => {
            if (accessPointId) {
              setLoading(true);
              await onSaveLocation(accessPointId);
              setLoading(false);
            }
          }}
          disabled={!accessPointId || loading}
        >
          Save
        </Button>
        <Button
          loading={loading}
          disabled={loading}
          onClick={closeForm}
          style={{ marginLeft: 8 }}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default AccessPointMapLocationForm;
