import { CaretDownOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React, { PureComponent } from 'react';

import { authentication } from '../../stores';
import ChangeDataScopeModal from '../ChangeDataScopeModal';

import styles from './index.module.less';
import RightContent from './RightContent';

interface IGlobalHeaderProps {
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => any;
}

interface IGlobalHeaderState {
  modalVisible: boolean;
}

class GlobalHeader extends PureComponent<IGlobalHeaderProps, IGlobalHeaderState> {
  state: IGlobalHeaderState = {
    modalVisible: false,
  };

  triggerResizeEvent = () => {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
    this.triggerResizeEvent();
  }

  render() {
    const { collapsed } = this.props;
    const { currentRoleNames, currentDataScope } = authentication;

    const roleTitle = currentRoleNames.length > 1
      ? `${currentRoleNames.length} ${currentDataScope.permissionScope?.toLowerCase()} roles`
      : `${currentRoleNames[0] || ''}`;

    return (
      <>
        <div className={styles.globalHeaderContainer}>
          <span className={styles.toggleMenu} onClick={this.toggle}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </span>

          <span
            className={`${styles.dataScopeInfo} ${styles.action}`}
            onClick={() => {
              this.setState({ modalVisible: true });
            }}
          >
            <span>{currentDataScope.scopeLabel}</span>
            <CaretDownOutlined style={{ fontSize: 9, marginLeft: 10 }} />
            <br />
            <span className={styles.subtitle}>{roleTitle}</span>
          </span>

          <RightContent authentication={authentication} />
        </div>
        <ChangeDataScopeModal
          visible={this.state.modalVisible}
          onCancel={() => this.setState({ modalVisible: false })}
        />
      </>
    );
  }
}

export default GlobalHeader;
