import { CheckOutlined } from '@ant-design/icons';
import { EngrainMap } from '@chirp/engrain-client';
import { Card, notification, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import EnumTag from '../../../components/EnumTag';
import ModelBreadcrumbs from '../../../components/ModelBreadcrumbs';
import TableActions from '../../../components/TableActions/TableActions';
import {
  PropertyMapTableFragment,
  usegetPropertyWithPropertyMapsQuery,
} from '../../../graphql/hasura/generated';
import BasicLayout from '../../../layouts/BasicLayout';
import LoadingLayout from '../../../layouts/LoadingLayout';
import { displayErrorMessage } from '../../../utils';
import * as propertyMapActions from '../../PropertyMap/actions';
import * as propertyMapColumns from '../../PropertyMap/columns';
import ViewPropertyMapAction from '../../PropertyMap/components/ViewPropertyMapAction';
import {
  getPropertyMapsFromEngrain,
  ILoadedEngrainMaps,
  syncMapByExternalMapId,
} from '../../PropertyMap/helpers';
import { PropertyMapModel } from '../../PropertyMap/model';
import { IModelAction } from '../../typings';
import model from '../model';

interface IMapTableRow {
  engrainMap: EngrainMap;
  propertyMap?: PropertyMapTableFragment | null;
}

const PropertyMapsPage: React.FC<RouteComponentProps<{ propertyId: string }>> = (props) => {
  const { propertyId } = props.match.params;
  const [engrainMaps, setEngrainMaps] = useState<ILoadedEngrainMaps | null>(null);
  const [loading, setLoading] = useState(true);

  const { data: propertyData } = usegetPropertyWithPropertyMapsQuery({
    variables: { propertyId },
    skip: !propertyId,
    fetchPolicy: 'network-only',
  });

  const property = propertyData ? propertyData.property : null;
  const sourceId = property?.propertySources.length ? property.propertySources[0].sourceId : null;

  useEffect(() => {
    if (!sourceId) { return; }

    getPropertyMapsFromEngrain(sourceId)
      .then((loadedEngrainMaps) => {
        if (loadedEngrainMaps) {
          setEngrainMaps(loadedEngrainMaps);
        }
      })
      .catch(error => displayErrorMessage(error))
      .finally(() => setLoading(false));
  }, [sourceId]);

  if (!property) {
    return <LoadingLayout pageTitle='Property Maps' />;
  }

  const syncedPropertyMaps = property.propertyMap ? [property.propertyMap] : [];
  const availableMaps = engrainMaps?.maps || [];

  const assetId = engrainMaps?.assetId;
  const externalFloors = engrainMaps?.floors.map(f => ({
    assetId: f.asset_id,
    floorId: f.id,
    label: f.label,
    name: f.name,
    shortLabel: f.short_label,
    sort: f.sort,
  })) || [];

  const rows: IMapTableRow[] = availableMaps.map((engrainMap) => ({
    engrainMap,
    propertyMap: syncedPropertyMaps.find(p => p.externalMapId === engrainMap.id),
  }));

  const columns: ColumnProps<IMapTableRow>[] = [
    {
      title: 'Map ID',
      key: 'mapId',
      render: (_, { engrainMap }) => engrainMap.id,
    },
    {
      title: 'Type',
      key: 'type',
      render: (_, { engrainMap, propertyMap }) => {
        if (propertyMap) {
          return propertyMapColumns.typeColumn.render({ type: propertyMap.type });
        }

        return <EnumTag colorMap={{}} enumValue={engrainMap.style_type?.toUpperCase()} />
      },
    },
    {
      title: 'Synced',
      render: (_, { propertyMap }) => {
        return !!propertyMap ? <CheckOutlined /> : null;
      },
    },
    {
      title: 'Published',
      render: (_, { propertyMap }) => {
        if (propertyMap) {
          return propertyMapColumns.publishedColumn.render({ published: propertyMap.published });
        }

        return null;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, { engrainMap, propertyMap }) => {
        if (propertyMap) {
          return (
            <TableActions
              row={propertyMap}
              actions={[
                propertyMapActions.viewMapAction,
                propertyMapActions.togglePublishedAction,
                {
                  ...PropertyMapModel.actions.deleteAction,
                  label: () => 'Remove from Chirp',
                },
              ]}
            />
          );
        }

        const { id: externalMapId } = engrainMap;

        const viewMapAction: IModelAction<EngrainMap> = {
          description: propertyMapActions.viewMapAction.description,
          enabledByModel: () => true,
          enabledByRow: () => true,
          render: () => {
            return (
              <ViewPropertyMapAction
                property={property}
                externalMapId={externalMapId}
                externalFloors={externalFloors}
                editMode={false}
              />
            );
          },
        };

        const syncMapAction: IModelAction<EngrainMap> = {
          label: () => 'Sync to Chirp',
          enabledByModel: () => true,
          enabledByRow: () => syncedPropertyMaps.length === 0,
          executes: async () => {
            try {
              if (!assetId) { return; }

              setLoading(true);
              await syncMapByExternalMapId(assetId, externalMapId, propertyId);

              notification.success({
                message: 'Success',
                description: 'The property map was successfully synced!',
              });
            } catch (error) {
              displayErrorMessage(error);
            } finally {
              setLoading(false);
            }
          },
        };

        return (
          <TableActions row={engrainMap} actions={[viewMapAction, syncMapAction]} />
        );
      }
    },
  ];

  return (
    <BasicLayout pageTitle='Property Maps'>
      <Card
        title={(
          <ModelBreadcrumbs
            model={model}
            rowBreadCrumb={{ row: property }}
            customBreadCrumbs={['Property Maps']}
          />
        )}
        style={{ marginBottom: 20 }}
      >
        <Typography.Paragraph>
          Currently, only a single map can be synced per property.
        </Typography.Paragraph>
        <Table
          loading={{
            size: 'large',
            spinning: loading,
          }}
          rowKey={r => r.engrainMap.id}
          columns={[...columns]}
          dataSource={rows}
          scroll={{ x: true }}
          pagination={false}
        />
      </Card>
    </BasicLayout>
  );
}

export default PropertyMapsPage;
