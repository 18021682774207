import { PermissionScope_enum } from '../../../graphql/hasura/generated';

export const SCOPE_OPTIONS = [
  {
    title: 'Global',
    scope: PermissionScope_enum.GLOBAL,
    description: [
      'Grant the highest level of access with permissions that apply to the entire Chirp platform',
    ],
  },
  {
    title: 'Organization',
    schemaName: 'Organization',
    scope: PermissionScope_enum.ORGANIZATION,
    description: [
      'Grant roles with permissions that apply to all properties within an organization',
    ],
  },
  {
    title: 'Property',
    schemaName: 'Property',
    scope: PermissionScope_enum.PROPERTY,
    description: [
      'Grant app and/or admin panel access to one or more properties for residents, guests, staff, etc.',
    ],
  },
  {
    title: 'Unit',
    schemaName: 'Unit',
    scope: PermissionScope_enum.UNIT,
    description: [
      'Grant app access to one or more units, including smart locks (if applicable)',
    ],
  },
  {
    title: 'Access Point',
    schemaName: 'AccessPoint',
    scope: PermissionScope_enum.ACCESS_POINT,
    description: [
      'Grant app access to one or more specific access points for a user to unlock.',
    ],
  },
];

export type ScopeOptions = typeof SCOPE_OPTIONS;
