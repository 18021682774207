import { Form } from '@ant-design/compatible';
import { Input, Radio } from 'antd';
import { FormikActions } from 'formik';
import React, { FC, useEffect } from 'react';

import { prox26ToHex, prox35CToHex, prox35XToHex, prox37XToHex } from './prox-to-hex'
import { FobFlow, ICredentialFormValues } from './typings';

const BIT_FORMAT = ['26', '35C', '35X', '37X'];

const ManualEnrollmentFlow: FC<{
  values: ICredentialFormValues,
  setFieldValue: FormikActions<ICredentialFormValues>['setFieldValue'],
}> = ({ values, setFieldValue }) => {

  useEffect(() => {
    const { bitFormat, facilityCode, cardNumber } = values;
    let identifier = null;

    switch (bitFormat) {
      case '26':
        try {
          identifier = prox26ToHex(facilityCode, cardNumber);
        } catch (error) {
          throw new Error(error)
        }
        break;
      case '35C':
        identifier = prox35CToHex(facilityCode, cardNumber);
        break;
      case '35X':
        identifier = prox35XToHex(facilityCode, cardNumber);
        break;
      case '37X':
        identifier = prox37XToHex(facilityCode, cardNumber);
        break;
      default:
    }

    setFieldValue('identifier', identifier);
  }, [setFieldValue, values.bitFormat, values.cardNumber, values.facilityCode])

  return (
    <>
      <Form.Item
        style={{ marginBottom: 5 }}
        className='label-align-top'
        label='Select Bit Format'
        required={values.fobFlow === FobFlow.MANUAL_ENROLLMENT}
      >
        <Radio.Group
          value={values.bitFormat}
          onChange={e => {
            setFieldValue('bitFormat', e.target.value)
          }}
        >
          {BIT_FORMAT.map(b => (
            <Radio key={b} value={b}>{b}</Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      {values.bitFormat !== '32X' &&
        <Form.Item
          style={{ marginBottom: 5 }}
          className='label-align-top'
          label='Facility Code'
          required={values.bitFormat !== '32X'}
        >
          <Input
            type='number'
            style={{ width: '40%', marginBottom: 20 }}
            name='facilityCode'
            value={values.facilityCode || undefined}
            onChange={e => {
              setFieldValue('facilityCode', e.target.value)
            }}
          />
        </Form.Item>
      }
      <Form.Item
        style={{ marginBottom: 5 }}
        className='label-align-top'
        label='Card Number'
        required={true}
      >
        <Input
          type='number'
          style={{ width: '40%', marginBottom: 20 }}
          name='cardNumber'
          value={values.cardNumber || undefined}
          onChange={e => {
            setFieldValue('cardNumber', e.target.value)
          }}
        />
      </Form.Item>
    </>
  )
};

export default ManualEnrollmentFlow;
