import {
  PermissionKey_enum,
  PropertyMap_bool_exp,
  PropertyMapTableFragment,
} from '../../graphql/hasura/generated';
import Model from '../Model';

export const PropertyMapModel = new Model<
  PropertyMap_bool_exp,
  PropertyMapTableFragment
>({
  names: {
    schemaName: 'PropertyMap',
  },

  permissionsOptions: {
    canRead: () => true,
    canDelete: ({ hasPermission }) => hasPermission(PermissionKey_enum.Property_ManageMaps),
  },
});

export default PropertyMapModel;
