import {
  createGuestPassDocument,
  createGuestPassMutation,
  createGuestPassMutationVariables,
  insertRolesDocument,
  insertRolesMutation,
  insertRolesMutationVariables,
  insertUnitsDocument,
  insertUnitsMutation,
  insertUnitsMutationVariables,
  upsertAccessPointsDocument,
  upsertAccessPointsMutation,
  upsertAccessPointsMutationVariables,
  upsertRoleRelationshipsDocument,
  upsertRoleRelationshipsMutation,
  upsertRoleRelationshipsMutationVariables,
} from '../generated';
import { hasuraClient } from '../hasuraClient';

// Create strongly typed functions for operations that cannot be called as hooks

export async function createGuestPass(variables: createGuestPassMutationVariables) {
  const { data } = await hasuraClient.mutate<createGuestPassMutation, createGuestPassMutationVariables>({
    variables,
    mutation: createGuestPassDocument,
  });

  return data?.custom_createGuestPass.userRoleId;
}

export async function insertRoles(variables: insertRolesMutationVariables) {
  const { data } = await hasuraClient.mutate<insertRolesMutation, insertRolesMutationVariables>({
    variables,
    mutation: insertRolesDocument,
  });

  return data?.roles?.returning || [];
}

export async function insertUnits(variables: insertUnitsMutationVariables) {
  const { data } = await hasuraClient.mutate<insertUnitsMutation, insertUnitsMutationVariables>({
    variables,
    mutation: insertUnitsDocument,
  });

  return data?.insertUnits?.returning;
}

export async function upsertAccessPoints(variables: upsertAccessPointsMutationVariables) {
  const { data } = await hasuraClient.mutate<upsertAccessPointsMutation, upsertAccessPointsMutationVariables>({
    variables,
    mutation: upsertAccessPointsDocument,
  });

  return data?.upsertAccessPoints?.returning;
}

export async function upsertRoleRelationships(variables: upsertRoleRelationshipsMutationVariables) {
  const { data } = await hasuraClient.mutate<upsertRoleRelationshipsMutation, upsertRoleRelationshipsMutationVariables>({
    variables,
    mutation: upsertRoleRelationshipsDocument,
  });

  return data?.roleRelationships || [];
}
