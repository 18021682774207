import model from './model';
import TicketDetailsPage from './pages/TicketDetailsPage';

model.routes.setRoutes({
  detailsRoute: {
    component: TicketDetailsPage,
  },
  createRoute: {
    // There is currently no use case for manually creating tickets,
    // other than for testing purposes
    enabled: ({ canCreate }) => canCreate() && process.env.NODE_ENV === 'development',
  },
  editRoute: {
    // Prevent backward compatible Hasura permissions from displaying "Edit" action
    enabled: () => false,
  },
});
