import parsePhoneNumberFromString from 'libphonenumber-js';
import { startCase } from 'lodash';
import camelCase from 'lodash/camelCase';
import mem from 'mem';
import validator from 'validator';
import * as yup from 'yup';

import { getFormLabel } from '../../utils';

function getValidationForType(
  fieldName: string,
  fieldType: string,
  required: boolean,
  model: string
) {
  let typeValidationSchema;

  const isIdField = fieldType === 'uuid' && fieldName.endsWith('Id');
  const label = isIdField ? startCase(fieldName.replace('Id', '')) : getFormLabel(fieldName);

  if (fieldName === 'createdAt' || fieldName === 'updatedAt') {
    // These fields have default values that should not be set by the frontend
    return;
  }

  switch (fieldType) {
    case 'String':
      // TODO: Implement better solution
      if (
        fieldName === 'phoneNumber' ||
        fieldName === 'supportPhoneNumber' ||
        fieldName === 'virtualDirectoryPhoneNumber'
      ) {
        typeValidationSchema = yup
        .string()
        .test(
          'phoneNumber',
          'Please enter a valid phone number',
          value => {
            return value ? !!(parsePhoneNumberFromString(value, 'US')?.isValid()) : true;
          },
        );
      } else if (fieldName === 'email' || fieldName === 'supportEmail') {
        typeValidationSchema = yup.string().email('Please enter a valid email address');
      } else {
        typeValidationSchema = yup.string();
      }

      break;

    case 'image':
    case 'Int':
      typeValidationSchema = yup
        .number()
        .positive()
        .integer();
      break;

    case 'date':
    case 'timestamptz':
      typeValidationSchema = yup.date();
      break;

    case 'Boolean':
      typeValidationSchema = yup.boolean();
      break;

    case 'uuid':
      typeValidationSchema = yup
        .string()
        .test(
          'uuid',
          'Please enter a valid UUID',
          value => {
            return value ? validator.isUUID(value) : true;
          },
        );
      break;
    case 'jsonb':
      typeValidationSchema = yup.mixed();
      break;

    default:
      console.warn(
        `Couldn't find the validation schema for ${model}.${fieldName} which has the field type of ${fieldType}`
      );
      return;
  }

  if (fieldName !== `${camelCase(model)}Id`) {
    typeValidationSchema = typeValidationSchema.nullable(!required);

    if (required) {
      typeValidationSchema = typeValidationSchema.required(`${label} is a required field`);
    }
  }

  return typeValidationSchema;
}

export default mem(getValidationForType);
