import { Form } from '@ant-design/compatible';
import { Col, Row, TimePicker } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';

import { TIME_FORMAT } from '../../utils';

interface ITimetableTimePickersProps {
  startTime: string | null | undefined;
  endTime: string | null | undefined;
  onChange: (time: Moment | null, position: 'start' | 'end') => any;
  errors?: any;
  disabled?: boolean;
}

const TimetableTimePickers: React.FC<ITimetableTimePickersProps> = (props) => {
  const { startTime, endTime, onChange, errors, disabled } = props;

  const startTimeError = errors ? errors.start : undefined;
  const endTimeError = errors ? errors.end : undefined;

  return (
    <Row justify='space-between' gutter={.5}>
      {[startTime, endTime].map((time, index) => {
        const label = index === 0 ? 'Start Time' : 'End Time';
        const error = index === 0 ? startTimeError : endTimeError;
        const position = index === 0 ? 'start' : 'end';

        return (
          <Col span={11} key={index}>
            <Form.Item
              label={label}
              colon
              validateStatus={error ? 'error' : undefined}
              help={error}
            >
              <TimePicker
                style={{ width: '100%' }}
                defaultValue={moment(time, TIME_FORMAT)}
                value={typeof time === 'string' ? moment(time, TIME_FORMAT) : time}
                format={TIME_FORMAT}
                use12Hours
                secondStep={60}
                onChange={(value => onChange(value, position))}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        );
      })}
    </Row>
  );
};

export default TimetableTimePickers;
