import { PermissionScope_enum } from '../../../../graphql/hasura/generated';

import { IScopeQueryFilters, IUseGrantableRolesArgs } from './typings';

export function getScopeQueryFilters(args: IUseGrantableRolesArgs): IScopeQueryFilters {
  const { permissionScope, scopedIds } = args;
  const filters: IScopeQueryFilters = {};

  if (scopedIds.length) {
    if (permissionScope === PermissionScope_enum.ORGANIZATION) {
      filters.organizationFilter = {
        organizationId: { _in: scopedIds },
      };
    } else if (permissionScope === PermissionScope_enum.PROPERTY) {
      filters.organizationFilter = {
        properties: {
          propertyId: { _in: scopedIds },
        },
      };
    } else if (permissionScope === PermissionScope_enum.UNIT) {
      filters.organizationFilter = {
        properties: {
          units: {
            unitId: { _in: scopedIds },
          },
        },
      };

      filters.unitAggregateFilter = {
        unitId: { _in: scopedIds },
        sourceId: { _is_null: false },
      };
    } else if (permissionScope === PermissionScope_enum.ACCESS_POINT) {
      filters.organizationFilter = {
        properties: {
          accessPoints: {
            accessPointId: { _in: scopedIds },
          },
        },
      };

      filters.accessPointFilter = {
        accessPointId: { _in: scopedIds },
      };
    }
  }

  return filters;
}
