import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { useDebounceCallback } from '@react-hook/debounce';
import { Button, Card, Input } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { PaginatedCsvExportButton } from '../../components/CsvExport/PaginatedCsvExportButton';
import PaginatedTable from '../../components/PaginatedTable';
import RefreshButton from '../../components/RefreshButton';
import TableSelector from '../../components/TableSelector';
import { useTableManager } from '../../hooks/useTableManager';
import useTableSelector from '../../hooks/useTableSelector';
import BasicLayout from '../../layouts/BasicLayout';
import { IModel } from '../../models/typings';
import { authentication } from '../../stores';
import { buildGrantAccessPageUrl } from '../GrantAccess/buildGrantAccessPageUrl';

import styles from './styles.module.less';

interface IModelMainTableProps {
  model: IModel;
}

export const ModelMainTable: React.FC<IModelMainTableProps> = (props) => {
  const { model } = props;
  const history = useHistory();

  const tables = model.tables.getMainTables().filter(t => t.enabled());
  const tableTitles = tables.map(t => t.title);

  const { selectTable, selectedTable } = useTableSelector({ tableTitles, useQueryParams: true });

  const tableManagers = tables.map(tableConfig => (
    useTableManager({
      tableConfig,
      useQueryParams: true,
      shouldSkipTotal: tableConfig.title !== selectedTable,
      shouldSkipList: tableConfig.title !== selectedTable,
    })
  ));

  const tableManager = tableManagers.find(t => t.title === selectedTable);

  if (!tableManager || !selectedTable) {
    return null;
  }

  const { tableState, tableData, onSearchChange, onTableChange, displayedTotal } = tableManager;

  const { queryOptions } = model;
  const getSearchConditions = queryOptions.getTableSearchConditions;
  const debouncedOnSearch = useDebounceCallback(onSearchChange, 300);

  // Typing search input should be immediate, while the queries themselves are debounced.
  const [searchValue, setSearchValue] = useState(tableState.search || '');

  const isUserRole = model.names.schemaName === 'UserRole';
  const createRouteEnabled = isUserRole
    ? !!authentication.grantableRoleScopes.length
    : model.routes.createRoute.enabled();

  const importRouteEnabled = model.routes.importRoute.enabled();
  const newButtonWidth = createRouteEnabled && importRouteEnabled ? '49.5%' : '100%';

  return (
    <BasicLayout
      pageTitle={model.names.pluralDisplayName}
      pageDescription={tableManager.tableConfig.description?.()}
    >
      <div className={styles.modelMainTable}>
        <Card
          className={styles.listCard}
          bordered={false}
          title={displayedTotal}
          extra={
            <div style={{ display: 'flex' }}>
              <RefreshButton
                disabled={tableData.listLoading || tableData.totalLoading}
                refresh={tableData.refetch}
                useSubscription={!!tableManager.useSubscription}
              />
              {tableManager.hasFiltersApplied && (
                <Button
                  style={{ marginRight: 8 }}
                  onClick={() => onTableChange({})}
                >
                  <FilterOutlined /> Clear All Filters
                </Button>
              )}
              <PaginatedCsvExportButton
                tableManager={tableManager}
                buttonStyle={{ marginRight: 8 }}
              />
              {getSearchConditions && (
                <Input.Search
                  placeholder='Search...'
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    debouncedOnSearch(e.target.value)
                  }}
                  onSearch={(v) => {
                    setSearchValue(v);
                    debouncedOnSearch(v)
                  }}
                  enterButton
                  allowClear
                />
              )}
            </div>
          }
          bodyStyle={{ padding: '0 32px 40px 32px' }}
        >
          <div>
            {createRouteEnabled && (
              <Button
                type='dashed'
                style={{ width: newButtonWidth, marginBottom: 24 }}
                icon={<PlusOutlined />}
                onClick={() => {
                  if (isUserRole) {
                    history.push(buildGrantAccessPageUrl({}))
                  } else {
                    history.push(model.routes.createRoute.path)
                  }
                }}
              >
                {isUserRole ? 'Grant Access' : `New ${model.names.displayName}`}
              </Button>
            )}
            {importRouteEnabled && (
              <Button
                type='dashed'
                style={{ width: newButtonWidth, marginBottom: 24, float: 'right' }}
                icon={<PlusOutlined />}
                onClick={() => history.push(model.routes.importRoute.path)}
              >
                Import from CSV
              </Button>
            )}
          </div>
          {tables.length > 1 && (
            <TableSelector
              value={selectedTable}
              tables={tables}
              selectTable={(nextTable) => {
                setSearchValue('');
                selectTable(nextTable)
              }}
            />
          )}
          <PaginatedTable tableManager={tableManager} />
        </Card>
      </div>
    </BasicLayout>
  );
}

export default ModelMainTable;
