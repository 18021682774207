import { CheckOutlined } from '@ant-design/icons';
import { Descriptions } from 'antd';
import React from 'react';

import { AlertTags } from '../../../components/AlertTags';
import { apiClient } from '../../../graphql/api/apiClient';
import { SmartLockDetailsFragment, usesmartLockDetailsQuery } from '../../../graphql/api/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import { Color, formatTimestamp } from '../../../utils';
import { accessAuditAction } from '../actions';
import * as columns from '../columns';
import { AugustWifiBridgeDescriptions } from '../components';
import { RefreshAugustLockStatuses } from '../components/RefreshAugustLockStatuses';
import { SmartLockModel } from '../model';

const { Item } = Descriptions;

const SmartLockDetailsLayout = createDetailsLayout<SmartLockDetailsFragment>();

export const SmartLockDetailsPage: React.FC<any> = () => {
  return (
    <SmartLockDetailsLayout
      modelName='SmartLock'
      useQuery={(smartLockId) => {
        return usesmartLockDetailsQuery({
          variables: { smartLockId },
          client: apiClient,
        });
      }}
      actions={[
        SmartLockModel.actions.editAction,
        SmartLockModel.actions.deleteAction,
        accessAuditAction,
        ...SmartLockModel.actions.defaultActions,
      ]}
      body={(smartLock) => {
        const { sourceId, serialNumber, createdAt, alerts, augustLock } = smartLock;
        const Bridge = augustLock?.Bridge;

        return (
          <>
            <Descriptions
              column={1}
              size='middle'
              bordered
              style={{ width: '49%', float: 'left' }}
            >
              <Item label={columns.propertyColumn.title}>
                {columns.propertyColumn.render(smartLock)}
              </Item>
              <Item label={columns.unitColumn.title}>
                {columns.unitColumn.render(smartLock)}
              </Item>
              <Item label={columns.nameColumn.title}>
                {columns.nameColumn.render(smartLock)}
              </Item>
              {/* Fall back to Chirp data if August data cannot be loaded */}
              <Item label='Source ID'>{augustLock?.LockID || sourceId}</Item>
              <Item label='Serial Number'>{augustLock?.SerialNumber || serialNumber}</Item>
              <Item label={columns.createdByUserColumn.title}>
                {columns.createdByUserColumn.render(smartLock)}
              </Item>
              <Item label='Provisioned Date'>{formatTimestamp(createdAt)}</Item>
              <Item label='Alerts'>
                {!!alerts.length && <AlertTags alerts={alerts} />}
                {!alerts.length && <>No Alerts <CheckOutlined style={{ color: Color.Green }} /></>}
              </Item>
            </Descriptions>
            <Descriptions
              column={1}
              size='middle'
              bordered
              style={{ width: '49%', float: 'right' }}
            >
              <Item label={columns.lockModelColumn.title}>
                {columns.lockModelColumn.render({ augustLock })}
              </Item>
              <Item label='Lock Timezone'>{augustLock?.timeZone}</Item>
              <Item label={columns.batteryColumn.title}>
                {columns.batteryColumn.render({ augustLock })}
              </Item>
              <Item label={columns.moduleFirmwareColumn.title}>
                {columns.moduleFirmwareColumn.render({ augustLock })}
              </Item>
              <Item label={columns.pinCodesColumn.title}>
                {columns.pinCodesColumn.render({ augustLock })}
              </Item>
              {Bridge && (
                <Item label={columns.wifiBridgeColumn.title}>
                  <AugustWifiBridgeDescriptions augustLock={augustLock} includeTimestamps />
                  <RefreshAugustLockStatuses augustLockId={sourceId} />
                </Item>
              )}
            </Descriptions>
          </>
        );
      }}
    />
  );
};

export default SmartLockDetailsPage;
