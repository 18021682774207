import moment, { Moment } from 'moment';

import { TimetableRule, TimetableRuleState, TimetableRuleType } from '../../graphql/api/generated';
import { displayErrorMessage } from '../../utils';

import { DEFAULT_TIMETABLE_RULE, TIMETABLE_STATE_SELECT_OPTIONS } from './constants';

export function useTimetableField(
  timetableProps: TimetableRule[] | undefined,
  onChange: (timetable: any[] | null) => any,
) {
  const getTimetable = (): TimetableRule[] => {
    return [...(timetableProps || [])]
      .map(rule => ({
        ...rule,
        days: [...rule.days],
      }));
  };

  const update = (nextValue: TimetableRule[] | null) => {
    // Do not store an empty array
    const formattedValue = Array.isArray(nextValue) && nextValue.length === 0 ? null : nextValue;

    onChange(formattedValue);
  };

  const updateState = (state: TimetableRuleState, ruleIndex: number) => {
    const timetable = getTimetable();
    const option = TIMETABLE_STATE_SELECT_OPTIONS.find(op => op.state === state);

    if (!option) {
      displayErrorMessage(new Error(`Unknown state: ${state}`));
      return;
    }

    if (state === TimetableRuleState.LOCKDOWN) {
      // Lockdown should be set to default, which has a 24/7 schedule
      timetable[ruleIndex] = {
        ...DEFAULT_TIMETABLE_RULE,
        state,
        type: TimetableRuleType.RESTRICTION,
      };
    } else {
      timetable[ruleIndex].type = option.type;
      timetable[ruleIndex].state = state;
      // Start/end dates should only apply to LOCKDOWN schedules
      timetable[ruleIndex].startDate = undefined;
      timetable[ruleIndex].endDate = undefined;
    }

    update(timetable);
  }

  const updateDays = (days: number[], ruleIndex: number) => {
    const timetable = getTimetable();

    Object.assign(timetable[ruleIndex], {
      days: days.sort().map((day: number) => day + 1)
    });

    update(timetable);
  };

  const updateTime = (time: Moment | null, ruleIndex: number, key: 'start' | 'end') => {
    const timetable = getTimetable();
    const rule = timetable[ruleIndex];

    // Stored format is 23:59 vs. displayed format: 11:59pm
    // @ts-ignore Allow null value for form
    rule[key] = time ? time.format('HH:mm') : null;

    update(timetable);
  };

  const updateDate = (date: Moment | null, ruleIndex: number, key: 'startDate' | 'endDate') => {
    const timetable = getTimetable();
    const rule = timetable[ruleIndex];

    // @ts-ignore Allow null value for form
    rule[key] = date ? moment.utc(date).toISOString() : null;

    update(timetable);
  };

  return {
    update,
    updateState,
    updateDays,
    updateTime,
    updateDate,
    timetable: getTimetable(),
  };
}

export default useTimetableField;
