import { List } from 'antd';
import uniqBy from 'lodash/uniqBy';
import React from 'react';

import EnumTag, { Color } from '../../../components/EnumTag';
import { FailurePlan_enum, GpioMode_enum, usegetRelaysQuery } from '../../../graphql/hasura/generated';
import { failurePlanColumn } from '../../Relay/columns';

import AccessPointFailurePlanModal from './AccessPointFailurePlanModal';

interface IAccessPointFailurePlanProps {
  accessPointId: string;
  canEdit: boolean;
}

const AccessPointFailurePlan = ({ accessPointId, canEdit }: IAccessPointFailurePlanProps) => {
  const { data: relaysData, loading } = usegetRelaysQuery({
    skip: !accessPointId,
    fetchPolicy: 'network-only',
    variables: {
      where: {
        accessPointRelays: {
          accessPointId: { _eq: accessPointId },
        },
      },
    },
  });

  if (loading) {
    return (
      <List style={{ paddingTop: 9 }}>Loading...</List>
    );
  }

  // Only factor in OUTPUT relays. Failure plans do not apply to INPUT relays
  const relays = (relaysData?.relays || []).filter(r => r.gpioMode === GpioMode_enum.OUTPUT);

  let failurePlan: FailurePlan_enum | 'HYBRID' | 'NONE' = 'NONE';

  if (relays.length) {
    const failurePlans = uniqBy(relays, 'failurePlan').map(r => r.failurePlan);

    if (failurePlans.length > 1) {
      failurePlan = 'HYBRID';
    } else {
      failurePlan = failurePlans[0];
    }
  }

  const isFailurePlanEnum = Object.values(FailurePlan_enum).includes(failurePlan as FailurePlan_enum);

  return (
    <List style={{ paddingTop: 9 }}>
      {isFailurePlanEnum
        ? failurePlanColumn.render({ failurePlan: failurePlan as FailurePlan_enum })
        : (
          <EnumTag
            enumValue={failurePlan}
            colorMap={{ HYBRID: Color.Blue }}
          />
        )
      }
      {relays.length ? (
        <AccessPointFailurePlanModal
          accessPointId={accessPointId}
          relays={relays}
          failurePlan={isFailurePlanEnum ? (failurePlan as FailurePlan_enum) : null}
          canEdit={canEdit}
        />
      ) : <span>(No enabled relays)</span>}
    </List>
  );
}

export default AccessPointFailurePlan;
