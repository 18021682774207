import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

import EnumTag from '../../../components/EnumTag';
import { Color } from '../../../utils';

interface IAugustPinCodeStatusProps {
  status: string;
  stale?: boolean;
}

export const AugustPinCodeStatus: React.FC<IAugustPinCodeStatusProps> = ({ status, stale }) => {
  const formattedPinCodeStatus = status === 'deleting' ? 'EXPIRED' : status.toUpperCase();

  return (
    <EnumTag
      enumValue={formattedPinCodeStatus}
      colorMap={{
        CREATED: Color.Blue,
        LOADED: Color.Green,
        DISABLED: Color.Orange,
        DISABLING: Color.Orange,
        ENABLING: Color.Blue,
        EXPIRED: Color.Red,
        UPDATING: Color.Blue,
      }}
      icon={stale ? <LoadingOutlined /> : null}
    />
  );
}

export default AugustPinCodeStatus;
