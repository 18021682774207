import { CheckOutlined } from '@ant-design/icons';
import { enumManagers } from '@chirp/enums';
import { Tag } from 'antd';
import { sortBy, take, uniqBy } from 'lodash';
import React from 'react';

import EnumTag, { Color } from '../../components/EnumTag';
import { AccountStatus_enum, RoleLabelFragment, UserRowFragment } from '../../graphql/hasura/generated';
import { formatPhoneNumber, formatUserName, truncateText } from '../../utils';
import RoleModel from '../Role/model';

import model from './model';

export function renderAccountStatusString(accountStatus: AccountStatus_enum) {
  return enumManagers.AccountStatus.getLabel(accountStatus);
}

export const accountStatusColumn = model.createColumn<{ accountStatus: AccountStatus_enum }>({
  title: 'Account Status',
  filterOptions: {
    type: 'ENUM',
    enumTable: 'AccountStatus',
  },
  render: ({ accountStatus }) => {
    if (!accountStatus) {
      return null;
    }

    return (
      <EnumTag
        enumValue={accountStatus}
        colorMap={{
          [AccountStatus_enum.ENABLED]: Color.Green,
          [AccountStatus_enum.DISABLED]: Color.Red,
        }}
      />
    );
  },
  renderString: ({ accountStatus }) => renderAccountStatusString(accountStatus),
});

export const firstNameColumn = model.createColumn<{ firstName?: string | null }>({
  title: 'First Name',
  dataIndex: 'firstName',
  render: ({ firstName }) => firstName,
});

export const lastNameColumn = model.createColumn<{ lastName?: string | null }>({
  title: 'Last Name',
  dataIndex: 'lastName',
  render: ({ lastName }) => lastName,
});

export const nameColumn = model.createColumn<{
  firstName?: string | null, lastName?: string | null,
}>({
  title: 'Name',
  dataIndex: 'firstName',
  render: user => formatUserName(user, false),
});

export const phoneNumberColumn = model.createColumn<{ phoneNumber?: string | null }>({
  dataIndex: 'phoneNumber',
  render: ({ phoneNumber }) => formatPhoneNumber(phoneNumber),
});

export const emailColumn = model.createColumn<{ email?: string | null }>({
  dataIndex: 'email',
  render: ({ email }) => truncateText(email),
  renderString: ({ email }) => email || '',
});

export const hasAugustAccountColumn = model.createColumn<UserRowFragment>({
  title: 'Has August Account',
  filterOptions: {
    type: 'RADIO',
    key: 'hasAugustAccount',
    options: [
      {
        label: 'Sub-User Account only',
        queryFilters: {
          augustSubUserId: { _is_null: false },
          augustUserId: { _is_null: true },
        },
      },
      {
        label: 'Legacy Account only',
        queryFilters: {
          augustSubUserId: { _is_null: true },
          augustUserId: { _is_null: false },
        },
      },
      {
        label: 'Sub-User Account and Legacy Account',
        queryFilters: {
          augustSubUserId: { _is_null: false },
          augustUserId: { _is_null: false },
        },
      },
      {
        label: 'No Account',
        queryFilters: {
          augustSubUserId: { _is_null: true },
          augustUserId: { _is_null: true },
        },
      },
    ],
  },
  render: ({ augustUserId, augustSubUserId }) => (augustUserId || augustSubUserId)
    ? <CheckOutlined />
    : null,
  renderString: ({ augustUserId, augustSubUserId }) => (augustUserId || augustSubUserId)
    ? 'Yes'
    : 'No',
});

export const hasPasswordColumn = model.createColumn<UserRowFragment>({
  title: 'Has Password',
  filterOptions: {
    type: 'RADIO',
    key: 'hasPassword',
    options: [
      {
        label: 'Chirp Password only',
        queryFilters: {
          password: {},
          hasAugustPassword: { _eq: false },
        },
      },
      {
        label: 'August Password only',
        queryFilters: {
          _not: {
            password: {},
          },
          hasAugustPassword: { _eq: true },
        },
      },
      {
        label: 'Chirp Password and August Password',
        queryFilters: {
          password: {},
          hasAugustPassword: { _eq: true },
        },
      },
      {
        label: 'No Password',
        queryFilters: {
          _not: {
            password: {},
          },
          hasAugustPassword: { _eq: false },
        },
      },
    ],
  },
  render: ({ password, hasAugustPassword }) => (!!password || hasAugustPassword)
    ? <CheckOutlined />
    : null,
  renderString: ({ password, hasAugustPassword }) => (!!password || hasAugustPassword)
    ? 'Yes'
    : 'No',
});

function getLimitedRoles(assignedUserRoles: { role: RoleLabelFragment }[]) {
  const roles = assignedUserRoles.map(ur => ur.role);
  const uniqueRoleNames = uniqBy(roles, r => r.name); // Some role names may match across orgs
  const sortedRoles = sortBy(uniqueRoleNames, r => r.name);
  const greaterThanMax = sortedRoles.length > 3;
  const limitedRoles = greaterThanMax ? take(sortedRoles, 3) : sortedRoles;

  return { limitedRoles, greaterThanMax };
}

export const assignedRolesColumn = model.createColumn<{
  assignedUserRoles: { role: RoleLabelFragment }[]
}>({
  title: 'Assigned Roles',
  // relationship: {
  //   model: RoleModel,
  //   path: 'assignedUserRoles.role',
  // },
  render: ({ assignedUserRoles }) => {
    const { limitedRoles, greaterThanMax } = getLimitedRoles(assignedUserRoles);

    return (
      <div>
        {limitedRoles.map((role) => (
          <Tag key={role.roleId} style={{ marginBottom: '8px' }}>
            {RoleModel.labels.getLabel(role)}
          </Tag>
        ))}
        {greaterThanMax ? '...' : null}
      </div>
    );
  },
  renderString: ({ assignedUserRoles }) => {
    const { limitedRoles, greaterThanMax } = getLimitedRoles(assignedUserRoles);
    const roleNames = limitedRoles.map(r => RoleModel.labels.getLabel(r));

    if (greaterThanMax) {
      roleNames.push('...');
    }

    return roleNames.join(', ');
  },
});
