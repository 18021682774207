import { Form } from '@ant-design/compatible';
import { DatePicker as AntDatePicker } from 'antd';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import { capitalize } from 'lodash';
import moment, { DurationInputArg2, Moment } from 'moment';
import React from 'react';

import { DATE_FORMAT } from '../../utils';

interface IDatePickerProps extends PickerDateProps<Moment> {
  unit: DurationInputArg2;
  startDate: string | null;
  setSelectedStartDate: (selectedStartDate: Moment) => void;
}

export const DatePicker = (props: IDatePickerProps) => {
  const { unit, startDate, setSelectedStartDate, ...pickerDateProps } = props;

  const datePickerProps: PickerDateProps<Moment> = {
    style: { width: '100%' },
    format: unit === 'month' ? 'MMMM, YYYY' : DATE_FORMAT,
    value: startDate ? moment(startDate).startOf(unit) : null,
    onChange: (d: Moment | null) => {
      if (d) {
        setSelectedStartDate(d.startOf(unit));
      }
    },
    disabledDate: (d: Moment | null) => d?.isAfter(moment(new Date()).endOf(unit)) || false,
    ...pickerDateProps,
  };

  let datePicker = null;

  if (unit === 'month') {
    datePicker = <AntDatePicker.MonthPicker {...datePickerProps} />;
  } else if (unit === 'week') {
    datePicker = <AntDatePicker.WeekPicker {...datePickerProps} />;
  } else {
    datePicker = <AntDatePicker {...datePickerProps} />;
  }

  return datePicker;
};

interface IDatePickerWithFormItemProps extends IDatePickerProps {
  label?: string;
  required?: boolean;
}

export const DatePickerWithFormItem = (props: IDatePickerWithFormItemProps) => {
  const { label, unit, required } = props;

  return (
    <Form.Item
      label={label || capitalize(unit)}
      style={{ marginBottom: 0 }}
      className='label-align-top'
      required={required}
    >
      <DatePicker {...props} allowClear={!required} />
    </Form.Item>
  );
};
