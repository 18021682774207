import {
  AccessPointRoleByUnitGroupFragment,
  AccessPointRoleByUnitGroupFragmentDoc,
} from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';

import * as columns from './columns';
import model from './model';

export const AccessPointRolesByUnitGroupTable = model.createTable<
  AccessPointRoleByUnitGroupFragment
>({
  title: 'Access Points',
  fragment: AccessPointRoleByUnitGroupFragmentDoc,
  fixedQueryFilters: (unitGroupId) => ({
    unitGroupId: { _eq: unitGroupId },
  }),
  columns: [
    columns.propertyColumn,
    columns.accessPointColumn,
    columns.roleColumn,
    columns.typeColumn,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});
