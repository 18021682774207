import { Descriptions } from 'antd';
import type { Property } from 'csstype';
import React from 'react';

import type { IChirpTableColumn } from '../../models/typings';

interface ISplitDescriptionsProps<TRowData> {
  row: TRowData;
  leftSideColumns: (IChirpTableColumn<TRowData> | null)[];
  rightSideColumns: (IChirpTableColumn<TRowData> | null)[];
}

const { Item } = Descriptions;

// tslint:disable-next-line:whitespace
export const SplitDescriptions = <TRowData,>(props: ISplitDescriptionsProps<TRowData>) => {
  const { row, leftSideColumns, rightSideColumns } = props;

  const sections = [
    {
      columns: leftSideColumns,
      float: 'left',
    },
    {
      columns: rightSideColumns,
      float: 'right',
    },
  ];

  return (
    <>
      {sections.map(({ columns, float }) => (
        <Descriptions
          key={float}
          column={1}
          size='middle'
          bordered
          style={{ float: float as Property.Float, width: '49%' }}
        >
          {columns.map((column, index) => {
            if (!column) {
              return null;
            }

            return (
              <Item
                key={index}
                label={<span style={{ whiteSpace: 'nowrap' }}>{column.title}</span>}
              >
                {column.render(row)}
              </Item>
            );
          })}
        </Descriptions>
      ))}
    </>
  );
};
