import { TimetableRule, TimetableRuleState, TimetableRuleType } from '../../graphql/api/generated';

export const DEFAULT_TIMETABLE_RULE: TimetableRule = {
  type: TimetableRuleType.ALLOWANCE,
  days: [1, 2, 3, 4, 5, 6, 7],
  start: '00:00',
  end: '00:00',
};

export interface ITimetableStateSelectOption {
  label: string;
  type: TimetableRuleType;
  state: TimetableRuleState;
}

export const TIMETABLE_STATE_SELECT_OPTIONS: ITimetableStateSelectOption[] = [
  {
    label: 'Controlled',
    type: TimetableRuleType.ALLOWANCE,
    state: TimetableRuleState.CONTROLLED,
  },
  {
    label: 'Unlocked',
    type: TimetableRuleType.ALLOWANCE,
    state: TimetableRuleState.UNLOCKED,
  },
  {
    label: 'Lockdown',
    type: TimetableRuleType.RESTRICTION,
    state: TimetableRuleState.LOCKDOWN,
  },
];

export const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export const TIMETABLE_STATE_DESCRIPTIONS: { [key in TimetableRuleState]: string } = {
  CONTROLLED: 'Access point is locked and will require valid credentials to unlock',
  UNLOCKED: 'Access point is unlocked; no credential is required for access',
  LOCKDOWN: 'No entry allowed, even with a valid credential (overrides other schedules)',
};
