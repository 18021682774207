import { CheckOutlined } from '@ant-design/icons';
import { Table, Typography } from 'antd';
import { snakeCase } from 'lodash';
import moment from 'moment';
import React from 'react';

import { useCSVDownloader } from '../../hooks/useCsvDownloader';
import { IModel, IModelFormField } from '../../models/typings';

interface IModelImportAllowedFieldsProps {
  model: IModel<any>;
  importFields: IModelFormField<any>[];
}

export const ModelImportAllowedFields: React.FC<IModelImportAllowedFieldsProps> = (props) => {
  const { model, importFields } = props;
  const sampleCsvFilename = model.formOptions.importOptions?.sampleCsvFilename;
  const sampleCsvData = model.formOptions.importOptions?.sampleCsvData;

  const { CSVDownloader, Type } = useCSVDownloader();

  const dataSource = importFields.map((field) => {
    const { fieldName, importOptions } = field;
    const rules: React.ReactNode[] = [];

    const allowedValues = typeof importOptions?.getAllowedValues === 'function'
      ? importOptions.getAllowedValues()
      : [];

    const introspectionField = field.getIntrospectionField();

    if (introspectionField?.scalarName === 'int' || introspectionField?.scalarName === 'float') {
      rules.push('Must be a number');
    }

    if (introspectionField?.scalarName === 'timestamptz') {
      const currentDate = moment(new Date());
      const dateFormats = [
        'MMM DD YYYY',
        'MM/DD/YYYY',
        'MM-DD-YYYY',
        'YYYY/MM/DD',
        'YYYY-MM-DD',
      ];

      rules.push(
        <ul style={{ listStylePosition: 'inside', padding: 0 }}>Must be a valid date format:
          {dateFormats.map((dateFormat) => (
            <li key={dateFormat}>{currentDate.format(dateFormat)}</li>
          ))}
        </ul>
      );
    }

    if (allowedValues.length) {
      rules.push(
        <ul style={{ listStylePosition: 'inside', padding: 0 }}>Allowed values:
          {allowedValues.map((value) => (
            <li key={value}>{value}</li>
          ))}
        </ul>
      );
    }

    if (typeof field.maxLength === 'number') {
      rules.push(`Maximum character length: ${field.maxLength}`);
    }

    if (typeof field.minValue === 'number') {
      rules.push(`Minimum value: ${field.minValue}`);
    }

    if (typeof field.maxValue === 'number') {
      rules.push(`Maximum value: ${field.maxValue}`);
    }

    if (importOptions?.rules) {
      for (const { description } of importOptions.rules) {
        rules.push(description);
      }
    }

    return {
      rules,
      name: importOptions?.importFieldName || fieldName,
      required: importOptions?.required || false,
      defaultValue: importOptions?.defaultValue,
    };
  });

  return (
    <div style={{ marginTop: 20, marginBottom: 40 }}>
      <Typography.Paragraph style={{ float: 'left' }}>
        The following fields are allowed to be imported from the CSV (extra fields will be ignored):
      </Typography.Paragraph>
      {sampleCsvData && (
        <div style={{ marginLeft: 'auto', float: 'right' }}>
          <CSVDownloader
            bom={true} /* Excel support */
            type={Type.Link}
            data={sampleCsvData}
            filename={sampleCsvFilename || `sample_${snakeCase(model.names.pluralDisplayName)}`}
          >
            Download Sample CSV File
          </CSVDownloader>
        </div>
      )}
      <Table
        columns={[
          {
            title: 'Field Name',
            render: (_, { name }) => name,
          },
          {
            title: 'Required',
            render: (_, { required }) => required ? <CheckOutlined /> : null,
          },
          {
            title: 'Default Value',
            render: (_, { defaultValue }) => defaultValue?.label || defaultValue?.value(),
          },
          {
            title: 'Rules',
            render: (_, { rules }) => (
              <ul style={{ padding: 0 }}>
                {rules.map((rule, index) => (
                  <li key={index}>{rule}</li>
                ))}
              </ul>
            ),
          },
        ]}
        dataSource={dataSource}
        rowKey={(row) => row.name}
        pagination={false}
        style={{ clear: 'both' }}
      />
    </div>
  );
};

export default ModelImportAllowedFields;
