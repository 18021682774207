import {
  ApartmentOutlined,
  BankOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  HomeOutlined,
  LockOutlined,
} from '@ant-design/icons';
import React from 'react';

import { PermissionScope_enum } from '../../graphql/hasura/generated';

export const PERMISSION_SCOPE_WEIGHTS: { [key in PermissionScope_enum]: number } = {
  GLOBAL: 1,
  ORGANIZATION: 2,
  PROPERTY: 3,
  UNIT: 4,
  ACCESS_POINT: 5,
  SMART_LOCK: 5,
};

export const PERMISSION_SCOPE_ICON_MAP: { [key in PermissionScope_enum]: React.ReactNode } = {
  GLOBAL: <GlobalOutlined />,
  ORGANIZATION: <ApartmentOutlined />,
  PROPERTY: <BankOutlined />,
  UNIT: <HomeOutlined />,
  ACCESS_POINT: <EnvironmentOutlined />,
  SMART_LOCK: <LockOutlined />,
};

export default PERMISSION_SCOPE_ICON_MAP;
