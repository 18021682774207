import { FormikProps } from 'formik';
import numeral from 'numeral';
import csvParse, { ParseResult } from 'papaparse';
import pluralize from 'pluralize';

import { IModel, IModelFormField } from '../../../models/typings';
import { displayErrorMessage } from '../../../utils';
import { IModelImportFormValues } from '../formik';
import ModelImportRow from '../ModelImportRow';

import validateParsedRows from './validateParsedRows';

interface IParseCsvFileArgs {
  file: FileList[0];
  model: IModel<any>;
  importFields: IModelFormField<any>[];
  formikProps: FormikProps<IModelImportFormValues>;
  onSuccess?: () => any;
}

export function parseCsvFile(args: IParseCsvFileArgs) {
  const { file, model, importFields, formikProps, onSuccess } = args;
  const { setFieldValue, values } = formikProps;
  const { applyAllValues } = values;

  setFieldValue('parsingRows', true);
  formikProps.handleSubmit(); // No-op (we're handling the actual submission below)

  csvParse.parse(file, {
    delimiter: ',',
    header: true,
    skipEmptyLines: true,
    complete: async (parseResult: ParseResult<any[]>, f) => {
      try {
        const { importOptions: modelImportOptions } = model.formOptions;
        const missingFields: string[] = [];

        if (!modelImportOptions) {
          throw new Error(
            `Importing is not allowed for ${model.names.pluralDisplayName.toLowerCase()}`,
          );
        }

        for (const { fieldName, importOptions } of importFields) {
          if (!importOptions) {
            continue;
          }

          const importFieldName = importOptions.importFieldName || fieldName;

          if (
            importOptions?.required &&
            !(parseResult.meta.fields || []).includes(importFieldName)
          ) {
            missingFields.push(importFieldName);
          }
        }

        if (missingFields.length) {
          throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
        } else if (parseResult.errors.length) {
          throw new Error(parseResult.errors[0].message);
        } else if (parseResult.data) {
          const rowCount = parseResult.data.length;

          if (!rowCount) {
            throw new Error('The CSV file must contain 1 or more rows');
          }

          const { rowLimit } = modelImportOptions;

          if (rowCount > rowLimit) {
            const displayedRowCount = numeral(rowCount).format('1,000');
            const displayedRowLimit = numeral(rowLimit).format('1,000');

            throw new Error(
              `The selected CSV file contains ${displayedRowCount} ${pluralize('row', rowCount)}. ` +
              `The row limit is ${displayedRowLimit}.`
            );
          }

          const rows = parseResult.data.map((parsedRow, index) => {
            return new ModelImportRow({ parsedRow, importFields, rowNumber: index + 1 });
          });

          const importDependencies = typeof modelImportOptions.getDependencies === 'function'
            ? await modelImportOptions.getDependencies(rows, applyAllValues)
            : {};

          await validateParsedRows({ model, rows, importFields });

          if (typeof modelImportOptions.validateRows === 'function') {
            await modelImportOptions.validateRows(rows, applyAllValues, importDependencies);
          }

          const validRows = rows.filter(r => !r.errors.length);

          await modelImportOptions.transformRows(validRows, applyAllValues, importDependencies);

          // Reset form
          setFieldValue('showImportingProgress', false);
          setFieldValue('importingProgress', 0);

          setFieldValue('importDependencies', importDependencies);
          setFieldValue('rows', rows);

          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        }
      } catch (error) {
        displayErrorMessage(error);
      } finally {
        formikProps.setSubmitting(false);
        setFieldValue('parsingRows', false);
      }
    },
  });
}

export default parseCsvFile;
