import { FeatureFlagKey_enum, PropertyFeatureFlag } from '../graphql/hasura/generated';

interface IPropertyFragment {
  propertyFeatureFlags: Pick<PropertyFeatureFlag, 'featureFlagKey'>[];
}

export type PropertyFeatureFlags = { [key in FeatureFlagKey_enum]: boolean };

const FEATURE_FLAG_KEYS = Object.values(FeatureFlagKey_enum);

export function getPropertyFeatureFlags(property: IPropertyFragment | null | undefined) {
  const featureFlags = property?.propertyFeatureFlags || [];

  return FEATURE_FLAG_KEYS.reduce((acc, key) => {
    return {
      ...acc,
      [key]: featureFlags.some((f) => f.featureFlagKey === key),
    };
  }, {}) as PropertyFeatureFlags;
}
