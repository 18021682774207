import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  syncResidentsByPropertyDocument,
  syncUnitsByPropertyDocument,
} from '../../graphql/api/generated';
import {
  PermissionKey_enum,
  PropertyActionsFragment,
  PropertyResidentAuditFragment,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import { getPropertyFeatureFlags } from '../../utils';

import model from './model';

function getEnabledPropertyRoutes(property: PropertyActionsFragment) {
  const { propertyId, propertySources_aggregate } = property;

  const totalPropertySources = propertySources_aggregate?.aggregate?.count || 0;
  const residentAuditEnabled = totalPropertySources > 0;
  const featureFlags = getPropertyFeatureFlags(property);

  const enabledActions = [
    {
      title: 'Resident Audit',
      url: `${model.routes.basePath}/resident-audit/${propertyId}`,
      enabled: residentAuditEnabled && authentication.hasPermission(PermissionKey_enum.Property_PmsResidentAudit),
    },
    {
      title: 'Smart Locks Report',
      url: `${model.routes.basePath}/smart-locks-report/${propertyId}`,
      enabled: authentication.hasPermission(PermissionKey_enum.SmartLock_PropertyReport),
    },
    {
      title: 'Hub Monitoring',
      url: `${model.routes.basePath}/hub-monitoring/${propertyId}`,
      enabled: authentication.hasPermission(PermissionKey_enum.Hub_Read),
    },
    {
      title: 'DPS Monitoring',
      url: `${model.routes.basePath}/dps-monitoring/${propertyId}`,
      enabled: featureFlags.DPS && authentication.hasPermission(PermissionKey_enum.AccessPoint_Read),
    },
    {
      title: 'Property Maps',
      url: `${model.routes.basePath}/maps/${propertyId}`,
      enabled: featureFlags.ENGRAIN_MAP_INTEGRATION && authentication.hasPermission(PermissionKey_enum.Property_ManageMaps),
    },
    {
      title: 'Virtual Directory Preview',
      url: `${model.routes.basePath}/virtual-directory/${propertyId}`,
      enabled: featureFlags.VIRTUAL_DIRECTORY,
    },
  ].filter(r => r.enabled);

  return enabledActions;
}

export const moreActions = model.createAction<PropertyActionsFragment>({
  enabledByModel: () => true,
  enabledByRow: (property) => {
    const enabledRoutes = getEnabledPropertyRoutes(property);

    return enabledRoutes.length > 0;
  },
  render(property) {
    const enabledRoutes = getEnabledPropertyRoutes(property);

    const menu = (
      <Menu>
        {enabledRoutes.map((route) => (
          <Menu.Item key={route.title}>
            <Link to={route.url}>{route.title}</Link>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
          More <DownOutlined />
        </a>
      </Dropdown>
    );
  },
});

export const syncUnitsAction = model.createAction<PropertyActionsFragment>({
  label: () => 'Sync Units',
  description: 'Synchronizes units for this property',
  enabledByModel: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.Property_PmsUnitSync)
  ),
  confirmation: {
    title: () => 'Synchronize Units',
    content: row => `Are you sure you want to synchronize units for ${row.name}?`,
  },
  async executes({ propertyId }, { apiClient, refetchContext }) {
    // @TODO: Do client-side check to make sure property has sources
    await apiClient.mutate({
      mutation: syncUnitsByPropertyDocument,
      variables: { propertyId },
    });

    await refetchContext();
  },
});

export const syncResidentsAction = model.createAction<PropertyResidentAuditFragment>({
  label: () => 'Sync Residents',
  description: 'Synchronizes residents for this property',
  enabledByModel: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.Property_PmsResidentSync)
  ),
  enabledByRow: (property) => {
    return getPropertyFeatureFlags(property).PMS_RESIDENT_SYNC;
  },
  confirmation: {
    title: () => 'Synchronize Residents',
    content: row => `Are you sure you want to synchronize residents for ${row.name}?`,
  },
  async executes({ propertyId }, { apiClient, hasuraClient }) {
    await apiClient.mutate({
      mutation: syncResidentsByPropertyDocument,
      variables: { propertyId },
    });

    await hasuraClient.resetStore(); // Unit occupancy could change
  },
});
