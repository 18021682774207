import { FieldFragment, TypeRefFragment } from '../hasura/generated';

function getFieldTypes(field: FieldFragment) {
  return field.type ? getTypeArray(field.type) : [];
}

function getTypeArray(type: TypeRefFragment, typesArr: TypeRefFragment[] = []) {
  if (type.kind) {
    typesArr.push({
      ...type,
      ofType: undefined
    });
  }

  if (type.ofType) {
    getTypeArray(type.ofType, typesArr);
  }

  return typesArr;
}

export default getFieldTypes;
