import { ApolloClient } from '@apollo/client';
import DataLoader from 'dataloader';
import { alignSingleValues } from 'dataloader-values';

import {
  getUserRolesDocument,
  getUserRolesQuery,
  getUserRolesQueryVariables,
  UserRoleUniqueLabelFragment as UserRole,
} from '../generated';

export class UserRoleLoader {

  private hasuraClient: ApolloClient<any>;

  private userRoleByPkLoader = new DataLoader<string, UserRole>(async (userRoleIds) => {
    const { data } = await this.hasuraClient.query<getUserRolesQuery, getUserRolesQueryVariables>({
      variables: {
        where: {
          userRoleId: { _in: userRoleIds as string[] },
        },
      },
      query: getUserRolesDocument,
    });

    const userRoles = alignSingleValues({
      values: data.userRoles,
      keys: userRoleIds as string[],
      getKey: ({ userRoleId }) => userRoleId,
    });

    return userRoles;
  });

  constructor(hasuraClient: ApolloClient<any>) {
    this.hasuraClient = hasuraClient;
  }

  public clearCache() {
    this.userRoleByPkLoader.clearAll();
  }

  public async getUserRoleByPk(userRoleId?: string | null): Promise<UserRole | null> {
    return userRoleId ? this.userRoleByPkLoader.load(userRoleId) : null;
  }
}

export default UserRoleLoader;
