import {
  IModelFormField,
  IModelImportRow,
  IModelImportRowError,
  IRowImportState,
  OnImportStateChangeCallback,
  RowImportStatus,
} from '../../models/typings';

interface IModelImportRowArgs<TParsedRow> {
  rowNumber: number;
  parsedRow: TParsedRow;
  importFields: IModelFormField<any>[];
}

export class ModelImportRow<
  TParsedRow,
  TTransformedRow
> implements IModelImportRow<TParsedRow, TTransformedRow> {

  public readonly rowNumber: number;
  public readonly parsedRow: TParsedRow;

  // tslint:disable variable-name
  private readonly errorsSet: Set<IModelImportRowError<TParsedRow>> = new Set();
  private onImportStateChange: OnImportStateChangeCallback;
  private _transformedRow?: TTransformedRow;
  private _importState: IRowImportState = { status: RowImportStatus['READY TO IMPORT'] };

  constructor(args: IModelImportRowArgs<TParsedRow>) {
    this.rowNumber = args.rowNumber;
    this.parsedRow = this.applyDefaultValues(args.parsedRow, args.importFields);
    this.onImportStateChange = () => {};
  }

  public get transformedRow() { return this._transformedRow; }
  public get importState() { return this._importState; }
  public get errors() { return [...this.errorsSet]; }

  public setTransformedRow(transformedRow: TTransformedRow) {
    return this._transformedRow = transformedRow;
  }

  public setImportState(importState: IRowImportState) {
    this._importState = importState;
    this.onImportStateChange(importState);

    return this.importState;
  }

  public setOnImportStateChange(callback: OnImportStateChangeCallback) {
    this.onImportStateChange = callback;
  }

  public addError(error: IModelImportRowError<TParsedRow>) {
    const defaultHighlightedFieldNames = error.errorFieldName ? [error.errorFieldName] : [];

    this.errorsSet.add({
      message: error.message,
      errorFieldName: error.errorFieldName,
      highlightedFieldNames: error.highlightedFieldNames || defaultHighlightedFieldNames,
    });
  }

  private applyDefaultValues(parsedRow: TParsedRow, importFields: IModelFormField<any>[]) {
    const newParsedRow = { ...parsedRow };

    for (const { fieldName, importOptions } of importFields) {
      const importFieldName = importOptions?.importFieldName || fieldName;

      const parsedValue = (newParsedRow as any)[importFieldName];
      const isEmpty = parsedValue === undefined || parsedValue === null || parsedValue === '';

      if (isEmpty && typeof importOptions?.defaultValue?.value === 'function') {
        (newParsedRow as any)[importFieldName] = importOptions.defaultValue.value();
      }
    }

    return newParsedRow;
  }
}

export default ModelImportRow;
