import { Form } from '@ant-design/compatible';
import { FormItemProps } from 'antd/lib/form';
import { FormikProps } from 'formik';
import { get, startCase } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { IModelSelectProps, ModelSelect } from '../../components/ModelSelect';
import { formatFieldErrorMessage } from '../../utils';

interface IModelFormSelectProps {
  fieldName: string;
  modelSelectProps: IModelSelectProps;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors: any;
  label?: string | React.ReactNode;
  colon?: boolean;
  formItemProps?: FormItemProps;
  submitCount?: FormikProps<{}>['submitCount'];
  extra?: React.ReactNode;
}

export const ModelFormSelect: React.FC<IModelFormSelectProps> = (props) => {
  const {
    setFieldValue,
    errors,
    fieldName,
    colon,
    submitCount,
    modelSelectProps,
    extra,
  } = props;

  const help = submitCount && get(errors, fieldName)
    ? formatFieldErrorMessage(fieldName, get(errors, fieldName))
    : undefined;

  const validateStatus = help ? 'error' : undefined;

  let label = props.label;

  if (label === undefined) {
    label = startCase(fieldName.replace('Id', ''));
  }

  const { multiple, value, model, required, onChange } = modelSelectProps;

  const link = !multiple && typeof value === 'string'
    ? model.routes.getRowLinkById(value)
    : null;

  return (
    <Form.Item
      {...props.formItemProps}
      required={required}
      help={help}
      validateStatus={validateStatus}
      label={link ? <Link to={link}>{label}</Link> : label}
      colon={colon}
    >
      <ModelSelect
        {...modelSelectProps}
        onChange={(nextValue, option) => {
          if (typeof onChange === 'function') {
            onChange(nextValue, option);
          }

          setFieldValue(fieldName, nextValue || null, true);
        }}
      />
      {extra}
    </Form.Item>
  );
}

export default ModelFormSelect;
