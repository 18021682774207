import { DocumentNode, useQuery } from '@apollo/client';
import { useState } from 'react';

import { apiClient } from '../../../graphql';
import { displayErrorMessage } from '../../../utils';
import type { ITableData, IUseTableDataArgs } from '../typings';

import { createPaginatedCsvFetcher } from './createPaginatedCsvFetcher';
import { formatListQueryOutput, formatTotalQueryOutput } from './formatQueryOutput';
import { formatQueryVariables } from './formatQueryVariables';

// Specific args for each table
interface ICreateTableDataHookArgs {
  totalOperation: DocumentNode;
  listOperation: DocumentNode;
}

export function useMainApiTableData(createArgs: ICreateTableDataHookArgs) {
  const { totalOperation, listOperation } = createArgs;

  return (hookArgs: IUseTableDataArgs): ITableData => {
    const { tableConfig, shouldSkipTotal, shouldSkipList } = hookArgs;
    const [refetching, setRefetching] = useState(false);

    const { totalQueryVariables, listQueryVariables } = formatQueryVariables(hookArgs);

    const totalResult = useQuery(totalOperation, {
      variables: totalQueryVariables,
      fetchPolicy: tableConfig.fetchPolicy || 'no-cache',
      skip: shouldSkipTotal,
      onError: error => displayErrorMessage(error),
      client: apiClient,
    });

    const listResult = useQuery(listOperation, {
      variables: listQueryVariables,
      fetchPolicy: tableConfig.fetchPolicy || 'no-cache',
      skip: shouldSkipList,
      onError: error => displayErrorMessage(error),
      client: apiClient,
    });

    const refetch = async () => {
      setRefetching(true);
      await Promise.all([ listResult.refetch(), totalResult.refetch() ]);
      setRefetching(false);
    };

    const totalLoading = !shouldSkipTotal && totalResult.loading;
    const total = formatTotalQueryOutput(totalResult.data);

    const listLoading = !shouldSkipList && (listResult.loading || refetching);
    const listData = formatListQueryOutput(listResult.data);

    return {
      refetch,
      totalLoading,
      total,
      listLoading,
      listData,
      totalError: totalResult.error,
      listError: listResult.error,
      paginatedCsvFetcher: createPaginatedCsvFetcher(hookArgs, listOperation),
    };
  };
}
