import { Tag } from 'antd';
import React from 'react';

import { Alert, AlertSeverity } from '../../graphql/api/generated';

type AlertFragment = Pick<Alert, 'type' | 'title' | 'severity'>;

interface IAlertTagsProps {
  alerts: AlertFragment[];
  tagContainerStyle?: React.CSSProperties;
}

export const AlertTags: React.FC<IAlertTagsProps> = ({ alerts, tagContainerStyle }) => {
  return (
    <>
      {alerts.map(({ type, title, severity }) => (
        <span key={type} style={tagContainerStyle}>
          <Tag
            style={{ color: 'rgba(0, 0, 0, 0.85)', marginBottom: 5 }}
            color={severity === AlertSeverity.HIGH ? 'red' : 'gold'}
          >
            {title}
          </Tag>
        </span>
      ))}
    </>
  );
}
