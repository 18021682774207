import {
  getPermissionsDocument,
  getPermissionsQuery,
  getPermissionsQueryVariables,
  getPropertyDetailsDocument,
  getPropertyDetailsQuery,
  getPropertyDetailsQueryVariables,
  getRoleByKeyDocument,
  getRoleByKeyQuery,
  getRoleByKeyQueryVariables,
  getRolesDocument,
  getRolesQuery,
  getRolesQueryVariables,
  getUnitsDocument,
  getUnitsQuery,
  getUnitsQueryVariables,
  RoleKey_enum,
  RoleUniqueLabelFragment,
} from '../generated';
import { hasuraClient } from '../hasuraClient';

// Create strongly typed functions for operations that cannot be called as hooks

export async function getPermissions(variables: getPermissionsQueryVariables) {
  const { data } = await hasuraClient.query<getPermissionsQuery, getPermissionsQueryVariables>({
    variables,
    query: getPermissionsDocument,
  });

  return data.permissions;
}

export async function getPropertyDetails(propertyId: string) {
  const { data } = await hasuraClient.query<getPropertyDetailsQuery, getPropertyDetailsQueryVariables>({
    query: getPropertyDetailsDocument,
    variables: { propertyId },
  });

  return data.property;
}

export async function getRoleByKey(key: RoleKey_enum): Promise<RoleUniqueLabelFragment | null> {
  const { data } = await hasuraClient.query<getRoleByKeyQuery, getRoleByKeyQueryVariables>({
    query: getRoleByKeyDocument,
    variables: { key },
  });

  const [role] = data.roles;

  return role;
}

export async function getRoles(variables: getRolesQueryVariables) {
  const { data } = await hasuraClient.query<getRolesQuery, getRolesQueryVariables>({
    variables,
    fetchPolicy: 'network-only',
    query: getRolesDocument,
  });

  return data.roles;
}

export async function getUnits(variables: getUnitsQueryVariables) {
  const { data } = await hasuraClient.query<getUnitsQuery, getUnitsQueryVariables>({
    variables,
    query: getUnitsDocument,
  });

  return data.units;
}
