import React from 'react';

import { IModel } from '../models/typings';

interface IModelsContext {
  models: IModel[];
  getModel: (schemaName: string) => IModel;
}

export const ModelsContext = React.createContext<IModelsContext>({
  models: [],
  getModel: () => {
    throw new Error('No models available');
  },
});
