import { LockTwoTone } from '@ant-design/icons';
import { Divider, Input, Radio, Skeleton, Switch, Typography } from 'antd';
import React, { useState } from 'react';

import {
  PermissionKey_enum,
  PermissionScope_enum,
  RoleDetailsFragment,
  usegetPermissionsQuery,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import useToggleRolePermissions from '../hooks/useToggleRolePermissions';

import {
  filterCategorizedPermissions,
  flattenPermissionCategories,
  getPermissionCategories,
} from './permission-categories';
import RolePermissionTable from './RolePermissionTable';
import { PermissionFilter } from './typings';

interface IRolePermissionManagerProps {
  role: RoleDetailsFragment;
  editMode: boolean;
  setEditMode: (editMode: boolean) => any;
}

const RolePermissionManager: React.FC<IRolePermissionManagerProps> = (props) => {
  const [permissionFilter, setPermissionFilter] = useState<PermissionFilter>(PermissionFilter.SHOW_ALL);
  const [search, setSearch] = useState<string>('');
  const { role, editMode, setEditMode } = props;
  const { permissionScope } = role;

  const toggleRolePermissions = useToggleRolePermissions(role);

  const { data, error, loading } = usegetPermissionsQuery({
    variables: {
      where: {
        scope: { _eq: permissionScope },
      },
    },
  });

  const allowedPermissions = data?.permissions;

  if (error || loading || !allowedPermissions) {
    return <Skeleton loading active />;
  }

  const enabledPermissionIds = role.rolePermissions.map(rp => rp.permission.permissionId);

  const categorizedPermissions = flattenPermissionCategories(
    getPermissionCategories(),
    allowedPermissions,
  );

  const filteredPermissions = filterCategorizedPermissions({
    categorizedPermissions,
    enabledPermissionIds,
    permissionFilter,
    search,
  });

  const withinPermissionScope = (
    (role.permissionScope !== PermissionScope_enum.GLOBAL && !!role.organization) ||
    authentication.hasPermission(PermissionKey_enum.Role_ManageUnrestricted)
  );

  const permissionsReadOnly = (
    role.isLocked ||
    !authentication.hasPermission(PermissionKey_enum.Role_ManagePermissions) ||
    !withinPermissionScope
  );

  return (
    <div style={{ paddingTop: 20 }}>
      <Radio.Group
        size='middle'
        buttonStyle='solid'
        defaultValue={PermissionFilter.SHOW_ALL}
        value={permissionFilter}
        onChange={(e) => setPermissionFilter(e.target.value)}
      >
        {Object.values(PermissionFilter).map((filter) => (
          <Radio.Button
            key={filter}
            value={filter}
            style={{ marginBottom: 25 }}
          >
            {filter}
          </Radio.Button>
        ))}
      </Radio.Group>

      <div className='ant-card-extra' style={{ padding: 0 }}>
        <Input.Search
          placeholder='Search...'
          defaultValue={search}
          onChange={e => setSearch(e.target.value)}
          onSearch={v => setSearch(v)}
          enterButton
          allowClear
        />
      </div>

      {!permissionsReadOnly && (
        <div>
          Edit Mode: <strong>{editMode ? 'ON' : 'OFF'}</strong>
          <Switch
            checked={editMode}
            onChange={(checked) => setEditMode(checked)}
            style={{ marginLeft: 15 }}
            disabled={permissionsReadOnly}
          />
        </div>
      )}

      {role.isLocked && (
        <Typography.Paragraph>
          <LockTwoTone style={{ marginRight: 5 }} /> Permissions are locked for this role and cannot be edited.
        </Typography.Paragraph>
      )}

      {!permissionsReadOnly && <Divider />}

      <RolePermissionTable
        categorizedPermissions={filteredPermissions}
        selectedRowKeys={enabledPermissionIds}
        readOnly={permissionsReadOnly || !editMode}
        toggleRolePermissions={toggleRolePermissions}
      />
    </div>
  );
};

export default RolePermissionManager;
