import type { ColumnFilters, IModelColumn } from '../typings';

interface IFormatRadioColumnArgs {
  column: IModelColumn<any>;
  filters?: ColumnFilters;
}

const DEFAULT_OPTIONS = [{ label: 'Yes' }, { label: 'No' }];

export function formatRadioColumn(args: IFormatRadioColumnArgs) {
  const { column, filters } = args;
  const { filterOptions } = column;

  if (filterOptions?.type !== 'RADIO') {
    return column;
  }

  const { key } = filterOptions;
  const filterValues = filters ? filters[key] : null;

  const columnOptions = (filterOptions.options || DEFAULT_OPTIONS as { label: string }[])
    .map(({ label }) => ({ text: label, value: label }));

  return {
    ...column,
    key,
    filtered: Boolean(filterValues?.length),
    filteredValue: filterValues || null, // null ensures it's treated as unfiltered
    filters: columnOptions,
    filterMultiple: false,
  };
}
