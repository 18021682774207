import { Alert } from 'antd';
import moment from 'moment';
import React from 'react';

import TimetableField from '../../components/TimetableField';
import { validateTimetable } from '../helpers/validate/timetable';

import model from './model';

const formFields = model.createFormFields([
  {
    fieldName: 'nickname',
    maxLength: 100,
  },
  {
    fieldName: 'notes',
    maxLength: 1500,
    textArea: true,
  },
  {
    fieldName: 'schedule',
    render: ({ field, disabled, formikProps, FormItem, formItemProps }) => {
      const { values, setFieldValue, touched, errors } = formikProps;

      return (
        <FormItem {...formItemProps}>
          <TimetableField
            values={values}
            fieldName='schedule'
            modelName={model.names.schemaName}
            onChange={(value) => {
              setFieldValue(field.name, value, true);
            }}
            touched={touched}
            errors={errors}
            disabled={disabled}
          />
        </FormItem>
      );
    },
  },
]);

export const managedWithinPropertyField = model.createFormField({
  fieldName: 'managedWithinPropertyId',
  label: 'Property',
  importOptions: {
    applyAll: true,
    required: true,
  },
});

export const roleField = model.createFormField({
  fieldName: 'roleId',
  importOptions: {
    applyAll: true,
    render: () => {
      return (
        <Alert
          showIcon
          type='info'
          message='User import is currently only supported for Residents'
        />
      );
    },
  },
});

export const activatesAtField = model.createFormField({
  fieldName: 'activatesAt',
  importOptions: {
    importFieldName: 'Activation Date',
    defaultValue: {
      label: 'Current date',
      value: () => moment(new Date()).format('MM/DD/YYYY'),
    },
  },
});

model.setFormOptions({
  fields: formFields,
  validate: values => validateTimetable(values, 'schedule'),
});
