import React from 'react';

import { TableCellDescriptions } from '../../components/TableCellDescriptions';
import { TicketStatus_enum, TicketTableFragment, TicketTableFragmentDoc } from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';
import { IModelTableExpandable } from '../typings';
import { UserModel } from '../User/model';

import { createUnitKeyAction, updateStatusAction } from './actions';
import * as columns from './columns';
import model from './model';

model.actions.setDefaultActions([createUnitKeyAction, updateStatusAction]);

export const expandable: IModelTableExpandable<TicketTableFragment> = {
  rowExpandable: ({ createdByUser }) => !!createdByUser,
  expandedRowRender: ({ createdByUser }) => {
    return (
      <TableCellDescriptions
        descriptions={{ 'Created By': UserModel.routes.renderRowLink(createdByUser) }}
      />
    );
  },
};

export const TicketsMainTable = model.createTable<TicketTableFragment>({
  expandable,
  fragment: TicketTableFragmentDoc,
  columns: [
    columns.idColumn,
    columns.propertyColumn,
    columns.unitColumn,
    columns.smartLockColumn,
    columns.typeColumn,
    columns.statusColumn,
    columns.assignedToColumn,
    columns.solvedByColumn,
    columns.solvedAtColumn,
    createdAtColumn,
  ],
});

export const OpenTicketsByUnitTable = model.createTable<TicketTableFragment, string>({
  expandable,
  title: 'Open Tickets',
  fragment: TicketTableFragmentDoc,
  fixedQueryFilters: (unitId) => ({
    unitId: { _eq: unitId },
    status: {
      _in: [TicketStatus_enum.PENDING, TicketStatus_enum.UNSOLVED],
    },
  }),
  columns: [
    columns.smartLockColumn,
    columns.typeColumn,
    columns.statusColumn,
    columns.assignedToColumn,
    createdAtColumn,
  ],
});

export const OpenTicketsBySmartLockTable = model.createTable<TicketTableFragment, string>({
  expandable,
  title: 'Open Tickets',
  fragment: TicketTableFragmentDoc,
  fixedQueryFilters: (smartLockId) => ({
    smartLockId: { _eq: smartLockId },
    status: {
      _in: [TicketStatus_enum.PENDING, TicketStatus_enum.UNSOLVED],
    },
  }),
  columns: [
    columns.typeColumn,
    columns.statusColumn,
    columns.assignedToColumn,
    createdAtColumn,
  ],
});
