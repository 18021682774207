import { notification } from 'antd';

import {
  generatePropertyPinCodeDocument,
  generatePropertyPinCodeMutation,
  generatePropertyPinCodeMutationVariables,
} from '../../graphql/api/generated';
import {
  PermissionKey_enum,
  PinCodeRequiredRowFragment,
  PinCodeType_enum,
  RoleKey_enum,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';

import model from './model';

export const regenerateAction = model.createAction<PinCodeRequiredRowFragment>({
  label: () => 'Regenerate',
  enabledByModel: () => true,
  enabledByRow: ({ type, userRoleId }, { hasPermission }) => {
    if (userRoleId && type === PinCodeType_enum.GATEWAY_PIN) {
      // Regeneration is not allowed for SGT PIN codes
      return false;
    }

    if (userRoleId && type === PinCodeType_enum.VENDOR_PIN) {
      return authentication.hasPermission(PermissionKey_enum.UserRole_GrantRevokeScoped) ||
        authentication.currentRoleRelationships.some(r => (
          r.relatedRole.key === RoleKey_enum.VENDOR_PIN &&
          r.canReadUserRole &&
          r.canGrantUserRole
        ));
    }

    return hasPermission(PermissionKey_enum.PinCode_GenerateRandomByUser);
  },
  confirmation: {
    title: () => 'Regenerate Random Code',
    content: () => (
      'Are you sure you want to regenerate a random code? ' +
      'This action cannot be undone.'
    ),
  },
  executes: async (pinCode, { apiClient, resetStores }) => {
    const { propertyId, assignedToUserId, userRoleId } = pinCode;

    await apiClient.mutate<
      generatePropertyPinCodeMutation,
      generatePropertyPinCodeMutationVariables
    >({
      mutation: generatePropertyPinCodeDocument,
      variables: {
        input: {
          propertyId,
          assignedToUserId,
          userRoleId,
        },
      },
    });

    resetStores();

    notification.success({
      message: 'Success',
      description: 'A new code was successfully regenerated!',
    });
  },
});
