import { Form } from '@ant-design/compatible';
import { enumManagers } from '@chirp/enums';
import { Alert, Button, Table } from 'antd';
import { withFormik } from 'formik';
import React from 'react';
import { v4 as uuid } from 'uuid';

import { usegetRolesQuery } from '../../../graphql/hasura/generated';
import { FORM_ITEM_DEFAULT_LAYOUT } from '../../../pages/ModelForm/form-fields';
import ModelFormSelect from '../../../pages/ModelForm/ModelFormSelect';
import { OrganizationModel } from '../../Organization/model';

import { DEPRECATED_TYPE_MAP } from './deprecated-types';
import { PRESET_ROLES } from './preset-roles';
import {
  getRoleRowKey,
  handleSubmit,
  IFormValues,
  IPresetRolesFormMergedProps,
  IPresetRolesFormProps,
  mapPropsToValues,
  PresetRoleRow,
  validate,
} from './preset-roles-formik';

const PresetRolesForm: React.FC<IPresetRolesFormMergedProps> = (props) => {
  const { ...formikProps } = props;
  const { values, setFieldValue, isSubmitting } = formikProps;

  const { loading } = usegetRolesQuery({
    skip: !values.organizationId,
    fetchPolicy: 'network-only',
    variables: {
      where: {
        organizationId: { _eq: values.organizationId },
        name: {
          _in: PRESET_ROLES.map(r => r.name),
        },
      },
    },
    onCompleted: ({ roles }) => {
      // Attach role ID to  preset roles
      const presetRoles: PresetRoleRow[] = PRESET_ROLES.map((presetRole) => {
        const existingRole = roles.find(r => (
          r.name === presetRole.name &&
          r.permissionScope === presetRole.permissionScope
        ));

        return {
          ...presetRole,
          // Randomly generate UUID for roles that will be inserted
          roleId: existingRole?.roleId || uuid(),
          exists: !!existingRole,
        };
      });

      setFieldValue('presetRoles', presetRoles);

      // Deselect roles that have already been created
      const nextSelectedRowKeys = values.selectedRowKeys.filter((rowKey) => {
        return !roles.some(r => getRoleRowKey(r) === rowKey);
      });

      setFieldValue('selectedRowKeys', nextSelectedRowKeys);
    },
  });

  const toggleRoleRowSelection = (role: PresetRoleRow) => {
    if (role.exists) {
      return;
    }

    const rowKey = getRoleRowKey(role);

    const prevSelected = values.selectedRowKeys.some(k => k === rowKey);
    const nextSelected = !prevSelected; // Toggle selection

    const nextSelectedRowKeys = nextSelected
      ? [...values.selectedRowKeys, rowKey]
      : values.selectedRowKeys.filter(k => k !== rowKey);

    setFieldValue('selectedRowKeys', nextSelectedRowKeys);
  };

  return (
    <Form style={{ marginTop: 8 }} onSubmit={props.handleSubmit}>
      <ModelFormSelect
        fieldName='organizationId'
        formItemProps={{ ...FORM_ITEM_DEFAULT_LAYOUT }}
        modelSelectProps={{
          value: values.organizationId,
          model: OrganizationModel,
          required: true,
          autoSelectSingleResult: true,
        }}
        setFieldValue={setFieldValue}
        errors={formikProps.errors}
        submitCount={formikProps.submitCount}
      />
      <Table<PresetRoleRow>
        loading={loading}
        columns={[
          {
            title: 'Role Name',
            render: (_, { name }) => name,
          },
          {
            title: 'Access App Role',
            render: (_, { deprecatedType }) => {
              return deprecatedType ? DEPRECATED_TYPE_MAP[deprecatedType] : null;
            },
          },
          {
            title: 'Permission Scope',
            render: (_, { permissionScope }) => enumManagers.PermissionScope.getLabel(permissionScope),
          },
          {
            title: 'Permissions',
            render: (_, role) => role.permissionKeys.length,
          },
          {
            title: 'Readable Roles',
            render: (_, role) => role.roleRelationships.filter(r => r.canReadUserRole).length,
          },
          {
            title: 'Grantable Roles',
            render: (_, role) => role.roleRelationships.filter(r => r.canGrantUserRole).length,
          },
          {
            title: 'Revocable Roles',
            render: (_, role) => role.roleRelationships.filter(r => r.canRevokeUserRole).length,
          },
          {
            render: (_, { exists }) => {
              if (exists) {
                return (
                  <Alert
                    showIcon
                    type='warning'
                    message='This role has already been created for the selected organization'
                  />
                );
              }

              return null;
            },
          },
        ]}
        dataSource={values.presetRoles}
        onRow={(role) => {
          return {
            onClick: () => toggleRoleRowSelection(role),
          };
        }}
        rowSelection={{
          type: 'checkbox',
          onSelect: (role) => toggleRoleRowSelection(role),
          onSelectAll: (selected, selectedRows) => {
            const nextSelectedRowKeys = selected ? selectedRows.map(r => getRoleRowKey(r)) : [];

            setFieldValue('selectedRowKeys', nextSelectedRowKeys);
          },
          selectedRowKeys: values.selectedRowKeys,
          getCheckboxProps: ({ exists }) => ({ disabled: isSubmitting || !!exists }),
        }}
        rowKey={r => getRoleRowKey(r)}
        rowClassName={({ exists }) => isSubmitting || !!exists ? 'disabled-row' : 'selectable-row'}
        style={{ paddingTop: 20, marginBottom: 40 }}
        pagination={false}
      />
      <section style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          type='primary'
          size='large'
          onClick={(e) => props.handleSubmit()}
          loading={isSubmitting}
          disabled={(
            !values.organizationId || !values.selectedRowKeys.length || isSubmitting
          )}
        >
          {isSubmitting ? 'Adding Preset Roles' : 'Add Preset Roles'}
        </Button>
      </section>
    </Form>
  );
}

const PresetRolesFormWithFormik = withFormik<IPresetRolesFormProps, IFormValues>({
  mapPropsToValues,
  validate,
  handleSubmit,
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
})(PresetRolesForm);

export default PresetRolesFormWithFormik;
