import moment from 'moment';

import {
  TimetableRule,
  TimetableRuleState,
  TimetableRuleType,
} from '../../../graphql/api/generated';

export function validateTimetable(values: any, fieldName = 'timetable') {
  const timetable: TimetableRule[] | undefined | null = values[fieldName];

  if (!timetable) {
    return {};
  }

  const errors: { [k: string]: any } = {
    [fieldName]: [],
  };

  for (const [index, rule] of timetable.entries()) {
    const { type, start, end, days, state, startDate, endDate } = rule;
    const ruleError: { [k: string]: any } = {};

    if (days.length === 0) {
      ruleError.days = `Please select at least one weekday`;
    }

    if (!start) {
      ruleError.start = `Please provide a start time`;
    }

    if (!end) {
      ruleError.end = `Please provide an end time`;
    }

    if (start && end) {
      const isAllDay = start === '00:00' && end === '00:00';

      if (!isAllDay && start === end) {
        ruleError.start = ruleError.end = 'Start and end time cannot match';
      } else if (end !== '00:00' && moment(start, 'hh:mm').isAfter(moment(end, 'hh:mm'))) {
        ruleError.start = 'Start time cannot be after end time';
      }
    }

    if (state === TimetableRuleState.LOCKDOWN) {
      if (type !== TimetableRuleType.RESTRICTION) {
        ruleError.type = 'Incorrect type set';
      }

      if (!startDate) {
        ruleError.startDate = 'Please provide a start date';
      }

      if (startDate && endDate && startDate === endDate) {
        ruleError.startDate = ruleError.endDate = 'Start and end date cannot match';
      } else if (startDate && endDate && moment(startDate).isAfter(moment(endDate))) {
        ruleError.startDate = 'Start date cannot be after end date';
      }
    }

    if (Object.keys(ruleError).length) {
      errors[fieldName][index] = ruleError;
    }
  }

  if (!errors[fieldName].length) {
    delete errors[fieldName]; // Reset if no errors were found
  }

  return errors;
}
