import { Radio } from 'antd';
import React from 'react';

import { IModelTable } from '../../models/typings';

interface ITableSelectorProps {
  value: string;
  tables: IModelTable<any, any, any>[];
  selectTable: (value: string) => any;
}

const TableSelector: React.FC<ITableSelectorProps> = ({ value, tables, selectTable }) => {
  return (
    <Radio.Group
      size='middle'
      buttonStyle='solid'
      defaultValue={value}
      value={value}
      onChange={(e) => selectTable(e.target.value)}
    >
      {tables.map((t, index) => (
        <Radio.Button
          key={index}
          value={t.title}
          style={{ marginRight: 25, marginBottom: 30 }}
        >
          {t.icon}
          <span style={{ marginLeft: t.icon ? 10 : 0 }}>
            {t.title}
          </span>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default TableSelector;
