import {
  getOrganizationsQuery,
  PermissionKey_enum,
  PermissionScope_enum,
  Role_bool_exp,
} from '../../../../graphql/hasura/generated';
import { authentication } from '../../../../stores';

export function getRoleQueryFilters(
  permissionScope: PermissionScope_enum | null | undefined,
  organizations: getOrganizationsQuery['organizations'],
): Role_bool_exp {
  const organizationIds = permissionScope === PermissionScope_enum.GLOBAL
    ? [] // Prevent previous organization data from conflicting with global scope
    : organizations.map(o => o.organizationId) || [];

  const orFilters: Role_bool_exp[] = [{
    organizationId: { _is_null: true }, // Default roles
  }];

  if (organizationIds.length === 1) {
    orFilters.push({
      // Custom roles for single organization
      organizationId: { _eq: organizationIds[0] },
    });
  }

  let andFilters: Role_bool_exp[] = [{
    // Grantable roles based on current user's roles
    roleRelationshipsByRelatedRole: {
      roleId: {
        _in: authentication.currentDataScope.userRoles.map(u => u.role.roleId),
      },
      canGrantUserRole: { _eq: true },
    },
  }];

  if (
    permissionScope === PermissionScope_enum.GLOBAL &&
    authentication.hasPermission(PermissionKey_enum.UserRole_GrantRevokeGlobal)
  ) {
    andFilters = []; // Remove filters to allow selection of all global roles
  } else if (
    permissionScope !== PermissionScope_enum.GLOBAL &&
    authentication.hasPermission(PermissionKey_enum.UserRole_GrantRevokeScoped)
  ) {
    andFilters = []; // Remove filters to allow selection of all scoped roles
  }

  return { _and: andFilters, _or: orFilters };
}
