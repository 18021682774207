import { Card } from 'antd';
import React from 'react';

import BasicLayout from './BasicLayout';

interface ILoadingLayoutProps {
  pageTitle: string;
}

const LoadingLayout = ({ pageTitle }: ILoadingLayoutProps) => {
  return (
    <BasicLayout pageTitle={pageTitle}>
      <Card
        loading
        title='...'
        style={{ marginBottom: 20 }}
        bordered={false}
      />
    </BasicLayout>
  );
};

export default LoadingLayout;
