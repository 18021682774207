import { notification } from 'antd';
import retry from 'async-retry';
import { FormikBag, FormikErrors, FormikProps } from 'formik';

import {
  PermissionKey_enum,
  Role_insert_input,
  RolePermission_insert_input,
  RoleRelationship_insert_input,
} from '../../../graphql/hasura/generated';
import {
  getPermissions,
  getRoles,
  insertRoles,
  upsertRoleRelationships,
} from '../../../graphql/hasura/operations';
import { history } from '../../../routes/history';
import { authentication } from '../../../stores';
import { displayErrorMessage } from '../../../utils';
import { RoleModel } from '../model';

import PRESET_ROLES, { IPresetRole } from './preset-roles';

export type PresetRoleRow = IPresetRole & {
  roleId?: string;
  exists?: boolean;
};

export interface IFormValues {
  organizationId?: string | null;
  presetRoles: PresetRoleRow[];
  selectedRowKeys: string[];
}

// tslint:disable-next-line no-empty-interface
export interface IPresetRolesFormProps {}

export interface IPresetRolesFormMergedProps extends IPresetRolesFormProps, FormikProps<IFormValues> {}

export function getRoleRowKey(role: Pick<PresetRoleRow, 'permissionScope' | 'name'>) {
  return `${role.permissionScope}:${role.name}`;
}

export function mapPropsToValues(props: IPresetRolesFormProps): IFormValues {
  return { presetRoles: [...PRESET_ROLES], selectedRowKeys: [] };
}

export function validate(values: IFormValues, props: IPresetRolesFormProps) {
  const errors: FormikErrors<IFormValues> = {};

  if (!values.organizationId) {
    errors.organizationId = 'Please select an organization';
  }

  if (!values.selectedRowKeys.length) {
    // @ts-ignore (ModelFormSelect does not handle array)
    errors.selectedRowKeys = 'Please select at least one preset role';
  }

  return errors;
}

export async function handleSubmit(
  values: IFormValues,
  formikBag: FormikBag<IPresetRolesFormProps, IFormValues>
) {
  try {
    const canManageLockedPermissions = authentication.hasPermission(PermissionKey_enum.Role_ManageUnrestricted);

    const permissions = await getPermissions({ where: {} });
    const defaultRoles = await getRoles({
      where: {
        organizationId: { _is_null: true },
      },
    });

    const roleInputs: Role_insert_input[] = [];
    const customRoleRelationshipInputs: RoleRelationship_insert_input[] = [];

    const selectedRoles = values.presetRoles.filter(r => (
      values.selectedRowKeys.includes(getRoleRowKey(r))
    ));

    for (const role of selectedRoles) {
      if (role.exists) {
        continue;
      }

      const rolePermissionInputs: RolePermission_insert_input[] = [];

      for (const permissionKey of role.permissionKeys) {
        const permission = permissions.find(p => (
          p.scope === role.permissionScope &&
          p.key === permissionKey
        ));

        if (permission?.isLocked && !canManageLockedPermissions) {
          throw new Error(
            `You are not authorized to grant a locked permission for ${role.name}`,
          );
        }

        if (permission) {
          rolePermissionInputs.push({ permissionId: permission.permissionId });
        }
      }

      const defaultRoleRelationshipInputs: RoleRelationship_insert_input[] = [];

      for (const roleRelationship of role.roleRelationships) {
        const { relatedRole, ...readGrantRevokePermissions } = roleRelationship;

        if ('key' in relatedRole) {
          const relatedRoleId = defaultRoles.find(r => r.key && r.key === relatedRole.key)?.roleId;

          if (relatedRoleId) {
            defaultRoleRelationshipInputs.push({
              relatedRoleId,
              ...readGrantRevokePermissions,
            });
          }
        } else if ('name' in relatedRole) {
          const customRole = values.presetRoles.find(r => (
            r.name === relatedRole.name &&
            r.permissionScope === relatedRole.permissionScope
          ));

          if (
            customRole &&
            (customRole.exists || selectedRoles.some(r => r.roleId === customRole.roleId))
          ) {
            customRoleRelationshipInputs.push({
              relatedRoleId: customRole.roleId,
              roleId: role.roleId,
              ...readGrantRevokePermissions,
            });
          }
        }
      }

      roleInputs.push({
        roleId: role.roleId, // Pass randomly generated UUID
        organizationId: values.organizationId,
        name: role.name,
        permissionScope: role.permissionScope,
        deprecatedType: role.deprecatedType,
        rolePermissions: {
          data: rolePermissionInputs,
        },
        roleRelationshipsByRole: {
          data: defaultRoleRelationshipInputs,
        },
      });
    }

    await insertRoles({ objects: roleInputs });

    // Unforunately, custom role relationships cannot be inserted at the same time that the related
    // role is inserted (because it doesn't exist yet), so we have to make a followup call.
    // However, it's possible one of the DB replicas will not have the newly inserted roles yet and
    // a foreign key constraint error will be thrown. So let's implement retries to account for this.
    // @TODO: Is there a better solution than this workaround?
    try {
      await retry(async () => {
        if (customRoleRelationshipInputs.length) {
          await upsertRoleRelationships({ objects: customRoleRelationshipInputs });
        }
      }, {
        retries: 4,
        minTimeout: 1000,
        maxTimeout: 2000,
      });

      notification.success({
        message: 'Success',
        description: 'The preset roles were successfully added!',
      });
    } catch (error) {
      displayErrorMessage(new Error('Unable to configure relationships between custom roles'));
    }

    const stringifiedParams = JSON.stringify({ organization: [values.organizationId] });
    const encodedParams = encodeURIComponent(stringifiedParams);
    const redirect = `${RoleModel.routes.basePath}?table=Custom%20Roles&filters=${encodedParams}`;

    history.push(redirect);
  } catch (error) {
    formikBag.setSubmitting(false);
    displayErrorMessage(error as Error);
  }
}
