import pluralize from 'pluralize';

export enum DurationUnit {
  SECOND = 'Seconds',
  MINUTE = 'Minutes',
  HOUR = 'Hours',
  DAY = 'Days',
  WEEK = 'Weeks',
  MONTH = 'Months',
}

export const MAX_DURATION_IN_SECONDS = 31536000; // 1 year (365 days)

// Keep sorted from largest to smallest
export const UNIT_IN_SECONDS: { [key in DurationUnit]: number } = {
  Months: 2592000,
  Weeks: 604800,
  Days: 86400,
  Hours: 3600,
  Minutes: 60,
  Seconds: 1,
};

export function convertSecondsToLargestUnit(
  durationSeconds: number,
  allowedDurationUnits?: DurationUnit[] | null,
) {
  let durationUnit: DurationUnit | undefined;

  // Find largest unit where seconds can be converted evenly
  for (const [unit, seconds] of Object.entries(UNIT_IN_SECONDS)) {
    const isAllowedDurationUnit = (
      !allowedDurationUnits || allowedDurationUnits.includes(unit as DurationUnit)
    );

    if (isAllowedDurationUnit && durationSeconds % seconds === 0) {
      durationUnit = unit as DurationUnit;
      break;
    }
  }

  // If duration cannot be converted evenly, fall back to largest unit
  if (!durationUnit) {
    for (const [unit, seconds] of Object.entries(UNIT_IN_SECONDS)) {
      const isAllowedDurationUnit = (
        !allowedDurationUnits || allowedDurationUnits.includes(unit as DurationUnit)
      );

      if (isAllowedDurationUnit && durationSeconds > seconds) {
        durationUnit = unit as DurationUnit;
        break;
      }
    }
  }

  // If duration is too small, fall back to smallest unit (which will result in a fraction)
  if (!durationUnit) {
    durationUnit = DurationUnit.SECOND;
  }

  const convertedValue = convertSecondsToUnit(durationSeconds, durationUnit);
  const displayedDurationUnit = getDisplayedDurationUnit(durationUnit, convertedValue);

  return {
    durationUnit,
    convertedValue,
    displayedValue: `${convertedValue} ${displayedDurationUnit}`,
  };
}

export function convertSecondsToUnit(
  valueInSeconds: number | null | undefined,
  durationUnit: DurationUnit,
) {
  return typeof valueInSeconds === 'number' ? valueInSeconds / UNIT_IN_SECONDS[durationUnit] : undefined;
}

export function convertUnitToSeconds(
  value: number | null | undefined,
  durationUnit: DurationUnit,
) {
  return typeof value === 'number' ? value * UNIT_IN_SECONDS[durationUnit] : undefined;
}

export function getDisplayedDurationUnit(durationUnit: DurationUnit, convertedValue?: number) {
  return pluralize(durationUnit, convertedValue);
}
