import {
  order_by,
  Organization_bool_exp,
  OrganizationLabelFragment,
  OrganizationLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import Model from '../Model';

export const OrganizationModel = new Model<
Organization_bool_exp,
  OrganizationLabelFragment
>({
  names: {
    schemaName: 'Organization',
  },

  permissionsOptions: {
    canRead: () => true,
  },

  labels: {
    getLabel: (o) => o.name,
    labelFragment: OrganizationLabelFragmentDoc,
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            {
              name: { _ilike: `%${word}%` },
            },
            {
              identifier: { _ilike: `%${word}%` },
            },
          ],
        }
      ))
    }),
    defaultSelectOrderBy: {
      name: order_by.asc,
    },
  }
});

export default OrganizationModel;
