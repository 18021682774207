import { Table } from 'antd';
import React from 'react';

import { TicketTableFragment, usegetTicketDetailsQuery } from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import normalizeColumns from '../../helpers/normalizeColumns';
import { TicketsMainTable } from '../tables';

const TicketDetailsLayout = createDetailsLayout<TicketTableFragment>();

export const TicketDetailsPage: React.FC<any> = () => {
  return (
    <TicketDetailsLayout
      modelName='Ticket'
      useQuery={(ticketId) => {
        return usegetTicketDetailsQuery({
          variables: { ticketId },
        });
      }}
      body={(ticket) => {
        const columns = normalizeColumns(TicketsMainTable.columns);

        return (
          <Table
            columns={columns}
            dataSource={[ticket]}
            rowKey={r => r.ticketId}
            pagination={false}
            expandable={TicketsMainTable.expandable}
          />
        );
      }}
    />
  );
};

export default TicketDetailsPage;
