import { groupBy, isEmpty } from 'lodash';
import moment from 'moment';
import validator from 'validator';

import { IModel, IModelFormField } from '../../../models/typings';
import ModelImportRow from '../ModelImportRow';

interface IValidateParsedRowArgs {
  model: IModel<any>;
  rows: ModelImportRow<any, any>[];
  importFields: IModelFormField<any>[];
}

export async function validateParsedRows(
  args: IValidateParsedRowArgs
): Promise<ModelImportRow<any, any>[]> {
  const { model, rows, importFields } = args;

  const parsedRows = rows.map(r => r.parsedRow);

  const uniqueKeys = model.formOptions.importOptions?.uniqueKeys || [];
  const getGroupKey = (parsedRow: any) => uniqueKeys.map(key => parsedRow[key]).join();
  const groupedRows = uniqueKeys.length ? groupBy(parsedRows, getGroupKey) : {};

  for (const row of rows) {
    for (const field of importFields) {
      const { fieldName, importOptions } = field;

      if (!importOptions) {
        continue;
      }

      const importFieldName = importOptions.importFieldName || fieldName;
      const parsedValue = row.parsedRow[importFieldName];

      const isValueEmpty = parsedValue === undefined || parsedValue === null || parsedValue === '';

      if (importOptions.required && isValueEmpty) {
        row.addError({
          errorFieldName: importFieldName,
          message: 'Required field',
        });

        continue;
      }

      if (isValueEmpty) {
        continue;
      }

      if (typeof importOptions.getAllowedValues === 'function') {
        const allowedValues = importOptions.getAllowedValues();

        if (!allowedValues.includes(parsedValue)) {
          row.addError({
            errorFieldName: importFieldName,
            message: 'Not an allowed value',
          });

          continue;
        }
      }

      const introspectionField = field.getIntrospectionField();

      if (introspectionField?.scalarName === 'int' || introspectionField?.scalarName === 'float') {
        if (!validator.isNumeric(parsedValue)) {
          row.addError({
            errorFieldName: importFieldName,
            message: 'Not a number',
          });

          continue;
        }

        if (typeof field.minValue === 'number' && +parsedValue < field.minValue) {
          row.addError({
            errorFieldName: importFieldName,
            message: 'Lower than minimum allowed value',
          });

          continue;
        }

        if (typeof field.maxValue === 'number' && +parsedValue > field.maxValue) {
          row.addError({
            errorFieldName: importFieldName,
            message: 'Higher than maximum allowed value',
          });

          continue;
        }
      }

      if (introspectionField?.scalarName === 'timestamptz') {
        if (!moment(new Date(parsedValue)).isValid()) {
          row.addError({
            errorFieldName: importFieldName,
            message: 'Invalid date',
          });

          continue;
        }
      }

      if (typeof field.maxLength === 'number') {
        if (typeof parsedValue === 'string' && parsedValue.length > field.maxLength) {
          row.addError({
            errorFieldName: importFieldName,
            message: 'Maximum character length exceeded',
          });

          continue;
        }
      }

      for (const rule of (importOptions.rules || [])) {
        if (typeof rule.validate === 'function') {
          const isValid = await rule.validate(row.parsedRow, parsedRows);

          if (!isValid) {
            row.addError({
              errorFieldName: importFieldName,
              message: rule.errorMessage || rule.description,
            });
          }
        }
      }
    }

    if (!isEmpty(groupedRows)) {
      const groupKey = getGroupKey(row.parsedRow);

      if (groupedRows[groupKey] && groupedRows[groupKey].length > 1) {
        row.addError({
          message: `Duplicate row (based on ${uniqueKeys.join(', ')})`,
          highlightedFieldNames: uniqueKeys,
        });

        continue;
      }
    }
  }

  return rows;
}

export default validateParsedRows;
