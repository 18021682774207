import { Form } from '@ant-design/compatible';
import { Button, Divider, Modal, notification, Radio, Select, Spin, Typography } from 'antd';
import { Formik, FormikActions, FormikConfig, FormikErrors } from 'formik';
import React, { FC, useContext, useState } from 'react';

import { generateCreateMutation, hasuraClient } from '../../../../graphql';
import {
  CredentialType_enum,
  usegetGamPropertiesByUserQuery,
  UserDetailsFragment as User,
} from '../../../../graphql/hasura/generated';
import { ModelsContext } from '../../../../hooks/providers';
import { authentication } from '../../../../stores';
import { formatUserName, isConstraintViolationError, selectError } from '../../../../utils';

import ManualEnrollmentFlow from './ManualEnrollmentFlow';
import ReaderEnrollmentFlow from './ReaderEnrollmentFlow';
import { FobFlow, ICredentialFormValues } from './typings';
import USBEnrollmentFlow from './USBEnrollmentFlow';

const canAssignFob = (values: ICredentialFormValues) => {
  switch (values.fobFlow) {
    case FobFlow.ENROLLMENT_READER:
    case FobFlow.ENROLLMENT_USB:
      return values.identifier && values.ownedByPropertyId
    case FobFlow.MANUAL_ENROLLMENT:
      return values.cardNumber &&
            values.facilityCode &&
            values.ownedByPropertyId &&
            !!values.identifier;
  }
};

const AssignNewFob: FC<{ user: User; }> = ({ user }) => {
  const { getModel } = useContext(ModelsContext);
  const CredentialModel = getModel('Credential');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [enableValidation, setEnableValidation] = useState<boolean>(false);

  const { data: gamPropertiesData, loading: gamPropLoading } = usegetGamPropertiesByUserQuery({
    skip: !isModalOpen,
    variables: { userId: user.userId },
  });

  const gamProperties = gamPropertiesData?.properties ?? [];

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleOnSubmit: FormikConfig<ICredentialFormValues>['onSubmit'] = async (values, actions) => {
    Modal.confirm({
      title: 'Confirmation',
      content: `Please confirm you want to assign this fob to ${formatUserName(user)}`,
      onCancel: () => actions.setSubmitting(false),
      onOk: async () => {
        const mutation = generateCreateMutation(CredentialModel);

        if (!mutation || !authentication) {
          return;
        }

        try {
          await hasuraClient.mutate({
            mutation,
            variables: {
              data: {
                assignedToUserId: user.userId,
                identifier: values.identifier?.toLowerCase(),
                type: CredentialType_enum.FOB,
                ownedByPropertyId: values.ownedByPropertyId,
                facilityCode: values.facilityCode,
                cardNumber: values.cardNumber,
              },
            },
          });

          closeModal();

          notification.success({
            message: 'The fob was successfully assigned to the user!',
          });
        } catch (error) {
          const selectedError = selectError(error);
          const constraintViolation = isConstraintViolationError(selectedError);

          const errorMessage = constraintViolation
            ? 'This fob is already assigned to a user'
            : 'There was an error assigning the fob to the user';

          notification.error({
            message: 'Error',
            description: errorMessage,
          });
        }
      }
    })
  }

  const handleValidate: FormikConfig<ICredentialFormValues>['validate'] = (values) => {
    setEnableValidation(true);
    const errors: FormikErrors<ICredentialFormValues> = {}

    if (!values.ownedByPropertyId) {
      errors.ownedByPropertyId = 'Please select a property'
    }

    if (!values.identifier) {
      switch (values.fobFlow) {
        case FobFlow.ENROLLMENT_READER:
          errors.identifier = 'Please select a Fob';
          break;
        case FobFlow.ENROLLMENT_USB:
          errors.identifier = 'Please enter card identifier';
          break;
          case FobFlow.MANUAL_ENROLLMENT:
          errors.identifier = 'Please enter card facility code and number';
          break;
        default:
      }
    }

    return errors
  }

  const resetData = (setFieldValue: FormikActions<ICredentialFormValues>['setFieldValue']) => {
    setFieldValue('identifier', null);
    setFieldValue('facilityCode', null);
    setFieldValue('cardNumber', null);
    setFieldValue('bitFormat', null);
  };

  return (
    <>
      <Button
        onClick={openModal}
        disabled={!!isModalOpen}
        style={{ margin: '5px', float: 'left' }}
      >
        Assign New Fob
      </Button>
      <Modal
        title='Assign New Fob'
        visible={!!isModalOpen}
        onCancel={closeModal}
        width='75%'
        footer={null}
      >
        {gamPropLoading ? (
          <Typography.Paragraph style={{ textAlign: 'center' }}>
            <Spin style={{ marginRight: '10px' }} /> Loading properties
          </Typography.Paragraph>
        ) : (
          isModalOpen && <Formik<ICredentialFormValues>
            initialValues={{
              fobFlow: FobFlow.ENROLLMENT_USB,
              ownedByPropertyId: gamProperties.length === 1 ? gamProperties[0].propertyId : null,
              identifier: null,
              facilityCode: null,
              cardNumber: null,
              bitFormat: null,
            }}
            onSubmit={handleOnSubmit}
            validateOnChange={enableValidation}
            validate={handleValidate}
            render={({
              values,
              errors,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              submitForm,
            }) => {
              const { fobFlow } = values;

              return (
                <>
                  <Form onSubmit={handleSubmit}>
                    {gamProperties.length === 0 ? (
                      <Typography.Paragraph style={{ textAlign: 'center' }}>
                        This user does not have access to a property with Gateway Access Manager.
                      </Typography.Paragraph>
                    ) : (
                      <>
                        <Form.Item
                          className='label-align-top'
                          required={true}
                          label={'Select property'}
                        >
                          <Select
                            showSearch
                            placeholder='Search for property'
                            value={values.ownedByPropertyId || undefined}
                            onChange={value => {
                              setFieldValue('ownedByPropertyId', value)
                              resetData(setFieldValue);
                            }}
                          >
                            {gamProperties.map(property => (
                              <Select.Option key={property.propertyId} value={property.propertyId}>
                                {property.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                          <Form.Item
                            className='label-align-top'
                            required={true}
                            label={'Select assignment method'}
                          >
                            <Radio.Group
                              disabled={!values.ownedByPropertyId}
                              value={fobFlow}
                              onChange={e => {
                                setFieldValue('fobFlow', e.target.value);
                                resetData(setFieldValue);
                              }}
                            >
                              <Radio value={FobFlow.ENROLLMENT_USB}>USB Enrollment</Radio>
                              <Radio value={FobFlow.ENROLLMENT_READER}>Enrollment Reader</Radio>
                              <Radio value={FobFlow.MANUAL_ENROLLMENT}>Manual Input</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Divider />
                          {fobFlow === FobFlow.ENROLLMENT_READER && <ReaderEnrollmentFlow values={values} setFieldValue={setFieldValue} />}
                          {fobFlow === FobFlow.ENROLLMENT_USB && <USBEnrollmentFlow values={values} setFieldValue={setFieldValue} />}
                          {fobFlow === FobFlow.MANUAL_ENROLLMENT && <ManualEnrollmentFlow values={values} setFieldValue={setFieldValue} />}
                      </>
                    )}
                  </Form>
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={
                      !!Object.values(errors).length ||
                      !canAssignFob(values)
                    }
                    loading={isSubmitting}
                    onClick={submitForm}
                  >
                    Assign Fob
                  </Button>
                </>
              )
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default AssignNewFob;
