import { Form } from '@ant-design/compatible';
import { DatePicker as AntDatePicker, } from 'antd';
import { RangePickerProps as AntRangePickerProps, } from 'antd/lib/date-picker/generatePicker';
import moment, { Moment, unitOfTime } from 'moment';
import React from 'react';

import { MIN_CALENDAR_START_DATE } from '../../config';
import { TIMESTAMP_FORMAT } from '../../utils';

interface IDateRange {
  startDate?: Moment | null;
  endDate?: Moment | null;
}

interface IRangePickerProps {
  startDate?: string | null;
  endDate?: string | null;
  setDateRange: (dateRange: IDateRange) => void;
  disableFutureDates?: boolean;
  allowClear?: boolean;
}

export const RangePicker = (props: IRangePickerProps) => {
  const { startDate, endDate, setDateRange, disableFutureDates, allowClear } = props;

  const maxEndDate = disableFutureDates ? moment(new Date()) : null;

  const getPresetDateRange = (unit: unitOfTime.StartOf) => {
    return [
      moment(new Date()).startOf(unit),
      maxEndDate || moment(new Date()).endOf(unit),
    ] as [moment.Moment, moment.Moment];
  };

  const rangePickerProps: AntRangePickerProps<Moment> = {
    allowClear,
    style: { width: '100%' },
    format: TIMESTAMP_FORMAT,
    value: [
      startDate ? moment(startDate) : null,
      endDate ? moment(endDate) : null,
    ],
    showTime: {
      use12Hours: true,
      secondStep: 60,
    },
    onChange: (dates) => {
      setDateRange({
        startDate: dates && dates.length ? dates[0] : null,
        endDate: dates && dates.length ? dates[1] : null,
      });
    },
    disabledDate: (date) => {
      if (date.isSameOrBefore(moment(MIN_CALENDAR_START_DATE).subtract(1, 'day').endOf('day'))) {
        return true;
      }

      if (disableFutureDates) {
        return date.isSameOrAfter(moment(new Date()).add(1, 'day').startOf('day'));
      }

      return false;
    },
    ranges: {
      Today: getPresetDateRange('day'),
      'This Week': getPresetDateRange('week'),
      'This Month': getPresetDateRange('month'),
      'This Year': getPresetDateRange('year'),
    },
    // @ts-ignore
    // https://ant.design/docs/react/faq#How-do-I-prevent-Select-Dropdown-DatePicker-TimePicker-Popover-Popconfirm-scrolling-with-the-page
    getPopupContainer: triggerNode => triggerNode.parentElement,
  };

  return <AntDatePicker.RangePicker {...rangePickerProps} />;
};

interface IRangePickerWithFormItemProps extends IRangePickerProps {
  label?: string;
  required?: boolean;
}

export const RangePickerWithFormItem = (props: IRangePickerWithFormItemProps) => {
  const { label, required } = props;

  return (
    <Form.Item
      label={label}
      style={{ marginBottom: 0 }}
      className='label-align-top'
      required={required}
    >
      <RangePicker {...props} />
    </Form.Item>
  );
};
