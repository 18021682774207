import { FormikProps } from 'formik';
import React, { useContext } from 'react';

import { ModelsContext } from '../../hooks/providers';
import { IModelFormField } from '../../models/typings';
import ModelFormSelect from '../ModelForm/ModelFormSelect';

import { IModelImportFormValues } from './formik';

export interface IModelImportApplyAllFieldsProps {
  fields: IModelFormField<any>[];
  formikProps: FormikProps<IModelImportFormValues>;
  onChange: () => any;
}

const ModelImportApplyAllFields: React.FC<IModelImportApplyAllFieldsProps> = (props) => {
  const { fields, formikProps, onChange } = props;
  const { values, setFieldValue } = formikProps;
  const { getModel } = useContext(ModelsContext);

  if (!fields.length) {
    return null;
  }

  // @TODO: Support more than just relationship fields(?)
  return (
    <div style={{ marginBottom: 35 }}>
      {fields.map(({ model, fieldName, label, importOptions }) => {
        if (!importOptions) {
          return null;
        }

        if (typeof importOptions.render === 'function') {
          return <div key={fieldName}>{importOptions.render(props)}</div>;
        }

        const relationship = model.introspection.relationships.find(r => (
          r.type === 'OBJECT' &&
          r.sourceField === fieldName
        ));

        if (relationship) {
          const relatedModel = getModel(relationship.targetModelName);
          const value = values.applyAllValues[fieldName];

          return (
            <ModelFormSelect
              {...formikProps}
              key={fieldName}
              fieldName={`applyAllValues.${fieldName}`}
              label={label || fieldName}
              formItemProps={{
                className: 'label-align-top',
              }}
              modelSelectProps={{
                value,
                model: relatedModel,
                required: importOptions?.required,
                style: { width: '100%' },
                autoSelectSingleResult: true,
              }}
              setFieldValue={(_, nextValue) => {
                setFieldValue('applyAllValues', {
                  ...values.applyAllValues,
                  [fieldName]: nextValue,
                });

                onChange();
              }}
              submitCount={1}
            />
          );
        }

        return null;
      })}
    </div>
  )
}

export default ModelImportApplyAllFields;
