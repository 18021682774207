import type { SmartLockLogFiltersInput } from '../../graphql/api/generated';
import { PermissionKey_enum } from '../../graphql/hasura/generated';
import Model from '../Model';

export const SmartLockLogModel = new Model<SmartLockLogFiltersInput>({
  names: {
    schemaName: 'SmartLockLog',
    displayName: 'Home Log',
    pluralDisplayName: 'Home Logs',
  },
  permissionsOptions: {
    // Bypass introspection check (queries will be made to Main API rather than Hasura)
    canRead: ({ hasPermission }) => hasPermission(PermissionKey_enum.SmartLockLog_Read),
  },
});

export default SmartLockLogModel;
