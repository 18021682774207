import { ApolloCache } from '@apollo/client';
import moment from 'moment';

import {
  deleteRoleRelationshipMutation,
  getRoleDetailsDocument,
  getRoleDetailsQuery,
  getRoleDetailsQueryVariables,
  RelatableRoleFragment,
  RoleDetailsFragment,
  RoleRelationshipDetailsFragment,
  upsertRoleRelationshipsMutation,
  usedeleteRoleRelationshipMutation,
  useupsertRoleRelationshipsMutation,
} from '../../../graphql/hasura/generated';
import { displayErrorMessage } from '../../../utils';

export function useEditRoleRelationship(role: RoleDetailsFragment) {
  const { roleId } = role;

  const writeCacheQuery = (
    cache: ApolloCache<upsertRoleRelationshipsMutation | deleteRoleRelationshipMutation>,
    updatedRoleRelationships: RoleRelationshipDetailsFragment[],
  ) => {
    cache.writeQuery<getRoleDetailsQuery, getRoleDetailsQueryVariables>({
      query: getRoleDetailsDocument,
      data: {
        role: {
          ...role,
          roleRelationshipsByRole: updatedRoleRelationships.map(rr => ({
            ...rr,
            // @ts-ignore
            __typename: 'RoleRelationship',
          })),
          // @ts-ignore
          __typename: 'Role',
        },
      },
      variables: { roleId: role.roleId },
    });
  };

  const [upsertRoleRelationship] = useupsertRoleRelationshipsMutation({
    update: (cache, { data }) => {
      const upsertedRoleRelationships = data?.roleRelationships?.returning || [];
      const [upsertedRoleRelationship] = upsertedRoleRelationships;

      if (!upsertedRoleRelationship) {
        return;
      }

      // Remove edited role relationship (if applicable)
      const updatedRoleRelationships = role.roleRelationshipsByRole.filter(rr => (
        !(
          rr.roleId === upsertedRoleRelationship?.roleId &&
          rr.relatedRole.roleId === upsertedRoleRelationship.relatedRole.roleId
        )
      ));

      // Add upserted role relationship
      updatedRoleRelationships.push({
        ...upsertedRoleRelationship,
        roleRelationshipId: upsertedRoleRelationship.relatedRole.roleId, // Temp ID
      });

      writeCacheQuery(cache, updatedRoleRelationships);
    },
  });

  const [deleteRoleRelationship] = usedeleteRoleRelationshipMutation({
    update: (cache, { data }) => {
      const deletedRoleRelationships = (data?.roleRelationships?.returning || []);

      // Remove deleted role relationship
      const updatedRoleRelationships = role.roleRelationshipsByRole.filter(rr => (
        !deletedRoleRelationships.some(deletedRr => (
          deletedRr.relatedRole.roleId === rr.relatedRole.roleId
        ))
      ));

      writeCacheQuery(cache, updatedRoleRelationships);
    },
  });

  const editRoleRelationship = async (
    relatedRole: RelatableRoleFragment,
    roleRelationship: Partial<RoleRelationshipDetailsFragment>,
  ) => {
    const relatedRoleId = relatedRole.roleId;
    const now = moment.utc(new Date()).toISOString();

    const editableFields = {
      canReadUserRole: roleRelationship.canReadUserRole || false,
      canGrantUserRole: roleRelationship.canGrantUserRole || false,
      canRevokeUserRole: roleRelationship.canRevokeUserRole || false,
    };

    const optimisticResponse = {
      roleId,
      relatedRole,
      roleRelationshipId: relatedRole.roleId, // Temp ID
      ...editableFields,
      createdAt: now,
      // @ts-ignore
      __typename: 'RoleRelationship',
    };

    try {
      if (
        !roleRelationship.canReadUserRole &&
        !roleRelationship.canGrantUserRole &&
        !roleRelationship.canRevokeUserRole
      ) {
        // If none of these flags are true, the role relationship should be deleted
        await deleteRoleRelationship({
          optimisticResponse: {
            roleRelationships: {
              returning: [optimisticResponse],
              // @ts-ignore
              __typename: 'RoleRelationship_mutation_response',
            },
          },
          variables: { roleId, relatedRoleId },
        });
      } else {
        await upsertRoleRelationship({
          optimisticResponse: {
            roleRelationships: {
              returning: [optimisticResponse],
              // @ts-ignore
              __typename: 'RoleRelationship_mutation_response',
            },
          },
          variables: {
            objects: [{
              roleId,
              relatedRoleId,
              ...editableFields,
            }],
          },
        });
      }
    } catch (error) {
      displayErrorMessage(error);
    }
  };

  return editRoleRelationship;
}

export default useEditRoleRelationship;
