import { enumManagers } from '@chirp/enums';
import { Col, Descriptions, Row, Statistic } from 'antd';
import React from 'react';

import { convertSecondsToLargestUnit } from '../../../components/DurationField/helpers';
import { PermissionKey_enum, RoleDetailsFragment } from '../../../graphql/hasura/generated';
import { formatTimestamp, truncateText } from '../../../utils';
import OrganizationModel from '../../Organization/model';
import { getGrantRevokeText } from '../columns';

import { DEPRECATED_TYPE_MAP } from './deprecated-types';

// https://github.com/ant-design/ant-design/issues/18104#issuecomment-518658522
const STATISTIC_COL_STYLE = {
  flexBasis: '33.33%',
  width: '33.33%',
};

interface IRoleDescriptionsProps {
  role: RoleDetailsFragment;
}

const RoleDescriptions: React.FC<IRoleDescriptionsProps> = (props) => {
  const { role } = props;
  const hasUserReadPermission = role.rolePermissions.some(rp => (
    rp.permission.key === PermissionKey_enum.User_Read
  ));

  const grantRevokeText = getGrantRevokeText(role.rolePermissions);
  const deprecatedTypeLabel = role.deprecatedType ? DEPRECATED_TYPE_MAP[role.deprecatedType] : null;

  return (
    <>
      <Descriptions
        column={1}
        size='middle'
        bordered
        className='chirp-descriptions'
        style={{ width: '50%', float: 'left' }}
      >
        <Descriptions.Item label='Organization'>
          {OrganizationModel.routes.renderRowLink(role.organization)}
        </Descriptions.Item>
        <Descriptions.Item label='Name'>
          {role.name}
        </Descriptions.Item>
        <Descriptions.Item label='Unique Name'>
          {role.uniqueName}
        </Descriptions.Item>
        <Descriptions.Item label='Permission Scope'>
          {enumManagers.PermissionScope.getLabel(role.permissionScope)}
        </Descriptions.Item>
        <Descriptions.Item label='Description'>
          {truncateText(role.description, 255)}
        </Descriptions.Item>
        <Descriptions.Item label='Maximum Access Duration'>
          {role.maxAccessDurationSeconds
            ? convertSecondsToLargestUnit(role.maxAccessDurationSeconds).displayedValue
            : null}
        </Descriptions.Item>
        {deprecatedTypeLabel && (
          <Descriptions.Item label='Access App Role'>
            {deprecatedTypeLabel}
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Created'>
          {formatTimestamp(role.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label='Updated'>
          {formatTimestamp(role.updatedAt)}
        </Descriptions.Item>
      </Descriptions>

      <div style={{ width: '50%', padding: '0 40px', float: 'right' }}>
        <Row style={{ marginBottom: 40 }}>
          <Col>
            <Statistic
              title='Enabled Permissions'
              value={role.rolePermissions.length}
            />
          </Col>
        </Row>
        <Row>
          {[
            {
              title: 'Readable Roles',
              value: hasUserReadPermission
                ? 'All Roles'
                : role.roleRelationshipsByRole.filter(r => r.canReadUserRole).length,
            },
            {
              title: 'Grantable Roles',
              value: grantRevokeText || role.roleRelationshipsByRole.filter(r => r.canGrantUserRole).length,
            },
            {
              title: 'Revocable Roles',
              value: grantRevokeText || role.roleRelationshipsByRole.filter(r => r.canRevokeUserRole).length,
            },
          ].map(({ title, value }) => (
            <Col key={title} style={{ ...STATISTIC_COL_STYLE }}>
              <Statistic title={title} value={value} />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default RoleDescriptions;
