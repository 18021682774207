import model from './model';

const formFields = model.createFormFields([
  {
    fieldName: 'name',
    maxLength: 50,
  },
  {
    fieldName: 'uniqueName',
    maxLength: 50,
  },
  {
    fieldName: 'description',
    maxLength: 4096,
  },
]);

model.setFormOptions({
  fields: formFields,
});
