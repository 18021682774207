import { Table } from 'antd';
import React from 'react';

import { UnitTableFragment, usegetUnitDetailsQuery } from '../../../graphql/hasura/generated';
import createDetailsLayout from '../../../layouts/createDetailsLayout';
import normalizeColumns from '../../helpers/normalizeColumns';
import { UnitsMainTable } from '../tables';

const UnitDetailsLayout = createDetailsLayout<UnitTableFragment>();

// @TODO: Make this the default settings for any model's details page
export const UnitDetailsPage: React.FC<any> = () => {
  return (
    <UnitDetailsLayout
      modelName='Unit'
      useQuery={(unitId) => {
        return usegetUnitDetailsQuery({
          variables: { unitId },
        });
      }}
      body={(unit) => {
        const columns = normalizeColumns(UnitsMainTable.columns);

        return (
          <Table
            columns={columns}
            dataSource={[unit]}
            rowKey={u => u.unitId}
            pagination={false}
            expandable={UnitsMainTable.expandable}
          />
        );
      }}
    />
  );
};

export default UnitDetailsPage;
