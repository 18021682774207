import { Button, Divider, Progress, Table, Typography } from 'antd';
import React from 'react';

import { apiClient } from '../../../graphql/api/apiClient';
import {
  HubActivationStatus,
  HubTableFragment as Hub,
  usegetHubByPkQuery,
} from '../../../graphql/api/generated';
import { PermissionKey_enum } from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { normalizeColumns } from '../../helpers/normalizeColumns';
import * as columns from '../columns';
import { useHubMessenger } from '../hooks/useHubMessenger';
import { useHubRestart } from '../hooks/useHubRestart';

interface IHubMonitoringProps {
  hubId: string;
}

const HubMonitoring: React.FC<IHubMonitoringProps> = ({ hubId }) => {
  const ping = useHubMessenger<Hub>();

  const pingHub = (hub: Hub) => {
    if (hub.activationStatus === HubActivationStatus.ACTIVATED) {
      ping.sendMessageToHubs({
        hubFilter: { hubIds: [hub.hubId] },
        hubMessage: { type: 'PING' },
      }, [hub]);
    }
  }

  const { data, loading } = usegetHubByPkQuery({
    client: apiClient,
    fetchPolicy: 'network-only',
    variables: { hubId },
    onCompleted: ({ hub }) => pingHub(hub),
  });

  const monitoredHub = ping.hubs[0] || data?.hub;
  const pingable = monitoredHub?.activationStatus === HubActivationStatus.ACTIVATED;

  const hubResponse = monitoredHub?.hubResponse;
  const pingState = hubResponse?.state;

  const hubRestart = useHubRestart({ hubId, deviceType: monitoredHub?.deviceType });

  const tableColumns = normalizeColumns([
    columns.propertyMonitoringColumn,
    columns.lastDisconnectionColumn,
    columns.pingTestColumn,
  ].map(column => ({ ...column, sorter: false })));

  return (
    <>
      <Table
        loading={{ size: 'large', spinning: loading }}
        columns={tableColumns}
        dataSource={monitoredHub ? [monitoredHub] : []}
        rowKey={h => h.hubId}
        pagination={false}
        showSorterTooltip={false}
        style={{ marginTop: 20, marginBottom: 20 }}
      />
      <Button
        style={{ marginRight: 10 }}
        onClick={() => pingHub(monitoredHub)}
        loading={ping.waiting}
        disabled={loading || !pingable || ping.waiting || hubRestart.waiting}
      >
        {ping.waiting ? 'Pinging Hub...' : 'Ping Hub'}
      </Button>
      {authentication.hasPermission(PermissionKey_enum.Hub_Restart) && (
        <>
          <Button
            onClick={() => hubRestart.restartHub()}
            loading={hubRestart.waiting}
            disabled={loading || ping.waiting || hubRestart.waiting || pingState !== 'SUCCESS'}
          >
            {hubRestart.waiting ? 'Restarting Hub...' : 'Restart Hub'}
          </Button>
          {(hubRestart.statusText) && (
            <>
              <Divider />
              <Progress
                percent={hubRestart.progress}
                status={!!hubRestart.error ? 'exception' : undefined}
                showInfo={!!hubRestart.error || hubRestart.progress === 100}
              />
              <Typography.Text type='secondary'>
                {hubRestart.statusText}
              </Typography.Text>
            </>
          )}
        </>
      )}
    </>
  );
};

export default HubMonitoring;
