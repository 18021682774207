import { PermissionKey_enum } from '../../graphql/hasura/generated';

import model from './model';
import CreateRolePage from './pages/CreateRolePage';
import RoleDetailsPage from './pages/RoleDetailsPage';

model.routes.setRoutes({
  defaultRoute: {
    enabled: ({ hasPermission }) => hasPermission(PermissionKey_enum.Role_Read),
  },
  detailsRoute: {
    component: RoleDetailsPage,
    enabled: ({ hasPermission }) => hasPermission(PermissionKey_enum.Role_Read),
  },
  createRoute: {
    component: CreateRolePage,
  },
  editRoute: {
    enabled: () => false,
  },
});
