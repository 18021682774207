import { Modal, notification } from 'antd';
import { DocumentNode } from 'graphql';
import React from 'react';
import { Link } from 'react-router-dom';

import { generateDeleteMutation } from '../graphql';
import { displayErrorMessage } from '../utils';

import { IModel, IModelAction, IModelActionOptions, IModelActions } from './typings';

export class ModelActions<TRequiredRowData> implements IModelActions<TRequiredRowData> {

  public readonly detailsAction: IModelAction<TRequiredRowData>;
  public readonly editAction: IModelAction<TRequiredRowData>;
  public readonly deleteAction: IModelAction<TRequiredRowData>;

  // tslint:disable variable-name
  private _actionsFragment?: DocumentNode;
  private _defaultActions: IModelAction<TRequiredRowData>[] = [];
  private readonly model: IModel<any, any, TRequiredRowData>;

  constructor(model: IModel<any, any, TRequiredRowData>) {
    this.model = model;

    this.detailsAction = this.createAction<TRequiredRowData>({
      enabledByModel: () => this.model.routes.detailsRoute?.enabled() || false,
      render: (row) => {
        const basePath = this.model.routes.basePath;
        const id = (row || {} as any)[this.model.primaryKey];

        return (
          <Link to={`${basePath}/details/${id}`}>Details</Link>
        );
      },
    });

    this.editAction = this.createAction<TRequiredRowData>({
      enabledByModel: () => this.model.routes.editRoute.enabled(),
      render: (row) => {
        const basePath = this.model.routes.basePath;
        const id = (row || {} as any)[this.model.primaryKey];

        return (
          <Link to={`${basePath}/edit/${id}`}>Edit</Link>
        );
      },
    });

    this.deleteAction = this.createAction<TRequiredRowData>({
      label: () => 'Delete',
      enabledByModel: () => this.model.permissions.canDelete(),
      enabledByRow: row => this.model.permissions.canDeleteRow(row),
      executes: (row, { hasuraClient, resetStores, history, location }) => {
        const title = `Delete ${this.model.names.displayName}`;
        const content = `Are you sure you want to delete this ${this.model.names.displayName}?`;

        Modal.confirm({
          title,
          content,
          okType: 'danger',
          centered: true,

          async onOk() {
            const primaryKey = model.primaryKey;
            const id = (row as any)[primaryKey];

            const mutation = generateDeleteMutation(model);

            try {
              await hasuraClient.mutate({
                mutation,
                variables: { [model.primaryKey]: id },
                update: async () => {
                  try {
                    // Reset cache to refetch queries
                    await resetStores();
                  } catch (error) {
                    console.error(error);
                  }
                },
              });

              if (
                location.pathname.includes(`details/${id}`) ||
                location.pathname.includes(`edit/${id}`)
              ) {
                history.push(model.routes.defaultRoute.path);
              }

              notification.success({
                message: 'Success',
                description: `The ${model.names.displayName.toLowerCase()} was successfully deleted!`,
              });
            } catch (error) {
              displayErrorMessage(error);
            }
          },
        });
      },
    });
  }

  public get actionsFragment() { return this._actionsFragment; }
  public get defaultActions() { return this._defaultActions; }

  public createAction<TRowData extends TRequiredRowData>(
    options: IModelActionOptions<TRowData>
  ): IModelAction<TRowData> {
    const enabledByModel = () => {
      try {
        return options.enabledByModel(this.model.permissions);
      } catch {
        return false; // Fallback in case there are data type issues
      }
    };

    return {
      ...options,
      enabledByModel,
      enabledByRow: (row: TRowData) => {
        if (!enabledByModel()) {
          return false;
        }

        try {
          if (typeof options.enabledByRow === 'function') {
            return options.enabledByRow(row, this.model.permissions);
          }
        } catch {
          return false; // Fallback in case there are data type issues
        }

        return true;
      },
    };
  }

  public setDefaultActions(
    actions: IModelAction<TRequiredRowData>[],
    actionsFragment?: DocumentNode,
  ) {
    this._defaultActions = actions;

    if (actionsFragment) {
      this._actionsFragment = actionsFragment;
    }
  }
}

export default ModelActions;
