import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale({
  ...en,
  now: {
    now: {
      current: '1 second ago',
      future: 'in a moment',
      past: '1 second ago',
    },
  },
});

export const timeAgo = new TimeAgo('en-US');
