import React from 'react';
import { Link } from 'react-router-dom';

import { syncResidentsByUnitDocument } from '../../graphql/api/generated';
import {
  getUnitWithPropertyQuery,
  PermissionKey_enum,
  PermissionScope_enum,
} from '../../graphql/hasura/generated';
import { buildGrantAccessPageUrl } from '../../pages/GrantAccess/buildGrantAccessPageUrl';
import { authentication } from '../../stores';
import { getPropertyFeatureFlags } from '../../utils';

import model from './model';

// @TODO: This logic is copied from "getPmsInterface" in the serverless codebase.
// Update this if Chirp eventually supports other PMS integrations.
export function hasPmsIntegration(sourceId?: string | null) {
  return Boolean(sourceId && sourceId.startsWith('RP-'));
}

export const residentAuditAction = model.createAction({
  description: 'Audits resident data from PMS to allow more users to access Chirp',
  enabledByModel: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.Unit_PmsResidentAudit)
  ),
  enabledByRow: ({ sourceId }) => hasPmsIntegration(sourceId),
  render({ unitId }) {
    return (
      <Link to={`/units/resident-audit/${unitId}`}>Resident Audit</Link>
    );
  },
});

export const createKeyAction = model.createAction({
  description: 'Create key to access the unit',
  enabledByModel: () => {
    return authentication.grantableRoleScopes.includes(PermissionScope_enum.UNIT);
  },
  render({ unitId }) {
    const url = buildGrantAccessPageUrl({
      userId: authentication.user?.userId,
      permissionScope: PermissionScope_enum.UNIT,
      scopedIds: [unitId],
    });

    return (
      <Link to={url}>Create Key</Link>
    );
  },
});

type UnitWithProperty = NonNullable<getUnitWithPropertyQuery['unit']>;

export const syncResidentsAction = model.createAction<UnitWithProperty>({
  label: () => 'Force Resident Sync',
  description: 'Forces resident synchronization for this unit',
  enabledByModel: ({ hasPermission }) => (
    hasPermission(PermissionKey_enum.Unit_PmsResidentSync)
  ),
  enabledByRow: (unit) => {
    const residentSyncEnabled = getPropertyFeatureFlags(unit.property).PMS_RESIDENT_SYNC;

    return residentSyncEnabled && hasPmsIntegration(unit.sourceId);
  },
  confirmation: {
    title: () => 'Synchronize Residents',
    content: unit => (
      `Are you sure you want to force resident synchronization for ` +
      `${model.labels.getUniqueLabel(unit)}?`
    ),
  },
  executes: async ({ unitId }, { apiClient, resetStores }) => {
    await apiClient.mutate({
      mutation: syncResidentsByUnitDocument,
      variables: { unitId },
    });

    await resetStores(); // Unit occupancy could change
  },
});
