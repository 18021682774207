import {
  PermissionKey_enum,
  PermissionScope_enum,
  UnitGroup_bool_exp,
  UnitGroupLabelFragment,
  UnitGroupLabelFragmentDoc,
  UnitGroupRowFragment,
  UnitGroupUniqueLabelFragment,
  UnitGroupUniqueLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import Model from '../Model';

// Hide Edit/Delete buttons for cross-property unit groups
function canManageScope(propertyId: string) {
  const { permissionScope, scopedId } = authentication.currentDataScope;

  return (
    permissionScope === PermissionScope_enum.GLOBAL ||
    permissionScope === PermissionScope_enum.ORGANIZATION ||
    (permissionScope === PermissionScope_enum.PROPERTY && scopedId === propertyId)
  );
}

export const UnitGroupModel = new Model<
  UnitGroup_bool_exp,
  UnitGroupLabelFragment,
  UnitGroupUniqueLabelFragment,
  UnitGroupRowFragment
>({
  names: {
    schemaName: 'UnitGroup',
  },

  permissionsOptions: {
    canRead: ({ hasPermission }) => hasPermission(PermissionKey_enum.Unit_Read),
    canCreate: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canCreate(),
    canUpdate: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canUpdate(),
    canDelete: (args) => !args.limitStratisPermissions && args.defaultPermissionChecks.canDelete(),
    canUpdateRow: (_, { propertyId, sourceId }) => !sourceId && canManageScope(propertyId),
    canDeleteRow: (_, { propertyId, sourceId }) => !sourceId && canManageScope(propertyId),
  },

  labels: {
    getLabel: (u) => u.name,
    labelFragment: UnitGroupLabelFragmentDoc,
    getUniqueLabel: (u) => {
      return [
        u.propertyName || '',
        u.parentUnitGroup?.name || '',
        u.name,
      ].filter(Boolean).join(' - ');
    },
    uniqueLabelFragment: UnitGroupUniqueLabelFragmentDoc,
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            {
              property: {
                name: { _ilike: `%${word}%` },
              },
            },
            {
              name: { _ilike: `%${word}%` },
            },
            {
              parentUnitGroup: {
                name: { _ilike: `%${word}%` },
              },
            },
          ],
        }
      )),
    }),
  },
});

export default UnitGroupModel;
