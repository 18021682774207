import { LockFilled, UnlockFilled } from '@ant-design/icons';
import React from 'react';

import EnumTag, { Color } from '../../../components/EnumTag';
import { TableCellDescriptions } from '../../../components/TableCellDescriptions';
import {
  AugustLockBridgeFragment,
  AugustLockReportFragment as AugustLock,
} from '../../../graphql/api/generated';
import { formatTimestamp } from '../../../utils';

function formatAugustBridgeStatus(Bridge: AugustLockBridgeFragment) {
  return Bridge.status.current?.toUpperCase() || 'UNKNOWN';
}

export function getAugustWifiBridgeDescriptions(augustLock: AugustLock | null | undefined) {
  if (!augustLock || !augustLock.Bridge) {
    return {
      'Bridge Status': null,
      'Lock Status': null,
    };
  }

  const { Bridge, LockStatus } = augustLock;

  return {
    'Bridge Status': formatAugustBridgeStatus(Bridge),
    'Lock Status': LockStatus.status.replace(/-/g, ' ').toUpperCase(),
  };
}

export const AugustWiFiBridgeStatus: React.FC<{ Bridge: AugustLockBridgeFragment }> = (props) => {
  return (
    <EnumTag
      enumValue={formatAugustBridgeStatus(props.Bridge)}
      colorMap={{
        ONLINE: Color.Green,
        OFFLINE: Color.Red,
      }}
    />
  );
};

interface IAugustWifiBridgeDescriptionsProps {
  augustLock: AugustLock | null | undefined;
  includeTimestamps?: boolean;
}

export const AugustWifiBridgeDescriptions: React.FC<IAugustWifiBridgeDescriptionsProps> = (props) => {
  const { augustLock, includeTimestamps } = props;

  if (!augustLock || !augustLock.Bridge) {
    return null;
  }

  const { Bridge, LockStatus } = augustLock;
  const descriptions = getAugustWifiBridgeDescriptions(augustLock);

  descriptions['Bridge Status'] = (
    <div style={{ marginBottom: 10 }}>
      <AugustWiFiBridgeStatus Bridge={Bridge} />
      {includeTimestamps && Bridge.status.updated
        ? <>as of {formatTimestamp(Bridge.status.updated)}</>
        : null}
    </div>
  ) as any;

  descriptions['Lock Status'] = (
    <>
      <EnumTag
        enumValue={descriptions['Lock Status']}
        colorMap={{
          'NOT ADVERTISING': Color.Red,
        }}
        iconMap={{
          LOCKED: <LockFilled />,
          UNLOCKED: <UnlockFilled />,
        }}
      />
      {includeTimestamps && LockStatus.dateTime
        ? <>as of {formatTimestamp(LockStatus.dateTime)}</>
        : null}
    </>
  ) as any;

  return <TableCellDescriptions descriptions={descriptions} />;
};
