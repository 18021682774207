import { flatten } from 'lodash';
import { useObservable, useObserver } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { Redirect, Route, RouteProps, Router, Switch } from 'react-router';
import { QueryParamProvider } from 'use-query-params';

import AppLoading from '../components/AppLoading';
import EulaModal from '../components/EulaModal';
import ScrollToTop from '../components/ScrollToTop';
import { models } from '../models/models';
import { IModel } from '../models/typings';
import DashboardPage from '../pages/Dashboard';
import { GrantAccessPage } from '../pages/GrantAccess';
import Login from '../pages/Login';
import SsoLogin from '../pages/SsoLogin';
import { authentication } from '../stores';

import history from './history';

const ProtectedRoute: React.FC<{ model?: IModel } & RouteProps> = ({
  component: Component, model, ...props
}) => {
  if (!authentication.isAuthenticated) {
    return <Route {...props} component={() => <Redirect to='/login' />} />;
  }

  const component = useCallback((cmpProps: any) => {
    return Component ? (
      <Component {...cmpProps} {...{ model }} />
    ) : null;
  }, [Component, model]);

  return (
    <Route {...props} component={component} />
  );
};

export const AdminPanelRouter: React.FC = () => {
  useObservable(authentication);

  return useObserver(() => {
    if (authentication.loading) {
      return <AppLoading />;
    }

    const customRoutes = flatten(models.map(m => m.routes.customRoutes));

    return (
      <Router history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ScrollToTop />
          <Switch>
          <Route exact path='/dashboard' component={DashboardPage} />
            <Redirect exact from='/' to='/dashboard' />

            <Route exact path='/login' component={Login} />
            <Route exact path='/login/sso' component={SsoLogin} />

            {!!authentication.grantableRoleScopes.length && (
              <ProtectedRoute exact path='/grant-access' component={GrantAccessPage} />
            )}

            {/* Now create routes based on the models under the configurations */}

            {models.filter((m) => m.routes.defaultRoute.enabled()).map((model, i) => (
              <ProtectedRoute exact key={`model-list-${i}`}
                path={model.routes.defaultRoute.path}
                component={model.routes.defaultRoute.component}
                model={model}
              />
            ))}

            {models.filter((m) => m.routes.createRoute.enabled()).map((model, i) => (
              <ProtectedRoute exact key={`model-new-${i}`}
                path={model.routes.createRoute.path}
                component={model.routes.createRoute.component}
                model={model}
              />
            ))}

            {models.filter((m) => m.routes.importRoute.enabled()).map((model, i) => (
              <ProtectedRoute exact key={`model-import-${i}`}
                path={model.routes.importRoute.path}
                component={model.routes.importRoute.component}
                model={model}
              />
            ))}

            {models.filter((m) => m.routes.editRoute.enabled()).map((model, i) => (
              <ProtectedRoute exact key={`model-edit-${i}`}
                path={model.routes.editRoute.path}
                component={model.routes.editRoute.component}
                model={model}
              />
            ))}

            {models.filter((m) => m.routes.detailsRoute?.enabled()).map((model, i) => {
              const detailsRoute = model.routes.detailsRoute;

              if (!detailsRoute) { return null; }

              return (
                <ProtectedRoute exact key={`model-edit-${i}`}
                  path={detailsRoute.path}
                  component={detailsRoute.component}
                  model={model}
                />
              );
            })}

            {customRoutes.filter((r) => r.enabled()).map((route, i) => (
              <ProtectedRoute exact key={`model-custom-${i}`}
                path={route.path}
                component={route.component}
              />
            ))}

            <Redirect to='/dashboard' />
          </Switch>
        </QueryParamProvider>
        {process.env.NODE_ENV !== 'production' &&
          authentication.user &&
          !authentication.user.acceptedEulaAt &&
          <EulaModal />}
      </Router>
    );
  });
};
