import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import AppError from '../../components/AppError';
import AppLoading from '../../components/AppLoading';
import { apiClient } from '../../graphql/api/apiClient';
import {
  createSsoSessionDocument as mutationDocument,
  createSsoSessionMutation as MutationResult,
  createSsoSessionMutationVariables as MutationVariables,
  SessionType,
} from '../../graphql/api/generated';
import history from '../../routes/history';
import Authentication from '../../stores/authentication';
import { getDisplayedErrorMessage } from '../../utils';

interface IParsedQueryParams {
  organizationId: string;
  userId: string;
  passcode: string;
}

interface ISsoLoginPageProps extends RouteComponentProps {
  authentication: Authentication;
}

interface ISsoLoginPageState {
  errorMessage: string | null;
}

@inject('authentication')
@observer
class SsoLoginPage extends Component<ISsoLoginPageProps, ISsoLoginPageState> {
  state: ISsoLoginPageState = {
    errorMessage: null,
  };

  async componentDidMount() {
    const params = this.parseQueryParams();

    if (!params) {
      history.push('/login');
      return;
    }

    await this.attemptLogin(params, this.props.authentication);
  }

  parseQueryParams = (): IParsedQueryParams | null => {
    const params = new URLSearchParams(this.props.location.search);

    const organizationId = params.get('organizationId');
    const userId = params.get('userId');
    const passcode = params.get('passcode');

    if (!organizationId || !userId || !passcode) {
      return null;
    }

    return { organizationId, userId, passcode };
  };

  createSsoSession = async (params: IParsedQueryParams) => {
    const { data } = await apiClient.mutate<MutationResult, MutationVariables>({
      mutation: mutationDocument,
      variables: {
        input: {
          organizationId: params.organizationId,
          userId: params.userId,
          passcode: params.passcode,
          sessionType: SessionType.ADMIN_PANEL,
        },
      },
    });

    if (!data) {
      throw new Error('Failed to log in via SSO');
    }

    return data.session;
  }

  attemptLogin = async (parsedParams: IParsedQueryParams, authentication: Authentication) => {
    try {
      const session = await this.createSsoSession(parsedParams);

      await authentication.logIn(session);
    } catch (error) {
      const errorMessage = getDisplayedErrorMessage(error as Error);

      this.setState({ errorMessage });
    }
  }

  render() {
    const { authentication } = this.props;
    const { errorMessage } = this.state;

    if (authentication.isAuthenticated) {
      return <Redirect to='/'/>;
    };

    if (errorMessage) {
      return <AppError errorMessage={errorMessage} />;
    }

    return <AppLoading />;
  }
};

export default SsoLoginPage;
