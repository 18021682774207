import { Table, Typography } from 'antd';
import uniq from 'lodash/uniq';
import React from 'react';

import { FailurePlan_enum, usegetAccessPointsWithRelaysQuery } from '../../../graphql/hasura/generated';
import { hasuraClient } from '../../../graphql/hasura/hasuraClient';
import { failurePlanColumn } from '../../Relay/columns';
import AccessPointModel from '../model';

const { Paragraph, Title } = Typography;

interface IEditAccessPointFailurePlanProps {
  accessPointId: string;
  failurePlan: FailurePlan_enum | null;
  relayIds: string[];
  onChangeFailurePlan: (failurePlan: FailurePlan_enum) => void;
  onLoadRelayIds: (relayIds: string[]) => void;
}

const EditAccessPointFailurePlan = ({
  accessPointId,
  failurePlan,
  relayIds,
  onChangeFailurePlan,
  onLoadRelayIds,
}: IEditAccessPointFailurePlanProps) => {
  const { data: accessPointsData, loading } = usegetAccessPointsWithRelaysQuery({
    client: hasuraClient,
    fetchPolicy: 'network-only',
    variables: {
      where: {
        accessPointRelays: {
          relay: {
            relayId: { _in: relayIds },
          },
        },
      },
    },
    onCompleted: (data) => {
      const affectedRelayIds = uniq(data.accessPoints
        .flatMap(({ accessPointRelays }) => accessPointRelays)
        .map(({ relay }) => relay.relayId)
      );

      onLoadRelayIds(affectedRelayIds);
    },
  });

  const accessPoints = accessPointsData?.accessPoints || [];
  const otherAccessPoints = accessPoints.filter(a => a.accessPointId !== accessPointId);

  return (
    <>
      <Title level={4}>Edit Internet Failure Plan</Title>
      <Table
        loading={loading}
        rowKey='failurePlan'
        columns={[
          {
            key: failurePlanColumn.key,
            title: failurePlanColumn.title,
            render: (_, relay) => failurePlanColumn.render(relay),
          },
          {
            key: 'description',
            title: 'Description',
            dataIndex: 'description',
          },
        ]}
        dataSource={[
          {
            failurePlan: FailurePlan_enum.FAIL_SAFE,
            description: (
              <span>
                The access point's relay(s) will be <strong>unlocked</strong> during Internet failure
              </span>
            ),
          },
          {
            failurePlan: FailurePlan_enum.FAIL_SECURE,
            description: (
              <span>
                The access point's relay(s) will be <strong>locked</strong> during Internet failure
              </span>
            ),
          },
        ]}
        onRow={(row) => {
          return {
            onClick: () => {
              onChangeFailurePlan(row.failurePlan);
            },
          };
        }}
        rowSelection={{
          type: 'radio',
          onSelect: (row) => {
            onChangeFailurePlan(row.failurePlan);
          },
          selectedRowKeys: failurePlan ? [failurePlan] : [],
        }}
        rowClassName={() => 'selectable-row'}
        style={{ padding: '20px 0px' }}
        pagination={false}
      />
      {otherAccessPoints.length > 0 && (
        <>
          <Paragraph>
            <em>Other access points that control the same relays will be updated to the same Internet failure plan:</em>
          </Paragraph>
          <ul>
            {otherAccessPoints.map((accessPoint) => (
              <li key={accessPoint.accessPointId}>
                {AccessPointModel.routes.renderRowLink(accessPoint)}
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default EditAccessPointFailurePlan;
