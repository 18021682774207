import { observable } from 'mobx';
import { persist } from 'mobx-persist';

import { Hydrated } from './hydrate';

class AppData extends Hydrated {
  @persist @observable public collapsedMenu = false;
  @persist('list') @observable public menuOpenKeys: string[] | undefined;

  constructor() {
    super('AppData');
  }

  toggleMenu = () => {
    this.collapsedMenu = !this.collapsedMenu;
  };
}

export default AppData;
