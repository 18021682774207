import {
  AccessPointActionsFragmentDoc,
  AccessPointByPropertyFragment,
  AccessPointByPropertyFragmentDoc,
  AccessPointRowFragment,
  AccessPointRowFragmentDoc,
  PermissionKey_enum,
} from '../../graphql/hasura/generated';
import { createdAtColumn } from '../common/columns';

import * as actions from './actions';
import * as columns from './columns';
import model from './model';

model.actions.setDefaultActions([
  actions.qrCodeAction,
  actions.unlockAction,
  actions.archivedAction,
], AccessPointActionsFragmentDoc);

const commonColumns = [
  columns.nameColumn,
  columns.imageColumn,
  columns.typeColumn,
  columns.accessTimeoutColumn,
  columns.availabilityColumn,
  columns.spaceshipModeEnabledColumn,
  columns.proximityRestrictionColumn,
];

export const AccessPointsMainTable = model.createTable<AccessPointRowFragment>({
  fragment: AccessPointRowFragmentDoc,
  columns: [
    columns.propertyColumn,
    ...commonColumns,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});

export const AccessPointsByPropertyTable = model.createTable<AccessPointByPropertyFragment, string>({
  fragment: AccessPointByPropertyFragmentDoc,
  fixedQueryFilters: (propertyId) => ({
    propertyId: { _eq: propertyId },
  }),
  columns: [
    ...commonColumns,
    model.createColumn({
      title: 'Total Relays',
      render: ({ accessPointRelays_aggregate }) => {
        const count = accessPointRelays_aggregate.aggregate?.count;

        return count !== 0 ? count : null;
      },
    }),
    model.createColumn({
      title: 'Total Beacons',
      enabled: ({ hasPermission }) => hasPermission(PermissionKey_enum.Beacon_Read),
      render: ({ beacons_aggregate }) => {
        const count = beacons_aggregate.aggregate?.count;

        return count !== 0 ? count : null;
      },
    }),
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});

export const AccessPointsByHubTable = model.createTable<AccessPointRowFragment, string>({
  fragment: AccessPointRowFragmentDoc,
  fixedQueryFilters: (hubId) => ({
    accessPointRelays: {
      relay: {
        _or: [
          {
            hubId: { _eq: hubId },
          },
          {
            childHubId: { _eq: hubId },
          },
        ],
      },
    },
  }),
  columns: [
    columns.propertyColumn,
    ...commonColumns,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});

export const AccessPointsByRelayTable = model.createTable<AccessPointRowFragment, string>({
  fragment: AccessPointRowFragmentDoc,
  fixedQueryFilters: (relayId) => ({
    accessPointRelays: {
      relayId: { _eq: relayId },
    },
  }),
  columns: [
    columns.propertyColumn,
    ...commonColumns,
    createdAtColumn,
  ],
  fetchPolicy: 'cache-first',
});
