import React from 'react';

export interface IDescriptions {
  [key: string]: React.ReactNode;
}

interface ITableCellDescriptionsProps {
  descriptions: IDescriptions;
}

export const TableCellDescriptions: React.FC<ITableCellDescriptionsProps> = (props) => {
  const { descriptions } = props;

  return (
    <>
      {Object.entries(descriptions)
        .filter(([, value]) => !!value)
        .map(([key, value]) => (
          <div key={key} style={{ display: 'table-row' }}>
            <span
              style={{
                display: 'table-cell',
                fontWeight: 600,
                paddingRight: 15,
                whiteSpace: 'nowrap',
              }}
            >
              {key}:&nbsp;
            </span>
            <span style={{ display: 'table-cell', paddingBottom: 5 }}>
              {value}
            </span>
          </div>
        ))}
    </>
  );
}

// For CSV export
export function renderTableCellDescriptionsString(
  descriptions: IDescriptions,
  separator = ', ',
): string {
  return Object.entries(descriptions)
    .filter(([, value]) => !!value)
    .map(([key, value]) => `${key}: ${value}`)
    .join(separator);
}
