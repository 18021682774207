import { enumManagers, UnitType_enum } from '@chirp/enums';

import { Unit_insert_input, UnitLabelFragment } from '../../graphql/hasura/generated';
import { getUnits, insertUnits } from '../../graphql/hasura/operations';

import * as columns from './columns';
import model from './model';

interface IParsedRow {
  'Unit Number': string;
  Building?: string;
  Type?: string;
}

interface IApplyAllValues {
  propertyId: string;
}

interface ITransformedRow {
  propertyId: string;
  unitNumber: string;
  building?: string;
  type?: UnitType_enum;
}

export const propertyField = model.createFormField<IParsedRow>({
  fieldName: 'propertyId',
  label: 'Property',
  importOptions: {
    applyAll: true,
    required: true,
  },
});

export const unitNumberField = model.createFormField<IParsedRow>({
  fieldName: 'unitNumber',
  disabled: ({ formikProps }) => !!formikProps.values.sourceId,
  maxLength: 50,
  importOptions: {
    importFieldName: 'Unit Number',
    required: true,
  },
});

export const buildingField = model.createFormField<IParsedRow>({
  fieldName: 'building',
  disabled: ({ formikProps }) => !!formikProps.values.sourceId,
  maxLength: 100,
  importOptions: {
    importFieldName: 'Building',
  },
});

const typeField = model.createFormField<IParsedRow>({
  fieldName: 'type',
  importOptions: {
    importFieldName: 'Type',
    getAllowedValues: () => enumManagers.UnitType.getOptions().map(o => o.label),
  },
});

const unitGroupField = model.createFormField<IParsedRow>({
  fieldName: 'unitGroupId',
  disabled: ({ formikProps }) => !formikProps.values.propertyId,
  getQueryFilters: ({ formikProps }) => {
    const { propertyId } = formikProps.values;

    return {
      propertyId: propertyId ? { _eq: propertyId } : undefined,
    };
  },
});

export function findMatchingUnit(
  unit: { unitNumber: string, building?: string | null },
  existingUnits: UnitLabelFragment[]
) {
  const matchingUnits = existingUnits.filter(u => (
    u.unitNumber === unit.unitNumber &&
    (u.building || null) === (unit.building || null)
  ));

  return matchingUnits.length === 1 ? matchingUnits[0] : null;
}

model.setFormOptions<IParsedRow, IApplyAllValues, ITransformedRow>({
  fields: [propertyField, unitNumberField, buildingField, typeField, unitGroupField],
  importOptions: {
    rowLimit: 2000,
    uniqueKeys: ['Unit Number', 'Building'],
    columns: [
      columns.unitNumberColumn,
      columns.buildingColumn,
      columns.typeColumn,
    ],
    transformRows: async (rows, { propertyId }) => {
      for (const row of rows) {
        const { parsedRow } = row;

        const type = parsedRow.Type
          ? enumManagers.UnitType.getValue(parsedRow.Type) as UnitType_enum
          : undefined;

        row.setTransformedRow({
          propertyId,
          type,
          unitNumber: parsedRow['Unit Number'],
          building: parsedRow.Building,
        });
      }

      return rows;
    },
    importRows: async (validRows, { propertyId }) => {
      const existingUnits = await getUnits({
        where: {
          propertyId: { _eq: propertyId },
        },
      });

      const unitsToInsert: Unit_insert_input[] = [];

      for (const row of validRows) {
        const { transformedRow } = row;

        const existingUnit = findMatchingUnit(transformedRow, existingUnits);

        if (!existingUnit) {
          unitsToInsert.push(transformedRow);
        }
      }

      const insertedUnits = await insertUnits({ objects: unitsToInsert });

      if (!insertedUnits) {
        throw new Error('Unable to import units');
      }

      return insertedUnits;
    },
    getRedirectPath: ({ routes }, { propertyId }) => {
      const stringifiedParams = JSON.stringify({ property: [propertyId]});
      const encodedParams = encodeURIComponent(stringifiedParams);

      return `${routes.basePath}?filters=${encodedParams}`;
    },
    sampleCsvData: [
      {
        'Unit Number': '1001',
        'Building': '1',
        'Type': 'Residential',
      },
      {
        'Unit Number': '1002',
        'Building': '1',
        'Type': 'Residential',
      },
      {
        'Unit Number': '1003',
        'Building': '1',
        'Type': 'Residential',
      },
      {
        'Unit Number': '2001',
        'Building': '2',
        'Type': 'Residential',
      },
      {
        'Unit Number': '2002',
        'Building': '2',
        'Type': 'Residential',
      },
      {
        'Unit Number': '2003',
        'Building': '2',
        'Type': 'Residential',
      },
      {
        'Unit Number': 'Leasing Office',
        'Type': 'Staff',
      },
      {
        'Unit Number': 'Maintenance Shop',
        'Type': 'Staff',
      },
    ],
  },
});
