import { Descriptions } from 'antd';
import { DescriptionsProps } from 'antd/lib/descriptions';
import React from 'react';

import {
  UserDetailsFragment,
  UserDetailsWithGlobalRolesFragment,
} from '../../../graphql/hasura/generated';
import { formatPhoneNumber, formatTimestamp, formatUserName } from '../../../utils';
import { accountStatusColumn, assignedRolesColumn } from '../columns';

interface IUserDescriptionsProps extends DescriptionsProps {
  user: UserDetailsFragment | UserDetailsWithGlobalRolesFragment;
};

const UserDescriptions = ({ user, ...descriptionsProps }: IUserDescriptionsProps) => (
  <Descriptions column={2} size='middle' {...descriptionsProps}>
    <Descriptions.Item label='Name'>
      {formatUserName(user)}
    </Descriptions.Item>
    <Descriptions.Item label='Account Status'>
      {accountStatusColumn.render(user)}
    </Descriptions.Item>
    <Descriptions.Item label='Phone Number'>
      {formatPhoneNumber(user.phoneNumber)}
    </Descriptions.Item>
    <Descriptions.Item label='Email'>
      {user.email}
    </Descriptions.Item>
    <Descriptions.Item label='Created'>
      {formatTimestamp(user.createdAt)}
    </Descriptions.Item>
    <Descriptions.Item label='Updated'>
      {formatTimestamp(user.updatedAt)}
    </Descriptions.Item>
    {'globalUserRoles' in user && user.globalUserRoles.length && (
      <Descriptions.Item label='Global Roles' span={2}>
        {assignedRolesColumn.render({ assignedUserRoles: user.globalUserRoles })}
      </Descriptions.Item>
    )}
  </Descriptions>
);

export default UserDescriptions;
