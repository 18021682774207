import {
  Relay_bool_exp,
  RelayActionFragment,
  RelayLabelFragment,
  RelayLabelFragmentDoc,
  RelayUniqueLabelFragment,
  RelayUniqueLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import { emptySearchConditionsFilter } from '../../utils';
import Model from '../Model';
import { PropertyModel } from '../Property/model';

export const RelayModel = new Model<
  Relay_bool_exp,
  RelayLabelFragment,
  RelayUniqueLabelFragment,
  RelayActionFragment
>({
  names: {
    schemaName: 'Relay',
  },

  labels: {
    getLabel: r => r.name,
    getUniqueLabel: (r: RelayUniqueLabelFragment) => {
      const propertyOrUnassignedHub = r.hub.property
        ? PropertyModel.labels.getLabel(r.hub.property)
        : 'UNASSIGNED HUB';

      return `${propertyOrUnassignedHub} - ${r.name}`
    },
    labelFragment: RelayLabelFragmentDoc,
    uniqueLabelFragment: RelayUniqueLabelFragmentDoc,
  },

  queryOptions: {
    getSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            {
              hub: {
                property: {
                  name: { _ilike: `%${word}%` },
                },
              },
            },
            {
              name: { _ilike: `%${word}%` },
            },
          ],
        }
      ))
    }),
    getTableSearchConditions: (words) => ({
      _and: words.map((word) => (
        {
          _or: [
            {
              hub: {
                property: {
                  name: { _ilike: `%${word}%` },
                },
              },
            },
            {
              hub: {
                name: { _ilike: `%${word}%` },
              },
            },
            {
              name: { _ilike: `%${word}%` },
            },
            {
              accessPointRelays: {
                accessPoint: {
                  name: { _ilike: `%${word}%` },
                },
              },
            },
          ].filter(emptySearchConditionsFilter),
        }
      ))
    }),
  },
});

export default RelayModel;
