import { Alert, Button, Tag } from 'antd';
import { toUpper } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { ResidentAuditFragment as ResidentAudit } from '../../graphql/api/generated';
import { IChirpTableColumn } from '../../models/typings';
import { UnitModel } from '../../models/Unit/model';
import { UserModel } from '../../models/User/model';
import { alphanumericSorter, formatDate, formatPhoneNumber, formatUserName } from '../../utils';

import {
  DATA_SCRUB_REQUIREMENT_FILTERS,
  DATA_SCRUB_WARNING_FILTERS,
  ELIGIBILITY_COLORS,
  ELIGIBILITY_FILTERS,
} from './constants';
import { useResidentAuditComparisonModal } from './ResidentAuditComparison';

export type ResidentAuditColumn = IChirpTableColumn<ResidentAudit>;

export const columns: ResidentAuditColumn[] = [
  {
    title: 'Unit',
    // @TODO: Add filtering for units
    render: ({ unit }) => {
      const { unitId, unitNumber } = unit;

      return (
        <Link to={`/units/resident-audit/${unitId}`}>
          {UnitModel.labels.getLabel({ unitId, unitNumber })}
        </Link>
      );
    },
    sorter: (auditA, auditB) => {
      const unitNumberA = Number(auditA.unit.unitNumber) || auditA.unit.unitNumber;
      const unitNumberB = Number(auditB.unit.unitNumber) || auditB.unit.unitNumber;

      return alphanumericSorter(unitNumberA, unitNumberB);
    },
  },
  {
    title: 'Name',
    render: (residentAudit) => {
      const { userRole, residentSource } = residentAudit;

      if (userRole && userRole.assignedToUser) {
        return UserModel.routes.renderRowLink({
          userId: userRole.assignedToUser.userId,
          ...residentSource,
        });
      }

      return formatUserName(residentSource);
    },
  },
  {
    title: 'Phone Number',
    render: ({ residentSource }) => {
      return (
        <span style={{ whiteSpace: 'nowrap' }}>
          {formatPhoneNumber(residentSource.phoneNumber)}
        </span>
      );
    },
  },
  {
    title: 'Email',
    render: ({ residentSource }) => residentSource.email,
  },
  {
    title: 'Move-In Date',
    render: ({ residentSource }) => (
      // Use UTC to prevent timezone conversion
      formatDate(residentSource.moveInDate, 'UTC')
    ),
    defaultSortOrder: 'descend',
    sorter: (auditA, auditB) => {
      return alphanumericSorter(auditA.residentSource.moveInDate, auditB.residentSource.moveInDate);
    },
  },
  // If we ever show former residents, we should display their move-out date
  {
    title: 'Eligibility',
    filters: ELIGIBILITY_FILTERS.map((filter) => {
      return ({
        value: filter,
        text: filter,
      });
    }),
    onFilter: (filter, record) => {
      return record.eligibility === filter;
    },
    render: ({ eligibility, ineligibilityReasons }) => {
      const color = ELIGIBILITY_COLORS[eligibility];

      return (
        <>
          <Tag color={color} style={{ fontWeight: 'bold' }}>
            {toUpper(eligibility)}
          </Tag>
          {eligibility === 'Ineligible' && !!ineligibilityReasons.length && (
            <>({ineligibilityReasons.join(', ')})</>
          )}
        </>
      );
    },
    renderString: ({ eligibility, ineligibilityReasons }) => {
      let formattedEligibility = toUpper(eligibility);

      if (eligibility === 'Ineligible' && !!ineligibilityReasons.length) {
        formattedEligibility += ` (${ineligibilityReasons.join(', ')})`;
      }

      return formattedEligibility;
    },
  },
  {
    title: 'Data Scrubbing',
    filters: [...DATA_SCRUB_REQUIREMENT_FILTERS, ...DATA_SCRUB_WARNING_FILTERS].map((filter) => {
      return ({
        value: filter,
        text: filter,
      });
    }),
    onFilter: (filter, record) => {
      // Ineligible residents don't require a data scrub
      return !record.ineligibilityReasons.length && (
        record.dataScrubRequirements.includes(String(filter)) ||
        record.dataWarnings.includes(String(filter))
      );
    },
    render: ({ ineligibilityReasons, dataScrubRequirements, dataWarnings }) => {
      if (ineligibilityReasons.length) {
        return []; // Ineligible residents don't require a data scrub
      }

      const requirements = dataScrubRequirements.map(message => ({ message, type: 'error' }));
      const warnings = dataWarnings.map(message => ({ message, type: 'warning' }));

      const combinedDataScrubbing = [...requirements, ...warnings];

      return (
        <>
          {combinedDataScrubbing.map(({ message, type }, index) => (
            <Alert
              key={message}
              showIcon
              type={type as any}
              message={message}
              style={{ marginBottom: index !== combinedDataScrubbing.length + 1 ? 10 : 0 }}
            />
          ))}
        </>
      );
    },
    renderString: ({ ineligibilityReasons, dataScrubRequirements, dataWarnings }) => {
      if (ineligibilityReasons.length) {
        return '';
      }

      return [...dataScrubRequirements, ...dataWarnings].join(', ');
    },
  },
  {
    title: 'Actions',
    render: (residentAudit) => {
      const { dataScrubRequirements, dataWarnings } = residentAudit;
      const { modal, openModal } = useResidentAuditComparisonModal(residentAudit);

      const combinedDataScrubbing = [...dataScrubRequirements, ...dataWarnings];
      const shouldShowModal = combinedDataScrubbing.includes('Chirp profile does not match PMS');

      if (!shouldShowModal) {
        return null;
      }

      return (
        <>
          {modal}
          <Button type='link' onClick={openModal} style={{ padding: 0 }}>
            Compare PMS to Chirp
          </Button>
        </>
      );
    },
  },
];
