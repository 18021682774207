import { Modal, Skeleton } from 'antd';
import React, { useState } from 'react';

import {
  PermissionScope_enum,
  usegetAllowedAndEnabledPermissionsQuery,
} from '../../../graphql/hasura/generated';

import {
  filterCategorizedPermissions,
  flattenPermissionCategories,
  getPermissionCategories,
} from './permission-categories';
import RolePermissionTable from './RolePermissionTable';
import { PermissionFilter } from './typings';

interface IRolePermissionModalProps {
  roleId: string;
  roleName: string;
  permissionScope: PermissionScope_enum;
  children: React.ReactNode;
}

export const RolePermissionModal: React.FC<IRolePermissionModalProps> = (props) => {
  const { roleId, roleName, permissionScope, children } = props;
  const [visible, setVisible] = useState(false);

  const { data, error, loading } = usegetAllowedAndEnabledPermissionsQuery({
    variables: { roleId, permissionScope },
    skip: !visible,
  });

  const allowedPermissions = data?.allowedPermissions || [];
  const enabledPermissions = data?.enabledPermissions || [];

  const enabledPermissionIds = enabledPermissions.map(p => p.permissionId);

  const categorizedPermissions = flattenPermissionCategories(
    getPermissionCategories(),
    allowedPermissions,
  );

  const filteredPermissions = filterCategorizedPermissions({
    categorizedPermissions,
    enabledPermissionIds,
    permissionFilter: PermissionFilter.SHOW_ENABLED,
  });

  return (
    <>
      <span onClick={() => setVisible(true)}>
        {children}
      </span>
      <Modal
        title={`${roleName} - Permissions`}
        visible={visible}
        maskClosable
        width='60%'
        onCancel={() => setVisible(false)}
        footer={null}
      >
        {(error || loading) && <Skeleton loading active />}
        {!(error || loading) && (
          <RolePermissionTable categorizedPermissions={filteredPermissions} readOnly />
        )}
      </Modal>
    </>
  )
};

export default RolePermissionModal;
