import { Steps } from 'antd';
import React from 'react';

import { TableActions } from '../../../components/TableActions/TableActions';
import { TableCellDescriptions } from '../../../components/TableCellDescriptions';
import {
  PermissionKey_enum,
  RoleKey_enum,
  UserRoleDetailsFragment as UserRole,
  UserRoleStatus_enum,
} from '../../../graphql/hasura/generated';
import { authentication } from '../../../stores';
import { getDescriptionsFromColumns } from '../../helpers/getDescriptionsFromColumns';
import * as pinCodeActions from '../../PinCode/actions';
import * as pinCodeColumns from '../../PinCode/columns';

export function shouldDisplayPropertyPinCode(userRole: UserRole): boolean {
  const { role, status, pinCodes } = userRole;
  const [propertyPinCode] = pinCodes;

  if (status === UserRoleStatus_enum.EXPIRED || status === UserRoleStatus_enum.REVOKED) {
    return false;
  }

  if (!authentication.hasPermission(PermissionKey_enum.PinCode_Read)) {
    return false;
  }

  return (
    (propertyPinCode && role.key === RoleKey_enum.GUEST_PROPERTY_KEY) ||
    (role.key === RoleKey_enum.VENDOR_PIN)
  );
}

export const PropertyPinCodeDescriptions: React.FC<{ userRole: UserRole }> = ({ userRole }) => {
  const { userRoleId, role, pinCodes } = userRole;
  const [propertyPinCode] = pinCodes;

  if (propertyPinCode && role.key === RoleKey_enum.GUEST_PROPERTY_KEY) {
    return (
      <Steps size='small'>
        <Steps.Step
          status='finish'
          title='Enabled for entries with keypads'
        />
      </Steps>
    );
  }

  if (role.key === RoleKey_enum.VENDOR_PIN) {
    return (
      <>
        <div style={{ float: 'right' }}>
          <TableActions row={{ userRoleId }} actions={[pinCodeActions.regenerateAction]} />
        </div>
        <div style={{ float: 'left' }}>
          {!propertyPinCode && (
            <Steps size='small'>
              <Steps.Step
                status='error'
                title='The property PIN code has been deleted'
              />
            </Steps>
          )}
          {propertyPinCode && (
            <TableCellDescriptions
              descriptions={getDescriptionsFromColumns(propertyPinCode, [
                pinCodeColumns.codeColumn,
                pinCodeColumns.createdByColumn,
                pinCodeColumns.updatedAtColumn,
              ])}
            />
          )}
        </div>
      </>
    );
  }

  return null;
};
