import { Form } from '@ant-design/compatible';
import { InputNumber } from 'antd';
import { FormikProps } from 'formik';
import React from 'react';

import EnumSelect from '../../../components/EnumSelect';
import {
  AccessPoint,
  AccessPointType_enum,
  usegetAccessPointRolesQuery,
} from '../../../graphql/hasura/generated';
import { FORM_ITEM_DEFAULT_LAYOUT } from '../../../pages/ModelForm/form-fields';
import { getFieldHelpText, getPropertyFeatureFlags } from '../../../utils';

import AccessPointRoleManager from './AccessPointRoleManager';
import AccessPointTypeDescription from './AccessPointTypeDescription';
import { getApplicableRolesByAccessPoint } from './helpers';

interface IAccessPointTypeManagerProps {
  formikProps: FormikProps<AccessPoint>;
  isNew: boolean;
}

const AccessPointTypeManager: React.FC<IAccessPointTypeManagerProps> = (props) => {
  const { formikProps, isNew } = props;
  const { accessPointId, type, maxOccupancy, archivedAt } = formikProps.values;

  const { data, refetch } = usegetAccessPointRolesQuery({
    skip: isNew,
    variables: {
      accessPointId,
      roleExp: getApplicableRolesByAccessPoint(accessPointId, type),
    },
  });

  const accessPointRoles = data?.accessPointRoles || [];
  const roles = data?.roles || [];

  const help = getFieldHelpText(formikProps, 'maxOccupancy');
  const validateStatus = help ? 'error' : undefined;

  const manageRolesEnabled = (
    !isNew &&
    formikProps.initialValues.type === type &&
    (
      type === AccessPointType_enum.COMMON_AREA ||
      type === AccessPointType_enum.RESTRICTED_WHITELIST ||
      type === AccessPointType_enum.RESTRICTED_BLACKLIST ||
      !!accessPointRoles.length
    )
  );

  const stratisProperty = getPropertyFeatureFlags(formikProps.values.property).COMMUNITY_CONNECT;

  return (
    <>
      <Form.Item
        {...FORM_ITEM_DEFAULT_LAYOUT}
        label='Type'
        required
      >
        <EnumSelect
          enumTable='AccessPointType'
          value={type}
          disabled={!!archivedAt}
          onChange={nextValue => {
            formikProps.setFieldValue('type', nextValue);

            if (nextValue !== AccessPointType_enum.RESERVATION_ONLY) {
              formikProps.setFieldValue('maxOccupancy', null);
            }
          }}
          filterOptions={options => {
            if (!stratisProperty) {
              return options;
            }

            return options.filter(
              (option) => option.value !== AccessPointType_enum.RESERVATION_ONLY
            );
          }}
        />
        <AccessPointTypeDescription type={type} />
      </Form.Item>

      {manageRolesEnabled && (
        <Form.Item
          {...FORM_ITEM_DEFAULT_LAYOUT}
          label={<div />} // Workaround to align the table within the layout
          colon={false}
        >
          <AccessPointRoleManager
            accessPoint={formikProps.values}
            accessPointRoles={accessPointRoles}
            roles={roles}
            refetch={refetch}
          />
        </Form.Item>
      )}

      {type === AccessPointType_enum.RESERVATION_ONLY && (
        <Form.Item
          {...FORM_ITEM_DEFAULT_LAYOUT}
          label='Max Occupancy'
          validateStatus={validateStatus}
          help={help}
          required
        >
          <InputNumber
            name='maxOccupancy'
            type='number'
            value={maxOccupancy || undefined}
            min={1}
            onChange={nextValue => formikProps.setFieldValue('maxOccupancy', nextValue)}
            style={{ width: '100%' }}
          />
        </Form.Item>
      )}
    </>
  );
};

export default AccessPointTypeManager;
