import { Button, Spin, Table, Typography } from 'antd';
import { FormikActions } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { apiClient } from '../../../../graphql';
import { HubMessage, usemessageSentFromHubSubscription } from '../../../../graphql/api/generated';
import { displayErrorMessage, formatTimestamp } from '../../../../utils';

import { FobFlow, ICredentialFormValues } from './typings';

interface IUnassignedCredentialHubMessage extends HubMessage {
  payload: {
    readerInput: string;
    relayId: string;
  }
}

interface IUnassignedFobScanned {
  messageId: HubMessage['messageId'];
  hubId: HubMessage['hubId'];
  readerInput: IUnassignedCredentialHubMessage['payload']['readerInput'];
  timestamp: HubMessage['timestamp'];
}

const ReaderEnrollmentFlow: FC<{
  values: ICredentialFormValues,
  setFieldValue: FormikActions<ICredentialFormValues>['setFieldValue'],
}> = ({ values, setFieldValue }) => {
  const { fobFlow } = values;

  const [unassignedFobScanned, setUnassignedFobScanned] = useState<IUnassignedFobScanned[]>([]);
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);

  const { data, error } = usemessageSentFromHubSubscription({
    client: apiClient,
    skip: fobFlow !== FobFlow.ENROLLMENT_READER || !values.ownedByPropertyId,
    variables: {
      input: {
        types: ['UNASSIGNED_CREDENTIAL_SCANNED'],
        propertyId: values.ownedByPropertyId,
      },
    },
  });

  const messageSentFromHub = data?.messageSentFromHub;
  const messageSentFromHubId = messageSentFromHub?.messageId;

  useEffect(() => {
    if (error) {
      displayErrorMessage(error);
    }
  }, [error]);

  useEffect(() => {
    setUnassignedFobScanned([]);
    setSelectedMessageId(null);
  }, [values.fobFlow, values.ownedByPropertyId]);

  useEffect(() => {
    if (messageSentFromHub) {
      (async function generateUnassignedFobScanned() {
        const unassignedFobLog: IUnassignedFobScanned = {
          messageId: messageSentFromHub.messageId,
          hubId: messageSentFromHub.hubId,
          readerInput: messageSentFromHub.payload.readerInput,
          timestamp: messageSentFromHub.timestamp,
        }

        setUnassignedFobScanned(prevUnassignedFobScanned => [unassignedFobLog, ...prevUnassignedFobScanned])
      })()
    }
  }, [messageSentFromHub, messageSentFromHubId]);

  return (
    <>
      <Typography.Paragraph style={{ textAlign: 'center' }}>
        {!values.ownedByPropertyId ? 'Please select a Property to listen for activity from' :
          <>
            <Spin style={{ marginRight: '10px' }} /> Listening for activity from unassigned fobs...
          </>
        }
      </Typography.Paragraph>
      <Table
        dataSource={unassignedFobScanned}
        pagination={false}
        rowKey={(record) => record.messageId}
        columns={[
          {
            title: 'Fob Identifier',
            key: 'readerInput',
            dataIndex: 'readerInput',
          },
          {
            title: 'Timestamp',
            key: 'timestamp',
            dataIndex: 'timestamp',
            render: (_, { timestamp }) => formatTimestamp(timestamp),
          },
          {
            key: 'selectedMessage',
            render: ({ messageId, readerInput }: IUnassignedFobScanned) => {
              if (selectedMessageId !== messageId) {
                return (
                  <Button
                    type='primary'
                    onClick={() => {
                      setSelectedMessageId(messageId);
                      setFieldValue('identifier', readerInput);
                    }}
                  >
                    Select Fob
                  </ Button >
                )
              }
              return (
                <Button
                  onClick={() => {
                    setSelectedMessageId(null)
                    setFieldValue('identifier', null)
                  }}
                >
                  Deselect
                </Button>
              )
            }
          },
        ]}
        style={{ marginBottom: '20px' }}
      />
    </>
  );
};

export default ReaderEnrollmentFlow;
