const env = process.env;

export const CHIRP_UNIVERSAL_LINK = env.REACT_APP_CHIRP_UNIVERSAL_LINK as string;
export const ENGRAIN_API_BASE_URL = env.REACT_APP_ENGRAIN_API_BASE_URL as string;
export const ENGRAIN_API_KEY = env.REACT_APP_ENGRAIN_API_KEY as string;
export const GRAPHQL_AUTH_SERVER = env.REACT_APP_GRAPHQL_AUTH_SERVER as string;
export const GRAPHQL_SERVER = env.REACT_APP_GRAPHQL_SERVER as string;
export const INTROSPECTION_TTL = +(env.REACT_APP_INTROSPECTION_TTL || (24 * 60 * 60 * 1000)); // Default = 1 day
export const INTROSPECTION_VERSION = env.REACT_APP_INTROSPECTION_VERSION as string; // For cache invalidation
export const MIN_CALENDAR_START_DATE = env.REACT_APP_MIN_CALENDAR_START_DATE || '2019-01-01';
export const MIN_VERSION_HOME_ACCESS_AUTH = '1.19.6';
export const PUBLIC_URL = env.PUBLIC_URL;
export const REALPAGE_EULA_URL = env.REACT_APP_REALPAGE_EULA_URL as string;
export const SENTRY_DSN = env.REACT_APP_SENTRY_DSN;
export const STRATIS_UNIVERSAL_LINK = env.REACT_APP_STRATIS_UNIVERSAL_LINK as string;
