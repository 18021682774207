import { ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Result } from 'antd';
import React from 'react';

import { DEFAULT_ERROR_MESSAGE, getDisplayedErrorMessage } from '../utils';

import BasicLayout from './BasicLayout';

interface IErrorLayoutProps {
  pageTitle: string;
  error?: any;
}

const ErrorLayout = ({ pageTitle, error }: IErrorLayoutProps) => {
  return (
    <BasicLayout pageTitle={pageTitle}>
      <Card
        style={{ marginBottom: 20 }}
        bordered={false}
      >
        <Result
          status='error'
          title={error ? getDisplayedErrorMessage(error) : DEFAULT_ERROR_MESSAGE}
          extra={
            <Button
              icon={<ReloadOutlined />}
              type='default'
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
          }
        />
      </Card>
    </BasicLayout>
  );
};

export default ErrorLayout;
