import validator from 'validator';

import {
  PermissionScope_enum,
  SmartLock_bool_exp,
  SmartLockActionFragment,
  SmartLockLabelFragment,
  SmartLockLabelFragmentDoc,
  SmartLockUniqueLabelFragment,
  SmartLockUniqueLabelFragmentDoc,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';
import { emptySearchConditionsFilter } from '../../utils';
import Model from '../Model';
import { UnitModel } from '../Unit/model';

export const SmartLockModel = new Model<
  SmartLock_bool_exp,
  SmartLockLabelFragment,
  SmartLockUniqueLabelFragment,
  SmartLockActionFragment
>({
  names: {
    schemaName: 'SmartLock',
  },

  labels: {
    labelFragment: SmartLockLabelFragmentDoc,
    getLabel: (s) => s.name,
    uniqueLabelFragment: SmartLockUniqueLabelFragmentDoc,
    getUniqueLabel: (s) => (
      `${UnitModel.labels.getUniqueLabel(s.unit)} - ${s.name}`
    ),
  },

  permissionsOptions: {
    canCreate: () => false,
  },

  queryOptions: {
    getTableCountLimit: () => {
      const permissionScope = authentication.currentDataScope.permissionScope;

      if (
        permissionScope === PermissionScope_enum.GLOBAL ||
        permissionScope === PermissionScope_enum.ORGANIZATION
      ) {
        return 100000; // Allow Camden to view/export all of their smart locks
      }

      return 10000;
    },
    getSearchConditions: (words) => ({
      _and: words.map((word) => {
        const searchConditions = getSearchConditionsByWord(word);

        return {
          _or: searchConditions,
        };
      }),
    }),
    getTableSearchConditions: (words) => ({
      _and: words.map((word) => {
        const searchConditions = getSearchConditionsByWord(word);

        return {
          _or: [
            ...searchConditions,
            {
              sourceId: { _eq: word.toUpperCase() },
            },
            {
              serialNumber: { _eq: word.toUpperCase() },
            },
          ],
        };
      }),
    }),
  },
});

export default SmartLockModel;

function getSearchConditionsByWord(word: string): SmartLock_bool_exp[] {
  const isNumeric = validator.isNumeric(word);

  return [
    {
      name: { _ilike: `%${word}%` },
    },
    {
      unit: {
        property: {
          name: { _ilike: `%${word}%` },
        },
      },
    },
    {
      unit: {
        unitNumber: { _ilike: !isNumeric ? `%${word}%` : undefined }, // Contains word
      },
    },
    {
      unit: {
        unitNumber: { _ilike: isNumeric ? `${word}%` : undefined }, // Starts with number
      },
    },
    {
      unit: {
        unitNumber: { _ilike: isNumeric ? `% ${word}` : undefined }, // Ends with space + number (e.g., "Unit 1")
      },
    },
  ].filter(emptySearchConditionsFilter);
}
