import { displayErrorMessage } from '../../../../../utils';

import paddingBit0 from './paddingBit0';

export default (facilityCode: string | null, cardNumber: string | null): string | null => {
  if (!facilityCode || !cardNumber) {
    return null;
  }

  if (Number.isInteger(+cardNumber) === false || +cardNumber < 0) {
    displayErrorMessage(new Error(`Card number must be a positive integer. Received "${cardNumber}"`));
    return null;
  }

  if (Number.isInteger(+facilityCode) === false || +facilityCode < 0) {
    displayErrorMessage(new Error(`Facility code must be a positive integer. Received "${facilityCode}"`));
    return null;
  }

  if (+facilityCode > 65535) {
    displayErrorMessage(
      new Error(`Facility Code ${facilityCode} is too large to encode in 37X-bit format. Maximum possible value is 65535.`)
    );

    return null;
  }

  if (+cardNumber > 524287) {
    displayErrorMessage(
      new Error(`Card number ${cardNumber} is too large to encode in 37X-bit format. Maximum possible value is 524287.`)
    );

    return null;
  }

  const fcBit = parseInt(facilityCode, 10).toString(2);
  const fullFCBit = paddingBit0(fcBit, 16);

  const cardNumberBit = parseInt(cardNumber, 10).toString(2);
  const fullCardNumberBit = paddingBit0(cardNumberBit, 19);

  const bit = fullFCBit + fullCardNumberBit;
  const bitArray = bit.split('');
  bitArray.unshift('0');
  bitArray.push('0');

  const sumEvenBits = bitArray.reduce((a, b, index) => {
    if (index <= 18) {
      return Number(a) + Number(b);
    }

    return Number(a) + 0;
  }, 0);

  if (sumEvenBits % 2 === 1) {
    bitArray[0] = '1';
  }

  const oddEvenBits = bitArray.reduce((a, b, index) => {
    if (index >= 18 && index <= 36) {
      return Number(a) + Number(b);
    }

    return Number(a) + 0;
  }, 0);

  if (oddEvenBits % 2 === 0) {
    bitArray[36] = '1';
  }

  return parseInt(bitArray.join(''), 2).toString(16);
};
