import { Form } from '@ant-design/compatible';
import { Button, notification } from 'antd';
import Select from 'antd/lib/select';
import { FormikErrors, FormikProps, withFormik } from 'formik';
import React from 'react';

import DurationField from '../../../components/DurationField';
import { DurationUnit } from '../../../components/DurationField/helpers';
import { PermissionScope_enum, Role } from '../../../graphql/hasura/generated';
import {
  EnumFormSelect,
  FORM_ITEM_DEFAULT_LAYOUT,
  FormItem,
  TextAreaField,
  TextField,
} from '../../../pages/ModelForm/form-fields';
import ModelFormSelect from '../../../pages/ModelForm/ModelFormSelect';
import { onSubmitForm } from '../../../pages/ModelForm/submit';
import { authentication } from '../../../stores';
import { DEFAULT_ERROR_MESSAGE, getErrorInfo } from '../../../utils';
import OrganizationModel from '../../Organization/model';
import model from '../model';

import { deprecatedTypeOptions } from './deprecated-types';

type FormValues = Partial<Pick<Role,
  'roleId' | 'organizationId' | 'name' | 'uniqueName' | 'permissionScope' |
  'description' | 'maxAccessDurationSeconds' | 'deprecatedType'
>>;

interface IRoleFormProps {
  isNew: boolean;
  role?: FormValues;
  onCancel: () => any;
  onSubmit: (roleId: string) => any;
}

interface IRoleFormWithFormikProps extends IRoleFormProps, FormikProps<FormValues> {}

const RoleForm: React.FC<IRoleFormWithFormikProps> = (props) => {
  const { isNew, role, onCancel, onSubmit, ...formikProps } = props;
  const { values, setFieldValue, resetForm, isSubmitting, handleSubmit } = formikProps;

  const handleCancelClick = () => {
    onCancel();
    resetForm();
  };

  const { deprecatedType, permissionScope } = values;
  const isSelectableDeprecatedType = deprecatedType && (
    deprecatedTypeOptions.some(o => o.value === deprecatedType)
  );

  const canEditDeprecatedType = (
    (!deprecatedType || isSelectableDeprecatedType) &&
    permissionScope === PermissionScope_enum.PROPERTY &&
    authentication.currentDataScope.permissionScope === PermissionScope_enum.GLOBAL
  );

  return (
    <Form style={{ marginTop: 8 }} onSubmit={handleSubmit}>
      {isNew && (
        <ModelFormSelect
          fieldName='organizationId'
          formItemProps={{ ...FORM_ITEM_DEFAULT_LAYOUT }}
          modelSelectProps={{
            value: values.organizationId,
            model: OrganizationModel,
            required: true,
            autoSelectSingleResult: true,
          }}
          setFieldValue={setFieldValue}
          errors={formikProps.errors}
          submitCount={formikProps.submitCount}
        />
      )}

      <TextField
        {...formikProps}
        required
        isNew={isNew}
        field={model.introspection.getField('name')}
        maxLength={50} // @TODO: Get maxLength from form options
      />

      <TextField
        {...formikProps}
        isNew={isNew}
        field={model.introspection.getField('uniqueName')}
        maxLength={50} // @TODO: Get maxLength from form options
        extra={
          <div className='help-text'>
            <em>
              Unique name is useful to differentiate roles that have
              the same name but different permission scopes.
            </em>
          </div>
        }
      />

      {isNew && (
        <EnumFormSelect
          enumSelectProps={{
            enumTable: 'PermissionScope',
            value: values.permissionScope,
            onChange: (nextValue) => {
              setFieldValue('permissionScope', nextValue);
            },
            filterOptions: (options) => {
              return options.filter(o => (
                ([
                  PermissionScope_enum.ORGANIZATION,
                  PermissionScope_enum.PROPERTY,
                  PermissionScope_enum.UNIT,
                ] as string[]).includes(o.value)
              ))
            },
          }}
          formItemProps={{
            ...formikProps,
            isNew,
            field: model.introspection.getField('permissionScope'),
          }}
        />
      )}

      <TextAreaField
        {...formikProps}
        isNew={isNew}
        field={model.introspection.getField('description')}
        maxLength={4096} // @TODO: Get maxLength from form options
      />

      <FormItem
        {...formikProps}
        isNew={isNew}
        field={model.introspection.getField('maxAccessDurationSeconds')}
        label='Maximum Access Duration'
      >
        <DurationField
          valueInSeconds={values.maxAccessDurationSeconds}
          onChange={(value) => setFieldValue('maxAccessDurationSeconds', value)}
          durationUnits={[
            DurationUnit.HOUR,
            DurationUnit.DAY,
            DurationUnit.WEEK,
            DurationUnit.MONTH,
          ]}
        />
        <div className='help-text'>
          <em>
            Optionally set a maximum duration of time the role can be granted access.
          </em>
        </div>
      </FormItem>

      {canEditDeprecatedType && (
        <FormItem
          {...formikProps}
          isNew={isNew}
          field={model.introspection.getField('deprecatedType')}
          label='Access App Role'
        >
          <Select
            value={values.deprecatedType || undefined}
            onChange={(nextValue, option) => {
              setFieldValue('deprecatedType', nextValue || null);
            }}
            allowClear
            options={deprecatedTypeOptions}
            virtual={false} // There is a visual bug with white space when reselecting
          />
          <div className='help-text'>
            <em style={{ display: 'block', marginBottom: 7.5 }}>
              Optionally assign an Access App Role to make staff features visible in the Chirp Access App.
            </em>
            <em>(Permissions still need to be enabled for creating unit keys, managing smart locks, and adjusting reader power.)</em>
          </div>
        </FormItem>
      )}

      <section style={{ marginTop: 50, display: 'flex', justifyContent: 'center' }}>
        <Button
          type='primary'
          htmlType='submit'
          loading={isSubmitting}
          disabled={isSubmitting}
          style={{ marginRight: 10 }}
        >
          {isSubmitting ? 'Saving' : 'Save'}
        </Button>

        <Button onClick={handleCancelClick} loading={isSubmitting} disabled={isSubmitting}>
          Cancel
        </Button>

      </section>
    </Form>
  );
}

const RoleFormWithFormik = withFormik<IRoleFormProps, FormValues>({
  mapPropsToValues: ({ role }) => role ? { ...role } : {},
  validate: (values, { isNew }) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.name) {
      errors.name = 'Please provide a name';
    }

    if (isNew) {
      if (!values.organizationId) {
        errors.organizationId = 'Please select an organization';
      }

      if (!values.permissionScope) {
        errors.permissionScope = 'Please select a permission scope';
      }
    }

    return errors;
  },
  handleSubmit: async (values, formikBag) => {
    try {
      const data = await onSubmitForm(values, formikBag, model, formikBag.props.isNew, (options) => ({
        ...options,
        refetchQueries: [],
      }));

      const roleId = (data as any)[Object.keys(data)[0]].roleId;

      formikBag.props.onSubmit(roleId);
    } catch (error) {
      // @TODO: Try to reuse code from ModelForm page
      const { friendlyErrorMessage, formErrors } = getErrorInfo(error);

      if (formErrors) {
        formikBag.setErrors(formErrors);
      }

      notification.error({
        message: 'Error',
        description: friendlyErrorMessage || DEFAULT_ERROR_MESSAGE,
      });
    }
  },
  enableReinitialize: true,
  validateOnChange: false,
  validateOnBlur: false,
})(RoleForm);

export default RoleFormWithFormik;
