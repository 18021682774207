import {
  PermissionScope_enum,
  RoleKey_enum,
  UserRoleReason_enum,
} from '../../graphql/hasura/generated';
import { authentication } from '../../stores';

interface IGrantAccessPageQueryParams {
  userId?: string | null;
  permissionScope?: PermissionScope_enum | null;
  scopedIds?: string[] | null | undefined;
  roleKey?: RoleKey_enum | null;
  reason?: UserRoleReason_enum | null;
}

export function buildGrantAccessPageUrl(params: IGrantAccessPageQueryParams) {
  const searchParams = new URLSearchParams();

  if (params.userId) {
    searchParams.set('userId', params.userId);
  }

  if (params.permissionScope) {
    searchParams.set('permissionScope', params.permissionScope);
  }

  if (params.scopedIds) {
    for (const scopedId of params.scopedIds) {
      searchParams.append('scopedIds', scopedId);
    }
  }

  if (params.roleKey) {
    searchParams.set('roleKey', params.roleKey);
  }

  if (params.reason) {
    searchParams.set('reason', params.reason);
  }

  const searchString = searchParams.toString();

  return `/grant-access${searchString.length ? `?${searchString}` : ''}`;
}

function getUnitKeyUrlParams(unitIds: string[], reason?: UserRoleReason_enum) {
  return {
    reason,
    userId: authentication.user?.userId,
    permissionScope: PermissionScope_enum.UNIT,
    scopedIds: unitIds,
    roleKey: RoleKey_enum.STAFF_UNIT_KEY,
  };
}

export function buildLockResetUnitKeyUrl(unitIds: string[]) {
  return buildGrantAccessPageUrl({
    ...getUnitKeyUrlParams(unitIds, UserRoleReason_enum.TURNOVER_LOCK_RESET),
  });
}

export function buildLowBatteryUnitKeyUrl(unitIds: string[]) {
  return buildGrantAccessPageUrl({
    ...getUnitKeyUrlParams(unitIds, UserRoleReason_enum.SMART_LOCK_LOW_BATTERY),
  });
}

export function buildLockSyncUnitKeyUrl(unitIds: string[]) {
  return buildGrantAccessPageUrl({
    ...getUnitKeyUrlParams(unitIds, UserRoleReason_enum.SMART_LOCK_SYNC),
  });
}
