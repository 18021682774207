import { PlusOutlined } from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import { Button, Modal, Tabs } from 'antd';
import { orderBy } from 'lodash';
import React, { useState } from 'react';

import {
  AccessPoint,
  AccessPointRoleTableFragment,
  AccessPointType_enum,
  getAccessPointRolesQuery,
  PermissionKey_enum,
  PermissionScope_enum,
  RoleWithStaffPermissionsFragment,
} from '../../../graphql/hasura/generated';
import { AccessPointRoleModel } from '../../AccessPointRole/model';
import { PERMISSION_SCOPE_WEIGHTS } from '../../helpers/permission-scopes';

import AccessPointRoleForm from './AccessPointRoleForm';
import {
  AccessPointRoleTable,
  IAccessPointRoleEditArgs,
  IAccessPointRoleRow,
} from './AccessPointRoleTable';

interface IAccessPointRoleManagerProps {
  accessPoint: AccessPoint;
  accessPointRoles: AccessPointRoleTableFragment[];
  roles: RoleWithStaffPermissionsFragment[];
  refetch(): Promise<ApolloQueryResult<getAccessPointRolesQuery>>;
}

interface IScreenState {
  screen: 'TABLE' | 'FORM';
  formProps?: {
    isNew: boolean;
  } & IAccessPointRoleEditArgs;
}

const AccessPointRoleManager: React.FC<IAccessPointRoleManagerProps> = (props) => {
  const { accessPoint, accessPointRoles, refetch } = props;
  const [tabKey, setTabKey] = useState<'MANUAL' | 'AUTO'>('MANUAL');
  const [visible, setVisible] = useState(false);
  const [screenState, setScreenState] = useState<IScreenState>({ screen: 'TABLE' });

  const isBlacklist = accessPoint.type === AccessPointType_enum.RESTRICTED_BLACKLIST;
  const isWhitelist = accessPoint.type === AccessPointType_enum.RESTRICTED_WHITELIST;

  const readOnly = (
    !!accessPoint.archivedAt || (
      !AccessPointRoleModel.permissions.canCreate() &&
      !AccessPointRoleModel.permissions.canUpdate() &&
      !AccessPointRoleModel.permissions.canDelete()
    )
  );

  const sortedRoles = orderBy(props.roles, [
    r => r.organization?.name,
    r => PERMISSION_SCOPE_WEIGHTS[r.permissionScope],
    r => r.name,
  ], ['asc', 'asc']);

  const autoWhitelistedRoles = sortedRoles.filter(r => (
    r.permissionScope === PermissionScope_enum.ACCESS_POINT ||
    r.rolePermissions.some(rp => rp.permission.key === PermissionKey_enum.AccessPoint_StaffAccess)
  ));

  const accessPointRoleRows = accessPointRoles.map(accessPointRole => {
    const role = sortedRoles.find(r => r.roleId === accessPointRole.role.roleId);

    if (!role) {
      return null;
    }

    return { role, accessPointRole };
  })
  .filter(Boolean)
  .filter(a => !!a?.accessPointRole.hasAccess === !isBlacklist) as IAccessPointRoleRow[];

  const manualRoleRows = accessPointRoleRows.filter(a => (
    !autoWhitelistedRoles.some(r => r.roleId === a.role.roleId)
  ));

  const autoWhitelistedRoleRows = autoWhitelistedRoles.map(role => ({
    role,
    accessPointRole: accessPointRoleRows.find(a => a.role.roleId === role.roleId)?.accessPointRole,
  }));

  function onEditRow({ accessPointRole, selectedRoleId }: IAccessPointRoleEditArgs) {
    setScreenState({
      screen: 'FORM',
      formProps: {
        accessPointRole,
        selectedRoleId,
        isNew: accessPointRole ? false : true,
      },
    });
  }

  let modalScreen = null;

  if (screenState.screen === 'TABLE') {
    const manualRolesScreen = (
      <>
        {!readOnly && AccessPointRoleModel.permissions.canCreate() && (
            <Button
              type='dashed'
              style={{ width: '100%', margin: '24px 0' }}
              icon={<PlusOutlined />}
              onClick={() => {
                setScreenState({
                  screen: 'FORM',
                  formProps: { isNew: true },
                });
              }}
            >
              Add Role
            </Button>
        )}
        {accessPoint.type === AccessPointType_enum.COMMON_AREA && (
          <div className='help-text' style={{ marginBottom: 25 }}>
            For common areas, all roles with property access are automatically whitelisted.
            Roles only need to be manually configured for setting custom schedules or
            for cross-property access.
          </div>
        )}
        <AccessPointRoleTable
          accessPoint={accessPoint}
          rows={manualRoleRows}
          onEditRow={onEditRow}
          refetch={refetch}
          readOnly={readOnly}
          filterColumns={isBlacklist ? c => c.title !== 'Schedule' : undefined}
        />
      </>
    );

    modalScreen = (
      <>
        {isWhitelist && (
          <Tabs
            animated={false}
            activeKey={tabKey}
            onChange={nextActiveTab => setTabKey(nextActiveTab as any)}
          >
            <Tabs.TabPane
              tab='Manually Whitelisted'
              key='MANUAL'
            >
              {manualRolesScreen}
            </Tabs.TabPane>
            <Tabs.TabPane tab='Automatically Whitelisted' key='AUTO'>
              <AccessPointRoleTable
                accessPoint={accessPoint}
                rows={autoWhitelistedRoleRows}
                onEditRow={onEditRow}
                refetch={refetch}
                readOnly={readOnly}
                filterColumns={c => c.title !== 'Unit Group'}
              />
            </Tabs.TabPane>
          </Tabs>
        )}
        {!isWhitelist && manualRolesScreen}
      </>
    );
  } else {
    modalScreen = (
      <AccessPointRoleForm
        isNew={!!screenState.formProps?.isNew}
        accessPoint={accessPoint}
        hasAccess={!isBlacklist}
        selectedRoleId={screenState.formProps?.selectedRoleId}
        accessPointRole={screenState.formProps?.accessPointRole}
        roles={sortedRoles}
        onCancel={() => setScreenState({ screen: 'TABLE' })}
        onSubmit={() => setScreenState({ screen: 'TABLE' })}
      />
    );
  }

  return (
    <>
      <div style={{ lineHeight: 'normal', marginBottom: 10 }}>
        {!isBlacklist && (
          <>
            <div>
              <strong>{manualRoleRows.length}</strong> roles manually {isWhitelist ? 'whitelisted' : 'configured'}
            </div>
            {isWhitelist && (
              <div><strong>{autoWhitelistedRoleRows.length}</strong> roles automatically whitelisted</div>
            )}
          </>
        )}
        {isBlacklist && (
          <div><strong>{manualRoleRows.length}</strong> roles blacklisted</div>
        )}
      </div>
      <div style={{ lineHeight: 'normal' }}>
        <a
          onClick={(e) => {
            e.preventDefault();
            setVisible(true);
          }}
        >
          {readOnly ? 'View' : 'Manage'} Roles
        </a>
      </div>
      <Modal
        title={`${readOnly ? '' : 'Manage '}${isBlacklist ? 'Blacklisted' : 'Whitelisted'} Roles`}
        visible={visible}
        maskClosable
        width='75%'
        footer={null}
        onCancel={() => setVisible(false)}
      >
        {modalScreen}
      </Modal>
    </>
  );
};

export default AccessPointRoleManager;
