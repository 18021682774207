type Validator = (values: any) => { [k: string]: any };

export default function combineValidators(...validators: Array<Validator>): Validator {
  return (values) => {
    return validators.reduce((prevErrors, currentValidator) => {
      const errors = currentValidator(values);
      return { ...prevErrors, ...errors };
    }, {});
  };
}
